import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CircleActionEnum} from 'src/app/objects/CircleActionEnum';

@Component({
  selector: 'app-circle-menu',
  templateUrl: './circle-menu.component.html',
  styleUrls: ['./circle-menu.component.scss']
})
export class CircleMenuComponent implements OnInit {
  ACTION = CircleActionEnum;
  @Input() user: {isAdmin: string, isOperator: string, isUser: string, username: string};
  @Output() actionEmitter = new EventEmitter<number>();
  isChecked = false;
  constructor() { }

  ngOnInit(): void {
  }

}
