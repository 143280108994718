<button [class]="'btn btn-cart pr-1 ' + ((showCart) ? 'show' : ((showCart === null) ? '' : 'noshow'))"
        (click)="showMenu()"><i class="fa fa-shopping-cart mr-1"></i>
  <span *ngIf="buyProducts.length !== 0">
    {{calcCount(buyProducts)}}
  </span>
</button>
<div [class]="'menu-carrello ' + ((showCart) ? 'show' : '')">
  <div class="p-2">
    <h4>Il mio carrello</h4>
    <div class="alert alert-warning" role="alert" *ngIf="buyProducts.length === 0">
      Nessun prodotto scelto da acquistare
    </div>
    <div *ngIf="buyProducts.length > 0">
      <div>
        <label>Totale provvisorio: &nbsp;</label><label class="tx-21"><b> {{calcTotal() | convertPrice}}</b></label>

        <button class="btn btn-primary w-100" (click)="communicatorService.backdropShow$.next(false)"
                routerLink="/pay-cart">Procedi all'ordine
        </button>
      </div>
      <div class="items-cart container-fluid">
        <div class="row" *ngFor="let product of buyProducts; let i = index">
          <div class="col-12">
            <hr>
          </div>
          <div class="col-12" *ngIf="i === 0">
            <label class="text-black-50">Fornitore: &nbsp;</label> <a
            [routerLink]="['../../../store/', product.username]">{{product.username}}</a>
          </div>
          <div class="col-4">
            <img class="w-100" [src]="'https://www.weddingsmart.it/api/images/products/' + product.imgname">
          </div>
          <div class="col-8">
            <app-product-cart-item [product]="product"></app-product-cart-item>
          </div>
          <div class="col-12" [ngClass]="{'mt-3' : product.type === '0', 'mt-less-3' : product.type === '1'}">
            <div class="row">

              <div class="col-auto">
                <div class="input-group">
                  <div class="input-group-prepend" (click)="removeItemToCart(i)">
                    <button class="btn" type="button" id="remove"><i class="fa fa-trash"></i></button>
                  </div>
                  <input type="text" class="form-control bg-white" readonly [(ngModel)]="product.quantity"
                         style="width: 32px;" *ngIf="product.type === '0'">
                  <div class="input-group-append" *ngIf="product.type === '0'">
                    <button [disabled]="product.quantity === 1" class="btn" type="button" id="minus"
                            (click)="product.quantity = product.quantity - 1;saveProduct()"><i
                      class="fa fa-minus"></i></button>
                    <button [disabled]="product.quantityMax === product.quantity" class="btn rounded-right"
                            type="button" id="add"
                            (click)="product.quantity = product.quantity + 1;saveProduct()"><i
                      class="fa fa-plus"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-payment text-center">
        <h6>PAGAMENTI SICURI CON</h6>
        <img src="https://www.weddingsmart.it/assets/images/footer_pagamenti.png">
      </div>
    </div>
  </div>
</div>
