import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import {isPlatformBrowser} from '@angular/common';
import {IOfferDetail} from '../../pages/offer-detail/IOffer';

@Component({
  selector: 'app-offer-image-gallery',
  templateUrl: './offer-image-gallery.component.html',
  styleUrls: ['./offer-image-gallery.component.scss']
})
export class OfferImageGalleryComponent implements OnInit {
  @Input() offer: IOfferDetail;
  images: {isHorizontal: boolean, data: string}[] = [];
  currentImage;
  innerWidth = 769;
  indexSelected = 0;
  xyZoom = [0,0];
  showZoom = false;
  constructor(@Inject(PLATFORM_ID) private platformId: object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    for (const key of ['imgname', 'imgname2']) {
      if (this.offer[key] && this.offer[key].length > 0) {
        this.images.push({isHorizontal: false, data: this.offer[key]});
      }
    }
    this.currentImage = this.images[0];
  }

  swipe(carousel: NgbCarousel, isIncrement: boolean) {
    if (isIncrement) {
      carousel.next();
    } else {
      carousel.prev();
    }
  }

  zoomImage(event: MouseEvent) {
    this.xyZoom = [event.offsetX / 1.4, event.offsetY / 1.4]
  }
}
