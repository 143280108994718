import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {ActivatedRoute} from '@angular/router';
import {IStore} from '../../objects/IStore';
import {IProduct} from '../../objects/IProduct';
import {Meta, Title} from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';
import {map, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CommunicatorService} from '../../services/communicator.service';
import {PopupInputComponent} from '../../components/popup/popup-input/popup-input.component';
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-fornitore-store',
  templateUrl: './fornitore-store.component.html',
  styleUrls: ['./fornitore-store.component.scss']
})
export class FornitoreStoreComponent implements OnInit {
  filter: {search: '', idmacrocategory: string} = {search: '', idmacrocategory: ''};
  innerWidth: number;

  storeProducts$: Observable<{store: IStore, products: IProduct[]}>;
  isRow = true;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private httpService: HttpService,
              public communicator: CommunicatorService, private modalService: NgbModal,
              private activatedRoute: ActivatedRoute, private title: Title, private meta: Meta) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    this.storeProducts$ = this.activatedRoute.paramMap.pipe(
      map(e => e.get('fornitore')),
      switchMap(fornitore => this.httpService.getStoreProducts(fornitore).pipe(
        tap(resp => {
          this.title.setTitle('Wedding Smart: ' + resp.store.title);
          this.meta.updateTag({
            description: resp.store.description
          });
        })
      )
      ));
  }

  changeCategory(category: string) {
    this.filter.idmacrocategory = (this.filter.idmacrocategory === category) ? '' : category;
  }

  changeViewType(isRow = true) {
    this.isRow = isRow;
  }

  segnala(id: string) {

    const rf = this.modalService.open(PopupInputComponent);
    rf.componentInstance.title = 'Descrivi il motivo della segnalazione';
    rf.componentInstance.closeDescription.subscribe((text: string) => {
      rf.close();
      if (text.length > 0) {
        this.httpService.postWarning({idref: +id, text, table: 'store'}).subscribe(resp => {
          if (resp && resp.result) {
            const mr2 = this.modalService.open(PopupComponent);
            mr2.componentInstance.title = 'Attenzione';
            mr2.componentInstance.body = 'Segnalazione effettuata con successo';
          }
        });
      }
    });
  }
}
