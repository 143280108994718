import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-nocan-auctions',
  templateUrl: './nocan-auctions.component.html',
  styleUrls: ['./nocan-auctions.component.scss']
})
export class NocanAuctionsComponent implements OnInit {
  @Input() stringArray: string[];
  constructor(
    private router: Router,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  changeUrl() {
    this.activeModal.close();
    this.router.navigateByUrl('/profile?showError=1');
  }
}
