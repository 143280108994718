<div class="bg-light pt-5 pb-5">

  <div class="container">
    <div class="card">
      <div class="card-body">
        <h6>Dati di spedizione o fruizione del servizio</h6>
        <div class="row">
          <div class="col">

            <label class="text-black-50 mb-0" style="font-size: .8rem;">{{address.fullname}}</label><br>
            <label class="text-black-50 mb-0" style="font-size: .8rem;">{{address.address + ' ' + address.number + ', ' + address.cap + ', ' + address.city}}</label><br>
            <label class="text-black-50 mb-0" style="font-size: .8rem;">{{address.phone}}</label>
          </div>
          <div class="col-auto">
            <button class="btn btn-primary btn-sm float-right rounded-0" (click)="showConfirm()"><i
              class="fa fa-edit mr-1"></i>Modifica
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <h5>RIEPILOGO ORDINE</h5>
        <div class="row" *ngFor="let pr of buyProducts">
          <div class="col-12 border-top mb-3 pt-2">
            <div class="row">
              <div class="col-12">
                <app-product-cart-item [product]="pr.prod" [isCart]="true" [iva]="spedition.store.sector_1_iva"></app-product-cart-item>
              </div>
            </div>
            <div class="row" *ngIf="pr.bonus">
              <div class="col text-black-50 font-weight-bold pr-0">
                <small>Bonus sposi Wedding Smart</small>
              </div>
              <div class="col-auto text-right text-black-50 font-weight-bolder">
                <span class="text-success font-weight-bold">-{{pr.percent}}</span> - {{pr.bonus | convertPrice:false:true}}
              </div>
            </div>
          </div>
          <hr>
        </div>
        <div class="row border-top mb-3 pt-2" *ngIf="spedition && spedition.store">
          <div class="col-12">
            <h5>Consegna prevista tra <span class="text-capitalize">{{spedition.store.minspedition}} - {{spedition.store.maxspedition}}</span></h5>
            <span class="font-weight-bolder">Tipo spedizione: </span> <span>Standard</span><br>
            <span class="font-weight-bolder">Corriere: </span> <span>{{spedition.store.speditionby}}</span>
          </div>
        </div>
        <div class="row border-top mb-3 pt-2">
          <div class="col-12">
            <div class="row">
              <div class="col text-black-50 font-weight-bolder text-right" style="font-size: .9rem;">
                Totale parziale
              </div>
              <div class="col-5 text-right text-black-50 font-weight-bolder">
                {{(lessTotal()) + '' | convertPrice:false:true}}
              </div>
            </div>
            <div class="row">
              <div class="col text-black-50 font-weight-bolder text-right" style="font-size: .9rem;">
                Spedizione (standard)
              </div>
              <div class="col-5 text-right text-black-50 font-weight-bolder">
                {{shipping | convertPrice:false:true}}
              </div>
            </div>
          </div>
        </div>
        <div class="row border-top">
          <div class="col text-right color-blue">
            <b>Totale</b> <span> (Iva inc.)</span>
          </div>
          <div class="col-5 text-right my-auto">
            <label class="price mb-0">{{amount.value | convertPrice:false:true}}</label>
          </div>
        </div>
        <h5 class="mt-3">Modalità di pagamento disponibili</h5>
        <div class="text-center">
          <div *ngIf="payPalConfig; else loader">
            <ngx-paypal [config]="payPalConfig" (scriptLoaded)="checkLoad($event)"></ngx-paypal>
          </div>
          <ng-template style="height: 260px;" class="text-center pt-5" #loader>
            <div class="spinner-border color-primary mt-5" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

</div>
