<div class="item-order" *ngFor="let item of items">
  <ng-container *ngIf="item.auctions">
    <h6 class="pl-2 title-group mt-2">{{item.sector}}</h6>
    <ng-container *ngFor="let auction of item.auctions">

      <div class="position-relative">
        <label class="title-item mb-0">
          <ng-container *ngIf="auction['orderid'] as order">
            Ordine #{{order}}<br>
          </ng-container>
          {{auction.title}}
        </label>
        <label class="auction-code" [routerLink]="'/auctions/detail/' + auction.id">Cod. {{auction.code}}</label>

        <span class="state-order font-weight-bold" *ngIf="!auction.reso && auction.states">
        {{auction.states[0].state_order}}
      </span>
      </div>
      <div class="row mt-less-5">
        <div class="col pr-0">
          <label class="pl-2 small-font budget text-black-50 mb-0">Il mio budget</label>
        </div>
        <div class="col-auto text-right">
          <b style="font-size: 1rem;" class="pl-2 text-black-50 mr-3">{{auction.budget_num | convertPrice:false:true}}</b>
        </div>
      </div>
      <div class="row mt-less-5">
        <div class="col pr-0">
          <label class="pl-2 small-font budget text-black-50 mb-0">Offerta accettata di <a
            [routerLink]="'/store/' + auction.desc_sector + '/' + item.sector + '/' + auction.seller">{{auction.seller}}</a>
          </label>
        </div>
        <div class="col-auto text-right">
          <b style="font-size: 1rem;" class="pl-2 text-black-50 mr-3">{{auction.offer_num | convertPrice:false:true}}</b>
        </div>
      </div>

      <div class="row mt-less-5" *ngIf="auction.bonus">
        <div class="col"><label class="pl-2 small-font budget text-black-50 mb-0">Bonus Sposi Wedding
          Smart</label>
        </div>
        <div class="col-auto text-right"><b style="font-size: 1rem;" class="text-black-50 mr-3">
          <b style="font-size: 0.8rem;"
             class="text-success">-{{((+auction['bonus'] * 100) / (+auction['offer_num'])).toFixed(2)}}%</b>
          - {{auction.bonus + '' | convertPrice:false:true}}</b>
        </div>
      </div>
      <div class="row mt-less-5" *ngIf="auction['costshipping'] != null">
        <div class="col">
          <label class="pl-2 small-font budget text-black-50 mb-0">Costo di spedizione</label>
        </div>
        <div class="col-auto text-right">
          <b style="font-size: 1rem;" class="text-black-50 mr-3" *ngIf="auction['costshipping'] !== 0 else free">
            {{auction['costshipping'] + '' | convertPrice:false:true}}
          </b>
          <ng-template #free>Gratuito</ng-template>
        </div>
      </div>

      <hr class="hr-total">

      <div class="row mt-less-5" *ngIf="auction['total']">
        <div class="col text-right color-blue">
          <b>Totale</b> <span> (Iva inc.)</span>
        </div>
        <div class="col-auto text-right mt-auto"><b style="font-size: 1.4rem;"
                                                    class="mr-3">{{auction.total + '' | convertPrice:false:true}} </b>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <div class="position-relative d-inline-block mb-less-5">
            <img src="https://www.weddingsmart.it/assets/icons/perc_discount.png" width="90">
            <ng-container *ngIf="auction['total']">
              <label class="sum_percentage" [style.left.px]="total.length === 4 ? 19 : 17"
                     *ngIf="(-((+auction['total']) * 100 / (+auction['budget_num']) - 100)).toFixed(2) as total">

                <span class="less" [style.left.px]="total.length === 4 ? -3 : -7">-</span>{{total}}<span class="perc">%</span>
              </label>
            </ng-container>
            <ng-container *ngIf="!auction['total']">
              <label class="sum_percentage" [style.left.px]="total.length === 4 ? 19 : 17"
                     *ngIf="(-((+auction['offer_num']) * 100 / (+auction['budget_num']) - 100)).toFixed(2) as total">
                <span class="less" [style.left.px]="total.length === 4 ? -3 : -7">-</span>{{total}}<span class="perc">%</span>
              </label>
            </ng-container>
          </div>
          <small class="sum_perc_desc">su questo ordine<br>rispetto al budget</small>
        </div>
        <div class="col-auto mt-auto text-right pr-4">
          <button class="btn btn-success pr-1 pl-1" [routerLink]="'/order-detail/auction/' + auction.id"
                  *ngIf="auction['winner_payed'] === '1'">Dettaglio ordine
          </button>
          <button class="btn btn-success pr-1 pl-1" [routerLink]="'/auctions/detail/' + auction.id"
                  *ngIf="auction['winner_payed'] !== '1'">Paga ora
          </button>
        </div>
      </div>
      <hr class="divisor mt-1">
    </ng-container>
  </ng-container>

</div>
