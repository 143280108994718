<div class="modal-body">
  <div class="card">
    <div class="card-body p-0">
      <div class="row no-gutters" *ngIf="!showDescription else description">
        <div class="col-5 col-md-3 my-auto">
          <img class="w-100" [src]="newAuction.image.imgname ? 'https://www.weddingsmart.it/api/images/auctions/' + newAuction.image.imgname : newAuction.image.data">
        </div>
        <div class="col">
          <div class="card-body position-relative pt-1 pt-md-3 pr-0">
            <span class="badge badge-success">0 Proposte</span> <span class="auction-code">Cod. {{newAuction.code ? newAuction.code : 'XXXXX'}}</span>
            <div class="mt-body-1">
              <h6 class="card-title">{{newAuction.title}}</h6>
              <small class="text-black-50 font-weight-bold">Località:</small>
              <label class="font-weight-bold ml-1">{{newAuction.city}}</label><br>

              <small class="text-black-50 font-weight-bold">Budget:</small>
              <label class="font-weight-bold ml-1">{{newAuction.budget}} €</label><br>

              <small class="text-black-50 font-weight-bold">Scadenza:</small>
              <small class="font-weight-bold ml-1">{{newAuction.expire | date:'dd/MM/yyyy, HH:mm'}}</small>
            </div>
          </div>
        </div>
      </div>
      <ng-template #description>
        <div class="text-center">
          <p>Descrizione</p>
        </div>
        <ng-container *ngFor="let description of descriptions">
          <div class="pl-3">
            <ng-container *ngIf="description.title === 'Vorrei il seguente numero di portate per il menu' else simpleText">

              <p class="mt-2 mb-0">{{description.title}}</p>
              <ng-container *ngFor="let option of getArray(description.label)">
                <div class="d-flex my-2">
                  <span>{{option}}</span>
                  <div class="portate-class" *ngIf="['5 portate', '4 portate', '3 portate'].includes(option)">
                    <small>{{description.label === '5 portate' ? 3 : 2}} assaggi di primo piatto</small><br>
                    <small>{{description.label === '3 portate' ? 1 : 2}} assaggio di secondo piatto</small>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #simpleText>

              <p class="mt-2 mb-0">{{description.title}}</p>
              <span class="w-100">{{description.label}}</span>
            </ng-template>
          </div>
        </ng-container>
      </ng-template>
      <div class="text-center">
        <button class="btn p-0">
          <i *ngIf="!showDescription" (click)="showDescription = true" class="fa fa-arrow-alt-circle-right color-primary"></i>
          <i *ngIf="showDescription" (click)="showDescription = false" class="fa fa-arrow-alt-circle-left color-primary"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer text-right">
  <button class="btn btn-outline-primary mr-1" (click)="activeModal.dismiss()">Annulla</button>
  <button class="btn btn-primary" (click)="confirm.emit(true)">Pubblica</button>
</div>
