<div class="container pt-3 pb-3" *ngIf="detailObj">

  <div class="row" *ngFor="let prod of detailObj.products">
    <div class="col-4 mb-2">
      <img id="img" class="w-100"
           [src]="'https://www.weddingsmart.it/api/images/' + (type === 'auction' ? 'offers' : 'products') + '/' + prod.imgname">
    </div>
    <div class="col-8 mb-2">
      <div class="row">
        <div class="col-12 col-md-6">

          <a *ngIf="type === 'order'" class="font-weight-bold"
             [routerLink]="'/prodotto/' + prod.code + '/' + prod.title">
            {{prod.title}}
          </a>
          <a *ngIf="type === 'auction'" class="font-weight-bold"
             [routerLink]="'/prodotto/' + prod.code + '/' + prod.title">
            {{prod.description}}
          </a>

          <br>
          <label class="text-black-50" *ngIf="prod.quantity">Q.tà: {{prod.quantity}}</label>
          <label class="text-black-50" *ngIf="!prod.quantity">Q.tà: 1</label>
          <br>
        </div>
        <div class="col-12 col-md-6 mb-2" *ngIf="prod.quantity">
          {{(+prod.total / +prod.quantity) + '' | convertPrice:false:true}}
        </div>
        <div class="col-12 col-md-6 mb-2" *ngIf="!prod.quantity">
          {{(+prod.offer_num) + '' | convertPrice:false:true}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="separator background-lightgray mb-3">
</div>
<ng-container *ngIf="timestampResoAlready">

  <div class="container">
    <div class="card border border-success" style="border-left-width: 6px!important;">
      <div class="card-body">
        <h6>Hai già inoltrato una richiesta di reso per questo ordine</h6>
        <label>
          Rimborso di <b class="text-success"> {{detailObj.total | convertPrice:false:true}} </b>
          <span *ngIf="timestampResoTransaction">
            emesso il <b>{{timestampResoTransaction | date:'dd/MM/yyyy'}} alle ore {{timestampResoTransaction | date:'HH:mm'}}</b> tramite PayPal
          </span>
          <span *ngIf="!timestampResoTransaction">in fase di emissione</span>
        </label><br>
        <small *ngIf="!timestampResoTransaction">
          Reso ricevuto il <b>{{timestampResoAlready | date:'dd/MM/yyyy HH:mm'}}</b>
        </small><br>
        <button class="btn btn-sm btn-primary mt-3" (click)="printEtichetta()">Stampa etichetta</button>
      </div>
    </div>
  </div>
  <div class="separator background-lightgray my-3">
  </div>
</ng-container>
<div class="container">
  <h5>Motivazione della richiesta</h5>
  <ng-container *ngIf="step === 0">

    <div class="card">
      <div class="card-body py-0">

        <div class="row sector" *ngFor="let reason of reasons" (click)="reasonReso = reason">
          <div class="col-auto">
            <i *ngIf="reasonReso != reason" class="far fa-circle"
               style="font-size: 22px; color: lightgrey;"></i>
            <i *ngIf="reasonReso == reason" class="fas fa-dot-circle"
               style="font-size: 22px; color: green;"></i>
          </div>
          <div class="col">
            {{reason}}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 1">
    <label>{{reasonReso}}</label>
    <h6>Commenti (facoltativo):</h6>
    <textarea class="form-control" rows="3" maxlength="200" [(ngModel)]="comment" [disabled]="timestampResoAlready">
    </textarea>
    <small *ngIf="!timestampResoAlready">{{200 - comment.length}} caratteri rimanenti.</small>
  </ng-container>
  <ng-container *ngIf="!timestampResoAlready">
    <div class="row my-3">
      <div class="col-6">
        <button class="btn btn-secondary w-100" [routerLink]="['/', 'order-detail', type, id]">
          Indietro
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary w-100" [disabled]="step === 0 && !reasonReso" (click)="continue()">
          Continua
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!timestampResoAlready && detailObj">
    <div class="text-center" *ngIf="detailObj.states[0] as laststate">
      <label class="text-black-50">Restituisci entro <b>{{laststate.timestampreso | date:'dd/MM/yyyy'}}</b></label>
    </div>
  </ng-container>
  <small class="text-black-50">
    <b>NOTA:</b> Non possiamo fare eccezioni alle nostre politiche o applicare trattamenti speciali in seguito ai
    feedback inseriti in questa pagina.
    Non inserire informazioni personali in quanto questi commenti potrebbero essere condivisi con servizi di terze parti
    per identificare eventuali
    difetti di prodotto.
  </small>
  <br><br>
</div>
