<div class="modal-body position-relative">
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
  <h4 class="text-center">Conferma di resettare tutto</h4>
  <div>
    <label>Attenzione, perderà tutti i step fatti fin'ora</label>

    <div class="progress">
      <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
           [style.width.%]="widthProgress" ></div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-outline-primary mr-1" (click)="activeModal.dismiss()">Annulla</button>
      <button class="btn btn-primary" (click)="confirm.emit(true)">Confermo</button>
    </div>
  </div>

</div>
