import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {DOCUMENT} from "@angular/common";
import {Seo} from '../objects/Seo';
import {SeoEnum} from '../objects/SeoEnum';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private renderer2: Renderer2;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private rendererFactory2: RendererFactory2,
    @Inject(DOCUMENT) private dom: Document) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  setConfig(seoConfig: Seo, type: SeoEnum) {
    this.setTitle(seoConfig.title);
    this.setDescription(seoConfig.description);
  }
  //
  // setConfigNews(news: NewsDTO) {
  //   if (news) {
  //     const url = Utility.getUrlFromType(ConfigEnum.news, this.translateService.currentLang) + '/' + (this.translateService.currentLang === 'it') ? news.slug : news.slugen;
  //     const seotitle = (this.translateService.currentLang === 'it') ? news.seotitle : news.seotitleen;
  //     const seodescription = (this.translateService.currentLang === 'it') ? news.seodescription : news.seodescriptionen;
  //
  //     this.setTitle(seotitle);
  //     this.setDescription(seodescription);
  //     this.updateCanonicalUrl(url);
  //     this.setFB(news, true, url);
  //     this.setTW(news);
  //     this.addWebsiteSchema(true);
  //     this.addWebpageSchema(news, url);
  //     this.addOrganizationSchema();
  //     this.addBreadcrumbSchema(ConfigEnum.news, news, url);
  //     this.addArticleSchema(news);
  //
  //   }
  // }

  private setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  private setDescription(description: string) {
    this.meta.updateTag({name: 'description', content: description});
  }
  // private updateCanonicalUrl(url: string) {
  //   let element: HTMLLinkElement | null = this.dom.head.querySelector(`link[rel='canonical']`) || null;
  //   if (element === null) {
  //     element= this.dom.createElement('link') as HTMLLinkElement;
  //     this.renderer2.appendChild(this.dom.head, element);
  //   }
  //   element.setAttribute('rel','canonical');
  //   element.setAttribute('href', 'https://moorsimply.com/' + url)
  // }
  // private setFB(config: SharedConfig, isArticle = false, url: string) {
  //   const urlImage = 'https://www.moorsimply.com/api/images/configs/';
  //   const fbtitle = (this.translateService.currentLang === 'it') ? config.fbtitle : config.fbtitleen;
  //   const fbdescription = (this.translateService.currentLang === 'it') ? config.fbdescription : config.fbdescriptionen;
  //
  //   this.meta.updateTag({property: 'fb:app_id', content: environment.idFacebook + ''});
  //   this.meta.updateTag({property: 'og:title', content: fbtitle});
  //   this.meta.updateTag({property: 'og:description', content: fbdescription});
  //   this.meta.updateTag({property: 'og:site_name', content: 'Moorsimply'});
  //   this.meta.updateTag({property: 'og:url', content: 'https://moorsimply.com/' + url});
  //   this.meta.updateTag({property: 'og:image', content: urlImage + config.fbimage, itemprop: 'image'});
  //   this.meta.updateTag({property: 'og:image:url', content: urlImage + config.fbimage, itemprop: 'image'});
  //   this.meta.updateTag({property: 'og:image:type', content: 'image/jpg'});
  //   if (isArticle) {
  //     this.meta.updateTag({property: 'og:type', content: 'article'});
  //   } else {
  //     this.meta.updateTag({property: 'og:type', content: 'website'});
  //   }
  // }

  // private setTW(config: SharedConfig) {
  //   const urlImage = 'https://www.moorsimply.com/api/images/configs/';
  //   const twtitle = (this.translateService.currentLang === 'it') ? config.twtitle : config.twtitleen;
  //   const twdescription = (this.translateService.currentLang === 'it') ? config.twdescription : config.twdescriptionen;
  //
  //   this.meta.updateTag({property: 'twitter:card', content: 'summary'});
  //   this.meta.updateTag({property: 'twitter:title', content: twtitle});
  //   this.meta.updateTag({property: 'twitter:description', content: twdescription});
  //   this.meta.updateTag({property: 'twitter:site', content: 'Moorsimply'});
  //   this.meta.updateTag({property: 'twitter:image', content: urlImage + config.fbimage, itemprop: 'image'});
  // }
  //
  // private addWebsiteSchema(hasSearch = false) {
  //
  //   if (!this.dom.head.innerText || !this.dom.head.innerText.includes('"WebSite"')) {
  //     const jsonSchema = {
  //       '@context': 'http://schema.org',
  //       '@type': 'WebSite',
  //       "@id": "https://www.moorsimpy.com/#website",
  //       'sourceOrganization': {
  //         "@id": "https://www.moorsimpy.com/#organization",
  //       },
  //       url: 'https://www.moorsimply.com/',
  //     };
  //     if (hasSearch) {
  //       // @ts-ignore
  //       jsonSchema.potentialAction = {
  //         "@type": "SearchAction",
  //         "target": "https://www.moorsimply.com/news-search/{search_term_string}",
  //         "query-input": "required name=search_term_string"
  //       };
  //     }
  //     const script = this.renderer2.createElement('script');
  //     script.type = `application/ld+json`;
  //     script.text = JSON.stringify(jsonSchema);
  //
  //     this.renderer2.appendChild(this.dom.head, script);
  //   }
  // }
  // private addOrganizationSchema() {
  //
  //   if (!this.dom.head.innerText || !this.dom.head.innerText.includes('"Organization"')) {
  //     const jsonSchema = {
  //       "@context": "http://schema.org",
  //       "@type":"Organization",
  //       "@id": "https://www.moorsimpy.com/#organization",
  //       "name": "Moorsimply",
  //       "url":"https://www.moorsimply.com",
  //       "logo":"https://www.moorsimply.com/assets/images/logo.png",
  //       "address": {
  //         "@type": "PostalAddress",
  //         "addressCountry" : "IT",
  //         "addressLocality" : "Pomezia",
  //         "postalCode": "00071",
  //         "streetAddress": "Via Monterosa 3"
  //       },
  //       "contactPoint": {
  //         "@type": "ContactPoint",
  //         "contactType": "customer service",
  //         "email" : "info@moorsimply.com",
  //         "areaServed": "IT",
  //         "faxNumber" : "+39 06 9145227",
  //         "telephone": "+39 06 9145226",
  //         "availableLanguage": ["it","en"]
  //       },
  //       member: [
  //         {
  //           "@type": "Person",
  //           "name": "Enrico Scozzari",
  //           "url": "https://www.moorsimply.com/about-us",
  //           "image": "https://www.moorsimply.com/assets/images/contacts/enricoScozzari.jpeg",
  //           "sameAs": "https://www.facebook.com/enrico.scozzari",
  //           "jobTitle": "CEO"
  //         },
  //         {
  //           "@type": "Person",
  //           "name": "Diego Occari",
  //           "url": "https://www.moorsimply.com/about-us",
  //           "image": "https://www.moorsimply.com/assets/images/contacts/diegoOccari.jpeg",
  //           "sameAs": "https://www.facebook.com/diego.occari",
  //           "jobTitle": "CEO",
  //         }
  //       ]
  //     };
  //     const script = this.renderer2.createElement('script');
  //     script.type = `application/ld+json`;
  //     script.text = JSON.stringify(jsonSchema);
  //
  //     this.renderer2.appendChild(this.dom.head, script);
  //   }
  // }
  //
  // private addVideoSchema() {
  //
  //   const name = this.translateService.currentLang === 'it' ?
  //     'Come funziona Moorsimply'
  //     :
  //     'How Moorsimply works';
  //   const description = this.translateService.currentLang === 'it' ?
  //     'In questo breve video viene mostrato il funzionamento di Moorsimply e come vengono semplificate le operazioni di ormeggio.'
  //     :
  //     'This video show how Moorsimply works.';
  //   const jsonSchema = {
  //     "@context": "https://schema.org",
  //     "@type": "VideoObject",
  //     name,
  //     description,
  //     "thumbnailUrl": "https://www.moorsimply.com/assets/images/logo.png",
  //     "embedUrl": "https://www.youtube.com/embed/Jzr4hKacrkw",
  //     "uploadDate": "2021-07-14"
  //   };
  //   const script = this.renderer2.createElement('script');
  //   script.type = `application/ld+json`;
  //   script.text = JSON.stringify(jsonSchema);
  //
  //   this.renderer2.appendChild(this.dom.head, script);
  // }
  //
  // private addVideo2Schema() {
  //
  //   const name = this.translateService.currentLang === 'it' ?
  //     'Vi presentiamo Moorsimply'
  //     :
  //     'Moorsimply presentation';
  //   const description = this.translateService.currentLang === 'it' ?
  //     'In questo breve video abbiamo il piacere di presentarvi il nostro innovativo braccio elettromeccanico per l\'assistenza all\'ormeggio.\n' +
  //     'Grazie a questo braccio, ormeggiare l\'imbarcazione non sarà più un problema!'
  //     :
  //     'This video show how Moorsimply works.';
  //   const jsonSchema = {
  //     "@context": "https://schema.org",
  //     "@type": "VideoObject",
  //     name,
  //     description,
  //     "thumbnailUrl": "https://www.moorsimply.com/assets/images/logo.png",
  //     "embedUrl": "https://www.youtube.com/embed/zR1FI3Y5-jk",
  //     "uploadDate": "2021-09-07"
  //   };
  //   const script = this.renderer2.createElement('script');
  //   script.type = `application/ld+json`;
  //   script.text = JSON.stringify(jsonSchema);
  //
  //   this.renderer2.appendChild(this.dom.head, script);
  // }
  //
  // private addBreadcrumbSchema(type: ConfigEnum, news: NewsDTO | null, url: string) {
  //   const jsonSchema = {
  //     "@context": "https://schema.org/",
  //     "@type": "BreadcrumbList",
  //     "@id": url + "/#breadcrumb",
  //     itemListElement: [{
  //       "@type": "ListItem",
  //       "position": 1,
  //       "name": "Home",
  //       "item": "https://www.moorsimply.com/"
  //     }]
  //   };
  //   let item2 = {name: '', item: 'https://www.moorsimply.com/'};
  //   switch (type) {
  //     case ConfigEnum.innovazione:
  //       const label1 = (this.translateService.currentLang === 'it') ? 'innovazione' : 'innovation';
  //       item2.name = label1.charAt(0).toUpperCase() + label1.slice(1).toLowerCase();
  //
  //       item2.item += label1;
  //       break;
  //     case ConfigEnum.news:
  //       item2.name = 'News';
  //       item2.item += 'news';
  //       break;
  //     case ConfigEnum.aboutus:
  //       item2.name = 'About us';
  //       item2.item += 'aboutus';
  //       break;
  //     case ConfigEnum.investitori:
  //       const label2 = (this.translateService.currentLang === 'it') ? 'investitori' : 'investors';
  //       item2.name = label2.charAt(0).toUpperCase() + label2.slice(1).toLowerCase();
  //       item2.item += label2;
  //       break;
  //     case ConfigEnum.contatti:
  //       const label3 = (this.translateService.currentLang === 'it') ? 'contatti' : 'contacts';
  //       item2.name = label3.charAt(0).toUpperCase() + label3.slice(1).toLowerCase();
  //       item2.item += label3;
  //       break;
  //   }
  //   if (item2.name) {
  //     const {name, item} = item2;
  //     jsonSchema.itemListElement.push({
  //       "@type": "ListItem",
  //       "position": 2,
  //       name,
  //       item
  //     })
  //   }
  //   if (news) {
  //     const slug = (this.translateService.currentLang === 'it') ? news.slug : news.slugen;
  //     let name = slug.split('-').join(' ');
  //     name = name.charAt(0).toUpperCase() + name.slice(1);
  //     const item = 'https://www.moorsimply.com/news/' + slug;
  //     jsonSchema.itemListElement.push({
  //       "@type": "ListItem",
  //       "position": 3,
  //       name,
  //       item
  //     });
  //   }
  //   const script = this.renderer2.createElement('script');
  //   script.type = `application/ld+json`;
  //   script.text = JSON.stringify(jsonSchema);
  //
  //   this.renderer2.appendChild(this.dom.head, script);
  // }
  //
  // private addArticleSchema(news: NewsDTO) {
  //
  //   const title = (this.translateService.currentLang === 'it') ? news.seotitle : news.seotitleen;
  //   const description = (this.translateService.currentLang === 'it') ? news.seodescription : news.seodescriptionen;
  //
  //   const jsonSchema = {
  //     "@context": "https://schema.org",
  //     "@type": "Article",
  //     "headline": title.slice(0, 110),
  //     articleBody: description,
  //     "image": "https://www.moorsimply.com/api/images/news/" + news.image,
  //     "author": {
  //       "@type": "Organization",
  //       "@id": "https://www.moorsimpy.com/#organization"
  //     },
  //     "publisher": {
  //       "@type": "Organization",
  //       "@id": "https://www.moorsimpy.com/#organization"
  //     },
  //     "datePublished": news.created,
  //     "dateModified": news.created,
  //     mainEntityOfPage: 'https://www.moorsimply.com/news'
  //   };
  //   const script = this.renderer2.createElement('script');
  //   script.type = `application/ld+json`;
  //   script.text = JSON.stringify(jsonSchema);
  //
  //   this.renderer2.appendChild(this.dom.head, script);
  // }
  //
  // private addSubscribeSchema() {
  //
  //
  //   const jsonSchema = {
  //     "@context": "http://schema.org",
  //     "@type": "SubscribeAction",
  //     "agent": {
  //       "@type": "Organization",
  //       "@id": "https://www.moorsimpy.com/#organization",
  //     }
  //   };
  //   const script = this.renderer2.createElement('script');
  //   script.type = `application/ld+json`;
  //   script.text = JSON.stringify(jsonSchema);
  //
  //   this.renderer2.appendChild(this.dom.head, script);
  // }
  //
  // private addWebpageSchema(config: SharedConfig, url: string) {
  //
  //   if (!this.dom.head.innerText || !this.dom.head.innerText.includes('"WebPage"')) {
  //     const title = (this.translateService.currentLang === 'it') ? config.title : config.titleen;
  //     const jsonSchema = {
  //       '@context': 'http://schema.org',
  //       '@type': 'WebPage',
  //       'name': title,
  //       "isPartOf":{"@id":"https://www.moorsimpy.com/#website"},
  //       'author': {
  //         "@id": "https://www.moorsimpy.com/#organization",
  //       },
  //       "inLanguage":"it-IT",
  //       "datePublished":config.created,
  //       "dateModified":config.created,
  //       "breadcrumb":{"@id": url + "/#breadcrumb"},
  //       url,
  //       "potentialAction":[{"@type":"ReadAction","target":[url]}]
  //     };
  //     const script = this.renderer2.createElement('script');
  //     script.type = `application/ld+json`;
  //     script.text = JSON.stringify(jsonSchema);
  //
  //     this.renderer2.appendChild(this.dom.head, script);
  //   }
  // }
}
