import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './result-review.component.html',
  styleUrls: ['./result-review.component.scss']
})
export class ResultReviewComponent implements OnInit {
  @Input() title: string;
  @Input() body: string;
  @Output() actionClicked = new EventEmitter<'0' | '1'>();
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
