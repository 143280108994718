import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {environment} from '../../../environments/environment';
import {isPlatformBrowser} from '@angular/common';
import {IProduct, IProductPrice} from '../../objects/IProduct';
import {STORAGE_PRODUCTS} from '../../objects/Constants';
import {CommunicatorService} from '../../services/communicator.service';
import {Utility} from '../../objects/Utility';
import {PopupComponent} from '../popup/popup/popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menu-cart',
  templateUrl: './menu-cart.component.html',
  styleUrls: ['./menu-cart.component.scss']
})
export class MenuCartComponent implements OnInit {
  url = environment.urlApi;

  showCart: boolean = null;
  buyProducts: IProduct[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId: object, private modalService: NgbModal,
              public communicatorService: CommunicatorService) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(STORAGE_PRODUCTS)) {
        this.buyProducts = JSON.parse(localStorage.getItem(STORAGE_PRODUCTS));
      }
    }
  }

  addItemToCart(prod: IProduct, price: IProductPrice, quantityString = '1', sizeSelected: string = null) {
    this.communicatorService.checkUser$.subscribe(resp => {
        if (resp && resp.isUser === '1') {
          const quantity = (price.type === '0') ? +quantityString : +price.quantitydays;
          if (price.type === '0') {
            const totalQuantity = this.buyProducts
              // @ts-ignore
              .filter(e => e.idprice === price.id)
              .reduce((a, b) => a += b.quantity, 0);
            if (totalQuantity >= +price.quantity) {

              const mr = this.modalService.open(PopupComponent);
              mr.componentInstance.title = 'Attenzione';
              mr.componentInstance.body = 'Non sono disponibili ulteriori quantità di questo prodotto';
              return;
            }
          }
          const index = this.buyProducts
            // @ts-ignore
            .findIndex(e => e.idprice === price.id && e.id === prod.id && e.sizeSelected == sizeSelected);
          if (index > -1 && price.type === '0') {
            this.buyProducts[index].quantity++;
          } else {
            const prodClone = JSON.parse(JSON.stringify(prod));
            const product = {
              ...prodClone, quantity, pay: price.price, useAdvance: price.useAdvance,
              condition: price.option1 + ', ' + price.option2, type: price.type, idprice: price.id,
              quantityMax: +price.quantity, choisestartdate: price.choisestartdate, choiseenddate: price.choiseenddate
            };
            if (sizeSelected !== null) {
              product.sizeSelected = sizeSelected;
              if (product.other_information.sizeShoes) {
                product.other_information.sizeShoes = [sizeSelected];
              } else if (product.other_information.sizeAddress) {
                product.other_information.sizeAddress = [sizeSelected];
              }
            }
            if (price.choisestartdate) {
              product.other_information.choisestartdate = price.choisestartdate;
              product.other_information.choiseenddate = price.choiseenddate;
            }
            console.log(product);
            this.buyProducts.push(product);
          }
          localStorage.setItem(STORAGE_PRODUCTS, JSON.stringify(this.buyProducts));
        } else {
          const modalRef2 = this.modalService.open(PopupComponent);
          modalRef2.componentInstance.title = 'Attenzione';
          modalRef2.componentInstance.body = 'Per poter procedere all\'acquisto devi prima' +
            ' registrarti come utente sposo e accedere al sito';
          modalRef2.result.then(() => {
            this.communicatorService.openLogin$.next(Math.random());
          }, () => {
            this.communicatorService.openLogin$.next(Math.random());
          });
        }
      }, () => {
        const modalRef2 = this.modalService.open(PopupComponent);
        modalRef2.componentInstance.title = 'Attenzione';
        modalRef2.componentInstance.body = 'Per poter procedere all\'acquisto devi prima' +
          ' registrarti come utente sposo e accedere al sito';
        modalRef2.result.then(() => {
          this.communicatorService.openLogin$.next(Math.random());
        }, () => {
          this.communicatorService.openLogin$.next(Math.random());
        });
      }
    );
  }

  removeItemToCart(index: number) {
    this.buyProducts.splice(index, 1);
    localStorage.setItem(STORAGE_PRODUCTS, JSON.stringify(this.buyProducts));
  }

  showMenu() {
    this.showCart = !this.showCart;
    this.communicatorService.backdropShow$.next(this.showCart);
  }

  calcTotal() {
    let total = 0;
    for (const prod of this.buyProducts) {
      let price = +prod.pay * prod.quantity;
      if (prod.useAdvance) {
        price = price * 0.3;
      }
      total += price;
    }
    return total.toFixed(2);
  }

  saveProduct() {
    localStorage.setItem(STORAGE_PRODUCTS, JSON.stringify(this.buyProducts));
  }

  calcCount(buyProducts: IProduct[]) {
    return buyProducts.reduce((a, b) => a += b.quantity, 0);
  }
}
