<div class="background-lightgray new-auction ">
  <div class="container" [style.opacity]="indexSelected !== 0 ? 1 : 0">

    <div class="row stepLabel" >
      <div class="col-10 my-auto">
        <label class="text-center">Step <span id="currentStep">{{indexSelected + 1}}</span> di <span
          id="totalStep">{{pagesSlide.length + (showImageInsert ? 10 : 9)}}</span> </label>
        <div class="progress">
          <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="0" aria-valuemin="0"
               aria-valuemax="100"
               [style.width.%]="(indexSelected * 100) / (pagesSlide.length + (showImageInsert ? 9 : 8))"></div>
        </div>
      </div>
      <div class="col mt-auto text-center">
        <button class="btn btn-sm p-0" (click)="showModalDismiss()">
          <i class="fa fa-trash color-primary"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="container position-relative height-body mt-3">
    <div class="slider-item" [class.active]="indexSelected === 0" [style.display]="displaySlide">

      <h4 class="text-center">
        Seleziona un Servizio/Prodotto
      </h4>

      <div class="slider-height">
        <ngb-accordion #a="ngbAccordion" [activeIds]="activeSector" [closeOthers]="true">
          <ngb-panel [id]="'custom-panel-' + i" *ngFor="let macroSector of macroSectors; let i = index">
            <ng-template ngbPanelHeader let-opened="opened">
              <div style="height: 20px;" class="d-flex align-items-center justify-content-between"
                   (click)="a.toggle('custom-panel-' + i)">
                <h6 class="m-0">{{macroSector.labelSector}}</h6>
                <button class="btn">
                  <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                  <i class="fa fa-chevron-up" *ngIf="opened"></i>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row sector" *ngFor="let sector of macroSector.sectors" [class.sector-disabled]="newAuction.id != null">
                <div class="col-auto" (click)="prepareSlides(+sector.id)">
                  <i *ngIf="newAuction.id_sector != sector.id" class="far fa-circle"
                     style="font-size: 22px; color: lightgrey;"></i>
                  <i *ngIf="newAuction.id_sector == sector.id" class="fas fa-dot-circle"
                     style="font-size: 22px; color: green;"></i>
                </div>
                <div class="col" (click)="prepareSlides(+sector.id)">
                  {{sector.desc}}
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="slider-item" *ngFor="let page of pagesSlide; let i = index" [class.active]="indexSelected === (i+1)" [style.display]="displaySlide">
      <h4 class="text-center">
        {{page.title}}
      </h4>

      <div class="slider-height">
        <ng-container *ngIf="page.options.length > 0 else specialSlide">

          <div class="row sector" *ngFor="let option of page.options">
            <ng-container *ngIf="!page.isMultiCheckbox">
              <div class="col-auto" (click)="checkEl(page, option)">
                <i *ngIf="!newAuction.other_information[page.key] || newAuction.other_information[page.key] !== option"
                   class="far fa-circle" style="font-size: 22px; color: lightgrey;"></i>
                <i *ngIf="newAuction.other_information[page.key] && newAuction.other_information[page.key] === option"
                   class="fas fa-dot-circle" style="font-size: 22px; color: green;"></i>
              </div>
            </ng-container>
            <ng-container *ngIf="page.isMultiCheckbox">

              <div class="col-auto" (click)="checkArray(page.key, option)">
                <i
                  *ngIf="!newAuction.other_information[page.key] || !newAuction.other_information[page.key].includes(option)"
                  class="far fa-square" style="font-size: 22px; color: lightgrey;"></i>
                <i
                  *ngIf="newAuction.other_information[page.key] && newAuction.other_information[page.key].includes(option)"
                  class="fas fa-check-square" style="font-size: 22px; color: green;"></i>
              </div>
            </ng-container>
            <div class="col"
                 [class.d-flex]="['5 portate', '4 portate', '3 portate'].includes(option)"
                 (click)="(page.isMultiCheckbox) ? checkArray(page.key, option) : checkEl(page, option)">
              {{option}}

              <textarea rows="5" class="form-control mt-1" *ngIf="page.key === 'locationTravel' && option.includes('Si, ho in mente')"
                     [(ngModel)]="newAuction.other_information['moreLocationTravel']"
                        placeholder="Specifica. (Es. Tour del Nord Europa, Crociera nel mediterraneo, oppure Bankok, Singapore, Puket, Parigi, Caraibi, etc.)">

              </textarea>

              <div *ngIf="page.isPortate && ['5 portate', '4 portate', '3 portate'].includes(option)"
                   class="portate-class">
                <small>{{option === '5 portate' ? 3 : 2}} assaggi di primo piatto</small><br>
                <small>{{option === '3 portate' ? 1 : 2}} assaggio di secondo piatto</small>
              </div>
            </div>
          </div>
          <div class="row sector" *ngIf="page.haveInputFinal">
            <ng-container *ngIf="!page.isMultiCheckbox">
              <div class="col-auto" (click)="page.checkInput = true; newAuction.other_information[page.key] = ''">
                <i *ngIf="!page.checkInput" class="far fa-circle" style="font-size: 22px; color: lightgrey;"></i>
                <i *ngIf="page.checkInput" class="fas fa-dot-circle" style="font-size: 22px; color: green;"></i>
              </div>
            </ng-container>
            <ng-container *ngIf="page.isMultiCheckbox">

              <div class="col-auto" (click)="page.checkInput = !page.checkInput">
                <i *ngIf="!page.checkInput" class="far fa-square" style="font-size: 22px; color: lightgrey;"></i>
                <i *ngIf="page.checkInput" class="fas fa-check-square" style="font-size: 22px; color: green;"></i>
              </div>
            </ng-container>
            <div class="col">
              <input [disabled]="!page.checkInput" class="form-control" placeholder="Altro (specifica)"
                     [(ngModel)]="page.valueInput">
            </div>
          </div>
        </ng-container>
        <ng-template #specialSlide>
          <div *ngIf="page.key === 'widthPerson2'">
            <div class="w-100 text-center"><small class="text-success">Opzionale</small></div>
            <div class="w-100 text-center"><small class="text-black-50">Questi dati possono essere comunicati al
              fornitore successivamente</small></div>

            <label>Petto</label>
            <div class="input-group mb-3">
              <input [(ngModel)]="widthPerson.petto" class="form-control optional" min="0" max="999" step="1"
                     type="number" placeholder="Es. 90">
              <div class="input-group-append"><span class="input-group-text">cm</span>
              </div>
            </div>
            <label>Vita</label>
            <div class="input-group mb-3">
              <input [(ngModel)]="widthPerson.vita" class="form-control optional" min="0" max="999" step="1"
                     type="number" placeholder="Es. 90">
              <div class="input-group-append"><span class="input-group-text">cm</span>
              </div>
            </div>
            <label>Fianchi</label>
            <div class="input-group mb-3">
              <input [(ngModel)]="widthPerson.fianchi" class="form-control optional" min="0" max="999" step="1"
                     type="number" placeholder="Es. 90">
              <div class="input-group-append"><span class="input-group-text">cm</span>
              </div>
            </div>
          </div>
          <div *ngIf="page.key === 'heightPerson'">

            <div class="input-group mb-3">
              <input [(ngModel)]="newAuction.other_information[page.key]" class="form-control optional" min="0"
                     max="999" step="1" type="number" placeholder="Es. 90">
              <div class="input-group-append"><span class="input-group-text">cm</span>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="slider-item" [class.active]="indexSelected === pagesSlide.length + 1" [style.display]="displaySlide">

      <h4 class="text-center">
        Inserisci una descrizione che possa essere d'aiuto per i fornitori
      </h4>
      <div class="w-100 text-right"><small>{{newAuction.description.length}} / 500</small></div>

      <textarea maxlength="500" class="form-control"
                rows="5"
                placeholder="La descrizione aggiuntiva permetterà agli operatori di fornirti un servizio/prodotto il più vicino possibile alle tue necessità, cerca di inserire più dettagli possibili"
                id="description" [(ngModel)]="newAuction.description"></textarea>
    </div>

    <div class="slider-item" [class.active]="indexSelected === pagesSlide.length + 2" [style.display]="displaySlide">

      <h4 class="text-center">
        Inserisci la località dove vuoi che venga fornito il servizio o inviato il prodotto
      </h4>
      <div class="row pt-3 pb-3">
        <div class="col-2 my-auto bg-white">
          <button class="btn btn-sm" (click)="location.userData = true">

            <i *ngIf="!location.userData" class="far fa-circle" style="font-size: 22px; color: lightgrey;"></i>
            <i *ngIf="location.userData" class="fas fa-dot-circle" style="font-size: 22px; color: green;"></i>
          </button>
        </div>
        <div class="col-10 bg-white" (click)="location.userData = true">
          <span>Usa i dati di registrazione</span>
        </div>
        <div class="col-12 text-center mt-2 mb-2">
          <small>Oppure</small>
        </div>
        <div class="col-2 mt-2">
          <button class="btn btn-sm" (click)="location.userData = false">

            <i *ngIf="location.userData !== false" class="far fa-circle" style="font-size: 22px; color: lightgrey;"></i>
            <i *ngIf="location.userData === false" class="fas fa-dot-circle" style="font-size: 22px; color: green;"></i>
          </button>
        </div>
        <div class="col-10">
          <div class="form-group">
            <input id="city" class="form-control" placeholder="Scrivi la città" [(ngModel)]="location.city"
                   [ngbTypeahead]="search" [disabled]="location.userData !== false"
                   (selectItem)="check($event.item);">
            <small *ngIf="!searching">Inizia a scrivere la tua città e seleziona uno dei suggerimenti</small>
            <small *ngIf="searching" class="form-text text-muted">Sto cercando...</small>
            <div class="invalid-feedback d-block" *ngIf="searchFailed && location.city !== ''">Spiacenti, nessun
              suggerimento è presente nella nostra banca dati.
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="slider-item" [class.active]="indexSelected === pagesSlide.length + 3" [style.display]="displaySlide">

      <h4 class="text-center">
        Quando si terrà l'evento
      </h4>

      <div class="row sector p-2"
           *ngFor="let option of ['In un giorno preciso', 'Vorrei indicare il mese']; let i = index">
        <div class="col-auto">

          <button class="btn btn-sm" (click)="indexDay = i; goNext()">

            <i *ngIf="indexDay !== i" class="far fa-circle" style="font-size: 22px; color: lightgrey;"></i>
            <i *ngIf="indexDay === i" class="fas fa-dot-circle" style="font-size: 22px; color: green;"></i>
          </button>
        </div>
        <div class="col" (click)="indexDay = i; goNext()">
          {{option}}
        </div>
      </div>
    </div>

    <div class="slider-item" [class.active]="indexSelected === pagesSlide.length + 4" [style.display]="displaySlide">
      <ng-container *ngIf="indexDay === 0">
        <ng-container *ngIf="newAuction.id_sector !== '27'">
          <h4 class="text-center">
            Indica il giorno
          </h4>
          <ngb-datepicker [(ngModel)]="dateSelect" (ngModelChange)="setDate(dateSelect)"
                          [minDate]="today"></ngb-datepicker>

        </ng-container>
        <ng-container *ngIf="newAuction.id_sector === '27'">
          <h4 class="text-center">
            Indica il periodo di soggiorno
          </h4>
          <app-date-range [duration]="duration"></app-date-range>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="indexDay === 1">

        <h4 class="text-center">
          Indica il mese
        </h4>
        <app-months-picker [(value)]="newAuction.other_information['date']"></app-months-picker>
      </ng-container>
    </div>

    <div class="slider-item" [class.active]="indexSelected === pagesSlide.length + 5" [style.display]="displaySlide">

      <h4 class="text-center">
        Inserisci un numero di cellulare
      </h4>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">+39</span>
        </div>
        <input type="tel" id="phone" class="form-control required" [(ngModel)]="newAuction.other_information['phone']">
      </div>
      <div class="card bg-success mt-3">
        <div class="card-body">
          <div class="row">
            <div class="col text-white">Il numero che hai inserito sarà visibile solo al fornitore che si aggiudicherà
              il tuo annuncio
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="slider-item" [class.active]="indexSelected === pagesSlide.length + 6" [style.display]="displaySlide">

      <h4 class="text-center">
        Inserisci il tuo budget <span *ngIf="newAuction.id_sector === '27'">per il periodo di soggiorno</span>
      </h4>
      <div class="w-100 text-right"><small class="chars-title">{{newAuction.budget.length}} / 15</small></div>
      <input class="form-control required"
             [(ngModel)]="newAuction.budget"
             (keydown)="onlyPrice($event)"
             (keyup)="checkPrice()"
             placeholder="Massimo 15 caratteri"
             max="15" minlength="3">
      <small>Scrivi il tuo budget massimo che sei disposta/o a spendere per questo servizio/prodotto</small>
    </div>

    <div class="slider-item" [class.active]="indexSelected === pagesSlide.length + 7" [style.display]="displaySlide">

      <h4 class="text-center">
        Inserisci un titolo al tuo annuncio
      </h4>
      <div class="w-100 text-right"><small class="chars-title">{{newAuction.title.length}} / 40</small></div>
      <input class="form-control required"
             [(ngModel)]="newAuction.title"
             placeholder="Es. Vorrei una giacca blu con mosaico nero" max="40" minlength="3">
    </div>


    <div class="slider-item" [class.active]="indexSelected === pagesSlide.length + 8" [style.display]="displaySlide">

      <h4 class="text-center">
        Per quanto tempo vuoi che sia pubblico il tuo annuncio?
      </h4>

      <div class="row sector p-2" *ngFor="let option of ['7 giorni', '10 giorni', '15 giorni']; let i = index">
        <div class="col-auto">

          <button class="btn btn-sm"
                  (click)="indexDuration = i; ((showImageInsert) ? goNext() : null);">

            <i *ngIf="indexDuration !== i" class="far fa-circle" style="font-size: 22px; color: lightgrey;"></i>
            <i *ngIf="indexDuration === i" class="fas fa-dot-circle" style="font-size: 22px; color: green;"></i>
          </button>
        </div>
        <div class="col" (click)="indexDuration = i; ((showImageInsert) ? goNext() : null);">
          {{option}}
        </div>
      </div>
    </div>

    <div class="slider-item" [class.active]="indexSelected === pagesSlide.length + 9" [style.display]="displaySlide">

      <h4 class="text-center d-md-block" [class.d-none]="imageChangedEvent">
        Inserisci una foto che sia da esempio per gli operatori
      </h4>

      <div class="slider-height">
        <div class="row">
          <div class="col-12 col-md-6 d-md-block" [class.d-none]="imageChangedEvent">
            <input type="file" accept="image/*" (change)="readFile($event)" #fileInput>
          </div>
          <div class="col-12 col-md-6 text-center" style="max-height: 41vh">
            <ng-container *ngIf="imageChangedEvent">


              <image-cropper *ngIf="imageChangedEvent"
                             [imageChangedEvent]="imageChangedEvent"
                             [maintainAspectRatio]="true"
                             backgroundColor="white"
                             [containWithinAspectRatio]="true"
                             [aspectRatio]="176 / 239"
                             format="jpeg"
                             (imageCropped)="imageCropped($event)"
              ></image-cropper>

              <button *ngIf="imageChangedEvent" class="mx-auto btn btn-primary" (click)="finishCropped()">Salva immagine
              </button>
            </ng-container>
            <ng-container *ngIf="newAuction.image as img">
              <img [src]="img.data || 'https://www.weddingsmart.it/api/images/auctions/' + img.imgname" style="max-height: 41vh;">
            </ng-container>
          </div>
        </div>
        <div class="text-center mt-3">
          <button class="btn btn-danger btn-sm" *ngIf="newAuction.image" (click)="newAuction.image = null; fileInput.value = '';">Elimina
            foto
          </button>
          <br>
          <small>Ti invieremo una e-mail per confermare la pubblicazione dell'annuncio</small>
        </div>
      </div>
    </div>

  </div>

  <div class="bg-white py-2 px-2 row-buttons">
    <div class="row no-gutters">
      <div class="col-6 pr-2" [style.padding-right.px]="4">
        <button class="btn btn-secondary w-100" (click)="goNext(false)"  *ngIf="indexSelected !== 0">
          Indietro
        </button>
        <button class="btn btn-secondary w-100" routerLink="/" *ngIf="indexSelected === 0">
          Torna in Home
        </button>
      </div>
      <div class="col" [style.padding-left.px]="4"
           *ngIf="pagesSlide[indexSelected - 1]?.key !== 'widthPerson2'
         || (pagesSlide[indexSelected - 1].key === 'widthPerson2' && widthPerson.fianchi && widthPerson.petto && widthPerson.vita)"
           [class.pl-2]="indexSelected !== 0">
        <button *ngIf="indexSelected !== pagesSlide.length + (showImageInsert ? 9 : 8)"
                [disabled]="isNextDisabled()"
                class="btn btn-primary w-100"
                (click)="checkAccessories();goNext()">Avanti
        </button>
        <button *ngIf="indexSelected === pagesSlide.length + (showImageInsert ? 9 : 8)"
                class="btn btn-primary w-100 btn-preview"
                [disabled]="!newAuction.image"
                (click)="showPreview()">Anteprima annuncio
        </button>
      </div>
      <div class="col"
           *ngIf="pagesSlide[indexSelected - 1] && pagesSlide[indexSelected - 1].key === 'widthPerson2' && (!widthPerson.fianchi || !widthPerson.petto || !widthPerson.vita)">
        <button class="btn btn-success w-100"
                (click)="goNext(); widthPerson = {fianchi: '', petto: '', vita: ''}">Salta
        </button>
      </div>
    </div>
  </div>
</div>
