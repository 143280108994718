import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FornitoreStoreComponent} from './pages/fornitore-store/fornitore-store.component';
import {ProductStoreComponent} from './pages/product-store/product-store.component';
import {HomeComponent} from './pages/home/home.component';
import {PayCartComponent} from './pages/pay-cart/pay-cart.component';
import {VetrineComponent} from './pages/vetrine/vetrine.component';
import {VetrinaFornitoreComponent} from './pages/vetrine/vetrina-fornitore/vetrina-fornitore.component';
import {ReviewComponent} from './pages/review/review.component';
import {AuctionsComponent} from './pages/auctions/auctions.component';
import {AuctionDetailComponent} from './pages/auction-detail/auction-detail.component';
import {DiscoverComponent} from './pages/discover/discover.component';
import {NewAuctionComponent} from './pages/new-auction/new-auction.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {MyAuctionsComponent} from './pages/my-auctions/my-auctions.component';
import {OrderDetailComponent} from './pages/order-detail/order-detail.component';
import {OrderRiepilogoComponent} from './pages/order-riepilogo/order-riepilogo.component';
import {OfferDetailComponent} from './pages/offer-detail/offer-detail.component';
import {RegisterComponent} from './pages/register/register.component';
import {ResultActivationComponent} from './pages/result-activation/result-activation.component';
import {CookiePolicyComponent} from './pages/cookie-policy/cookie-policy.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {EffettuaResoComponent} from './pages/effettua-reso/effettua-reso.component';
import {ConsegnaEsitoComponent} from './pages/consegna-esito/consegna-esito.component';
import {FavoritesComponent} from './pages/favorites/favorites.component';
// import {WelcomeComponent} from './pages/welcome/welcome.component';
import {GuardService} from './services/guard.service';
import {FaqComponent} from './pages/faq/faq.component';
import {TerminiComponent} from './pages/termini/termini.component';
import {HowWorkComponent} from "./pages/how-work/how-work.component";
import {ResetPasswordComponent} from "./pages/reset-password/reset-password.component";


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {state: 'home'},
    canActivate: [GuardService]
  },
  {
    path: 'come-funziona',
    component: HowWorkComponent,
    data: {state: 'home'},
    canActivate: [GuardService]
  },
  { 
    path: 'welcome', 
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule),
    data: {state: 'welcome'},
    canActivate: [GuardService]
  },
  {
    path: 'welcome/:id',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule),
    data: {state: 'welcome'},
    canActivate: [GuardService]
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {state: 'register'},
    canActivate: [GuardService]
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
    data: {state: 'cookie-policy'},
    canActivate: [GuardService]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {state: 'cookie-policy'},
  },
  {
    path: 'result-activation/:result/:token',
    component: ResultActivationComponent,
    data: {state: 'result-auction'}
  },
  {
    path: 'discover',
    component: DiscoverComponent,
    data: {state: 'discover'},
    canActivate: [GuardService]
  },
  {
    path: 'new-auction',
    component: NewAuctionComponent,
    data: {state: 'new-auction'},
    canActivate: [GuardService]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: {state: 'profile'},
    canActivate: [GuardService]
  },
  {
    path: 'my-auctions',
    component: MyAuctionsComponent,
    data: {state: 'my-auctions'},
    canActivate: [GuardService]
  },
  {
    path: 'my-auctions/:tab',
    component: MyAuctionsComponent,
    data: {state: 'my-auctions'},
    canActivate: [GuardService]
  },
  {
    path: 'order-detail/:type/:id',
    component: OrderDetailComponent,
    data: {state: 'order-detail'},
    canActivate: [GuardService]
  },
  {
    path: 'effettua-reso/:type/:id',
    component: EffettuaResoComponent,
    data: {state: 'reso'},
    canActivate: [GuardService]
  },
  {
    path: 'order-detail/:type/:id/riepilogo',
    component: OrderRiepilogoComponent,
    data: {state: 'order-detail'},
    canActivate: [GuardService]
  },
  {
    path: 'auctions',
    canActivate: [GuardService],
    children: [
      {
        path: '', component: AuctionsComponent,
        data: {state: 'auctions'}
      },
      {
        path: 'detail/:id',
        component: AuctionDetailComponent,
        data: {state: 'auction-detail'}
      },
      {
        path: 'city/:city',
        component: AuctionsComponent,
        data: {state: 'auctions'}
      },
      {
        path: 'city/:city/:category',
        component: AuctionsComponent,
        data: {state: 'auctions'}
      },
      {
        path: 'city/:city/:category/:subcategory',
        component: AuctionsComponent,
        data: {state: 'auctions'}
      },
      {
        path: ':category',
        component: AuctionsComponent,
        data: {state: 'auctions'}
      },
      {
        path: ':category/:subcategory',
        component: AuctionsComponent,
        data: {state: 'auctions'}
      },
      {
        path: ':category/:subcategory/:sposo',
        component: AuctionsComponent,
        data: {state: 'auctions'}
      }
    ]
  },
  {
    path: 'offer-detail/:id',
    component: OfferDetailComponent,
    data: {state: 'offer-detail'},
    canActivate: [GuardService]
  },
  {
    path: 'vetrine-fornitori',
    canActivate: [GuardService],
    children: [
      {
        path: '',
        component: VetrineComponent,
        data: {state: 'vetrine-fornitori'}
      },
      {
        path: 'city/:city',
        component: VetrineComponent,
        data: {state: 'vetrine-fornitori'}
      },
      {
        path: 'city/:city/:category',
        component: VetrineComponent,
        data: {state: 'vetrine-fornitori'}
      },
      {
        path: 'city/:city/:category/:subcategory',
        component: VetrineComponent,
        data: {state: 'vetrine-fornitori'}
      },
      {
        path: ':category',
        component: VetrineComponent,
        data: {state: 'vetrine-fornitori'}
      },
      {
        path: ':category/:subcategory',
        component: VetrineComponent,
        data: {state: 'vetrine-fornitori'}
      },
      {
        path: ':category/:subcategory/:fornitore',
        component: VetrinaFornitoreComponent,
        data: {state: 'vetrina-detail'}
      }
    ]
  },
  {
    path: 'store/:category/:subcategory/:fornitore',
    component: FornitoreStoreComponent,
    data: {state: 'store-detail'},
    canActivate: [GuardService]
  },
  {
    path: 'prodotto/:code/:title',
    component: ProductStoreComponent,
    data: {state: 'product-detail'},
    canActivate: [GuardService]
  },
  {
    path: 'review/:type/:id',
    component: ReviewComponent,
    data: {state: 'review-detail'},
    canActivate: [GuardService]
  },
  {
    path: 'pay-cart',
    component: PayCartComponent,
    data: {state: 'pay-detail'},
    canActivate: [GuardService]
  },
  {
    path: 'consegna-esito/:esito/:type/:idorder',
    component: ConsegnaEsitoComponent,
    data: {state: 'consegna'},
    canActivate: [GuardService]
  },
  {
    path: 'favorites',
    component: FavoritesComponent,
    data: {state: 'favorites'},
    canActivate: [GuardService]
  },
  {
    path: 'faq/aiuto/weddingsmart-aiuto-supporto',
    component: FaqComponent,
    data: {state: 'faq'}
  },
  {
    path: 'termini-e-condizioni-generali-del-servizio',
    component: TerminiComponent,
    data: {state: 'termini'}
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    data: {state: 'reset'},
  },
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
