import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService, FacebookLoginProvider, GoogleLoginProvider} from 'ng-social-login-module';
import {Subscription} from 'rxjs';
import {UtilityService} from "../../../services/utility.service";

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit, OnDestroy {
  url = environment.urlApi;
  bodyLogin: {username: string, password: string, provider: string, token: string} =
    {username: '', password: '', provider: null, token: null};
  subscriber$: Subscription;
  @Output() login = new EventEmitter<{username: string, password: string, provider: string, token: string}>();
  @Output() rememberPassword = new EventEmitter();
  @Output() goRegisterPage = new EventEmitter();
  constructor(public activeModal: NgbActiveModal,
              private authService: AuthService,
              public utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.subscriber$ = this.authService.authState.subscribe(user => {
      console.log('authState', user);
      if (user && user.provider === 'GOOGLE') {
        this.bodyLogin.provider = user.provider;
        this.bodyLogin.token = user.token;
        this.login.emit(this.bodyLogin);
      }
    });
  }

  signInWithFB() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  ngOnDestroy(): void {
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }
  }
}
