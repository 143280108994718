import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IAuction, IQuestion} from '../../../objects/IAuction';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  @Input() questions: IQuestion[];
  @Input() auction: IAuction;
  @Input() user: {isAdmin: string, isOperator: string, isUser: string, username: string};
  @Output() sendQuestion = new EventEmitter<string>();
  @Output() sendAnswer = new EventEmitter<{text: string, idQuestion: string}>();
  constructor() { }

  ngOnInit(): void {
  }

}
