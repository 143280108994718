import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './termini.component.html',
  styleUrls: ['./termini.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerminiComponent implements OnInit {
  @Input() isModal = false;
  constructor(
    public modal: NgbModal
  ) {
  }

  ngOnInit(): void {
    window.scrollTo({left: 0, top: 0});
  }

}
