
<div class="card w-100" (click)="goDetail(message)">
  <div class="card-body">
    <div class="row">
      <div class="col-auto image-notification">
        <ng-container *ngIf="user.isAdmin === '1' else imgAdmin">

          <img *ngIf="message.imgto else imageDefault"
             class="rounded-circle" [src]="'https://www.weddingsmart.it/api/images/users/' + message.imgto">
            <ng-template #imageDefault>
              <i class="fa fa-user-circle color-primary">

              </i>
            </ng-template>
        </ng-container>
        <ng-template #imgAdmin>
          <img class="rounded-circle" src="https://www.weddingsmart.it/assets/icons/icon.jpg">
        </ng-template>
      </div>
      <div class="col">
        <h6 class="card-subtitle mb-2 text-muted" *ngIf="user.isAdmin === '1'">{{message.usernameto}}</h6>
        {{message.message}}

        <div class="row">
          <div class="col-auto">
            <span *ngIf="user.isAdmin === '1'" class="badge badge-primary">
              {{(message.isfornitoreto === '1' ? 'Fornitore' : 'Sposo')}}
            </span>
          </div>
          <div class="col text-right">
            <small>{{message.timestamp | date:'dd/MM/yyyy HH:mm'}}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
