import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {CommunicatorService} from '../../services/communicator.service';
import {SeoEnum} from '../../objects/SeoEnum';
import {SeoService} from '../../services/seo.service';
import {ActivatedRoute} from "@angular/router";
import {LoginModalComponent} from "../../components/popup/login-modal/login-modal.component";
import {RememberPasswordComponent} from "../../components/popup/remember-password/remember-password.component";
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilityService} from "../../services/utility.service";

@Component({
  selector: 'app-home',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  constructor(private httpService: HttpService,
              public communicator: CommunicatorService,
              private activatedRoute: ActivatedRoute,
              private modalService: NgbModal,
              private utilityService: UtilityService,
              private seoService: SeoService) {
  }

  ngOnInit(): void {
    this.httpService.getSeo(SeoEnum.welcome).subscribe(resp => {
      this.seoService.setConfig(resp, SeoEnum.welcome);
    });
  }

  scrollToBottom() {
    const el =
      window.innerWidth > 1199 ?
      document.getElementById('registerSection2') :
        document.getElementById('registerSection');
    // window.scrollTo(0, el.scrollTop);
    el.scrollIntoView({behavior: 'smooth'});
  }

  openLogin() {

    const modalRef = this.modalService.open(LoginModalComponent);
    modalRef.componentInstance.rememberPassword.subscribe( () => {
      modalRef.dismiss();
      const modalRef2 = this.modalService.open(RememberPasswordComponent);
      modalRef2.componentInstance.isSent = false;
      modalRef2.componentInstance.sendEmail.subscribe(email => {
        this.httpService.rememberPassword(email).subscribe((resp) => {
          modalRef2.componentInstance.isSent = true;
        }, error1 => {
          modalRef2.dismiss();

          const mr3 = this.modalService.open(PopupComponent);
          mr3.componentInstance.title = 'Attenzione';
          mr3.componentInstance.body = error1.error.message;
        });
      });
    });
    modalRef.componentInstance.login.subscribe( (bodyLogin) => {
      const cookieAccepted = JSON.parse(localStorage.getItem('cookieAccepted'));
      this.httpService.login({...bodyLogin, ...cookieAccepted, ...this.utilityService.position}).subscribe((resp) => {
        localStorage.setItem('authorization', resp.token);
        localStorage.setItem('bypassall', '1');
        if (bodyLogin.username === 'admin') {
          window.location.href = '/portal/dashboard';
        } else {
          const modalRef2 = this.modalService.open(PopupComponent);
          modalRef2.componentInstance.title = 'Attenzione';
          modalRef2.componentInstance.body = resp.message;
          window.location.href = '/';
        }
      }, error1 => {
        modalRef.dismiss();

        const modalRef2 = this.modalService.open(PopupComponent);
        modalRef2.componentInstance.title = 'Attenzione';
        modalRef2.componentInstance.body = error1.error.message;
      });
    });
  }
}
