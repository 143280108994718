<div class="photo-list" *ngIf="photos" >
  <button class="btn btn-sm close-carousel" (click)="closeCarousel()"><i class="fa fa-times"></i> </button>
  <div class="row no-gutters flex-nowrap bg-white h-100">
    <div class="col-12 col-md-auto mr-1" *ngFor="let src of photos; let i = index">
      <div class="text-center">
        <img [src]="'https://www.weddingsmart.it/api/images/' + src" [id]="i">
      </div>
    </div>
  </div>
</div>
