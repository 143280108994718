<div class="modal-body position-relative">
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
  <h4>{{title}}</h4>
  <small class="float-right">{{500 - description.length}} / 500 caratteri</small>
  <textarea class="form-control" rows="5" maxlength="500" [(ngModel)]="description"></textarea>
  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-secondary mr-3" (click)="activeModal.dismiss()">Annulla</button>
      <button class="btn btn-primary rounded-0" (click)="closeDescription.emit(description)">Fatto</button>
    </div>
  </div>

</div>
