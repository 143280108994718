<ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs mt-2">
  <li [ngbNavItem]="1">
    <a ngbNavLink class="text-center text-dark pl-0 pr-0">
      <small>Informazioni</small>
    </a>
    <ng-template ngbNavContent>
      <div class="row no-gutters mt-1 mb-1">
        <div class="col-6 my-auto">
          <small class="text-black-50">Prezzo <br>A partire da: {{vetrina.minprice | convertPrice:false:true}}</small>
        </div>
        <div class="col text-right">
          <button class="btn btn-secondary" [routerLink]="['/store', vetrina.macrosector, vetrina.sector, vetrina.username]">VEDI STORE</button>
        </div>
      </div>
      <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1" [closeOthers]="true">
        <ngb-panel id="custom-panel-1">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" (click)="a.toggle('custom-panel-1')">
              <h6 class="m-0" [class.blue]="opened">Azienda</h6>
              <button class="btn">
                <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                <i class="fa fa-chevron-up" *ngIf="opened"></i>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <label
              class="text-black-50">{{(vetrina.azienda) ? vetrina.azienda : 'Nessuna informazione inserita'}}</label>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="custom-panel-2">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" (click)="a.toggle('custom-panel-2')">
              <h6 class="m-0" [class.blue]="opened">Servizi e/o prodotti offerti</h6>
              <button class="btn">
                <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                <i class="fa fa-chevron-up" *ngIf="opened"></i>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <label
              class="text-black-50">{{(vetrina.servizi) ? vetrina.servizi : 'Nessuna informazione inserita'}}</label>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="custom-panel-3">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" (click)="a.toggle('custom-panel-3')">
              <h6 class="m-0" [class.blue]="opened">Termini e condizioni generali di vendita  <span style="font-size: 12px!important;">(art. 49 del Codice del Consumo)</span></h6>
              <button  class="btn">
                <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                <i class="fa fa-chevron-up" *ngIf="opened"></i>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <label
              class="text-black-50">{{(vetrina.condizioni) ? vetrina.condizioni : 'Nessuna informazione inserita'}}</label>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="custom-panel-4">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" (click)="a.toggle('custom-panel-4')">
              <h6 class="m-0" [class.blue]="opened">Politica resi, rimborsi, cambi e disdette</h6>
              <button class="btn">
                <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                <i class="fa fa-chevron-up" *ngIf="opened"></i>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <label
              class="text-black-50">{{(vetrina.regole) ? vetrina.regole : 'Nessuna informazione politica resi inserita'}}</label>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="custom-panel-6">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" (click)="a.toggle('custom-panel-6')">
              <h6 class="m-0" [class.blue]="opened">Privacy Policy Fornitore</h6>
              <button class="btn">
                <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                <i class="fa fa-chevron-up" *ngIf="opened"></i>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <label
              class="text-black-50">{{(vetrina.policy) ? vetrina.policy : 'Nessuna informazione policy inserita'}}</label>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="custom-panel-5" *ngIf="vetrina.address">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" (click)="a.toggle('custom-panel-5')">
              <h6 class="m-0" [class.blue]="opened">Mappa</h6>
              <button class="btn">
                <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                <i class="fa fa-chevron-up" *ngIf="opened"></i>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="div-map">
              <app-map *ngIf="isDevice" [address]="vetrina.address"></app-map>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink class="text-center text-dark pl-0 pr-0">
      <small>Foto</small>&nbsp;<small class="x-small">{{vetrina.fotos}}</small>
    </a>
    <ng-template ngbNavContent>
      <div class="row no-gutters flex-nowrap">
        <div class="col-11 col-md-4 p-1" *ngFor="let fo of fotos; let i = index" (click)="showPhotos(i)">
          <img class="w-100" [src]="'https://www.weddingsmart.it/api/images/foto-vetrina/' + fo.nome">
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink class="text-center text-dark pl-0 pr-0">
      <small>Recensioni</small>
    </a>
    <ng-template ngbNavContent>
      <app-reviews-accordion [showItemBuyed]="true" [reviews]="reviews" [avgReview]="vetrina.avgreview" [showFilter]="true" [voteFilter]="vote"></app-reviews-accordion>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
