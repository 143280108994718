<div class="modal-body position-relative">
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
  <h4>{{title}}</h4>
  <label for="input">{{description}}</label>
  <input id="input" class="form-control" type="text" *ngIf="hasInput" [(ngModel)]="input">

  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-outline-primary mr-1" (click)="activeModal.dismiss()">Annulla</button>
      <button class="btn btn-primary" (click)="confirm.emit(input)">Confermo</button>
    </div>
  </div>

</div>
