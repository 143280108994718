<div class="modal-body position-relative" *ngIf="isSuccess">
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
  <h3 class="text-center">Grazie per aver effettuato il pagamento</h3>
  <h4 class="text-center text-success">Transazione effettuata con successo</h4>
  <div class="text-center">
    <label>I dettagli del pagamento ti saranno inviati tramite email</label><br>

    <i class="fa fa-thumbs-up text-success">     </i>
  </div>
  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-primary btn-sm" (click)="activeModal.dismiss()">OK</button>
    </div>
  </div>

</div>
<div class="modal-body position-relative" *ngIf="!isSuccess">
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
  <h3 class="text-center">Siamo spiacenti, pagamento NON effettuato</h3>
  <h4 class="text-center text-danger">Transazione non eseguita</h4>
  <div class="text-center">

    <i class="fa fa-exclamation-circle text-danger">     </i>
    <label class="mt-3">Gentile cliente, la informiamo che la transazione bancaria non è andata a buon fine, pertanto la invitiamo a riprovare inserendo i dati correttamente</label>
  </div>
  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-primary btn-sm" (click)="activeModal.dismiss()">OK</button>
    </div>
  </div>

</div>
