import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {IProduct} from '../../objects/IProduct';
import {NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-product-image-gallery',
  templateUrl: './product-image-gallery.component.html',
  styleUrls: ['./product-image-gallery.component.scss']
})
export class ProductImageGalleryComponent implements OnInit {
  @Input() product: IProduct;
  images: {isHorizontal: boolean, data: string}[] = [];
  currentImage;
  innerWidth = 769;
  indexSelected = 0;
  xyZoom = [0,0];
  showZoom = false;
  constructor(@Inject(PLATFORM_ID) private platformId: object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    for (const key of ['imgname', 'imgname2', 'imgname3', 'imgnameor']) {
      if (this.product[key] && this.product[key].length > 0) {
        this.images.push({isHorizontal: key === 'imgnameor', data: this.product[key]});
      }
    }
    this.currentImage = this.images[0];
  }

  swipe(carousel: NgbCarousel, isIncrement: boolean) {
    if (isIncrement) {
      carousel.next();
    } else {
      carousel.prev();
    }
  }

  zoomImage(event: MouseEvent) {
    this.xyZoom = [event.offsetX / 1.4, event.offsetY / 1.4]
  }
}
