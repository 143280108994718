import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from "../../services/http.service";
import {Utility} from "../../objects/Utility";
import {PopupComponent} from "../../components/popup/popup/popup.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CommunicatorService} from '../../services/communicator.service';

@Component({
  selector: 'app-consegna-esito',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form = {password: '', repeat: ''};
  token = '';
  showError = false;
  constructor(private activatedRoute: ActivatedRoute,
              private httpService: HttpService,
              private modalService: NgbModal,
              private router: Router,
              private communicatorService: CommunicatorService
              ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
  }

  saveNewPassword() {
    this.showError = true;
    if (this.form.password.length >= 8 && this.form.repeat.length >= 8 && this.form.password === this.form.repeat) {
      this.httpService.sendResetPassword(this.token, this.form).subscribe(response => {
        if (response.result) {
          const modalRef2 = this.modalService.open(PopupComponent);
          modalRef2.componentInstance.title = 'Attenzione';
          modalRef2.componentInstance.body = 'Password aggiornata correttamente!';
          modalRef2.componentInstance.close.subscribe(() => {
            this.communicatorService.openLogin$.next(Math.random());
            this.router.navigateByUrl('/');
          });
        } else {
          const modalRef2 = this.modalService.open(PopupComponent);
          modalRef2.componentInstance.title = 'Attenzione';
          modalRef2.componentInstance.body = 'Non è stato possibile modificare la password, ' +
            'clicca sul link sulla tua ultima email di reset password e riprova';
        }
      });
    }
  }
}
