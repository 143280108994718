<div class="position-relative w-100">
  <div class="timeline-item">
    <div class="animated-background">
      <div class="background-masker header-top"></div>
      <div class="background-masker header-left"></div>
      <div class="background-masker header-title"></div>
      <div class="background-masker header-subtitle"></div>
      <div class="background-masker subheader-left"></div>
      <div class="background-masker subheader-right"></div>
    </div>
  </div>
</div>
