import {Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {CommunicatorService} from '../../services/communicator.service';
import {ActivatedRoute} from '@angular/router';
import {HttpService} from '../../services/http.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SeoEnum} from '../../objects/SeoEnum';
import {SeoService} from '../../services/seo.service';
import {ISector} from '../../objects/ISector';
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'ng-social-login-module';
import {Subscription} from 'rxjs';
import {TerminiComponent} from '../termini/termini.component';
import {CookiePolicyComponent} from '../cookie-policy/cookie-policy.component';
import {PrivacyPolicyComponent} from "../privacy-policy/privacy-policy.component";
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @Input() innested = false;
  sectors: ISector[] = [];
  macro = {username: '', email: '', repeatEmail: '', password: '', password2: '', advertising: '0',
    personal: '0', privacy: '0', cookie: '0', regulation: '0'};
  user = {};
  operator = {sector_1: ''};
  showErrorUser = false;
  showErrorOperator = false;
  loading = false;
  disableEmail = false;
  subscriber$: Subscription;
  type1 = 'password';
  type2 = 'password';
  type3 = 'password';
  type4 = 'password';
  constructor(public communicator: CommunicatorService, private activatedRoute: ActivatedRoute, private httpService: HttpService,
              private seoService: SeoService, private authService: AuthService,
              private modalService: NgbModal) { }

  ngOnDestroy(): void {
    if (this.subscriber$) {
      this.subscriber$.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (!this.innested) {
      this.httpService.getSeo(SeoEnum.registrazione).subscribe(resp => {
        this.seoService.setConfig(resp, SeoEnum.registrazione);
      });
    }
    this.httpService.getSectors().subscribe(resp => {
      this.sectors = resp;
    });
    this.subscriber$ = this.authService.authState.subscribe(user => {
      console.log('authState', user);
      if (user && user.provider === 'GOOGLE') {
        this.macro.email = user.email;
        this.macro.username = user.name.replace(' ', '');
        this.disableEmail = true;
      } else if (user && user.provider === 'FACEBOOK') {
        this.macro.username = user.name.replace(' ', '');
        if (user.email) {
          this.macro.email = user.email;
          this.disableEmail = true;
        }
      }
    });
  }


  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {

    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  registerUser() {
    this.showErrorUser = true;
    if (this.macro.email && this.macro.password && this.macro.password2 &&
      this.macro.regulation === '1' && this.macro.email === this.macro.repeatEmail) {
      this.macro.username = this.macro.email;
      if (this.macro.password === this.macro.password2) {
        this.loading = true;
        this.httpService.registerUser(this.macro, this.user).subscribe(resp => {
          this.loading = false;
          const modalRef2 = this.modalService.open(PopupComponent);
          modalRef2.componentInstance.title = 'Attenzione';
          modalRef2.componentInstance.body = 'Registrazione avvenuta con successo,' +
            ' ti è stata inviata una email contenente il link per confermare la tua email';
        }, error => {
          this.loading = false;
          console.log(error);
          const modalRef2 = this.modalService.open(PopupComponent);
          modalRef2.componentInstance.title = 'Attenzione';
          modalRef2.componentInstance.body = error.error.message;
        });
      }
    }
  }
  registerOperator() {
    this.showErrorOperator = true;
    if (this.macro.email && this.macro.password && this.macro.password2 &&
      this.operator.sector_1 && this.macro.email === this.macro.repeatEmail) {
      this.macro.username = this.macro.email;
      if (this.macro.password === this.macro.password2) {
        this.loading = true;
        this.httpService.registerOperator(this.macro, this.operator).subscribe(resp => {
          this.loading = false;
          const modalRef2 = this.modalService.open(PopupComponent);
          modalRef2.componentInstance.title = 'Attenzione';
          modalRef2.componentInstance.body = 'Registrazione avvenuta con successo,' +
            ' ti è stata inviata una email contenente il link per confermare la tua email';
        }, error => {
          this.loading = false;
          console.log(error);
          const modalRef2 = this.modalService.open(PopupComponent);
          modalRef2.componentInstance.title = 'Attenzione';
          modalRef2.componentInstance.body = error.error.message;
        });
      }
    }
  }

  openWeddingsmart() {
    const rf = this.modalService.open(TerminiComponent, {size: 'lg', backdrop: 'static'});
    rf.componentInstance.isModal = true;

  }

  openCookie() {
    const rf = this.modalService.open(CookiePolicyComponent, {size: 'lg', backdrop: 'static'});
    rf.componentInstance.isModal = true;
  }

  openPrivacy() {
    const rf = this.modalService.open(PrivacyPolicyComponent, {size: 'lg', backdrop: 'static'});
    rf.componentInstance.isModal = true;
  }
}
