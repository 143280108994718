
<div class="container pt-5 pb-5" id="container-esito">
  <h4>Reset password</h4>
  <div class="row">
    <div class="col-12">
      <label for="password">Inserisci una nuova password</label>
      <input class="form-control" type="password" autocomplete="new-password" id="password" [(ngModel)]="form.password">
      <span *ngIf="showError && form.password.length < 8" class="invalid-feedback invalid-password2">Inserire almeno 8 caratteri</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <label for="password2">Ripeti la nuova password</label>
      <input class="form-control" type="password" id="password2" [(ngModel)]="form.repeat">
      <span *ngIf="showError && form.repeat.length < 8" class="invalid-feedback invalid-password2">Inserire almeno 8 caratteri</span>
      <span *ngIf="showError && form.repeat.length >= 8 && form.repeat !== form.password"
            class="invalid-feedback invalid-password2">Le due password non coincidono</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-primary" (click)="saveNewPassword()">Salva</button>
    </div>
  </div>
</div>
