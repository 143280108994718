<div [className]="(!showItemBuyed ? 'mx-less' : '')">

  <div class="card mt-2" *ngFor="let rev of reviews">
    <div class="card-body" *ngIf="!isExtended else templateRef">
      <div class="row">
        <div class="col-12">
          <label class="w-100">
            <b class="color-primary">{{utility.getName(rev.from)}}</b>
            <span class="votes float-right">
                  <i class="fa fa-star" *ngFor="let i of [1,2,3,4,5]" style="font-size: 21px;"
                     [ngClass]="{'yellow': i <= rev.vote}"></i>
                </span>
          </label>
          <label class="w-100 font-weight-bold text-black-50">{{rev.timestamp | date:'dd/MM/yyyy HH:mm'}}
            - {{rev.title}}</label>
          <label class="w-100 font-weight-bold text-black-50" *ngIf="!rev.idoffer">Prodotto: <a
            [routerLink]="'/prodotto/' + rev.code + '/' + rev.prodotto">{{rev.prodotto}}</a></label>
          <label class="w-100 font-weight-bold text-black-50" *ngIf="rev.idoffer">Offerta: <a
            [href]="'https://www.weddingsmart.it/offer-detail/' + rev.offer">{{rev.code.toUpperCase()}}</a></label>
          <span>{{rev.description}}</span>
        </div>
      </div>
    </div>
    <ng-template #templateRef>

      <div class="card-body">
        <div class="row mb-2">
          <div class="col-2 col-md-1 my-auto text-center pr-0">
            <i class="fa fa-user-circle fa-2x color-primary"></i>
          </div>
          <div class="col pr-0">
          <span class="w-100">
            <b class="color-primary">{{utility.getName(rev.from)}}</b><br>
            <span class="w-100 font-weight-bold text-black-50">Recensione
              del {{rev.timestamp | date:'dd/MM/yyyy HH:mm'}}</span>
          </span>
          </div>
        </div>

        <span class="votes">
                  <i class="fa fa-star" *ngFor="let i of [1,2,3,4,5]" style="font-size: 21px;"
                     [ngClass]="{'yellow': i <= rev.vote}"></i>
            </span><br>
        <label class="w-100 font-weight-bold">{{rev.title}}</label>
        <span>{{getDescription(rev)}}</span>
        <ng-container *ngIf="rev.description.length > maxLengthDesc">
          <br>
          <button class="btn btn-link p-0" *ngIf="!rev.showOther" (click)="rev.showOther = true">Visualizza l'intera
            recensione
          </button>
          <button class="btn btn-link p-0" *ngIf="rev.showOther" (click)="rev.showOther = false">Mostra meno</button>
        </ng-container>
        <div class="mt-1">
          <button class="btn btn-secondary">Utile ?</button>
        </div>
        <div class="card mt-2" *ngIf="showItemBuyed"
             (click)="goToDetail(rev)">
          <div class="card-body pt-1 pb-1">
            <div class="row">
              <div class="col-2 pr-0" *ngIf="!rev.idoffer">
                <img class="w-100" [src]="'https://www.weddingsmart.it/api/images/products/' + rev.imgname">
              </div>
              <div class="col-2 pr-0" *ngIf="rev.idoffer">
                <img class="w-100" [src]="'https://www.weddingsmart.it/api/images/offers/' + rev.imageoffer">
              </div>
              <div class="col">
                <span style="max-height: 48px; display: block; overflow: hidden" class="w-100" *ngIf="!rev.idoffer">Prodotto: {{rev.prodotto}}</span>
                <span style="max-height: 48px; display: block; overflow: hidden" class="w-100" *ngIf="rev.idoffer">Offerta: {{rev.offerdesc}}</span>
                <div class="d-flex">

              <span class="votes">
                <ng-container *ngFor="let i of [1,2,3,4,5]">
                  <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star-y.png"
                       *ngIf="i <= +rev.avgvotes else half">
                  <ng-template #half>
                    <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/half-star.png"
                         *ngIf="((i - 0.5 < +rev.avgvotes) && (i > +rev.avgvotes)) else nohalf">
                  </ng-template>
                  <ng-template #nohalf>
                    <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star.png"
                         *ngIf="i > +rev.avgvotes">
                  </ng-template>
                </ng-container>
              </span>
                  <label class="text-black-50 ml-1">{{rev.votes}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </ng-template>
  </div>

</div>
