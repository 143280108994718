
<section class="container-fluid second">
  <div class="row">
    <div class="col-12 col-md-5 text-center text-md-right">

      <picture>
        <source
          media="(min-width: 780px)"
          srcset="/assets/images/home/scontipc.png">
        <img class="w-100" src="/assets/images/home/sconti.png">
      </picture>
    </div>
    <div class="col-12 col-md-6 position-relative">
      <div class="d-none d-md-block"><img class="arrow" src="/assets/images/home/arrow.png"></div>

      <h4 class="mt-2 position-relative">Bonus Sposi Wedding Smart<span class="copyright" >&#174;</span></h4>
      <label class="text-black-50"><span class="position-relative mr-1"> Wedding Smart <span class="copyright fix" style="font-size: 11px;">&#174;</span></span>
        offre
        <mark class="text-black-50">a tutti i futuri sposi</mark>
        dei Bonus sconti fruibili all'interno della piattaforma.</label>
      <label class="text-black-50">Ogni qualvolta che si acquista un prodotto, un servizio o si accetta una proposta da
        parte del fornitore, al checkout, vi verrà applicata una percentuale di sconto.</label>
      <label class="text-black-50">Più acquisti si fanno all'interno della piattaforma, più sconti riceverete, fino a
        <mark class="text-black-50 price">2.500€</mark>
        e più.</label>
      <label class="text-black-50">Crea i tuoi annunci, sfrutta le potenzialità che ti offre <span
        class="font-weight-500">"gratis"</span>
        <span class="position-relative mr-1"> Wedding Smart<span class="copyright fix" style="right:-12px!important; font-size: 11px;">&#174;</span> </span> .</label><br>
      <label class="text-black-50 font-weight-500">Cosa aspetti? <br>Comincia a risparmiare!</label><br>
      <label class="text-black-50">Organizzare con noi il tuo matrimonio non è stato mai così facile. </label>
      <div class="div-button">

        <ng-container *ngIf="communicator.checkUser$ | async as user">
          <button *ngIf="user && user.isUser === '1' else annunci" class="btn btn-primary d-block mx-auto rounded-pill pl-4 pr-4"
                  routerLink="/new-auction">Crea il tuo annuncio</button>
          <ng-template #annunci>
            <button *ngIf="user && user.isOperator === '1' else registrati" class="btn btn-primary d-block mx-auto rounded-pill pl-4 pr-4"
                    routerLink="/auctions">Annunci sposi</button>
          </ng-template>
          <ng-template #registrati>
            <button class="btn btn-primary d-block mx-auto rounded-pill pl-4 pr-4" *ngIf="!user || user.isOperator !== '1'"
                    routerLink="/register"
                   >Registrati ora gratis</button>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</section>
<section class="container-fluid third mt-3" id="discover">
  <div class="row">
    <div class="col-12 position-relative">
      <div class="text-center"><img class="arrow" src="/assets/images/home/arrow.png" width="20" height="10"></div>
      <blockquote>
        <i class="fa fa-quote-left left"></i>
        <h4 class="text-center" >Cos'è e come funziona <br class="d-block d-md-none"> <span
          class="position-relative mr-3">Wedding Smart<span class="copyright">&#174;</span></span>?</h4>
        <p class="text-center"><span class="position-relative mr-2">Wedding Smart<span
          class="copyright fix" style="font-size: 11px;">&#174;</span></span> è il primo marketplace al mondo sul matrimonio, <br class="d-none d-lg-block"> ideato per far
          "incontrare" in modo <br class="d-block d-md-none">innovativo, trasparente, sicuro <br class="d-none d-lg-block"> ed intelligente<br class="d-block d-md-none"> futuri sposi e aziende del settore</p>
        <i class="fa fa-quote-right float-right right"></i>
      </blockquote>
    </div>
  </div>
  <hr class="hr-primary mt-0 mt-md-5">
</section>

<section class="container-fluid fourth mt-3 mt-md-5">
  <h4 class="text-center">Come funziona sposi</h4>
  <div class="row">
    <div class="col-12 col-md-4">
      <img class="mx-auto d-block mt-2 mt-md-0" src="/assets/images/home/step1.png">
      <h5 class="text-center">Crea i tuoi annunci</h5>
      <p class="text-center mx-md-3">Indica cosa hai bisogno in pochi semplici click. Crea il tuo annuncio personalizzato,
        inserisci il tuo budget e pubblicalo.</p>
    </div>
    <div class="col-12 col-md-4">
      <div style="padding-right: 0.7rem;">
        <img class="mx-auto d-block" src="/assets/images/home/step2.png">
      </div>
      <h5 class="text-center">Confronta le proposte</h5>
      <p class="text-center mx-md-3">Ricevi proposte direttamente sul tuo annuncio generate mediante un sistema di negoziazione
        creato da una "competizione" tra i fornitori.</p>
    </div>
    <div class="col-12 col-md-4">
      <img class="mx-auto d-block" src="/assets/images/home/step3.png">
      <h5 class="text-center">Accelera e semplifica gli acquisti</h5>
      <p class="text-center mx-md-3">Accetta la proposta del professionista che più si avvicina alle tue necessità dopo aver
        confrontato le varie offerte e consultato le loro vetrine.</p>
    </div>
  </div>
</section>

<section class="container-fluid fiveth mt-3 d-block d-lg-none">
  <h4 class="text-center">Come funziona aziende</h4>
  <img class="d-block" src="/assets/images/home/fornitori1.png">
  <h5>Registrazione, creazione store e dashboard dedicata</h5>
  <p>Registrati in pochi semplici click e inizia a creare <b>GRATIS</b> il tuo Store. Avrai una dashboard dedicata dove
    potrai gestire al meglio
    la tua vetrina ed il tuo store, monitorare l'inventario, visualizzare statistiche, SEO, controllare quanto hai
    venduto e guadagnato giornalmente e tanto altro ancora</p>


  <img class="ml-auto d-block" src="/assets/images/home/fornitori2.png">
  <h5 class="text-right">Invia proposte direttamente nell'annuncio sposi</h5>
  <p class="text-right">Potrai inviare le tue proposte personalizzate direttamente nell'annuncio degli sposi ottenendo
    così una risposta immediata.
    <br>Basta inviare inutili preventivi, da oggi vendi direttamente </p>

  <img class="d-block" src="/assets/images/home/fornitori3.png">
  <h5>Accelera e semplifica i processi di vendita</h5>
  <p>Con questo marketplace innovativo potrai accelerare e semplificare tutti i tuoi processi di vendita. I futuri
    sposi potranno visionare direttamente nel tuo store i servizi
    o prodotti e procedere direttamente all'acquisto. Nello stesso tempo potrai anche inviare le tue proposte
    personalizzate direttamente negli annunci creati dai futuri sposi, così
    facendo avrai più opportunità di vendita</p>

  <div class="row">
    <div class="col-6 text-right">

      <ng-container *ngIf="communicator.checkUser$ | async as user else noUser">
        <button class="btn btn-primary rounded-0"
                [routerLink]="(user && user.isUser === '1') ? '/new-auction' : ((user && user.isOperator === '1') ? '/auctions' : '/register')"
        >Provalo subito</button>
      </ng-container>
      <ng-template #noUser>
        <button class="btn btn-primary rounded-0"
                routerLink="/register"
        >Provalo subito</button>
      </ng-template>
    </div>
    <div class="col-6">
      <button class="btn btn-secondary" routerLink="/discover">Scopri di più</button>
    </div>
  </div>
</section>
<section class="fivethmd mt-5 d-none d-lg-block container-fluid">

  <h4 class="text-center">Come funziona aziende</h4>
  <img src="/assets/images/home/fornitoripc.png">
  <div class="first">
    <h5>Registrazione, creazione store e dashboard dedicata</h5>
    <p>Registrati in pochi semplici click e inizia a creare <b>GRATIS</b> il tuo Store. Avrai una dashboard dedicata
      dove potrai gestire al meglio
      la tua vetrina ed il tuo store, monitorare l'inventario, visualizzare statistiche, SEO, controllare quanto hai
      venduto e guadagnato giornalmente e tanto altro ancora</p>
  </div>

  <div class="second">
    <h5 class="text-right">Invia proposte direttamente nell'annuncio sposi</h5>
    <p class="text-right">Potrai inviare le tue proposte personalizzate direttamente nell'annuncio degli sposi ottenendo
      così una risposta immediata.
      <br>Basta inviare inutili preventivi, da oggi vendi direttamente </p>
  </div>

  <div class="third">
    <h5>Accelera e semplifica i processi di vendita</h5>
    <p>Con questo marketplace innovativo potrai accelerare e semplificare tutti i tuoi processi di vendita. I futuri
      sposi potranno visionare direttamente nel tuo store i servizi
      o prodotti e procedere direttamente all'acquisto. Nello stesso tempo potrai anche inviare le tue proposte
      personalizzate direttamente negli annunci creati dai futuri sposi, così
      facendo avrai più opportunità di vendita</p>
  </div>

</section>
