import { Pipe, PipeTransform } from '@angular/core';
import {IAuction} from '../../objects/IAuction';

@Pipe({
  name: 'groupByCategory'
})
export class GroupByCategoryPipe implements PipeTransform {

  transform(values: any[], filterType: number, ...args: unknown[]): {category: string, items: {sector: string, auctions: any[]}[]}[] {
    if(!values) {
      return null;
    }
    const items:  {category: string, items: {sector: string, auctions: any[]}[]}[] = [];

    if (filterType === 0) {
      values = values.filter(e => !e.paypalbuyer);
    } else if (filterType === 1) {
      values = values
        .filter(e => !!e.paypalbuyer)
        .filter(e => e.states && !e.states.map(a => a.state_order.toLowerCase()).includes('spedito'));
    } else if (filterType === 2) {
      values = values
        .filter(e => !!e.paypalbuyer)
        .filter(e => e.states && e.states.map(a => a.state_order.toLowerCase()).includes('spedito'));
    }
    const categories = Array.from(new Set(values.map(e => e.desc_sector)));
    for (const category of categories) {
      const item = {category, items: []};
      const array = values.filter(e => e.desc_sector === category);

      const sectors = Array.from(new Set(array.map(e => e.sector)));
      for (const sector of sectors) {
        item.items.push({
          sector, auctions: array.filter(e => e.sector === sector)
        }
        );
      }
      if(item.items.length > 0) {
        items.push(item);
      }
    }
    items.sort((a, b) => {
      if (a.category === 'Sposa') {
        return -1;
      } else if (b.category === 'Sposa') {
        return 1;
      } else {
        if (a.category === 'Sposo') {
          return -1;
        } else if (b.category === 'Sposo') {
          return 1;
        } else {
          if (a.category === 'Fornitori') {
            return -1;
          } else {
            return 1;
          }
        }
      }
    });
    return items;
  }

}
