import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {CommunicatorService} from '../../services/communicator.service';
import {HttpService} from '../../services/http.service';
import {ConfirmComponent} from "../popup/confirm/confirm.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-action-favorite',
  templateUrl: './action-favorite.component.html',
  styleUrls: ['./action-favorite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionFavoriteComponent implements OnInit {
  @Input() tableId: number;
  @Input() tableName: string;
  @Input() idProprierty: number | string;
  @Input() classButton = '';
  @Input() keyUser: 'isOperator' | 'isUser' = 'isUser';
  @Input() isRed = false;
  @Input() showConfirm = false;
  @Output() isRedChange = new EventEmitter<boolean>();
  @Output() changeFavorite = new EventEmitter();
  constructor(public communicator: CommunicatorService, private httpService: HttpService, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  addRemoveFavorite(event: Event) {
    if (this.showConfirm) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      const mr2 = this.modalService.open(ConfirmComponent);
      mr2.componentInstance.title = 'Attenzione';
      mr2.componentInstance.description = 'Sei sicuro di voler togliere questa scheda dai preferiti?';
      mr2.componentInstance.confirm.subscribe(() => {
        mr2.close();
        this.sendFavorite();
      });
    } else {
      this.sendFavorite();
    }
  }

  sendFavorite() {
    this.httpService.postFavorite(this.tableId, this.tableName, this.idProprierty).subscribe(resp => {
      this.isRed = resp?.isFavorite;
      this.isRedChange.emit(this.isRed);
      this.changeFavorite.emit();
    });
  }
}
