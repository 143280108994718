import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommunicatorService} from '../../../../services/communicator.service';
import {Router} from '@angular/router';
import {IMessage} from "../IMessage";
import {ICheckUser} from "../../../../objects/ICheckUser";

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent implements OnInit {
  @Input() message: IMessage;
  @Input() user: ICheckUser;
  @Output() closeNotifications = new EventEmitter();
  constructor(private communicator: CommunicatorService,
              private router: Router
  ) { }

  ngOnInit(): void {
  }

  goDetail(message: IMessage) {
    this.communicator.checkUser$.subscribe(user => {
      if (user.isAdmin === '1') {
        this.closeNotifications.emit();
        this.router.navigate(['/message', message.isfornitoreto, message.idmacroto, message.usernameto]);
      }
    });
  }
}
