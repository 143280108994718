import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {IProduct} from '../../objects/IProduct';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpService} from '../../services/http.service';
import {isPlatformBrowser} from '@angular/common';
import {STORAGE_PRODUCTS} from '../../objects/Constants';
import {Utility} from '../../objects/Utility';
import {IStoreShipping} from '../../objects/IStore';
import {ICreateOrderRequest, IPayPalConfig, IShipping, ITransactionItem, IUnitAmount} from 'ngx-paypal';
import {AddressComponent} from '../../components/popup/address/address.component';
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {PaypalResultComponent} from '../../components/popup/paypal-result/paypal-result.component';

@Component({
  selector: 'app-pay-cart',
  templateUrl: './pay-cart.component.html',
  styleUrls: ['./pay-cart.component.scss']
})
export class PayCartComponent implements OnInit {

  buyProducts: { prod: IProduct, bonus: string, percent: string }[] = [];
  public amount: IUnitAmount = {
    currency_code: 'EUR',
    value: '0',
    breakdown: {
      item_total: {
        currency_code: 'EUR',
        value: '0'
      }
    }
  };
  transactionItem: ITransactionItem[] = [];
  spedition: IStoreShipping;
  public payPalConfig: IPayPalConfig;
  shipping: string;
  addresses: {address: string, city: string, cap: string, number: string}[] = [];
  address = {address: '', cap: '', city: '', number: '', fullname: '', phone: ''};

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private modalService: NgbModal, private router: Router,
              private httpService: HttpService) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(STORAGE_PRODUCTS)) {
        this.httpService.getUser().subscribe((resp: { address: string, phone: string, fullname: string }) => {
          this.address.phone = resp.phone;
          this.address.fullname = resp.fullname;
          this.getAddresses();
        });
      }
    }
  }

  calcTransactionsByProducts(buyProducts: IProduct[]) {

    this.httpService.getShippingStore(buyProducts[0].username).subscribe(resp => {
      this.spedition = resp;
      const {transactionItem} = Utility.calcTransactionsByProducts(buyProducts);
      let index = 0;
      let total = 0;
      for (const product of buyProducts) {
        if (resp.bonus && resp.bonus.length > 0) {
          let discount = '';
          let percent = '';
          const bonus = resp.bonus.find(e => e.idcategory === product.idcategory);
          if (bonus) {
            if (bonus.limit_down && (+product.pay * product.quantity) > +bonus.limit_down) {
              percent = bonus.bonus_up + '%';
              discount = Utility.round((+bonus.bonus_up * (+product.pay) / 100));
            } else {
              percent = bonus.bonus_down + '%';
              discount = Utility.round(+bonus.bonus_down * (+product.pay) / 100);
            }
          }
          discount = Utility.round(+discount * product.quantity);
          percent = percent.replace('.00', '');
          this.buyProducts.push({prod: product, percent, bonus: discount});
          const value = Utility.round(((+product.pay * product.quantity) - +discount) / product.quantity);
          console.log(value);
          console.log(discount);
          transactionItem[index].unit_amount = {
            currency_code: 'EUR',
            value,
            breakdown: {item_total: {value: product.pay, currency_code: 'EUR'}, discount: {value: discount, currency_code: 'EUR'}}
          };
          total += +Utility.round(+product.pay * product.quantity - +discount);
          index++;
        } else {
          total += +Utility.round(+product.pay * product.quantity);
          this.buyProducts.push({prod: product, percent: '0', bonus: null});
        }
      }
      this.transactionItem = transactionItem;

      // @ts-ignore
      if (resp.store.shippingfree === '1' && total > +resp.store.shippingmin) {
        this.shipping = '0.00';
      } else {
        this.shipping = resp.store.shippingcost;
      }
      this.amount.breakdown.shipping = {currency_code: 'EUR', value: this.shipping};
      this.amount.value = Utility.round(+total + +this.shipping);
      this.amount.breakdown.item_total.value = Utility.round(total);
      this.initConfig(resp.store.paypal);
      console.table(this.buyProducts[0].prod)
    });
  }

  private initConfig(merchant_id): void {
    // const shipping: IShipping = {
    //   address:
    //     {address_line_1: this.address.address, country_code: 'IT', postal_code: this.address.cap, admin_area_2: this.address.city},
    //   name: {full_name: 'Simone Tavoletti'}
    // };

    this.payPalConfig = {
      currency: 'EUR',
      clientId: environment.clientPaypal,
      createOrderOnClient: (data) => ({
        intent: 'CAPTURE',
        purchase_units: [{
          payee: {
            merchant_id
          },
          amount: this.amount,
          items: this.transactionItem,
          // shipping
        }]
      }) as ICreateOrderRequest,
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });

      },
      onClientAuthorization: (data) => {

        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        // this.showSuccess = true;
        //
        this.sendTransactions(data.id);
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);

        const modalRef2 = this.modalService.open(PaypalResultComponent, {size: 'lg'});
        modalRef2.componentInstance.isSuccess = false;
        // this.showCancel = true;
      },
      onError: err => {
        console.log('OnError', err);
        const modalRef2 = this.modalService.open(PaypalResultComponent, {size: 'lg'});
        modalRef2.componentInstance.isSuccess = false;
        // this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        // this.resetStatus();
      }
    };
  }

  sendTransactions(idtransaction: string) {
    const idseller = this.buyProducts[0].prod.idmacro;
    const total = this.amount.value;
    const addressshipping = this.address.address + ', ' + this.address.cap + ', ' + this.address.city;
    const costshipping = this.shipping;
    const iva = this.spedition.store.sector_1_iva;
    const products = this.buyProducts.map(e => e.prod);
    this.httpService.sendTransactions({
      products, idtransaction, iva,
      idseller, total, addressshipping, costshipping
    }).subscribe(resp => {
      if (resp.result) {

        const modalRef2 = this.modalService.open(PaypalResultComponent, {size: 'lg'});
        modalRef2.componentInstance.isSuccess = true;

        localStorage.setItem(STORAGE_PRODUCTS, '[]');

        this.router.navigateByUrl('/my-auctions/1');
      }
    });
  }

  showConfirm() {
    const {address, city, cap, number} = this.address;
    const rf = this.modalService.open(AddressComponent);
    rf.componentInstance.addresses = this.addresses;
    rf.componentInstance.objAddress = {address, city, cap, number};
    rf.componentInstance.confirm.subscribe((resp: {address: string, city: string, cap: string, number: string}) => {
      this.address.address = resp.address;
      this.address.city = resp.city;
      this.address.cap = resp.cap;
      this.address.number = resp.number;
      rf.close();
    });
  }

  lessTotal() {
    return +this.amount.value - +this.shipping;
  }

  private getAddresses() {
    this.httpService.getAddresses().subscribe((addresses) => {
      this.addresses = addresses;
      if (addresses.length > 0 && this.address.address === '') {
        const {address, city, cap, number} = addresses[0];
        this.address = {...this.address, address, cap, city, number};
      }
      this.calcTransactionsByProducts(JSON.parse(localStorage.getItem(STORAGE_PRODUCTS)));
    });
  }

  checkLoad(event: any) {
    console.log(event);
  }
}
