<ng-container *ngIf="isModal else body">
  <div class="modal-header">
    <h2 class="modal-title">Ecco la nostra Cookie Policy</h2>
    <button type="button" class="close" (click)="modal.dismissAll()">&times;</button>
  </div>
  <div class="modal-body">
    <ng-template [ngTemplateOutlet]="body"></ng-template>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="modal.dismissAll()">Chiudi</button>
  </div>
</ng-container>

<ng-template #body>
  <div [class.container]="showContainer">

    <h3 *ngIf="!isModal"><strong>Ecco la nostra Cookie Policy</strong></h3>
    <p>I Cookie sono costituiti da porzioni di codice installate all&rsquo;interno del browser che assistono il Titolare
      nell&rsquo;erogazione del Servizio in base alle finalit&agrave; descritte. Alcune delle finalit&agrave; di
      installazione dei Cookie potrebbero, inoltre, necessitare del consenso dell&rsquo;Utente.</p>
    <p>Quando l&rsquo;installazione di Cookies avviene sulla base del consenso, tale consenso pu&ograve; essere revocato
      liberamente in ogni momento seguendo le istruzioni contenute in questo documento.</p>
    <p><strong>Cookie tecnici e di statistica aggregata</strong></p>
    <p><strong>Attivit&agrave; strettamente necessarie al funzionamento</strong></p>
    <p>Questa Applicazione utilizza Cookie per salvare la sessione dell&rsquo;Utente e per svolgere altre attivit&agrave;
      strettamente necessarie al funzionamento di questa Applicazione, ad esempio in relazione alla distribuzione del
      traffico.</p>
    <p><strong>Attivit&agrave; di salvataggio delle preferenze, ottimizzazione e statistica</strong></p>
    <p>Questa Applicazione utilizza Cookie per salvare le preferenze di navigazione ed ottimizzare l&rsquo;esperienza di
      navigazione dell&rsquo;Utente. Fra questi Cookie rientrano, ad esempio, quelli per impostare la lingua e la valuta o
      per la gestione di statistiche da parte del Titolare del sito.</p>
    <p><strong>Altre tipologie di Cookie o strumenti terzi che potrebbero installarne</strong></p>
    <p>Alcuni dei servizi elencati di seguito raccolgono statistiche in forma aggregata ed anonima e potrebbero non
      richiedere il consenso dell&rsquo;Utente o potrebbero essere gestiti direttamente dal Titolare &ndash; a seconda di
      quanto descritto &ndash; senza l&rsquo;ausilio di terzi.</p>
    <p>Qualora fra gli strumenti indicati in seguito fossero presenti servizi gestiti da terzi, questi potrebbero &ndash;
      in aggiunta a quanto specificato ed anche all&rsquo;insaputa del Titolare &ndash; compiere attivit&agrave; di
      tracciamento dell&rsquo;Utente. Per informazioni dettagliate in merito, si consiglia di consultare le privacy policy
      dei servizi elencati.</p>
    <p><strong>Contattare l&rsquo;Utente</strong></p>
    <p><strong>Mailing list o newsletter (questa Applicazione)</strong></p>
    <p>Con la registrazione alla mailing list o alla newsletter, l&rsquo;indirizzo email dell&rsquo;Utente viene
      automaticamente inserito in una lista di contatti a cui potranno essere trasmessi messaggi email contenenti
      informazioni, anche di natura commerciale e promozionale, relative a questa Applicazione. L&rsquo;indirizzo email
      dell&rsquo;Utente potrebbe anche essere aggiunto a questa lista come risultato della registrazione a questa
      Applicazione o dopo aver effettuato un acquisto.</p>
    <p>Dati Personali raccolti: cognome, Cookie, email e nome.</p>
    <p><strong>Gestione contatti e invio di messaggi</strong></p>
    <p>Questo tipo di servizi consente di gestire un database di contatti email, contatti telefonici o contatti di
      qualunque altro tipo, utilizzati per comunicare con l&rsquo;Utente.<br/> Questi servizi potrebbero inoltre
      consentire di raccogliere dati relativi alla data e all&rsquo;ora di visualizzazione dei messaggi da parte dell&rsquo;Utente,
      cos&igrave; come all&rsquo;interazione dell&rsquo;Utente con essi, come le informazioni sui click sui collegamenti
      inseriti nei messaggi.</p>
    <p><strong>Mailchimp (The Rocket Science Group, LLC.)</strong></p>
    <p>Mailchimp &egrave; un servizio di gestione indirizzi e invio di messaggi email fornito da The Rocket Science Group,
      LLC.</p>
    <p>Dati Personali raccolti: cognome, Cookie, email, nome e varie tipologie di Dati.<br/> Luogo del trattamento: Stati
      Uniti &ndash;&nbsp;<a href="https://mailchimp.com/legal/privacy/">Privacy Policy</a>. Soggetto aderente al Privacy
      Shield.</p>
    <p><strong>Gestione di landing page e pagine di invito</strong></p>
    <p>Questo tipo di servizi permette di costruire e gestire landing page e pagine di invito, ossia pagine di
      presentazione di un prodotto o servizio, che possono consentire l&rsquo;inserimento delle proprie informazioni di
      contatto, come l&rsquo;indirizzo email.<br/> La gestione di queste pagine comporta il trattamento, da parte di
      questi servizi, dei Dati Personali raccolti tramite tali pagine, inclusi quelli di i Dati di Utilizzo.</p>
    <p><strong>Mailchimp Landing Page (The Rocket Science Group LLC)</strong></p>
    <p>Mailchimp Landing Page &egrave; un servizio di gestione di landing page fornito da The Rocket Science Group LLC che
      permette a questa Applicazione di raccogliere indirizzi email degli Utenti interessati al proprio servizio.<br/>
      Mailchimp Landing Page permette al Titolare del Trattamento di tener traccia ed analizzare la risposta da parte dell&rsquo;Utente,
      in termini di traffico o di comportamento, in relazione a modifiche della struttura, del testo o di qualunque altro
      componente delle landing page create.</p>
    <p>Dati Personali raccolti: cognome, Cookie, email, nome e numero di telefono.<br/> Luogo del trattamento: Stati Uniti
      &ndash;&nbsp;<a href="https://mailchimp.com/legal/privacy/">Privacy Policy</a>. Soggetto aderente al Privacy Shield.
    </p>
    <p><strong>Interazione con piattaforme di raccolta dati e altre terze parti</strong></p>
    <p>Questo tipo di servizi consente agli Utenti di interagire con le piattaforme di raccolta dati o altri servizi
      direttamente dalle pagine di questa Applicazione allo scopo di salvare e riutilizzare i dati.<br/> Nel caso in cui
      sia installato uno di questi servizi, &egrave; possibile che, anche nel caso gli Utenti non utilizzino il servizio,
      lo stesso raccolga Dati di Utilizzo relativi alle pagine in cui &egrave; installato.</p>
    <p><strong>Mailchimp widget (The Rocket Science Group LLC)</strong></p>
    <p>Il widget Mailchimp consente di interagire con il servizio di gestione degli indirizzi email e dell&rsquo;invio di
      messaggi Mailchimp fornito da The Rocket Science Group LLC.</p>
    <p>Dati Personali raccolti: cognome, Cookie, Dati di utilizzo, email, nome e numero di telefono.&nbsp;</p>
    <p>Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a href="https://mailchimp.com/legal/privacy/">Privacy Policy</a>.
      Soggetto aderente al Privacy Shield.</p>
    <p><strong>Interazione con social network e piattaforme esterne</strong></p>
    <p>Questo tipo di servizi permette di effettuare interazioni con i social network, o con altre piattaforme esterne,
      direttamente dalle pagine di questa Applicazione.<br/> Le interazioni e le informazioni acquisite da questa
      Applicazione sono in ogni caso soggette alle impostazioni privacy dell&rsquo;Utente relative ad ogni social network.
    </p>
    <p>Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico per le pagine dove il servizio &egrave;
      installato, anche quando gli Utenti non lo utilizzano.<br/> Si raccomanda di disconnettersi dai rispettivi servizi
      per assicurarsi che i dati elaborati su questa Applicazione non vengano ricollegati al profilo dell&rsquo;Utente.
    </p>
    <p><strong>Pulsante Mi Piace e widget sociali di Facebook (Facebook, Inc.)</strong></p>
    <p>Il pulsante &ldquo;Mi Piace&rdquo; e i widget sociali di Facebook sono servizi di interazione con il social network
      Facebook, forniti da Facebook, Inc.</p>
    <p>Dati Personali raccolti: Cookie e Dati di utilizzo.<br/> Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://www.facebook.com/privacy/explanation">Privacy Policy</a>. Soggetto aderente al Privacy Shield.</p>
    <p><strong>Protezione dallo SPAM</strong></p>
    <p>Questo tipo di servizi analizza il traffico di questa Applicazione, potenzialmente contenente Dati Personali degli
      Utenti, al fine di filtrarlo da parti di traffico, messaggi e contenuti riconosciuti come SPAM.</p>
    <p><strong>Google reCAPTCHA (Google Inc.)</strong></p>
    <p>Google reCAPTCHA &egrave; un servizio di protezione dallo SPAM fornito da Google Inc.<br/> L&rsquo;utilizzo del
      sistema reCAPTCHA &egrave; soggetto alla&nbsp;<a href="https://www.google.com/intl/it/policies/privacy/">privacy
        policy</a>&nbsp;e ai&nbsp;<a href="https://www.google.com/intl/it/policies/terms/">termini di utilizzo</a>&nbsp;di
      Google.</p>
    <p>Dati Personali raccolti: Cookie e Dati di utilizzo.<br/> Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://www.google.com/intl/it/policies/privacy/">Privacy Policy</a>. Soggetto aderente al Privacy Shield.</p>
    <p><strong>Remarketing e behavioral targeting</strong></p>
    <p>Questo tipo di servizi consente a questa Applicazione ed ai suoi partner di comunicare, ottimizzare e servire
      annunci pubblicitari basati sull&rsquo;utilizzo passato di questa Applicazione da parte dell&rsquo;Utente. Questa
      attivit&agrave; viene effettuata tramite il tracciamento dei Dati di Utilizzo e l&rsquo;uso di Cookie, informazioni
      che vengono trasferite ai partner a cui l&rsquo;attivit&agrave; di remarketing e behavioral targeting &egrave;
      collegata.</p>
    <p>Alcuni servizi offrono un&rsquo;opzione di remarketing basata sulle liste di indirizzi email.<br/> In aggiunta alle
      possibilit&agrave; di effettuare l&rsquo;opt-out offerte dai servizi di seguito riportati, l&rsquo;Utente pu&ograve;
      optare per l&rsquo;esclusione rispetto alla ricezione dei cookie relativi ad un servizio terzo, visitando la&nbsp;<a
        href="http://optout.networkadvertising.org/">pagina di opt-out del Network Advertising Initiative</a>.</p>
    <p><strong>Remarketing con Google Analytics (Google LLC)</strong></p>
    <p>Remarketing con Google Analytics &egrave; un servizio di remarketing e behavioral targeting fornito da Google LLC
      che collega l&rsquo;attivit&agrave; di tracciamento effettuata da Google Analytics e dai suoi Cookie con il network
      di advertising Google Ads ed il Cookie Doubleclick.</p>
    <p>Dati Personali raccolti: Cookie e Dati di utilizzo.<br/> Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a
      href="https://adssettings.google.com/authenticated?hl=it">Opt Out.</a>&nbsp;Soggetto aderente al Privacy Shield.</p>
    <p><strong>Remarketing Google Ads (Google LLC)</strong></p>
    <p>Remarketing Google Ads &egrave; un servizio di remarketing e behavioral targeting fornito da Google LLC che collega
      l&rsquo;attivit&agrave; di questa Applicazione con il network di advertising Google Ads ed il Cookie
      DoubleClick.</p>
    <p>Gli Utenti possono scegliere di non utilizzare i cookie di Google per la personalizzazione degli annunci visitando
      le&nbsp;<a href="https://adssettings.google.com/authenticated">Impostazioni annunci</a>&nbsp;di Google.</p>
    <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
    <p>Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a
      href="https://adssettings.google.com/authenticated?hl=it">Opt Out</a>. Soggetto aderente al Privacy Shield.</p>
    <p><strong>AdRoll (AdRoll, Inc.)</strong></p>
    <p>AdRoll &egrave; un servizio di advertising fornito da AdRoll, Inc. Dati Personali raccolti: Cookie e Dati di
      utilizzo.<br/> Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a href="https://www.adroll.com/about/privacy">Privacy
        Policy</a>&nbsp;&ndash;&nbsp;<a href="https://app.adroll.com/optout/">Opt Out</a>.</p>
    <p><strong>Criteo Dynamic Retargeting (Criteo SA)</strong></p>
    <p>Criteo Dynamic Retargeting &egrave; un servizio di remarketing e behavioral targeting fornito da Criteo SA., che
      collega l&rsquo;attivit&agrave; di questa Applicazione con il network di advertising Criteo.</p>
    <p>Dati Personali raccolti: Cookie e Dati di utilizzo.<br/> Luogo del trattamento: Francia &ndash;&nbsp;<a
      href="http://www.criteo.com/it/privacy/full-privacy-text/">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a
      href="http://www.criteo.com/it/privacy/">Opt Out</a>.</p>
    <p><strong>Facebook Remarketing (Facebook, Inc.)</strong></p>
    <p>Facebook Remarketing &egrave; un servizio di remarketing e behavioral targeting fornito da Facebook, Inc. che
      collega l&rsquo;attivit&agrave; di questa Applicazione con il network di advertising Facebook.</p>
    <p>Dati Personali raccolti: Cookie e Dati di utilizzo.<br/> Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://www.facebook.com/about/privacy/">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a
      href="https://www.aboutads.info/choices/">Opt Out</a>. Soggetto aderente al Privacy Shield.</p>
    <p><strong>Statistica</strong></p>
    <p>I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati
      di traffico e servono a tener traccia del comportamento dell&rsquo;Utente.</p>
    <p><strong>Google Analytics (Google Inc.)</strong></p>
    <p>Google Analytics &egrave; un servizio di analisi web fornito da Google Inc. (&ldquo;Google&rdquo;). Google utilizza
      i Dati Personali raccolti allo scopo di tracciare ed esaminare l&rsquo;utilizzo di questa Applicazione, compilare
      report e condividerli con gli altri servizi sviluppati da Google.<br/> Google potrebbe utilizzare i Dati Personali
      per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.</p>
    <p>Dati Personali raccolti: Cookie e Dati di utilizzo.<br/> Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://www.google.com/intl/it/policies/privacy/">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a
      href="https://tools.google.com/dlpage/gaoptout?hl=it">Opt Out</a>. Soggetto aderente al Privacy Shield.</p>
    <p><strong>Google Analytics con IP anonimizzato (Google Inc.)</strong></p>
    <p>Google Analytics &egrave; un servizio di analisi web fornito da Google Inc. (&ldquo;Google&rdquo;). Google utilizza
      i Dati Personali raccolti allo scopo di tracciare ed esaminare l&rsquo;utilizzo di questa Applicazione, compilare
      report e condividerli con gli altri servizi sviluppati da Google.<br/> Google potrebbe utilizzare i Dati Personali
      per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.</p>
    <p>Questa integrazione di Google Analytics rende anonimo il tuo indirizzo IP. L&rsquo;anonimizzazione funziona
      abbreviando entro i confini degli stati membri dell&rsquo;Unione Europea o in altri Paesi aderenti all&rsquo;accordo
      sullo Spazio Economico Europeo l&rsquo;indirizzo IP degli Utenti. Solo in casi eccezionali, l&rsquo;indirizzo IP sar&agrave;
      inviato ai server di Google ed abbreviato all&rsquo;interno degli Stati Uniti.</p>
    <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
    <p>Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a href="https://www.google.com/intl/it/policies/privacy/">Privacy
      Policy</a>&nbsp;&ndash;&nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=it">Opt Out</a>. Soggetto aderente
      al Privacy Shield.&nbsp;</p>
    <p><strong>Come posso esprimere il consenso all&rsquo;installazione di Cookie?</strong></p>
    <p>In aggiunta a quanto indicato in questo documento, l&rsquo;Utente pu&ograve; gestire le preferenze relative ai
      Cookie direttamente all&rsquo;interno del proprio browser ed impedire &ndash; ad esempio &ndash; che terze parti
      possano installarne. Tramite le preferenze del browser &egrave; inoltre possibile eliminare i Cookie installati in
      passato, incluso il Cookie in cui venga eventualmente salvato il consenso all&rsquo;installazione di Cookie da parte
      di questo sito. L&rsquo;Utente pu&ograve; trovare informazioni su come gestire i Cookie con alcuni dei browser pi&ugrave;
      diffusi ad esempio ai seguenti indirizzi:&nbsp;<a
        href="https://support.google.com/chrome/answer/95647?hl=it&amp;p=cpn_cookies">Google Chrome</a>,&nbsp;<a
        href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">Mozilla Firefox</a>,&nbsp;<a
        href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/">Apple Safari</a>&nbsp;e&nbsp;<a
        href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies">Microsoft Internet Explorer</a>.
    </p>
    <p>Con riferimento a Cookie installati da terze parti, l&rsquo;Utente pu&ograve; inoltre gestire le proprie
      impostazioni e revocare il consenso visitando il relativo link di opt out (qualora disponibile), utilizzando gli
      strumenti descritti nella privacy policy della terza parte o contattando direttamente la stessa.</p>
    <p>Fermo restando quanto precede, l&rsquo;Utente pu&ograve; avvalersi delle informazioni fornite da&nbsp;<a
      href="http://www.youronlinechoices.eu/">EDAA</a>&nbsp;(UE),&nbsp;<a
      href="https://www.networkadvertising.org/understanding-digital-advertising">Network Advertising Initiative</a>&nbsp;(USA)
      e&nbsp;<a href="https://www.aboutads.info/consumers/">Digital Advertising Alliance</a>&nbsp;(USA),&nbsp;<a
        href="https://youradchoices.ca/understanding-online-advertising/">DAAC</a>&nbsp;(Canada),&nbsp;<a
        href="http://www.ddai.info/optout">DDAI</a>&nbsp;(Giappone) o altri servizi analoghi. Con questi servizi &egrave;
      possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare,
      pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite dal presente
      documento.</p>
    <p><strong>Titolare del Trattamento dei Dati</strong></p>
    <p>xLoma S.r.l.s.&nbsp;<br/> Via Anguillara,8&nbsp;<br/> 00048 Nettuno (Roma) &ndash; Italia&nbsp;<br/> P.Iva:
      IT14873011002</p>
    <p><strong>Indirizzo email del Titolare:&nbsp;</strong><a href="mailto:privacy@xloma.com">privacy@xloma.com</a></p>
    <p>Dal momento che l&rsquo;installazione di Cookie e di altri sistemi di tracciamento operata da terze parti tramite i
      servizi utilizzati all&rsquo;interno di questa Applicazione non pu&ograve; essere tecnicamente controllata dal
      Titolare, ogni riferimento specifico a Cookie e sistemi di tracciamento installati da terze parti &egrave; da
      considerarsi indicativo. Per ottenere informazioni complete, l&rsquo;Utente &egrave; invitato a consultare la
      privacy policy degli eventuali servizi terzi elencati in questo documento.</p>
    <p>Vista l&rsquo;oggettiva complessit&agrave; di identificazione delle tecnologie basate sui Cookie l&rsquo;Utente
      &egrave; invitato a contattare il Titolare qualora volesse ricevere qualunque approfondimento relativo all&rsquo;utilizzo
      dei Cookie stessi tramite questa Applicazione.</p>
    <p><strong>Definizioni e riferimenti legali</strong></p>
    <p><strong>Dati Personali (o Dati)</strong></p>
    <p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con
      qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o
      identificabile una persona fisica.</p>
    <p><strong>Dati di Utilizzo</strong></p>
    <p>Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze
      integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall&rsquo;Utente
      che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l&rsquo;orario
      della richiesta, il metodo utilizzato nell&rsquo;inoltrare la richiesta al server, la dimensione del&nbsp;file
      ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il
      paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie
      connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all&rsquo;itinerario
      seguito all&rsquo;interno dell&rsquo;Applicazione, con particolare riferimento alla sequenza delle pagine
      consultate, ai parametri relativi al sistema operativo e all&rsquo;ambiente informatico dell&rsquo;Utente.</p>
    <p><strong>Utente</strong></p>
    <p>L&rsquo;individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l&rsquo;Interessato.</p>
    <p><strong>Interessato</strong></p>
    <p>La persona fisica cui si riferiscono i Dati Personali.</p>
    <p><strong>Responsabile del Trattamento (o Responsabile)</strong></p>
    <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per
      conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
    <p><strong>Titolare del Trattamento (o Titolare)</strong></p>
    <p>La persona fisica o giuridica, l&rsquo;autorit&agrave; pubblica, il servizio o altro organismo che, singolarmente o
      insieme ad altri, determina le finalit&agrave; e i mezzi del trattamento di dati personali e gli strumenti adottati,
      ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare
      del Trattamento, salvo quanto diversamente specificato, &egrave; il titolare di questa Applicazione.</p>
    <p><strong>Questa Applicazione</strong></p>
    <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>
    <p><strong>Servizio</strong></p>
    <p>Il Servizio fornito da questa Applicazione cos&igrave; come definito nei relativi termini (se presenti) su questo
      sito/applicazione.</p>
    <p><strong>Unione Europea (o UE)</strong></p>
    <p>Salvo ove diversamente specificato, ogni riferimento all&rsquo;Unione Europea contenuto in questo documento si
      intende esteso a tutti gli attuali stati membri dell&rsquo;Unione Europea e dello Spazio Economico Europeo.</p>
    <p><strong>Cookie</strong></p>
    <p>Piccola porzione di dati conservata all&rsquo;interno del dispositivo dell&rsquo;Utente.</p>
    <p><strong>Riferimenti legali</strong></p>
    <p>La presente informativa privacy &egrave; redatta sulla base di molteplici ordinamenti legislativi, inclusi gli
      artt. 13 e 14 del Regolamento (UE) 2016/679.</p>
    <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.&nbsp;</p>
    <p><strong>Ultima modifica: 21 ottobre 2021</strong></p>
    <p>&nbsp;</p>

  </div>
</ng-template>
