<div class="d-block d-lg-none">
  <section>
    <div class="position-relative first">
      <picture>
        <source
          media="(min-width: 780px)"
          srcset="/assets/images/home/bannerpc.jpg">
        <img class="w-100" src="/assets/images/home/banner.png">
      </picture>
      <h1>
        <b>Sposi</b> <br class="d-block d-md-none"> e <br class="d-block d-md-none"><b>Fornitori</b><br>
        tutto su un solo
        <br class="d-none d-md-block"> <b>Marketplace!</b>
      </h1>
      <h5 class="subtitle"><b>Wedding Smart<span class="copyright">&#174;</span> </b><br>il primo <b>marketplace</b><br>
        al <b>mondo</b> sul <b>matrimonio <br> tutto italiano</b></h5>
      <div class="subtitle-final">
        <button class="btn btn-primary rounded-15" (click)="scrollToBottom()"
        >REGISTRATI ORA GRATIS
        </button>
        <div class="text-center pb-3">
          <!-- <small>Se sei già registrato, </small> -->
          <button class="btn btn-outline-primary capitalize mt-3 rounded-15" (click)="openLogin()">OPPURE EFFETTUA IL LOGIN</button>
        </div>
        <div class="background-lightgray">
          <div class="position-relative back-part1">

            <!--     Daniele   <img src="assets/images/welcome/part1.png">-->
            <h5 class="mt-3 font-weight-normal"><b>Unico </b><br> nel suo genere</h5>
            <h6 class="text-center">
              <a class="color-primary" target="_blank" href="assets/video/spot.mp4">< Guarda il video Spot ></a>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="background-lightgray pb-3">
    <br><br><br>
    <h5 class="mt-7">Siamo il primo <br> <b>marketplace</b> costruito <br>attorno alle esigenze dei <br><b>futuri
      sposi</b> e delle <b>aziende</b><br>
      del <b>settore matrimonio.</b> <br> <b>Registrati ora gratis</b>
    </h5>

    <div class="text-center">
      <button class="btn btn-primary" style="border-radius: 16px;" (click)="scrollToBottom()"
      >Registrati gratis
      </button>
    </div>

  </section>

  <section>

    <h1 class="mt-1">
      Quali sono i <br>vantaggi per i <br> <b>futuri sposi?</b>
    </h1>
    <div class="br-lightgray">
      <div class="background-lightgray verticalbar"></div>
      <div class="card m-3">
        <div class="card-body px-2">
          <div class="card-title">
            <label>1. Sconti fino a 2.500 €</label>
          </div>
          <div class="card-body p-0">
            <label>
              Il primo vantaggio che offre in assoluto Wedding Smart<span class="copyright-card">&#174;</span> è quello
              di
              regalare <b>a tutti i futuri sposi</b>, che acquistano all'interno del <b>marketplace</b>,
              dei buoni sconto, fino a <b style="font-size: 18px">2.500 €</b>
            </label>
            <label>
              <b>
                Più acquisti si fanno, più sconti si riceveranno.
              </b>
            </label>
            <label>
              <b>
                Non è il solito concorso dove chi vince è sempre uno solo
              </b>
            </label>
            <label>
              Sul <b>marketplace</b> di Wedding Smart<span class="copyright-card">&#174;</span> <b class="ml-1">i buoni
              sconto</b>
              sono
              per tutti i futuri sposi ma solo per un numero limitato (250)
            </label>
            <label>
              <b>Sbrigati quindi, non rimanere senza, ne sono rimasti pochi!</b>
            </label>
            <a class="color-primary font-weight-bold" (click)="scrollToBottom()">Registrati ora gratis.</a>
          </div>
        </div>
      </div>
      <div class="card m-3">
        <div class="card-body px-2">
          <div class="card-title">
            <label>2. Crea annunci mirati "GRATIS"</label>
          </div>
          <div class="card-body p-0">
            <label>
              Inizia subito a creare i tuoi annunci personalizzati. <br> Inserisci il budget massimo che sei disposto a
              spendere per il servizio o prodotto che stai cercando
              e in breve tempo potrai ricevere, da parte dei fornitori interesati al tuo annuncio, offerte con importo
              uguale o inferiori rispetto al budget da te indicato.<br>
              Non dovrai più visionare numerosi preventivi.<br>
              <b>Cosa aspetti?</b>
              <a class="ml-1 color-primary font-weight-bold" (click)="scrollToBottom()">Registrati ora gratis.</a>
            </label>
          </div>
        </div>
      </div>
      <div class="card m-3">
        <div class="card-body px-2">
          <div class="card-title">
            <label>3. Budget sempre sotto controllo</label>
          </div>
          <div class="card-body p-0">
            <label>
              Dalla tua area riservata, potrai tenere sempre sotto controllo il tuo budget.<br>
              Controllare quanto stai spendendo, quanto budget ti è rimasto, quanto sconto hai ricevuto da parte di
              Wedding
              Smart<span class="copyright-card">&#174;</span>
              e, cosa più importante, quanto stai risparmiando rispetto al budget iniziale che ti sei programmato di
              spendere.
            </label>
          </div>
        </div>
      </div>
      <div class="card m-3">
        <div class="card-body px-2">
          <div class="card-title">
            <label>4. Acquista dallo store dei fornitori</label>
          </div>
          <div class="card-body p-0">
            <label>
              Potrai acquistare anche direttamente dallo store dei fornitori senza aspettare noiosi e inutili
              preventivi.
            </label>
            <label>
              Con il <b>marketplace</b> di Wedding Smart<span class="copyright-card">&#174;</span> <b
              class="ml-1">acquisti</b> in real
              time.
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button class="btn btn-primary" style="border-radius: 16px;" (click)="scrollToBottom()"
      >Registrati gratis
      </button>
    </div>
  </section>
  <section class="position-relative">
    <div class="verticalbar background-lightgray" style="top: 22rem!important;">

    </div>

    <div class="background-lightgray">

      <h1 class="mt-3 back-part2 ">
        Quali sono i <br>vantaggi per le <br> <b>aziende?</b>
      </h1>
    </div>
    <div class="card m-3" style="margin-top: -2.5rem!important">
      <div class="card-body px-2">
        <div class="card-title">
          <label>1. Creazione store gratis</label>
        </div>
        <div class="card-body p-0">
          <label>
            Il primo vantaggio che offre in assoluto Wedding Smart<span class="copyright-card">&#174;</span> è quello di
            far creare
            <b>a tutti i fornitori</b>, uno store gratuito
          </label>
        </div>
      </div>
    </div>
    <div class="card m-3">
      <div class="card-body px-2">
        <div class="card-title">
          <label>2. Dashboard dedicata</label>
        </div>
        <div class="card-body p-0">
          <label>
            Avrai a disposizione una <b>dashboard dedicata</b> dove potrai gestire al meglio la tua <b>vetrina</b> ed il
            <b>tuo store.</b>
          </label>
          <label>
            Potrai monitorare l'inventario, visualizzare statistiche, SEO, controllare quanto <b>hai venduto</b> e <b>guadagnato</b>
            giornalmente e tanto altro ancora.
          </label>
          <label>
            <b>Cosa aspetti?</b>
            <a class="ml-1 color-primary font-weight-bold" (click)="scrollToBottom()">Registrati ora gratis.</a>
          </label>
        </div>
      </div>
    </div>
    <div class="card m-3">
      <div class="card-body px-2">
        <div class="card-title">
          <label>3. Invio proposte direttamente nell'annuncio sposi</label>
        </div>
        <div class="card-body p-0">
          <label>
            Potrai inviare le tue proposte personalizzate direttamente nell'annuncio creato dai futuri sposi, ottenendo
            così una risposta immediata
          </label>
          <label>
            Basta inviare inutili preventivi, da oggi vendi direttamente
          </label>
        </div>
      </div>
    </div>
    <div class="card m-3">
      <div class="card-body px-2">
        <div class="card-title">
          <label>4. Accelera e semplifica i processi di vendita</label>
        </div>
        <div class="card-body p-0">
          <label>
            Con questo <b>marketplace innovativo</b> potrai <b>accelerare</b> e <b>semplificare</b> tutti i tuoi <b>processi
            di vendita</b>
          </label>
          <label>
            I futuri sposi potranno visionare direttamente nel tuo store i servizi o prodotti e procedere direttamente
            all'acquisto
          </label>
          <label>
            Nello stesso tempo potrai anche inviare le tue proposte personalizzate direttamente negli annunci creati dai
            futuri sposi, così facendo avrai più opportunità di vendita.
          </label>
          <label>
            Con il <b>marketplace</b> di Wedding Smart<span class="copyright-card">&#174;</span> <b
            class="ml-1">vendi</b>
            in real time
          </label>
        </div>
      </div>
    </div>

    <div class="text-center">
      <button class="btn btn-primary" style="border-radius: 16px;" (click)="scrollToBottom()"
      >Registrati gratis
      </button>
    </div>
  </section>

  <section class="position-relative" style="margin-top: -1rem; padding-top: 1rem;">
    <div class="verticalbar background-lightgray" style="bottom: 16rem!important;"></div>

    <h1 class="mt-3">
      Con il <b>marketplace</b> <br>di <span class="position-relative">

    Wedding Smart<span class="copyright">&#174;</span> </span><br>
      <b>acquisti</b> e <b>vendite</b><br> in real time.
    </h1>
    <div class="background-lightgray">
      <div class="text-right back-part3">
        <img src="https://www.weddingsmart.it/assets/images/mano-welcome.png" style="width: 80%">
      </div>
    </div>

  </section>
  <div class="w-100 background-lightgray" style="height: 15px;">

  </div>
  <section class="position-relative" style="margin-top: -1rem; padding-top: 1rem;">
    <div class="background-lightgray verticalbar"></div>
    <h4 class="mt-3 text-center">
      <b id="registerSection">
        Registrati ora gratis
      </b>
    </h4>
    <h6 class="small-h6"  style="font-size:22px">
<!--      Registrati e rimani in contatto per il lancio del <br>marketplace-->
      Se sei già registrato, <button class="btn btn-primary" (click)="openLogin()">clicca qui </button>
    </h6>
    <app-register [innested]="true"></app-register>
  </section>
</div>
<div class="d-none d-lg-block">
  <section>
    <div class="position-relative first">
      <picture>
        <source
          media="(min-width: 780px)"
          srcset="/assets/images/home/bannerpc.jpg">
        <img class="w-100" src="/assets/images/home/banner.png">
      </picture>
      <h1>
        <b>Sposi</b> <br class="d-block d-md-none"> e <br class="d-block d-md-none"><b>Fornitori</b><br>
        tutto su un solo
        <br class="d-none d-md-block"> <b>Marketplace!</b>
      </h1>
      <h5 class="subtitle"
          style="font-size: 40px!important;line-height:42px!important;"
      ><b>Wedding Smart<span class="copyright">&#174;</span> </b><br>il primo <b>marketplace</b><br>
        al <b>mondo</b> sul <b>matrimonio <br> tutto italiano</b></h5>
      <div class="subtitle-final">
        <button class="btn btn-primary rounded-15" (click)="scrollToBottom()"
        >REGISTRATI ORA GRATIS
        </button>
        <div class="text-center"  style="font-size:22px">
          <!-- <small>Se sei già registrato,</small>  -->
          <button class="btn btn-outline-primary capitalize mt-3 rounded-15" (click)="openLogin()">OPPURE EFFETTUA IL LOGIN</button>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="position-relative">

        <!--        <img src="assets/images/welcome/part1.png">-->
        <h5 class="mt-3 font-weight-normal"><b>Unico </b><br> nel suo genere</h5>
      </div>
    </div>
  </section>
  <section>
    <div class="background-lightgray pt-3 pb-3 mt-4">
      <div class="row no-gutters">
        <div class="col-6">
          <div style="margin-left: 5rem; margin-right: 5rem;">
            <img src="assets/images/welcome/logo-desktop.jpg" class="w-100">
          </div>
          <div class="text-center mt-4">
              <span class="btn-play">
                <i class="fa fa-play"></i>
              </span>
            <h6 class="text-center mt-3">
              <a class="color-primary" target="_blank" href="assets/video/spot.mp4"
                 style="font-size: 28px;"
              >< Guarda il video Spot ></a>
            </h6>
          </div>
        </div>
        <div class="col-6 bg-white">

          <h5 class="mt-5 text-left pl-5" style="line-height: 48px">Siamo il primo <br> <b>marketplace</b> costruito
            <br>attorno alle esigenze dei <br><b>futuri
              sposi</b> e delle <b>aziende</b><br>
            del <b>settore matrimonio.</b> <br> <b>Registrati ora gratis</b>
          </h5>

          <div class="text-center mb-3">
            <button class="btn btn-primary" style="border-radius: 16px; font-size: 22px;" (click)="scrollToBottom()"
            >Registrati gratis
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="mt-5 pt-5 pl-5 pr-5">
      <div class="row">
        <div class="col text-center">
          <h5 class="text-center">Quali sono i vantaggi per <b>i</b>
            <div class="mt-3">
              <b>futuri sposi?</b>
            </div>
          </h5>
          <button class="btn btn-primary" style="font-size: 19px; margin-top: 13px;" (click)="scrollToBottom()"
          >Registrati gratis
          </button>
        </div>
        <!-- <div class="col-auto text-center">
        </div> -->
      </div>
    </div>
    <div class="mt-5 pl-5 w-100" style="overflow-x: auto">
      <div class="d-flex" style="width: fit-content">
        <div class="card m-3 position-relative" style="width: 450px;">
          <div class="number-card" style="background: #00A688">
            1
          </div>
          <div class="card-body">
            <div class="card-title">
              <label>Sconti fino a 2.500 €</label>
            </div>
            <div class="card-body p-0">
              <label>
                Il primo vantaggio che offre in assoluto Wedding Smart<span class="copyright-card">&#174;</span> è
                quello
                di
                regalare <b>a tutti i futuri sposi</b>, che acquistano all'interno del <b>marketplace</b>,
                dei buoni sconto, fino a <b style="font-size: 18px">2.500 €</b>
              </label>
              <label>
                <b>
                  Più acquisti si fanno, più sconti si riceveranno.
                </b>
              </label>
              <label>
                <b>
                  Non è il solito concorso dove chi vince è sempre uno solo
                </b>
              </label>
              <label>
                Sul <b>marketplace</b> di Wedding Smart<span class="copyright-card">&#174;</span> <b class="ml-1">i
                buoni sconto</b>
                sono
                per tutti i futuri sposi ma solo per un numero limitato (250)
              </label>
              <label>
                <b>Sbrigati quindi, non rimanere senza, ne sono rimasti pochi!</b>
              </label>
              <a class="color-primary font-weight-bold" (click)="scrollToBottom()">Registrati ora gratis.</a>
            </div>
          </div>
        </div>
        <div class="card m-3 position-relative" style="width: 450px;">
          <div class="number-card" style="background: #2E465E">
            2
          </div>
          <div class="card-body">
            <div class="card-title">
              <label>Crea annunci mirati "GRATIS"</label>
            </div>
            <div class="card-body p-0">
              <label>
                Inizia subito a creare i tuoi annunci personalizzati. <br> Inserisci il budget massimo che sei disposto
                a
                spendere per il servizio o prodotto che stai cercando
                e in breve tempo potrai ricevere, da parte dei fornitori interesati al tuo annuncio, offerte con importo
                uguale o inferiori rispetto al budget da te indicato.<br>
                Non dovrai più visionare numerosi preventivi.<br><br>
                <b>Cosa aspetti?</b><br>
                <a class="ml-1 color-primary font-weight-bold" (click)="scrollToBottom()">Registrati ora gratis.</a>
              </label>
            </div>
          </div>
        </div>
        <div class="card m-3 position-relative" style="width: 450px;">
          <div class="number-card" style="background: var(--primary-color)">
            3
          </div>
          <div class="card-body">
            <div class="card-title">
              <label>Budget sempre sotto controllo</label>
            </div>
            <div class="card-body p-0">
              <label>
                Dalla tua area riservata, potrai tenere sempre sotto controllo il tuo budget.<br>
                Controllare quanto stai spendendo, quanto budget ti è rimasto, quanto sconto hai ricevuto da parte di
                Wedding
                Smart<span class="copyright-card">&#174;</span>
                e, cosa più importante, quanto stai risparmiando rispetto al budget iniziale che ti sei programmato di
                spendere.
                <br><br><br>
                <a class="ml-1 color-primary font-weight-bold" (click)="scrollToBottom()">Registrati ora gratis.</a>
              </label>
            </div>
          </div>
        </div>
        <div class="card m-3 position-relative" style="width: 450px;">
          <div class="number-card" style="background: var(--secondary-color)">
            4
          </div>
          <div class="card-body">
            <div class="card-title">
              <label>Acquista dallo store dei fornitori</label>
            </div>
            <div class="card-body p-0">
              <label>
                Potrai acquistare anche direttamente dallo store dei fornitori senza aspettare noiosi e inutili
                preventivi.
              </label>
              <label>
                Con il <b>marketplace</b> di Wedding Smart<span class="copyright-card">&#174;</span> <b
                class="ml-1">acquisti</b> in real
                time.
                <br><br><br>
                <a class="ml-1 color-primary font-weight-bold" (click)="scrollToBottom()">Registrati ora gratis.</a>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="mx-3 background-lightpink position-relative" style="margin-top: 50px;">
      <div class="background-lightgray square-absolute"></div>
      <div class="row no-gutters">
        <div class="col-9">
          <h1 class="text-center" style="font-size: 58px; margin-top: 190px; line-height: 65px;">
            Con il <b>marketplace</b> di Wedding Smart<span class="copyright-card">&#174;</span>
            <br><b>acquisti</b> e <b>vendite</b> in real time.
          </h1>
        </div>
        <div class="col-3 pt-5">

          <div class="text-right">
            <img src="https://www.weddingsmart.it/assets/images/mano-welcome.png" style="width: 80%">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container-fluid fivethmd" style="margin-top: 80px;">
    <h1 class="text-left pl-5">Quali sono i vantaggi per le<br><b>aziende?</b></h1>

    <img src="/assets/images/home/fornitoripc.png">
    <div class="first">
      <h5 class="text-left"><b>Store, vetrina e dashboard dedicata "Gratis"</b></h5>
      <p>
        Il primo vantaggio che offre in assoluto Wedding Smart<span class="copyright-card">&#174;</span> è quello di
        far creare
        <b>a tutti i fornitori</b>, uno store gratuito
        Avrai a disposizione una <b>dashboard dedicata</b> dove potrai gestire al meglio la tua <b>vetrina</b> ed il
        <b>tuo store.</b>
        Potrai monitorare l'inventario, visualizzare statistiche, SEO, controllare quanto <b>hai venduto</b> e <b>guadagnato</b>
        giornalmente e tanto altro ancora.
      </p>
    </div>

    <div class="second">
      <h5 class="text-right"><b>Invia proposte direttamente nell'annuncio sposi</b></h5>
      <p class="text-right">Potrai inviare le tue proposte personalizzate direttamente nell'annuncio degli sposi
        ottenendo
        così una risposta immediata.
        <br>Basta inviare inutili preventivi, da oggi vendi direttamente </p>
    </div>

    <div class="third">
      <h5 class="text-left"><b>Accelera e semplifica i processi di vendita</b></h5>
      <p>Con questo marketplace innovativo potrai accelerare e semplificare tutti i tuoi processi di vendita. I futuri
        sposi potranno visionare direttamente nel tuo store i servizi
        o prodotti e procedere direttamente all'acquisto. Nello stesso tempo potrai anche inviare le tue proposte
        personalizzate direttamente negli annunci creati dai futuri sposi, così
        facendo avrai più opportunità di vendita</p>
    </div>
  </section>
  <section>

    <h1 class="mt-3" id="registerSection2">
      <b>
        Cosa aspetti?<br>
        Registrati ora gratis
      </b>
    </h1>
    <h6 class="small-h6 mt-3" style="font-size:22px">
      <small>Se sei già registrato, </small> <button class="btn btn-primary" (click)="openLogin()">clicca qui </button>
    </h6>
    <app-register [innested]="true"></app-register>
  </section>
</div>

