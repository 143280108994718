import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbCarousel, NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {HttpService} from '../../services/http.service';
import {MENU_LINKS} from '../../objects/Constants';
import {Observable} from 'rxjs';
import { IVetrina } from 'src/app/objects/IVetrina';
import {IAuction} from '../../objects/IAuction';
import {IReview, IReviewOffer} from '../../objects/IReview';
import {CommunicatorService} from '../../services/communicator.service';
import {filter, switchMap, tap, toArray} from 'rxjs/operators';
import {SeoEnum} from '../../objects/SeoEnum';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-how-work',
  templateUrl: './how-work.component.html',
  styleUrls: ['./how-work.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HowWorkComponent implements OnInit {
  @ViewChild('carousel') carousel: NgbCarousel;
  vetrine$: Observable<IVetrina[]>
  auctions$: Observable<IAuction[]> 
  reviews$: Observable<{store: IReview[], offers: IReviewOffer[]}>
  random = Math.random() * 1000;
  multipleAuctions = [];
  constructor(config: NgbCarouselConfig, private httpService: HttpService, public communicator: CommunicatorService, private seoService: SeoService) {
    config.showNavigationArrows = false;
    config.showNavigationIndicators = false;
    this.vetrine$ = this.httpService.getVetrine({city: '', subcategories: {
      'Sposa' : MENU_LINKS[0].links, 'Sposo': MENU_LINKS[1].links, 'Fornitori': MENU_LINKS[2].links,
        'Ricevimento': MENU_LINKS[3].links, 'Pets Wedding': MENU_LINKS[4].links}
    });
    this.auctions$ =  this.httpService.getAuctions({city: '', type:'', subcategories: {
      'Sposa' : MENU_LINKS[0].links, 'Sposo': MENU_LINKS[1].links, 'Fornitori': MENU_LINKS[2].links,
      'Ricevimento': MENU_LINKS[3].links, 'Pets Wedding': MENU_LINKS[4].links}
    }).pipe(
      switchMap(array => array),
      filter(e => e.is_expired !== '1'),
      toArray(),
      tap(auctions => {
        for (const multiple of [2,4,6,8,10,12])
        if (auctions.length >= multiple - 1) {
          this.multipleAuctions.push(multiple)
        }
      })
    );
    this.reviews$ = this.httpService.getReviews();
  }

  ngOnInit(): void {
    this.httpService.getSeo(SeoEnum.home).subscribe(resp => {
      this.seoService.setConfig(resp, SeoEnum.home);
    });
  }

  goTo(url: string) {
    if (url) {
      window.location.href = url;
    }
  }

  scrollTo(discover: string) {
    const el = document.getElementById(discover);
    // window.scrollTo(0, el.scrollTop);
    el.scrollIntoView({behavior: 'smooth'});
  }
}
