import {IProduct} from './IProduct';
import {ITransactionItem} from 'ngx-paypal';
import {OrderDetail} from '../pages/order-detail/orderDetail';

export class Utility {
  static getReasonsReso = [
    'Acquistato per errore', 'Miglior prezzo disponibile', 'Prestazioni o qualità non adeguate',
    'Incompatibile o inadeguato', 'Articolo danneggiato, confenzione esterna intatta', 'Articolo arrivato in ritardo',
    'Componenti o accessori mancanti', 'Articolo e confezione esterna danneggiati', 'Ricevuto articolo errato',
    'Articolo difettoso o non funzionante', 'Ricevuto articolo non ordinato (nessun rimborso necessario)', 'Non più necessario',
    'Acquisto non autorizzato', 'Il prodotto o il manuale non è in italiano', 'Desccrizione sul sito non accurata'
  ];
  static checkScrollBody(isOpen: boolean) {
    const body = document.getElementsByTagName('body');
    if (isOpen) {
      body[0].classList.add('overflow-hidden');
    } else {
      body[0].classList.remove('overflow-hidden');
    }
  }

  static convertPrice(value: string, showIva = false, afterEuro = false): string {
    if (!value) {
      return '';
    }
    const values = value.split('.') as string[];
    if (values[0].length > 3) {
      values[0] = values[0].substr(0, values[0].length - 3) + '.' + values[0].substr(values[0].length - 3, values[0].length);
    }
    value = values[0];
    if (values[1]) {
      if (values[1].length === 1) {
        values[1] += '0';
      }
      value += ',' + values[1];
    } else {
      value += ',00';
    }
    if (showIva) {
      value += ' *';
    }
    if (afterEuro) {
      return value + ' €';
    }
    return '€ ' + value;
  }

  static calcTransactionsByProducts(buyProducts: IProduct[]) {
    const transactionItem: ITransactionItem[] = [];
    let total = 0;
    for (const product of buyProducts) {
      const quantity = product.quantity;
      transactionItem.push({
        sku: product.id + '',
        name: product.title,
        quantity: quantity + '',
        category: 'DIGITAL_GOODS',
        unit_amount: {
          currency_code: 'EUR',
          value: (+product.pay).toFixed(2)
        }
      });
      const value = (+product.pay * quantity).toFixed(2);
      total += +value;
    }
    return {transactionItem, total};
  }

  static round(num: number): string {
    return (Math.round((num + Number.EPSILON) * 100) / 100) + '';
  }

  static getName(author: string) {
    if (author !== 'Utente sposo') {

      const names = author.split(' ');
      return names[0] + ' ' + names[1].slice(0, 1) + '.';
    }
    return author;
  }

  static resoHtml(detailObj: OrderDetail) {
    const addressed = detailObj.addressbilling.split(',');
    const addressed2 = detailObj.selleraddress.split(',');
    return '<div style="border:2px solid black; padding: 16px; margin: 100px 48px;">' +
      '<b>Mittente</b><br>' + detailObj.buyerFullname + '<br>' + addressed[0] + '<br>' + addressed.slice(1).join(', ') +
      '<br>' + detailObj.buyerPhone + '<br>' +
      '<div style="text-align: right">' +  '<b>Destinatario</b><br>' + detailObj.sellerfullname + '<br>' + addressed2[0] +
      '<br>' + addressed2.slice(1).join(', ') + '<br>' + detailObj.sellerphone +
      '<br>' + 'Ordine n.' + detailObj.id +
      '</div> ' +
      '</div>';
  }
}
