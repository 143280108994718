import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {UtilityService} from './utility.service';
import {finalize, tap} from 'rxjs/operators';
import {Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

export class InterceptorService implements HttpInterceptor {

  constructor(private utilityService: UtilityService, @Inject(PLATFORM_ID) private platformId: object) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({
      Authorization: (localStorage.getItem('authorization') ? localStorage.getItem('authorization')  : ''),
    });
    let queryParams = '';
    if (isPlatformBrowser(this.platformId)) {
      if (!document.cookie && localStorage.getItem('authorization')) {
        document.cookie += '; authorization=' + localStorage.getItem('authorization');
      }
      if (window.innerWidth < 768) {
        if (localStorage.getItem('authorization')) {
          queryParams = '?authorization=' + localStorage.getItem('authorization');
        }
      }
    }
    const url = ((req.url.includes('http')) ? req.url : environment.urlApi + req.url) + queryParams;
    const newReq = req.clone({
      url,
      headers
    });
    this.utilityService.isLoading$.next(true);
    return next.handle(newReq).pipe(
      finalize(() => this.utilityService.isLoading$.next(false))
    );
  }

}
