import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {Foto, IVetrina, Review} from '../../../../objects/IVetrina';
import {isPlatformBrowser} from '@angular/common';
import {CommunicatorService} from '../../../../services/communicator.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-vetrina-detail',
  templateUrl: './vetrina-detail.component.html',
  styleUrls: ['./vetrina-detail.component.scss']
})
export class VetrinaDetailComponent implements OnInit {
  @Input() vetrina: IVetrina;
  @Input() fotos: Foto[];
  @Input() reviews: Review[];
  activeId = 1;
  isDevice = false;
  vote = '0';
  constructor(@Inject(PLATFORM_ID) private platformId: object, private communicator: CommunicatorService, private route: ActivatedRoute) { }

  ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        if(params['tab']) {
          this.activeId = +params['tab'];
        }
        if(params['vote']) {
          this.vote = params['vote'];
        }
      });
    this.isDevice = isPlatformBrowser(this.platformId);

  }

  showPhotos(select: number) {
    const photos = this.fotos.map(e => 'foto-vetrina/' + e.nome);
    this.communicator.images$.next({photos, select});
  }
}
