<div class="container position-relative" [class.mb-5]="!innested" [class.pb-3]="innested">
  <div class="row mt-3">
    <div class="col-12 text-center mb-4" *ngIf="!innested">
      <span class="font-weight-bold">Iscriviti gratis</span>
    </div>
    <div class="col position-relative">
      <ul class="nav nav-tabs nav-fill" ngbNav #nav="ngbNav">
        <li class="nav-item background-skyblue" ngbNavItem>
          <a ngbNavLink class="text-dark nav-link background-skyblue" (click)="showErrorUser = false; showErrorOperator = false; "
          style="font-weight: bold;">Registrazione Aziende</a>
          <ng-template ngbNavContent>
            <div class="tab-pane container pt-3" id="registrazione-fornitore">
              <!--              <div class="row" *ngIf="!disableEmail">-->
              <!--                <div class="col-sm-12 col-md-6 text-center">-->
              <!--                  <button class="btn btn-facebook" (click)="signInWithFB()">-->
              <!--                    <i class="fab fa-facebook-f mr-3"></i>-->
              <!--                    Compila campi con facebook-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--                <div class="col-sm-12 col-md-6 text-center mt-3 mt-md-0">-->
              <!--                  <button class="btn btn-outline-dark bg-white text-dark" (click)="signInWithGoogle()">-->
              <!--                    <i class="fab fa-google mr-3"></i>-->
              <!--                    Compila campi con google-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="row mt-3 mb-3" *ngIf="!disableEmail">-->
              <!--                <div class="col-12 text-center">-->
              <!--                  <label class="small"> &#45;&#45; Oppure &#45;&#45; </label>-->
              <!--                </div>-->
              <!--              </div>-->

              <div class="row">

<!--                <div class="col-12 col-sm-6">-->
<!--                  <div class="form-group">-->
<!--                    <label for="user">Username*:</label>-->
<!--                    <input type="text" [(ngModel)]="macro.username" class="form-control"-->
<!--                           placeholder="Inserisci il tuo username"-->
<!--                           autocomplete="username" id="user" minlength="5" required max="100">-->
<!--                    <div class="invalid-feedback d-block" *ngIf="showErrorOperator && !macro.username">-->
<!--                      Campo obbligatorio-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="email">Email*:</label>
                    <input type="email" [(ngModel)]="macro.email" class="form-control"
                           placeholder="Verrà usata come username" [disabled]="disableEmail"
                           max="100" autocomplete="email"
                           id="email" required>
                    <div class="invalid-feedback d-block" *ngIf="showErrorOperator && !macro.email">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="emailConfirm">Conferma Email*:</label>
                    <input type="email" [(ngModel)]="macro.repeatEmail" class="form-control"
                           placeholder="Inserisci la tua email" [disabled]="disableEmail"
                           autocomplete="email" id="emailConfirm" required>
                    <div class="invalid-feedback d-block" *ngIf="showErrorOperator && !macro.repeatEmail">
                      Campo obbligatorio
                    </div>
                    <div class="invalid-feedback d-block" *ngIf="showErrorOperator && macro.repeatEmail !== macro.email">
                      Il campo non corrisponde alla mail
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-6 form-group">
                    <label for="password">Password*:</label>
                  <div class="input-group">
                    <input [type]="type3" [(ngModel)]="macro.password" class="form-control"
                           placeholder="Inserisci la tua password"
                           [style.border-color]="macro.password && macro.password === macro.password2 ? '#28a745' : null"
                           style="border-width: 2px"
                           minlength="8" id="password" autocomplete="new-password" required>

                    <div class="input-group-append">
                      <span class="input-group-text"
                            [style.border-color]="macro.password && macro.password === macro.password2 ? '#28a745' : null"
                            style="border-width: 2px"
                      >
                        <i class="fa fa-eye" *ngIf="type3 === 'text'" (click)="type3 = 'password'"></i>
                        <i class="fa fa-eye-slash" *ngIf="type3 === 'password'" (click)="type3 = 'text'"></i>
                      </span>
                    </div>
                    <div class="invalid-feedback d-block" *ngIf="showErrorOperator && !macro.password">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 form-group">
                    <label for="repassword">Conferma password*:</label>
                  <div class="input-group">
                    <input [type]="type4" [(ngModel)]="macro.password2" class="form-control"
                           placeholder="Ripeti la tua password"
                           [style.border-color]="macro.password && macro.password === macro.password2 ? '#28a745' : null"
                           style="border-width: 2px"
                           autocomplete="new-password" minlength="8" id="repassword">

                    <div class="input-group-append">
                      <span class="input-group-text"
                            [style.border-color]="macro.password && macro.password === macro.password2 ? '#28a745' : null"
                            style="border-width: 2px"
                      >
                        <i class="fa fa-eye" *ngIf="type4 === 'text'" (click)="type4 = 'password'"></i>
                        <i class="fa fa-eye-slash" *ngIf="type4 === 'password'" (click)="type4 = 'text'"></i>
                      </span>
                    </div>
                    <div class="invalid-feedback d-block" *ngIf="showErrorOperator && !macro.password2">
                      Campo obbligatorio
                    </div>
                    <div class="invalid-feedback d-block"
                         *ngIf="showErrorOperator && macro.password2 && macro.password !== macro.password2">
                      Le due password non corrispondono
                    </div>
                  </div>
                </div>
              </div>
              <p class="mt-3">Settore di attività <span class="font-weight-normal">(una sola scelta per profilo)*</span>
              </p>
              <div class="invalid-feedback d-block"
                   *ngIf="showErrorOperator && !operator.sector_1">
                Campo obbligatorio
              </div>
              <div
                *ngFor="let macroSector of ['Sposa', 'Sposo', 'Fornitori', 'Ricevimento', 'Pets Wedding']; let i = index">
                <p>{{macroSector}}</p>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4" *ngFor="let sector of sectors | getBySector:i+1">
                    <div class="custom-control custom-checkbox mb-3">
                      <input type="checkbox" (change)="operator.sector_1 = $event.target['checked'] ? sector.id : ''"
                             class="custom-control-input check-2" [checked]="operator.sector_1 === sector.id"
                             value="1" [id]="sector.id">
                      <label class="custom-control-label" [for]="sector.id">{{sector.desc}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <p>Facendo click sul tasto "Registrami":</p>
                </div>
                <div class="col-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" (change)="macro.regulation = $event.target['checked'] ? '1' : '0'"
                           class="custom-control-input"
                           [checked]="macro.regulation === '1'"
                           id="checkRegolamento" required>
                    <label class="custom-control-label" for="checkRegolamento">Dichiaro di aver preso visione dei
                      Termini e Condizioni di
                      <button class="btn btn-link p-0" (click)="openWeddingsmart()"> Weddingsmart.it</button>*</label>
                    <div class="invalid-feedback d-block" *ngIf="showErrorUser && macro.regulation !== '1'">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" (change)="macro.cookie = $event.target['checked'] ? '1' : '0'"
                           class="custom-control-input" value="1"
                           [checked]="macro.cookie === '1'"
                           id="checkCookie">
                    <label class="custom-control-label" for="checkCookie">Dichiaro di aver preso visione
                      <button class="btn btn-link p-0" (click)="openCookie()">dell'informativa cookies</button>*</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" (change)="macro.privacy = $event.target['checked'] ? '1' : '0'"
                           class="custom-control-input" value="1"
                           [checked]="macro.privacy === '1'"
                           id="checkPrivacy">
                    <label class="custom-control-label" for="checkPrivacy">Dichiaro di aver preso visione
                      <button class="btn btn-link p-0" (click)="openPrivacy()">
                        dell'informativa privacy*</button>*</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" value="1"
                           [checked]="macro.personal === '1'"
                           (change)="macro.personal = $event.target['checked'] ? '1' : '0'"
                           id="checkProfi">
                    <label class="custom-control-label" for="checkProfi">Acconsento al trattamento dei miei dati
                      personali
                      per lo svolgimento di attività di profilazione*</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" value="1"
                           [checked]="macro.advertising === '1'"
                           (change)="macro.advertising = $event.target['checked'] ? '1' : '0'"
                           id="checkAdv">
                    <label class="custom-control-label" for="checkAdv">Acconsento al trattamento dei miei dati per
                      finalità di marketing</label>
                  </div>
                </div>
                <div class="col-12 text-right">
                  <button type="button" class="btn btn-primary" (click)="registerOperator()"
                          [disabled]="macro.personal !== '1' || macro.regulation !== '1' || macro.cookie !== '1' || macro.privacy !== '1'">
                    Registrami
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li class="nav-item background-lightgray" ngbNavItem>
          <a ngbNavLink class="text-dark nav-link" data-toggle="tab"
             (click)="showErrorUser = false; showErrorOperator = false; ">Registrazione Sposo / Sposa</a>

          <ng-template ngbNavContent>

            <div class="tab-pane container active pt-3" id="registrazione-sposo">

              <!--              <div class="row" *ngIf="!disableEmail">-->
              <!--                <div class="col-sm-12 col-md-6 text-center">-->
              <!--                  <button class="btn btn-facebook" (click)="signInWithFB()">-->
              <!--                    <i class="fab fa-facebook-f mr-3"></i>-->
              <!--                    Compila campi con facebook-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--                <div class="col-sm-12 col-md-6 text-center mt-3 mt-md-0">-->
              <!--                  <button class="btn btn-outline-dark bg-white" (click)="signInWithGoogle()">-->
              <!--                    <i class="fab fa-google mr-3"></i>-->
              <!--                    Compila campi con google-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="row mt-3 mb-3" *ngIf="!disableEmail">-->
              <!--                <div class="col-12 text-center">-->
              <!--                  <label class="small"> &#45;&#45; Oppure &#45;&#45; </label>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="row">
                <!--                <div class="col-12 col-sm-6">-->
                <!--                  <div class="form-group">-->
                <!--                    <label for="username">Username*:</label>-->
                <!--                    <input type="text" [(ngModel)]="macro.username" class="form-control"-->
                <!--                           placeholder="Inserisci il tuo username"-->
                <!--                           autocomplete="username" id="username" minlength="5" required>-->
                <!--                    <div class="invalid-feedback d-block" *ngIf="showErrorUser && !macro.username">-->
                <!--                      Campo obbligatorio-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="emailUser">Email*:</label>
                    <input type="email" [(ngModel)]="macro.email" class="form-control"
                           placeholder="Verrà usata come username" [disabled]="disableEmail"
                           autocomplete="email" id="emailUser" required>
                    <div class="invalid-feedback d-block" *ngIf="showErrorUser && !macro.email">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="emailUserConfirm">Conferma Email*:</label>
                    <input type="email" [(ngModel)]="macro.repeatEmail" class="form-control"
                           placeholder="Inserisci la tua email" [disabled]="disableEmail"
                           autocomplete="email" id="emailUserConfirm" required>
                    <div class="invalid-feedback d-block" *ngIf="showErrorUser && !macro.repeatEmail">
                      Campo obbligatorio
                    </div>
                    <div class="invalid-feedback d-block" *ngIf="showErrorUser && macro.repeatEmail !== macro.email">
                      Il campo non corrisponde alla mail
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 form-group">
                  <label for="passwordUser">Password*:</label>
                  <div class="input-group">
                    <input [type]="type1" [(ngModel)]="macro.password" class="form-control"
                           placeholder="Inserisci la tua password"
                           [style.border-color]="macro.password && macro.password === macro.password2 ? '#28a745' : null"
                           style="border-width: 2px"
                           minlength="8" id="passwordUser" autocomplete="new-password" required>
                    <div class="input-group-append">
                      <span class="input-group-text"
                            [style.border-color]="macro.password && macro.password === macro.password2 ? '#28a745' : null"
                            style="border-width: 2px"
                      >
                        <i class="fa fa-eye" *ngIf="type1 === 'text'" (click)="type1 = 'password'"></i>
                        <i class="fa fa-eye-slash" *ngIf="type1 === 'password'" (click)="type1 = 'text'"></i>
                      </span>
                    </div>
                    <div class="invalid-feedback d-block" *ngIf="showErrorUser && !macro.password">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 form-group">
                  <label for="new-passwordUser">Conferma password*:</label>
                  <div class="input-group">
                    <input [type]="type2" [(ngModel)]="macro.password2" class="form-control"
                           placeholder="Ripeti la tua password"
                           [style.border-color]="macro.password && macro.password === macro.password2 ? '#28a745' : null"
                           style="border-width: 2px"
                           autocomplete="new-password" minlength="8" id="new-passwordUser">
                    <div class="input-group-append">
                      <span class="input-group-text"
                            [style.border-color]="macro.password && macro.password === macro.password2 ? '#28a745' : null"
                            style="border-width: 2px"
                      >
                        <i class="fa fa-eye" *ngIf="type2 === 'text'" (click)="type2 = 'password'"></i>
                        <i class="fa fa-eye-slash" *ngIf="type2 === 'password'" (click)="type2 = 'text'"></i>
                      </span>
                    </div>
                    <div class="invalid-feedback d-block" *ngIf="showErrorUser && !macro.password2">
                      Campo obbligatorio
                    </div>
                    <div class="invalid-feedback d-block"
                         *ngIf="showErrorUser && macro.password2 && macro.password !== macro.password2">
                      Le due password non corrispondono
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <p>Facendo click sul tasto "Registrami":</p>
                </div>


                <div class="col-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" (change)="macro.regulation = $event.target['checked'] ? '1' : '0'"
                           class="custom-control-input"
                           [checked]="macro.regulation === '1'"
                           id="checkRegolamento" required>
                    <label class="custom-control-label" for="checkRegolamento">Dichiaro di aver preso visione dei
                      Termini e Condizioni di
                      <button class="btn btn-link p-0" (click)="openWeddingsmart()"> Weddingsmart.it</button>*</label>
                    <div class="invalid-feedback d-block" *ngIf="showErrorUser && macro.regulation !== '1'">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" (change)="macro.cookie = $event.target['checked'] ? '1' : '0'"
                           class="custom-control-input" value="1"
                           [checked]="macro.cookie === '1'"
                           id="checkCookie">
                    <label class="custom-control-label" for="checkCookie">Dichiaro di aver preso visione
                      <button class="btn btn-link p-0" (click)="openCookie()">dell'informativa cookies</button>*</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" (change)="macro.privacy = $event.target['checked'] ? '1' : '0'"
                           class="custom-control-input" value="1"
                           id="checkPrivacy"
                           [checked]="macro.privacy === '1'">
                    <label class="custom-control-label" for="checkPrivacy">
                      Dichiaro di aver preso visione
                      <button class="btn btn-link p-0" (click)="openPrivacy()">
                        dell'informativa privacy*</button>
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" value="1"
                           [checked]="macro.personal === '1'"
                           (change)="macro.personal = $event.target['checked'] ? '1' : '0'"
                           id="checkProfi">
                    <label class="custom-control-label" for="checkProfi">Acconsento al trattamento dei miei dati
                      personali
                      per lo svolgimento di attività di profilazione*</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" value="1"
                           [checked]="macro.advertising === '1'"
                           (change)="macro.advertising = $event.target['checked'] ? '1' : '0'"
                           id="checkAdv">
                    <label class="custom-control-label" for="checkAdv">Acconsento al trattamento dei miei dati per
                      finalità di marketing</label>
                  </div>
                </div>

                <div class="col-12 text-right">
                  <button type="button" class="btn btn-primary" (click)="registerUser()"
                          [disabled]="macro.personal !== '1' || macro.regulation !== '1' || macro.cookie !== '1' || macro.privacy !== '1'">
                    Registrami
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div class="tab-content background-lightgray" [ngbNavOutlet]="nav">

      </div>
      <div class="position-absolute loader-register text-white text-center" style="z-index: 10" *ngIf="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>


</div>
