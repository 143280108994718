import {Component, EventEmitter, Input, Output} from '@angular/core';
import {INotification} from '../../../objects/INotification';
import {HttpService} from '../../../services/http.service';
import {CommunicatorService} from "../../../services/communicator.service";
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {IMessage} from "./IMessage";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  @Input() notifications: INotification[];
  @Output() closeNotifications = new EventEmitter();
  @Output() refreshNotification = new EventEmitter();
  showNotifications = true;
  maxNotifications = 6;
  maxMessages = 6;
  messages$: Observable<IMessage[]>;
  constructor(private httpService: HttpService,
              public communicator: CommunicatorService,
              private router: Router
              ) {

    this.messages$ = this.communicator.checkUser$.pipe(
      switchMap(user =>
        this.httpService.getMessages().pipe(
          map(array => {
            if (user.isAdmin === '1') {
              const finalMessage = [];
              for (const message of array) {
                if (!finalMessage.some(e => e.idmacroto === message.idmacroto)) {
                  finalMessage.push(message);
                }
              }
              return finalMessage;
            } else {
              return array;
            }
          })
        ))
    );

  }

  deleteNotification(not: INotification, array: INotification[]) {
    const index = array.findIndex(e => e.id === not.id);
    array.splice(index, 1);
    this.httpService.deleteNotification(not.id).subscribe(resp => {
      this.refreshNotification.emit();
    });
  }
  goDetail(message: IMessage) {
    this.communicator.checkUser$.subscribe(user => {
      if (user.isAdmin === '1') {
        this.closeNotifications.emit();
        window.location.href = '/portal/' + ['message', message.isfornitoreto, message.idmacroto, message.usernameto].join('/');
      }
    });
  }
}
