<div class="container pt-3 pb-3" *ngIf="detailObj">
  <div class="row" id="row-product">
    <ng-container *ngFor="let prod of detailObj.products">

      <div class="col-6 text-center">
        <img id="img" class="img" [src]="'https://www.weddingsmart.it/api/images/' + (type === 'auction' ? 'offers' : 'products') + '/' + prod.imgname">
      </div>
      <div class="col-6">
        <a *ngIf="type === 'order'" [routerLink]="'/prodotto/' + prod.code + '/' + prod.title">
          {{prod.title}}
        </a>
        <a *ngIf="type === 'auction'" [routerLink]="'/prodotto/' + prod.code + '/' + prod.title">
          {{prod.description}}
        </a>
        <br>
        <button class="btn btn-link p-0 text-left"><i class="fa fa-cloud-upload-alt"></i> Condividi questo articolo</button>
      </div>
    </ng-container>

  </div>
  <button class="btn btn-outline-store w-100 text-left mt-3" [routerLink]="'/store/' + detailObj.macrosector + '/' + detailObj.sector + '/' + detailObj.seller">
    Visita store fornitore
    <span>
      <i class="fa fa-chevron-right"></i>
    </span>
  </button>
  <hr>
  <div class="row" *ngIf="detailObj.states[0]">
    <div class="col-12" *ngIf="!states.includes('RICEVUTO')">
      <h5>Consegna prevista: 31/12/2020<br>

        <label *ngIf="states.includes('SPEDITO')" class="text-black-50 font-14">Il
          fornitore ha spedito il prodotto
          il {{speditionOrder.timestamp | date:'dd/MM/yyyy'}}
          tramite corriere: {{speditionOrder.speditionby}}</label>

        <label *ngIf="!states.includes('SPEDITO')" class="text-black-50 font-14">Il
          fornitore sta preparando il pacco per la spedizione</label>
      </h5>

      <button class="btn btn-outline-store w-100 text-left mb-3"
              *ngIf="states.includes('SPEDITO')"
              (click)="tracciaPacco(speditionOrder.shipping_number, speditionOrder.speditionby)">
        Traccia il mio pacco
        <span>
          <i class="fa fa-chevron-right"></i>
        </span>
      </button>
      <div class="d-none d-md-block">
        <div class="circle c-success">
          <label>Pagato</label>
        </div>
        <label class="line l-success"></label>
        <div class="circle" [class.c-success]="states.includes('SPEDITO')">
          <label>Spedito</label>
        </div>
        <label class="line" [class.l-success]="states.includes('RICEVUTO')"></label>
        <div class="circle" [class.c-success]="states.includes('RICEVUTO')">
          <label>Consegnato</label>
        </div>
      </div>
      <div class="d-block d-md-none text-center">
        <div class="circle c-success">
          <label>Pagato</label>
        </div>
        <br>
        <label class="vertical-line l-success"></label><br>
        <label
          [class]="'vertical-line ' + ((states.includes('SPEDITO')) ? 'l-success' : '')"></label><br>
        <div [class]="'circle ' + ((states.includes('SPEDITO')) ? 'c-success' : '')">
          <label>Spedito</label>
        </div>
        <br>
        <label
          [class]="'vertical-line ' + ((states.includes('SPEDITO')) ? 'l-success' : '')"></label><br>
        <label class="vertical-line"></label><br>
        <div [class]="'circle ' + ((states.includes('RICEVUTO')) ? 'c-success' : '')">
          <label>Consegnato</label>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="states.includes('RICEVUTO')">
      <h5> Consegnato il {{ordered | date:'dd/MM/yyyy'}} <br>
        <label class="text-black-50 font-14"> Il prodotto è stato consegnato presso il domicilio
          selezionato: {{detailObj.addressshipping}} </label>
      </h5>
      <span>Com'è andata la consegna?</span>
      <div class="row">
        <div class="col-6">
          <button class="btn btn-primary w-100" [routerLink]="'/consegna-esito/1/' + type + '/' + id"><i class="fas fa-thumbs-up"></i> Bene</button>
        </div>
        <div class="col-6">
          <button class="btn btn-primary w-100" [routerLink]="'/consegna-esito/0/' + type + '/' + id"><i class="fas fa-thumbs-down"></i> Non bene
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="row" *ngIf="detailObj.states[0] as laststate">
    <div class="col-12 mb-3 col-md-6">
      <h4>Hai bisogno di aiuto per il tuo articolo?</h4>

      <div id="helpDiv">
        <ng-container *ngIf="states.includes('RICEVUTO')">
          <button *ngIf="!detailObj.reso" class="btn btn-outline-store w-100 text-left" [routerLink]="'/effettua-reso/' + type + '/' + id">
            Restituisci articoli
            <br> <small class="text-black-50">Idoneo fino al  {{laststate.timestampreso | date:'dd/MM/yyyy'}}</small>
            <span class="big">
              <i class="fa fa-chevron-right"></i>
            </span>
          </button>
          <div *ngIf="detailObj.reso as reso" class="mb-3">
            <div class="row">
              <div class="col-auto">
                <i class="fas fa-check-square color-primary" style="font-size: 27px;"></i>
              </div>
              <div class="col">

                <h5 class="mb-0">Reso {{reso.timestamptransaction ? 'completo' : 'richiesto'}}</h5>
                <label>Il reso è {{reso.timestamptransaction ? 'completo' : 'stato inoltrato'}}</label>
              </div>
            </div>
            <a  [routerLink]="'/effettua-reso/' + type + '/' + id" *ngIf="!reso.timestamptransaction">Quando riceverò il mio rimborso?</a>
              <button class="btn btn-outline-store w-100 text-left" [routerLink]="'/effettua-reso/' + type + '/' + id">
                Visualizza stato / Cancella reso
                <span>
                  <i class="fa fa-chevron-right"></i>
                </span>
              </button>
          </div>
        </ng-container>
        <ng-container *ngIf="!states.includes('RICEVUTO')">
          <button class="btn btn-outline-store w-100 text-left">
            Contatta l'assistenza
            <span>
              <i class="fa fa-chevron-right"></i>
            </span>
          </button>

          <button class="btn btn-outline-store w-100 text-left mt-3">
            Contatta il fornitore
            <span>
              <i class="fa fa-chevron-right"></i>
            </span>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="col-12" *ngIf="states.includes('RICEVUTO')">
      <h4>In che condizioni è il tuo articolo?</h4>
      <button class="btn btn-outline-store w-100 text-left" [routerLink]="['/', 'review', 'store', id]">
        Scrivi una recensione per il prodotto
        <span>
        <i class="fa fa-chevron-right"></i>
      </span>
      </button>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <h4>Info ordine</h4>
    </div>
    <div class="col">
      <button class="btn btn-outline-store w-100 text-left" [routerLink]="'/order-detail/' + type + '/' + id + '/riepilogo'">
        Visualizza dettagli ordine
        <span>
            <i class="fa fa-chevron-right"></i>
          </span>
      </button>
    </div>
  </div>

  <label class="text-black-50 mt-3" id="whenOrder">Ordinato il {{ordered | date:'dd/MM/yyyy'}}</label>
</div>
