import { Component, OnInit } from '@angular/core';
import {IVetrina} from '../../objects/IVetrina';
import {HttpService} from '../../services/http.service';
import {IProduct} from '../../objects/IProduct';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  activeId = 0;
  vetrine: IVetrina[];
  store: IVetrina[];
  products: IProduct[];
  isRow = window.innerWidth < 769;
  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
    this.httpService.getFavorites().subscribe(resp => {
      for (const el of resp.vetrine) {
        el.isfavorite = true;
      }
      for (const el of resp.store) {
        el.isfavorite = true;
      }
      for (const el of resp.products) {
        el.isfavorite = true;
      }
      this.vetrine = resp.vetrine;
      this.store = resp.store;
      this.products = resp.products;
    });
  }

}
