<div class="row pt-1 pb-1 no-gutters w-100" [ngClass]="{'notread' : notification.read === '0'}" (click)="sendRead(notification)">
  <div class="col-2 text-center my-auto image-notification">
    <img *ngIf="notification.imageSposo else imageDefault" class="rounded-circle" [src]="'https://www.weddingsmart.it/api/images/users/' + notification.imageSposo">
    <ng-template #imageDefault>
      <i class="fa fa-user-circle color-primary">

      </i>
    </ng-template>
    <div class="icon-notification">
      <img [src]="'https://www.weddingsmart.it/assets/icons/notification/' + NOTIFICATION_TYPES[notification.type]">
    </div>
  </div>
  <div class="col-9 d-flex flex-column">
    <p class="text-wrap mb-0"><b>{{notification.fullnameSposo}}</b> {{notification.text}}</p>
    <p class="text-wrap mb-0" *ngIf="notification.product"><b>{{notification.product.title}}</b></p>
    <small class="color-grey mt-1">{{notification.timestamp | timestampDiff}}</small>
  </div>
  <div class="col-1 pl-0 text-center">
    <button class="btn p-0" (click)="deleteEmitter.emit()"><i class="fa fa-trash"></i> </button>
  </div>
</div>
