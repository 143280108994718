import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginModalComponent} from '../popup/login-modal/login-modal.component';
import {PopupComponent} from '../popup/popup/popup.component';
import {RememberPasswordComponent} from '../popup/remember-password/remember-password.component';
import {HttpService} from '../../services/http.service';
import {CommunicatorService} from '../../services/communicator.service';
import {MENU_LINKS, MENU_LINKS_UTILS} from 'src/app/objects/Constants';
import {Observable} from 'rxjs';
import {INotification} from '../../objects/INotification';
import {Utility} from '../../objects/Utility';
import {filter, switchMap, toArray} from 'rxjs/operators';
import {UtilityService} from "../../services/utility.service";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  url = environment.urlApi;
  isCollapsed = [true, true, true, true, true, true];
  classMenu = '';
  classMenuRight = '';
  isAdmin = false;
  isOperator = false;
  notifications$: Observable<INotification[]>;
  MENU_LINKS = MENU_LINKS;
  MENU_LINKS_UTILS = MENU_LINKS_UTILS;
  macroCollaped = false;
  orders$: Observable<any[]>;
  utility = Utility;
  idRandom = Math.random() * 1000;
  url$ = null;
  countRefreshNotification = 0;
  constructor(private modalService: NgbModal,
              private httpService: HttpService,
              private router: Router,
              private utilityService: UtilityService,
              public communicator: CommunicatorService) {
    communicator.openLogin$.subscribe(() => {
      this.loginClick();
    });
    this.url$ = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
  }

  ngOnInit(): void {
    this.mapCheckUser();
    this.orders$ = this.httpService.getOrders().pipe(
      switchMap(array => array),
      filter(e => {
        const today = new Date();
        const dateOffer = new Date(e.timestamp);
        return dateOffer.getDate() === today.getDate() &&
          dateOffer.getMonth() === today.getMonth() &&
          dateOffer.getFullYear() === today.getFullYear();
      }),
      toArray()
    );
  }

  mapCheckUser() {
    this.communicator.checkUser$.subscribe(resp => {
      this.isAdmin = (resp) ? resp.isAdmin === '1' : false;
      this.isOperator = (resp) ? resp.isOperator === '1' : false;
      if(resp) {
        this.notifications$ = this.httpService.getNotifications();
      }
    });
  }

  loginClick() {

    const modalRef = this.modalService.open(LoginModalComponent);
    modalRef.componentInstance.rememberPassword.subscribe( () => {
      modalRef.dismiss();
      const modalRef2 = this.modalService.open(RememberPasswordComponent);
      modalRef2.componentInstance.isSent = false;
      modalRef2.componentInstance.sendEmail.subscribe(email => {
        this.httpService.rememberPassword(email).subscribe((resp) => {
          modalRef2.componentInstance.isSent = true;
        }, error1 => {
          modalRef2.dismiss();

          const mr3 = this.modalService.open(PopupComponent);
          mr3.componentInstance.title = 'Attenzione';
          mr3.componentInstance.body = error1.error.message;
        });
      });
    });
    modalRef.componentInstance.goRegisterPage.subscribe( () => {
      this.showMenuLeft(false);
    });
    modalRef.componentInstance.login.subscribe( (bodyLogin) => {
      const cookieAccepted = JSON.parse(localStorage.getItem('cookieAccepted'));
      this.httpService.login({...bodyLogin, ...cookieAccepted, ...this.utilityService.position}).subscribe((resp) => {
        localStorage.setItem('authorization', resp.token);

        if (bodyLogin.username === 'admin') {
          window.location.href = '/portal/dashboard';
        } else {
          const modalRef2 = this.modalService.open(PopupComponent);
          modalRef2.componentInstance.title = 'Attenzione';
          modalRef2.componentInstance.body = resp.message;
          window.location.reload();
        }
      }, error1 => {
        modalRef.dismiss();

        const modalRef2 = this.modalService.open(PopupComponent);
        modalRef2.componentInstance.title = 'Attenzione';
        modalRef2.componentInstance.body = error1.error.message;
      });
    });
  }

  showMenuLeft(show: boolean) {
    if (this.classMenuRight) {
      return;
    }
    if (show) {
      this.classMenu = 'show';
    } else {
      this.classMenu = '';
    }
    Utility.checkScrollBody(show);
    this.communicator.backdropShow$.next(show);
  }
  showMenuRight(show: boolean) {
    if (this.classMenu) {
      return;
    }
    if (show) {
      this.classMenuRight = 'show';
    } else {
      this.classMenuRight = '';
    }
    Utility.checkScrollBody(show);
    this.communicator.backdropShow$.next(show);
  }

  logout() {
    this.httpService.logout().subscribe(() => {
      localStorage.setItem('authorization', '');
      this.communicator.checkUser$ = null;
      window.location.reload();
    });
  }


  goTo(url: string) {
    window.location.href = url;
  }

  showInfoEntrate() {

    const mr = this.modalService.open(PopupComponent);
    mr.componentInstance.body = 'Importo al lordo delle Commissioni da Versare a Wedding Smart';
  }

  calcTotal(orders: any[]): string {
    return (orders.reduce((a,b) => a += +b.total, 0) as number).toFixed(2);
  }

  resetCollapsed(index) {
    const value = this.isCollapsed[index];
    this.isCollapsed = [true, true, true, true, true, true];
    this.isCollapsed[index] = !value;
  }
}
