<div class="container pt-3 pb-3" *ngIf="detailObj">
  <h4>
    Visualizza dettagli ordine
  </h4>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <span class="text-black-50">Data dell'ordine</span>
        </div>
        <div class="col-6">
          <span id="whenOrder">{{ordered | date:'dd/MM/yyyy'}}</span>
        </div>
        <div class="col-6">
          <span class="text-black-50">Ordine #</span>
        </div>
        <div class="col-6">
          <a id="numOrder" routerLink="/my-auctions">{{detailObj.id}}</a>
        </div>
        <div class="col-6">
          <span class="text-black-50">Totale ordine</span>
        </div>
        <div class="col-6">
          <span id="totOrder">{{detailObj.total | convertPrice:false:true}}</span>
        </div>
      </div>
    </div>
  </div>

  <h4 class="mt-3">
    Dettagli spedizione
  </h4>
  <div class="card">
    <div class="card-body">
      <h6 id="stateShipping">{{detailObj.states[0].state_order}}</h6>
      <span id="whenShippingText">Consegna prevista:</span>
      <label class="text-success font-weight-bold ml-1" id="whenShipping">31/12/2020</label>
      <label class="text-black-50 ml-1">Venduto da: <b><a id="seller" [routerLink]="'/store/' + detailObj.macrosector + '/' + detailObj.sector + '/' + detailObj.seller">
        {{detailObj.seller}}</a></b></label>
      <div class="row" *ngFor="let prod of detailObj.products">
        <div class="col-4 mb-2">
          <img id="img" class="w-100"
               [src]="'https://www.weddingsmart.it/api/images/' + (type === 'auction' ? 'offers' : 'products') + '/' + prod.imgname">
        </div>
        <div class="col-8 mb-2">
          <div class="row">
            <div class="col-12 col-md-6">

              <a *ngIf="type === 'order'" class="font-weight-bold" [routerLink]="'/prodotto/' + prod.code + '/' + prod.title">
                {{prod.title}}
              </a>
              <a *ngIf="type === 'auction'" class="font-weight-bold" [routerLink]="'/prodotto/' + prod.code + '/' + prod.title">
                {{prod.description}}
              </a>

              <br>
              <label class="text-black-50" *ngIf="prod.quantity">Q.tà: {{prod.quantity}}</label>
              <label class="text-black-50" *ngIf="!prod.quantity">Q.tà: 1</label>
              <br>
            </div>
            <div class="col-12 col-md-6 mb-2" *ngIf="prod.quantity">
              {{(+prod.total / +prod.quantity) + '' | convertPrice:false:true}}
            </div>
            <div class="col-12 col-md-6 mb-2" *ngIf="!prod.quantity">
              {{(+prod.offer_num) + '' | convertPrice:false:true}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h4 class="mt-3">Informazioni di pagamento</h4>
  <div class="card">
    <div class="card-body">
      <h6>Metodo di pagamento: <b>PayPal</b></h6>
      <h6>Cod. transazione: <b id="codePaypal">{{detailObj.paypalbuyer}}</b></h6>
      <hr>
      <h6>Indirizzo di fatturazione</h6>
      <label id="addressbilling"></label>
    </div>
  </div>
  <h4 class="mt-3">Indirizzo di spedizione</h4>
  <div class="card">
    <div class="card-body">
      <h6 id="buyer">{{detailObj.buyer}}</h6>
      <label id="addressshipping">{{detailObj.addressshipping}}</label>
    </div>
  </div>
  <h4 class="mt-3">Riepilogo ordine</h4>
  <div class="card">
    <div class="card-body" id="riepilogoOrdine">
      <ng-container *ngFor="let prod of detailObj.products">

        <h6>
          <a [routerLink]="(!prod.idauction) ? ('/prodotto/' + prod.code + '/' + prod.title) : ('/my-auctions')">Cod. {{prod.code}} -
            <b> {{prod.title}} </b>
          </a>
        </h6>

<!--        <label class="text-black-50 w-100 mb-0" *ngIf="prod.total && detailObj.iva">-->
<!--          IVA applicata ({{detailObj.iva}} %): &nbsp;<b class="text-dark">{{calcIva(prod.total, detailObj.iva) | convertPrice:false:true}}</b></label>-->

        <label class="text-black-50 w-100 mb-0" *ngIf="prod.other_information?.howDressLong">
          Condizione: &nbsp;<b class="text-dark">{{prod.other_information?.howDressLong}}</b></label>

        <label class="text-black-50 w-100 mb-0" *ngIf="prod.other_information?.widthPerson">
          Taglia: &nbsp;<b class="text-dark">{{prod.other_information?.widthPerson}}</b></label>

        <label class="text-black-50 w-100 mb-0" *ngIf="prod.other_information?.whatColor">
          Colore: &nbsp;<b class="text-dark">{{prod.other_information?.whatColor}}</b></label>

        <label class="text-right w-100 text-black-50">
          <b style="font-size: 1.1rem;" *ngIf="prod.offer_num">{{prod.offer_num | convertPrice:false:true}}</b>
          <b style="font-size: 1.1rem;" *ngIf="prod.total && !prod.idauction">{{prod.total | convertPrice:false:true}}</b>
        </label>
        <div class="row" *ngIf="prod.bonus_buyer as bonus">
          <div class="col text-black-50 font-weight-bold pr-0">
            <small>Bonus sposi Wedding Smart</small>
          </div>
          <div class="col-auto text-right text-black-50 font-weight-bolder">
            <span class="text-success font-weight-bold">-{{bonus}}%</span>
            - {{prod.tax_payed | convertPrice:false:true}}
          </div>
        </div>
      </ng-container>


      <div class="row border-top mb-3 pt-2">
        <div class="col-12">
          <div class="row">
            <div class="col text-black-50 font-weight-bolder text-right" style="font-size: .9rem;">
              Totale parziale
            </div>
            <div class="col-5 text-right text-black-50 font-weight-bolder">
              {{getTotal() | convertPrice:false:true}}
            </div>
          </div>
          <div class="row">
            <div class="col text-black-50 font-weight-bolder text-right" style="font-size: .9rem;">
              Spedizione (standard)
            </div>

            <div class="col-5 text-right text-black-50 font-weight-bolder">
              {{detailObj.costshipping | convertPrice:false:true}}
            </div>
          </div>
        </div>
      </div>
      <div class="row border-top">
        <div class="col text-right color-blue">
          <b>Totale</b> <span> (Iva inc.)</span>
        </div>
        <div class="col-5 text-right my-auto">
          <label class="price mb-0">{{detailObj.total | convertPrice:false:true}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
