import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IProduct} from '../objects/IProduct';
import {IStore, IStoreShipping} from '../objects/IStore';
import {Observable, of} from 'rxjs';
import {IMacroVetrina, IVetrina} from '../objects/IVetrina';
import {IAddressDTO} from '../objects/IAddressDTO';
import {IUser} from '../objects/IUser';
import {AuctionOrder, StoreOrder} from '../objects/IOrderDetail';
import {IAuction, IOffer, IQuestion} from '../objects/IAuction';
import {ISector} from '../objects/ISector';
import {INotification} from '../objects/INotification';
import {BonusDTO} from '../objects/BonusDTO';
import {IReview, IReviewOffer} from '../objects/IReview';
import {CanauctionsI} from '../pages/new-auction/canauctionsI';
import {IProfile} from '../objects/IProfile';
import {IBudget} from '../pages/my-auctions/IBudget';
import {IAddress} from '../pages/my-auctions/IAddress';
import {OfferOrder, OrderDetail} from '../pages/order-detail/orderDetail';
import {IOfferDetail} from '../pages/offer-detail/IOffer';
import {SeoEnum} from '../objects/SeoEnum';
import {Seo} from '../objects/Seo';
import {IMessage} from "../components/header/notifications/IMessage";
import {ICheckUser} from "../objects/ICheckUser";


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient) { }

  login(body: any) {
    return this.httpClient.post<{token: string, message: string}>('/api/login', body);
  }

  checkUser() {
    if (!localStorage.getItem('authorization')) {
      return of(null);
    }
    return this.httpClient.get<ICheckUser>('/api/check-token');
  }
  getPermissions() {
    return this.httpClient.get<{permissions: string[], super: '0' | '1'}>('/api/permissions');
  }

  getStoreProducts(fornitore: string) {
    return this.httpClient.get<{store: IStore, products: IProduct[]}>('/api/store-products/' + fornitore);
  }

  getProductByCode(code: string) {
    return this.httpClient.get<IProduct>('/api/products/code/' + code);
  }
  getProductById(id: string) {
    return this.httpClient.get<IProduct>('/api/products/detail/' + id);
  }

  getShippingStore(username: string) {
    return this.httpClient.get<IStoreShipping>('/api/store-shipping/' + username);
  }

  sendTransactions(body: {products: IProduct[], idtransaction: string, idseller: string,
    iva, total: string, addressshipping: string, costshipping: string}) {
    return this.httpClient.post<{message: string, result: string}>('/api/transactions', body);
  }

  getUser() {
    return this.httpClient.get<IUser>('/api/users-profile');
  }

  getAddresses() {
    return this.httpClient.get<IAddress[]>('/api/address-spedition');
  }

  saveAddress(objAddress: IAddress) {
    return this.httpClient.post('/api/address-spedition' + (objAddress.id ? '/' + objAddress.id : ''), objAddress);
  }

  getVetrine(body: {'city': string, 'subcategories': {'Sposa' : string[], 'Sposo': string[], 'Fornitori': string[], 'Ricevimento': string[], 'Pets Wedding': string[]}}) {
    return this.httpClient.post<IVetrina[]>('/api/vetrine', body);
  }
  getVetrina(fornitore: string) {
    return this.httpClient.get<IMacroVetrina>('/api/vetrine/' + fornitore);
  }
  getAddress(address: string) {
    return this.httpClient.get<IAddressDTO[]>('https://nominatim.openstreetmap.org/search?format=json&q=' + address);
  }
  getReview(type: string, id: number) {
    if(type === 'store') {
      return this.httpClient.get<StoreOrder>('/api/order-detail/' + id)
    } else {
      return this.httpClient.get<AuctionOrder>('/api/orderoffer-detail/' + id);
    }
  }
  postReview(type: string, id: number, body: {votes: any[], from: string}) {
    if(type === 'store') {
      return this.httpClient.post<{result: boolean, message: string}>('/api/order-detail/review/' + id, body)
    } else {
      return this.httpClient.post<{result: boolean, message: string}>('/api/orderoffer-detail/review/' + id, body);
    }
  }

  getAuctions(body: {'city': string,
    'subcategories': {'Sposa' : string[], 'Sposo': string[], 'Fornitori': string[], 'Ricevimento': string[], 'Pets Wedding': string[]},
    type: string}) {
    return this.httpClient.patch<IAuction[]>('/api/auctions', body);
  }

  getSectors() {
    return this.httpClient.get<ISector[]>('/api/sectors');
  }

  getNotifications() {
    return this.httpClient.get<INotification[]>('/api/notifications');
  }

  postFavorite(tableid: number, tablename: string, idmacrooperator: number | string) {
    return this.httpClient.post<{isFavorite: boolean}>('/api/favorites', {tableid, tablename, idmacrooperator});
  }

  deleteNotification(id: string) {
    return this.httpClient.delete<{result: boolean}>('/api/notifications/' + id);
  }

  getAuctionById(id: string) {
    return this.httpClient.get<IAuction>('/api/auctions-detail/' + id);
  }

  getOffersByAction(idAuction: string, body: any) {
    return this.httpClient.patch<IOffer[]>('/api/offers/' + idAuction, body);
  }

  getQuestions(idAuction: string) {
    return this.httpClient.get<IQuestion[]>('/api/questions/' + idAuction);
  }

  canOperator(idAuction: string) {
    return this.httpClient.get<any>('/api/can-operator/' + idAuction);
  }

  sendRead(id: string) {
    return this.httpClient.get<{result: boolean}>('/api/notifications/' + id);
  }

  postQuestions(body: { idAuction: string; text: string }) {
    return this.httpClient.post<IQuestion[]>('/api/questions', body);
  }
  sendAnswer(body: { text: string; idQuestion: string; }) {
    return this.httpClient.post('/api/answers', body);
  }

  postWarning(body: { idref: number; text: string, table: string }) {
    return this.httpClient.post<{result: boolean}>('/api/warning', body);
  }

  postOffers(body: any) {
    return this.httpClient.post<{result: boolean}>('/api/offers', body);
  }

  sendAcceptOffer(numberOffer: number, idAuction: string) {
    return this.httpClient.get<{result: boolean}>('/api/accept-auction/' + idAuction + '/' + numberOffer);
  }
  getBonus(username: string) {
    return this.httpClient.get<BonusDTO>('/api/store-shipping/' + username);
  }

  sendOrderAuction(body: any) {
    return this.httpClient.post<{result: boolean}>('/api/order-auction', body);
  }

  getOrders() {
    return this.httpClient.get<any[]>('/api/orders');
  }

  searchCity(term: string) {
    return this.httpClient.get<{city: string}[]>('/api/cities/' + term);
  }

  getReviews() {
    return this.httpClient.get<{store: IReview[], offers: IReviewOffer[]}>('/api/reviewshome');
  }

  rememberPassword(email: string) {
    return this.httpClient.post<{result: string}>('/api/reset-password', {email});
  }

  canAuctions() {
    return this.httpClient.get<CanauctionsI>('/api/can-auctions');
  }

  postAuction(bodyAuction: IAuction) {
    return this.httpClient.post<{result: string}>('/api/auctions', bodyAuction);
  }

  getProfile() {
    return this.httpClient.get<IProfile>('/api/users-profile');
  }

  updateImageProfile(image: { data: string }) {
    return this.httpClient.post<IProfile>('/api/user-profile-image', {image});
  }

  updateProfile(userInfo: IProfile) {
    return this.httpClient.post<IProfile>('/api/users-profile', userInfo);
  }

  patchPassword(body: { oldPassword: string; newPassword: string }) {
    return this.httpClient.patch<{result: number}>('/api/password', body);
  }

  getBudget() {
    return this.httpClient.get<IBudget>('/api/users-budget');
  }

  getMyAuctions() {
    return this.httpClient.get<IAuction[]>('/api/auctions-user');

  }

  getMyAuctionsFinal() {
    return this.httpClient.get<{auctions: IAuction[], orders: any[]}>('/api/auctions-user-final');
  }

  deleteAddress(id: string) {
    return this.httpClient.delete<{result: string}>('/api/address-spedition/' + id);
  }

  postBudget(budget: string) {
    return this.httpClient.post('/api/users-budget', {budget});
  }

  getOrderDetail(id: string) {
    return this.httpClient.get<OrderDetail>('/api/order-detail/' + id);
  }

  getOfferOrderDetail(id: string) {
    return this.httpClient.get<OfferOrder>('/api/orderoffer-detail/' + id)
  }

  getOfferDetail(id: string) {
    return this.httpClient.get<IOfferDetail>('/api/offer-detail/' + id);
  }

  setOfferVote(idOffer: string, vote: number) {
    return this.httpClient.get<IOfferDetail>('/api/offer-vote/' + idOffer + '/' + vote)
  }

  refusedAuction(idAuction: string) {
    return this.httpClient.get('/api/refused-auction/' + idAuction);
  }

  getSeo(seoEnum: SeoEnum) {
    return this.httpClient.get<Seo>('/api/seo/' + seoEnum);
  }

  registerUser(macro: any, user: any) {
    return this.httpClient.post<{message: string}>('/api/users', {macro, user});
  }

  registerOperator(macro: any, operator: { sector_1: string }) {
    return this.httpClient.post<{message: string}>('/api/operators', {macro, operator});
  }

  sendReso(newReso: { reason: string; idorder: number; idorderauction: number; comment: string }) {
    return this.httpClient.post<{result: string}>('/api/reso', newReso);
  }

  postEsito(body: { esito: string; idorder: null; idorderauction: null }) {
    return this.httpClient.post<{result: string}>('/api/esito-order', body);
  }

  getFavorites() {
    return this.httpClient.get<{vetrine: IVetrina[], store: IVetrina[], products: IProduct[]}>('/api/favorites');
  }

  saveCookie(cookie) {
    return this.httpClient.post('/api/saveCookie', cookie);
  }

  getMessages(id?: string) {
    return this.httpClient.get<IMessage[]>('/api/messages' + (id ? '/' + id : ''));
  }

  sendResetPassword(token: string, form: { password: string; repeat: string }) {
    return this.httpClient.post<{ result: '0' | '1' }>('/api/reset-password/' + token, form);
  }

  postEditAuction(body: IAuction, text: string) {
    return this.httpClient.post<{result: boolean}>('/api/edit-auction', {...body, text});
  }

  sendUpdateFlags(macro: { regulation: string; cookie: string; privacy: string; personal: string; advertising: string }) {
    return this.httpClient.patch<{result: boolean}>('/api/operators-profile', macro);
  }

  getFlags(token: string) {
    return this.httpClient.get<{regulation: string, cookie: string, privacy: string, personal: string, advertising: string}>('/api/users-flags/' + token);
  }

  logout() {
    return this.httpClient.get('/api/logout');
  }
}
