import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'counterAcquisti'
})
export class CounterAcquistiPipe implements PipeTransform {

  transform(auctions: any[], orders: any[], ...args: unknown[]): {daPagare: number, pagati: number, spediti: number, costTotal: number, bonusTotal: number} {
    if(!auctions) {
      return null;
    }
    const daPagare = auctions.filter(e => !e.paypalbuyer).length;
    const pagati = auctions
        .filter(e => !!e.paypalbuyer)
        .filter(e => e.states && !e.states.map(a => a.state_order.toLowerCase()).includes('spedito')).length
      +
      orders
        .filter(e => e.states && !e.states.map(a => a.state_order.toLowerCase()).includes('spedito')).length;
    const speditiA = auctions.filter(e => e.states && e.states.map(a => a.state_order.toLowerCase()).includes('spedito')).length;
    const speditiO = orders.filter(e => e.states && e.states.map(a => a.state_order.toLowerCase()).includes('spedito')).length;
    let costTotal = 0;
    let bonusTotal = 0;

    for (let el of auctions) {
      costTotal += Number(el['total']);
      bonusTotal += Number(el['bonus']);
    }
    for (let el of orders) {
      costTotal += Number(el['total']);
      bonusTotal += Number(el['products'].reduce((a,b) => a += +b.tax_payed, 0));
    }
    costTotal = +costTotal.toFixed(2);
    bonusTotal = +bonusTotal.toFixed(2);
    return {daPagare, pagati, spediti: speditiA + speditiO, costTotal, bonusTotal};
  }

}
