import {Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {IProduct} from '../../objects/IProduct';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-product-store-item',
  templateUrl: './product-store-item.component.html',
  styleUrls: ['./product-store-item.component.scss']
})
export class ProductStoreItemComponent implements OnInit {
  @Input() product: IProduct;
  @Input() isHorizontal = false;
  @Input() isRow: boolean;
  @Input() isFavorite = false;
  @Output() addItem = new EventEmitter<IProduct>();
  @Output() reloadFavorite = new EventEmitter();
  innerWidth: number;
  constructor(@Inject(PLATFORM_ID) private platformId) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
  }



}
