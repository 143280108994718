import {Component, OnInit, TemplateRef} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpService} from '../../services/http.service';
import {CommunicatorService} from '../../services/communicator.service';
import {IProfile} from '../../objects/IProfile';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {CustomDatepickerI18n} from '../../services/datepicker/custom-datepicker-i18n.service';
import {I18n} from '../../services/datepicker/i8n.service';
@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class ProfileComponent implements OnInit {
  userInfo: IProfile;
  imageChangedEvent: any;
  imageCroppedTemp: any;
  image = {data: ''};
  weddingDate: NgbDateStruct;
  showError = false;
  showErrorPass = false;
  notCurrentPassValid = false;
  password = {current: '', new: '', new2: ''};
  cacheClear = Math.random() * 1000;
  constructor(private httpService: HttpService,
              public communicator: CommunicatorService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.httpService.getProfile().subscribe(resp => {
      this.showError = window.location.href.includes('showError=1');
      this.userInfo = resp;
      const {wedding_date} = resp;
      if (wedding_date) {
        const dates = (wedding_date + '').split('-');
        this.weddingDate = {day: +dates[2], month: +dates[1], year: +dates[0]};
      }
    });
  }


  showModal(modalImage: TemplateRef<any>) {
    this.modalService.open(modalImage);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageCroppedTemp = event;
  }
  finishCropped(modal) {
    this.imageChangedEvent = null;
    this.image = {data: this.imageCroppedTemp.base64};
    this.httpService.updateImageProfile(this.image).subscribe(() => {
      modal.dismiss();
      window.location.reload();
    });
  }
  save() {
    const {address, fullname, email, username, phone, advertising, gender, wedding_locality, personal, regulation, privacy} = this.userInfo;
    this.showError = true;
    if (address && fullname && email && gender && this.weddingDate && wedding_locality) {
      const body: any = {
        macro: {username, address, fullname, phone, email, personal, regulation, privacy, advertising},
        user: {gender, 'wedding-date' : this.weddingDate.year + '-' + this.weddingDate.month + '-' + this.weddingDate.day,
          'wedding-locality': wedding_locality}};
      if (this.image.data) {
        body['image'] = this.image;
      }

      this.httpService.updateProfile(body).subscribe(resp => {
        this.cacheClear = Math.random() * 1000;
        const modalRef2 = this.modalService.open(PopupComponent);
        modalRef2.componentInstance.title = 'Attenzione';
        modalRef2.componentInstance.body = 'Salvataggio eseguito con successo';
        this.showError = false;
        window.location.reload();
      });
    }
  }

  setDate(weddingDate: NgbDateStruct) {
    this.userInfo.wedding_date = new Date(weddingDate.year, weddingDate.month - 1, weddingDate.day);
  }

  changePassword(modal) {
    this.showErrorPass = true;

    if (this.password.current.length > 0 && this.password.new.length > 0 && this.password.new2.length > 0) {
      if(this.password.new === this.password.new2) {
        const body = {oldPassword: this.password.current, newPassword: this.password.new};
        this.httpService.patchPassword(body).subscribe(resp => {
          if (resp && resp['result'] === 1) {
            modal.dismiss();
            const modalRef2 = this.modalService.open(PopupComponent);
            modalRef2.componentInstance.title = 'Attenzione';
            modalRef2.componentInstance.body = 'Password aggiornata correttamente!';
            this.password = {current: '', new2: '', new: ''};
            this.showError = false;
            this.notCurrentPassValid = false;
          }else if(resp['result'] === 0){
            this.notCurrentPassValid = true;
          }
        });
      }
    }
  }

}
