<div class="container-fluid pt-3">
  <span>ORDINA PER</span>
  <button (click)="close.emit();" class="close pr-1" style="font-size: 2.2rem; margin-top: -10px;">&times;</button>
  <hr>
  <div class="row" *ngFor="let item of orders">
    <div class="col-10" (click)="orderChange.emit(item)">
      <span>{{item.label}}</span>
    </div>
    <div class="col my-auto">
      <i class="fa fa-check" *ngIf="order.key === item.key && order.value === item.value"></i>
    </div>
  </div>
</div>
