import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IOtherInformation} from '../../objects/IOtherInformation';

@Component({
  selector: 'app-product-other-information',
  templateUrl: './product-other-information.component.html',
  styleUrls: ['./product-other-information.component.scss']
})
export class ProductOtherInformationComponent implements OnInit {
  @Input() otherInformation: Partial<IOtherInformation>;
  @Input() category: string;
  @Input() sizeSelected: string;
  @Input() onlySize: boolean;
  @Input() hideCustom = false;
  @Output() sizeSelectedChange = new EventEmitter<string>();
  readyCustom = false;
  constructor() { }

  ngOnInit(): void {
  }

}
