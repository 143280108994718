import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IAuction} from '../../../objects/IAuction';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auction-preview',
  templateUrl: './auction-preview.component.html',
  styleUrls: ['./auction-preview.component.scss']
})
export class AuctionPreviewComponent implements OnInit {
  @Input() newAuction: Partial<IAuction>;
  @Output() confirm = new EventEmitter<boolean>();
  showDescription = false;
  descriptions: {title: string, label: string}[] = [];
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

    let keys = Object.keys(this.newAuction['other_information']);
    for (let key of keys) {
      let text = this.newAuction['other_information'][key];
      if(text[text.length-1] === ',') {
        text = text.substr(0, text.length - 1);
        this.newAuction['other_information'][key] = text;
      }
      let label = '';
      switch (key) {
        case 'manyPersonHotel':label = 'Ho bisogno delle camere per il seguente numero di persone complessive';break;
        case 'manyRoomsHotel':label = 'Ho bisogno delle seguenti camere';break;
        case 'whatNeed':label = 'Ho bisogno di';break;
        case 'whatStyle':label = 'Con stile';break;
        case 'whatColor':label = 'Con colore';break;
        case 'whatType':label = 'Tessuto';break;
        case 'addDetail':label = 'Con aggiunte particolari';break;
        case 'howDressLong':label = 'Il prodotto lo vorrei';break;
        case 'condition':label = 'Il prodotto deve essere nella condizione';break;
        case 'when':
          if(this.newAuction['other_information'][key].includes('indicare il mese')) {
            label = 'Per il mese di';
            text = months[new Date(this.newAuction['other_information']['date']).getMonth()];
          }else {
            label = 'Per il giorno';
            text = new Date(this.newAuction['other_information']['date']).toLocaleDateString();
          }
          break;
        case 'heightPerson':label = 'La mia altezza è';break;
        case 'widthPerson':label = 'La mia taglia è';break;
        case 'widthPerson2':label = 'Misure';break;
        case 'widthThing':label = 'Misura dell\'oggetto desiderato';break;
        case 'destination':label = 'Destinazione desiderata';break;
        case 'numberPerson':label = 'Numero indicativo degli invitati';break;
        case 'timeOfService':label = 'Tempo desiderato del servizio';break;
        case 'WithPerson':label = 'Con o senza conducente';break;
        case 'addServices':label = 'Vorrei anche i seguenti servizi aggiuntivi';break;
        case 'timeNeed':label = 'Ho bisogno di questo servizio per';break;
        case 'genreMusic':label = 'Ho bisogno di questi generi di musica';break;
        case 'yearsPerson':label = 'Durante il servizio, l\'età media indicativa è';break;
        case 'serviceType':label = 'Vorrei il seguente servizio';break;
        case 'numberPlates':
          label = 'Vorrei il seguente numero di portate per il menu';
          break;
        case 'typeMenu':label = 'Vorrei il seguente menu';break;
        case 'momentEvent':label = 'Per questo momento della giornata';break;
        case 'whereEvent':label = 'L\'evento si svolgerà';break;
        case 'manyPerson':label = 'Gli invitati sono orientativamente';break;
        case 'quantityCars':label = 'Mi occorrono questo numero di mezzi';break;
        case 'look':label = 'Vorrei questo look';break;
        case 'whichService':label = 'Sono interessato/a ai seguenti servizi';break;
        case 'sizeShoes':label = 'Misura';break;
        case 'whatAccessories':label = 'Sto cercando questi accessori';break;
        case 'manyPhoto':
          label = 'Vorrei il seguente numero di fotografie post prodotte';break;
        case 'timePhoto':label = 'Ho bisogno del fotografo per questo tempo';break;
        case 'stylePhoto':label = 'Vorrei questo stile fotografico';break;
        case 'distancePhoto':label = 'La distanza tra una location e l\'altra è';break;
        case 'locationPhoto':label = 'Il servizio fotografico si svolgerà per queste location';break;
        case 'ageChildren':label = 'L\'età media dei bambini presenti è';break;
        case 'whatWedding':label = 'Vorrei che il professionista si occupasse dei seguenti servizi';break;
        case 'serviceWedding':label = 'Vorrei i seguenti servizi aggiuntivi';break;
        case 'needMusic':label = 'Ho bisogno del seguente intrattenimento musicale';break;
        case 'whereFlower':label = 'Vorrei gli allestimenti floreali nei seguenti luoghi';break;
        case 'whereWedding':label = 'Il ricevimento si terrà';break;
        case 'siteWedding':label = 'Il luogo della cerimonia sarà';break;
        case 'riteWedding':label = 'Il rito della cerimonia sarà';break;
        case 'styleWedding':label = 'Lo stile del mio matrimonio sarà';break;
        case 'whereTravel': label = 'Vorrei soggiornare'; break;
        case 'howTravel': label = 'Vorrei effettuare i spostamenti con'; break;
        case 'typeTravel': label = 'Vorrei fare un viaggio tipo'; break;
        case 'timeTravel': label = 'Il viaggio durerà orientativamente'; break;
        case 'childrenTravel':
          if(!text.includes('No')) {
            label = 'La presenza dei bambini e la loro età è';
          }
          break;
        case 'personTravel': label = 'Le persone che viaggeranno saranno'; break;
        case 'locationTravel': label = 'Vorrei raggiungere le seguenti località';
        if(this.newAuction['other_information']['locationTravel'].includes('Si, ho in mente')) {
          text = this.newAuction['other_information']['moreLocationTravel'];
        }
        break;
      }
      if(label.length > 0) {
        this.descriptions.push({title: label, label: text})
      }
    }

    if(this.newAuction.description) {
      this.descriptions.push({title: 'Altre informazioni', label: this.newAuction.description});
    }
  }

  getArray(label: string) {
    return label.split(', ');
  }
}
