import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IProductPrice} from '../../objects/IProduct';

@Component({
  selector: 'app-product-prices',
  templateUrl: './product-prices.component.html',
  styleUrls: ['./product-prices.component.scss']
})
export class ProductPricesComponent implements OnInit {
  @Input() prices: IProductPrice[];
  @Input() category: string;
  @Input() quantity: string;
  @Input() priceSelected: IProductPrice;
  @Output() priceSelectedChange = new EventEmitter<IProductPrice>();
  @Output() quantityChange = new EventEmitter<string>();
  optionsId = ['5', '14', '26'];
  optionsQuantity: {id: string, label: string}[] = [];
  optionsCondition: {id: string, label: string, type: string}[] = [];
  optionsAcquisto: {id: string, label: string}[] = [];
  typeSelected = '0';
  constructor() {
  }

  ngOnInit(): void {
    if (this.priceSelected && this.priceSelected.quantity && +this.priceSelected.quantity !== 0) {
      let id = 1;
      while (id <= +this.priceSelected.quantity) {
        this.optionsQuantity.push({id: id + '', label: 'Qtà: ' + id});
        id++;
      }
    }
    for (let i = 0; i < this.prices.length; i++) {
      const price = this.prices[i];
      const label = (this.optionsId.includes(this.category)) ? 'Opzione ' + i :
        'Condizione: ' + price.option1 + ((price.option2 && price.option2.length > 0) ? ', ' + price.option2 : '');
      const id = i + '';
      this.optionsCondition.push({id, label, type: price.type});
      if (price.type === '0') {
        if (this.optionsAcquisto.filter(e => e.id === '0').length === 0) {
          this.optionsAcquisto.push({id: price.type, label: 'Acquisto'});
        }
      } else {
        if (this.optionsAcquisto.filter(e => e.id === '1').length === 0) {
          this.optionsAcquisto.push({id: price.type, label: 'Noleggio'});
        }
      }
    }
    this.typeSelected = this.optionsAcquisto[0].id;
  }

  changePrice(iSelected) {
    console.log(iSelected);
    this.priceSelectedChange.emit(this.prices[+iSelected]);
    this.optionsQuantity = [];
    if (this.prices[+iSelected].quantity && +this.prices[+iSelected].quantity !== 0) {
      let id = 1;
      while (id <= +this.prices[+iSelected].quantity) {
        this.optionsQuantity.push({id: id + '', label: 'Qtà: ' + id});
        id++;
      }
    }
  }

  calculateTotal(price: string, quantity: string): string {
    return String(+price * +quantity);
  }

  calculateTotalAdvance(price: string, quantity: string): string {
    return (+price * +quantity * 0.3).toFixed(2);
  }

  changePriceByType(type: string) {
    const id = this.optionsCondition.filter(e => e.type === type)[0].id;
    this.changePrice(id);
  }
}
