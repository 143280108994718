<div [class]="'card mt-2 ' + ((auction.id_offer_winner === offer.offer_number) ? 'winner' : '')">
  <button *ngIf="isModal" class="btn btn-sm btn-close" (click)="closePreview.emit();"><i class="fa fa-times fa-2x"></i> </button>
  <img *ngIf="auction.id_offer_winner === offer.offer_number" class="textWinner" src="https://www.weddingsmart.it/assets/images/winner.png">

  <div class="card-body card-body-offer">
    <div class="row" [routerLink]="'/offer-detail/' + offer.offer_detail" *ngIf="!isModal">
      <div class="col text-dark business-name"> {{offer.business_name}}
      </div>
    </div>
    <div class="row row-margin-5" [routerLink]="'/offer-detail/' + offer.offer_detail" *ngIf="!isModal">
      <div class="col-12 text-center col-img-offer position-relative">
        <img class="img-offers" [src]="'https://www.weddingsmart.it/api/images/offers/' + offer.imgname">
        <small class="percentage"> {{percentage}} %</small>
      </div>
    </div>
    <div class="row row-margin-5" *ngIf="isModal">
      <div class="col-12 text-center col-img-offer position-relative">
        <img class="img-offers" [src]="image.data" *ngIf="images[0] as image">
        <small class="percentage"> {{percentage}} %</small>
      </div>
    </div>

    <div [class]="'row row-hearts hearts-' + offer.offer_number + ' vote-' + offer.vote">
      <div class="col text-center bg-white pl-0 pr-0"
           [ngbTooltip]="(user && user.isUser === '1' && user.username === auction.username) ?
                  'Aiuta i fornitori a offrirti il meglio per le tue preferenze mettendo un voto a questa offerta' :
                  'Questo voto indica il punteggio di quanto è di buon grado questa offerta, allo sposo, in base alle sue preferenze'">
           <i *ngFor="let i of [1,2,3,4,5]" class="m-1 fa fa-heart" (click)="setVotes(i)"></i>
       </div>
    </div>


    <div [routerLink]="'/offer-detail/' + offer.offer_detail">
      <h5 class="card-title text-dark"><span class="text-white font-offer">Offerta: </span> {{offer.offer + (!offer.offer.includes(',') ? ',00' : '')}} €</h5>
      <div class="d-none d-md-block">
        <p class="card-text">Descrizione e tessuti</p>
        <label class="text-white height-48"> {{offer.description}} </label>
        <p class="card-text">Tempo di realizzazione e spedizione</p>
        <label class="text-white height-24"> {{offer.days}} </label>
        <p class="card-text">Condizioni</p>
        <label class="text-white"> {{offer.detail}} </label>

        <div class="row">
          <div class="col-12 text-center">
            <p class="text-white card-text font-weight-normal">Proposta n. <span
              class="text-dark number-offer"> {{offer.offer_number}} </span>
            </p>
            <small class="text-center"><span class="text-inserted font-weight-bold">Inserita il</span> <a
              class="text-danger font-weight-bold">  {{offer.date_inserted}} </a></small>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-8 offset-2 text-center pl-0 pr-0">
        <button *ngIf="user && auction.refusedall === '0' && auction.username === user.username && auction.id_offer_winner == null else procediOrdine"
                class="btn btn-success buttonAccept" (click)="acceptOffer.emit(offer.offer_number)">Accetta proposta
        </button>

        <ng-template #procediOrdine>
          <button
            *ngIf="auction.id_offer_winner && auction.id_offer_winner == offer.offer_number && user && auction.username === user.username && auction.is_buyed !== '1'"
            class="btn btn-success buttonAccept" (click)="showModalOrder.emit()">Procedi all'ordine
          </button>
        </ng-template>
      </div>
      <div class="col-2 text-right mt-auto pl-0">
        <span class="little-number"> {{offer.offer_number}}</span>
      </div>

    </div>
  </div>
</div>
