import { RegisterModule } from './pages/register/register.module';
import { Injectable, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginModalComponent } from './components/popup/login-modal/login-modal.component';
import { PopupComponent } from './components/popup/popup/popup.component';
import { RememberPasswordComponent } from './components/popup/remember-password/remember-password.component';
import { ProductStoreItemComponent } from './components/product-store-item/product-store-item.component';
import { FornitoreStoreComponent } from './pages/fornitore-store/fornitore-store.component';
import { ConvertPricePipe } from './pipes/convert-price.pipe';
import { FilterByCategoryPipe } from './pipes/filter-by-category.pipe';
import { GetProductByCodePipe } from './pipes/get-product-by-code.pipe';
import { InterceptorService } from './services/interceptor.service';
import { UtilityService } from './services/utility.service';
// import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { Select2Module } from 'ng-select2-component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPayPalModule } from 'ngx-paypal';
import { ActionFavoriteComponent } from './components/action-favorite/action-favorite.component';
import { CircleMenuComponent } from './components/circle-menu/circle-menu.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { FilterLeftComponent } from './components/filter-left/filter-left.component';
import { GetOnlyNewPipe } from './components/header/notifications/get-only-new.pipe';
import { NotificationItemComponent } from './components/header/notifications/notification-item/notification-item.component';
import { TimestampDiffPipe } from './components/header/notifications/notification-item/timestamp-diff.pipe';
import { NotificationsComponent } from './components/header/notifications/notifications.component';
import { ImageComponent } from './components/image/image.component';
import { MapComponent } from './components/map/map.component';
import { MenuCartComponent } from './components/menu-cart/menu-cart.component';
import { OfferImageGalleryComponent } from './components/offer-image-gallery/offer-image-gallery.component';
import { OrderRightComponent } from './components/order-right/order-right.component';
import { AddressComponent } from './components/popup/address/address.component';
import { ConfirmComponent } from './components/popup/confirm/confirm.component';
import { OrderSummaryComponent } from './components/popup/order-summary/order-summary.component';
import { PaypalResultComponent } from './components/popup/paypal-result/paypal-result.component';
import { PopupInputComponent } from './components/popup/popup-input/popup-input.component';
import { PrivacyCookieComponent } from './components/privacy-cookie/privacy-cookie.component';
import { ProductCartItemComponent } from './components/product-cart-item/product-cart-item.component';
import { ProductImageGalleryComponent } from './components/product-image-gallery/product-image-gallery.component';
import { ProductOtherInformationComponent } from './components/product-other-information/product-other-information.component';
import { FilterByTypePipe } from './components/product-prices/filter-by-type.pipe';
import { ProductPricesComponent } from './components/product-prices/product-prices.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { ScrollUpComponent } from './components/scroll-up/scroll-up.component';
import { SelectTypeaheadComponent } from './components/select-typeahead/select-typeahead.component';
import { VetrinaLoadingComponent } from './components/templates-loading/vetrina-loading/vetrina-loading.component';
import { AuctionDetailComponent } from './pages/auction-detail/auction-detail.component';
import { ExtractDescriptionPipe } from './pages/auction-detail/extract-description.pipe';
import { OfferCardComponent } from './pages/auction-detail/offer-card/offer-card.component';
import { QuestionsComponent } from './pages/auction-detail/questions/questions.component';
import { AuctionItemComponent } from './pages/auctions/auction-item/auction-item.component';
import { AuctionsFilterMdComponent } from './pages/auctions/auctions-filter-md/auctions-filter-md.component';
import { GroupByMacroPipe } from './pages/auctions/auctions-filter-md/group-by-macro.pipe';
import { InFilterPipe } from './pages/auctions/auctions-filter-md/in-filter.pipe';
import { AuctionsComponent } from './pages/auctions/auctions.component';
import { HomeComponent } from './pages/home/home.component';
import { AcquistoItemComponent } from './pages/my-auctions/acquisto-item/acquisto-item.component';
import { TotalProductsPipe } from './pages/my-auctions/acquisto-item/total-products.pipe';
import { AuctionOrderItemComponent } from './pages/my-auctions/auction-order-item/auction-order-item.component';
import { CounterAcquistiPipe } from './pages/my-auctions/counter-acquisti.pipe';
import { CounterAuctionsPipe } from './pages/my-auctions/counter-auctions.pipe';
import { FilterAuctionsPipe } from './pages/my-auctions/filterAuctions.pipe';
import { FilterOrderPipe } from './pages/my-auctions/filterOrder.pipe';
import { GroupByCategoryPipe } from './pages/my-auctions/group-by-category.pipe';
import { MyAuctionItemComponent } from './pages/my-auctions/my-auction-item/my-auction-item.component';
import { MyAuctionsComponent } from './pages/my-auctions/my-auctions.component';
import { AuctionPreviewComponent } from './pages/new-auction/auction-preview/auction-preview.component';
import { ConfirmResetComponent } from './pages/new-auction/confirm-reset/confirm-reset.component';
import { MonthsPickerComponent } from './pages/new-auction/months-picker/months-picker.component';
import { NewAuctionComponent } from './pages/new-auction/new-auction.component';
import { NocanAuctionsComponent } from './pages/new-auction/nocan-auctions/nocan-auctions.component';
import { OfferDetailComponent } from './pages/offer-detail/offer-detail.component';
import { OrderDetailComponent } from './pages/order-detail/order-detail.component';
import { OrderRiepilogoComponent } from './pages/order-riepilogo/order-riepilogo.component';
import { PayCartComponent } from './pages/pay-cart/pay-cart.component';
import { PhotosDetailComponent } from './pages/photos-detail/photos-detail.component';
import { ProductStoreComponent } from './pages/product-store/product-store.component';
import { ReviewsAccordionComponent } from './pages/product-store/reviews-accordion/reviews-accordion.component';
import { ReviewsByVotePipe } from './pages/product-store/reviews-accordion/reviews-by-vote.pipe';
import { ProfileComponent } from './pages/profile/profile.component';
// import { GetBySectorPipe } from './pages/register/getBySector.pipe';
// import { RegisterComponent } from './pages/register/register.component';
import { ResultActivationComponent } from './pages/result-activation/result-activation.component';
import { ReviewItemComponent } from './pages/review/review-item/review-item.component';
import { ReviewComponent } from './pages/review/review.component';
import { ShareComponent } from './pages/share/share.component';
import { VetrinaDetailComponent } from './pages/vetrine/vetrina-element/vetrina-detail/vetrina-detail.component';
import { VetrinaElementComponent } from './pages/vetrine/vetrina-element/vetrina-element.component';
import { VetrinaFornitoreComponent } from './pages/vetrine/vetrina-fornitore/vetrina-fornitore.component';
import { VetrineComponent } from './pages/vetrine/vetrine.component';
import { GetPricePipe } from './pipes/get-price.pipe';
import { SortByKeyPipe } from './pipes/sort-by-key.pipe';
import { SplitPipe } from './pipes/split.pipe';
import { TruncFullnamePipe } from './pipes/trunc-fullname.pipe';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'ng-social-login-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import { GetOnlyNewMessagesPipe } from './components/header/notifications/get-only-new-messages.pipe';
import { MessageItemComponent } from './components/header/notifications/message-item/message-item.component';
import { ResultReviewComponent } from './components/popup/result-review/result-review.component';
import { FilterOffersPipe } from './pages/auction-detail/filter-offers.pipe';
import { ConsegnaEsitoComponent } from './pages/consegna-esito/consegna-esito.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { EffettuaResoComponent } from './pages/effettua-reso/effettua-reso.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FavoritesComponent } from './pages/favorites/favorites.component';
import { HowWorkComponent } from './pages/how-work/how-work.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { TerminiComponent } from './pages/termini/termini.component';
// import { WelcomeComponent } from './pages/welcome/welcome.component';
import { WelcomeModule } from './pages/welcome/welcome.module';
import { FilterPipe } from './pipes/filter.pipe';
import { GetWebpPipe } from "./pipes/get-webp.pipe";
// import { WelcomeRoutingModule } from './pages/welcome/welcome-routing.module';

@Injectable()
class HammerConfig extends HammerGestureConfig  {
  overrides = {
    swipe: {direction: 6},
    pinch: {enable: false},
    rotate: {enable: false}
  };
}

const configSocial = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('680879788195-4bpgosn2fujie3plbjdvkjfaj1aov15s.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('1064628243879978')
  }
], false);
export function provideConfig() {
  return configSocial;
}
@NgModule({
  declarations: [
    AppComponent,
    FornitoreStoreComponent,
    HeaderComponent,
    FooterComponent,
    PopupComponent,
    ResultReviewComponent,
    LoginModalComponent,
    RememberPasswordComponent,
    ProductStoreItemComponent,
    GetProductByCodePipe,
    ConvertPricePipe,
    FilterByCategoryPipe,
    MenuCartComponent,
    ProductStoreComponent,
    HomeComponent,
    HowWorkComponent,
    GetPricePipe,
    ProductOtherInformationComponent,
    ProductPricesComponent,
    SplitPipe,
    DateRangeComponent,
    ProductImageGalleryComponent,
    SelectTypeaheadComponent,
    PayCartComponent,
    ConfirmComponent,
    AddressComponent,
    ProductCartItemComponent,
    VetrineComponent,
    VetrinaElementComponent,
    VetrinaDetailComponent,
    VetrinaFornitoreComponent,
    MapComponent,
    PhotosDetailComponent,
    ReviewComponent,
    ReviewItemComponent,
    AuctionsComponent,
    AuctionItemComponent,
    AuctionsFilterMdComponent,
    GroupByMacroPipe,
    InFilterPipe,
    ReviewsComponent,
    ReviewsAccordionComponent,
    ActionFavoriteComponent,
    NotificationsComponent,
    GetOnlyNewMessagesPipe,
    MessageItemComponent,
    NotificationItemComponent,
    GetOnlyNewPipe,
    TimestampDiffPipe,
    AuctionDetailComponent,
    ExtractDescriptionPipe,
    OfferCardComponent,
    ImageComponent,
    QuestionsComponent,
    CircleMenuComponent,
    PopupInputComponent,
    OrderSummaryComponent,
    ReviewsByVotePipe,
    VetrinaLoadingComponent,
    FilterLeftComponent,
    SortByKeyPipe,
    GetWebpPipe,
    OrderRightComponent,
    TruncFullnamePipe,
    ScrollUpComponent,
    PaypalResultComponent,
    FilterByTypePipe,
    ShareComponent,
    NewAuctionComponent,
    MonthsPickerComponent,
    ConfirmResetComponent,
    AuctionPreviewComponent,
    NocanAuctionsComponent,
    ProfileComponent,
    MyAuctionsComponent,
    CounterAuctionsPipe,
    CounterAcquistiPipe,
    MyAuctionItemComponent,
    AcquistoItemComponent,
    TotalProductsPipe,
    GroupByCategoryPipe,
    AuctionOrderItemComponent,
    FilterOrderPipe,
    FilterAuctionsPipe,
    OrderDetailComponent,
    OrderRiepilogoComponent,
    OfferDetailComponent,
    OfferImageGalleryComponent,
    // RegisterComponent,
    // GetBySectorPipe,
    ResultActivationComponent,
    PrivacyCookieComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    EffettuaResoComponent,
    ConsegnaEsitoComponent,
    FavoritesComponent,
    FilterOffersPipe,
    // WelcomeComponent,
    FaqComponent,
    TerminiComponent,
    FilterPipe,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    // WelcomeRoutingModule,
    // AngularMultiSelectModule,
    FormsModule,
    NgbModule,
    NgxPayPalModule,
    HttpClientModule,
    HammerModule,
    ImageCropperModule,
    Select2Module,
    RoundProgressModule,
    SocialLoginModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics),
    WelcomeModule,
    RegisterModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true,
    deps: [UtilityService, PLATFORM_ID]
  },
  {
    provide: AuthServiceConfig,
    useFactory: provideConfig
  },
    {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: HammerConfig
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
