import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() model: string;
  @Input() label: string;
  @Input() isEdit = true;
  @Input() showInput = true;
  @Input() path = 'store';
  @Input() aspectRatio: number;
  @Input() useCropper = false;
  @Output() changeImageEmitter = new EventEmitter<{data: string}>();
  changeImage: {data: string} = { data: '' };
  imageChangedEvent: any;
  baseUrl = environment.urlApi;
  imageCroppedTemp: ImageCroppedEvent;
  constructor() { }

  ngOnInit(): void {
  }


  readUrl(event) {
    if (event.length === 0) {
      return;
    } else if (this.useCropper) {
      this.imageChangedEvent = event;
      return;
    }
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      this.changeImage.data = e.target.result as string;
      this.changeImageEmitter.emit(this.changeImage);
    };
  }
  imageCropped(event: ImageCroppedEvent) {
    this.imageCroppedTemp = event;
  }
  finishCropped() {
    this.imageChangedEvent = null;
    this.changeImage = {data: this.imageCroppedTemp.base64};
    this.changeImageEmitter.emit(this.changeImage);
  }
}
