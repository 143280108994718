<div class="pb-3 bg-white" *ngIf="product$ | async as product">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 text-center pr-1 pl-1 col-sticky">
        <app-product-image-gallery [product]="product"></app-product-image-gallery>
      </div>

      <div class="col mt-0">
        <div class="row">
          <div class="col-12 text-right order-md-1 col-md-auto">

            <app-action-favorite class="mr-1" classButton="btn-info rounded-circle" [tableId]="product.id"
                                 tableName="products"
                                 [idProprierty]="+product.idmacro" [isRed]="product.isfavorite"
            ></app-action-favorite>
            <button class="btn btn-sm btn-info rounded-circle mr-1"><i
              class="fa fa-share-alt"  (click)="communicator.sharePage$.next({})"></i></button>
            <ng-container *ngIf="communicator.checkUser$ | async as user">
              <button class="btn btn-sm btn-info rounded-circle mr-1" *ngIf="user.isUser === '1'" (click)="segnala(product.id)"><i
                class="fa fa-exclamation"></i></button>
            </ng-container>
          </div>
          <div class="col order-md-0">
            <h6>Store fornitore: <a [routerLink]="['/store/', product.macrocategory, product.category, product.username]">{{product.storetitle}}</a></h6>
          </div>
        </div>
        <span class="mt-3 color-primary font-weight-bold">Cod. {{product.code}}</span>
        <h3 class="mt-1">{{product.title}}</h3>

        <div class="row">
          <div class="col-auto">
            <label class="text-black-50" (click)="scrollBottom()">
              <ng-container *ngFor="let i of [1,2,3,4,5]">
                <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star-y.png"
                     *ngIf="i <= +product.avgreview else half">
                <ng-template #half>
                  <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/half-star.png"
                       *ngIf="((i - 0.5 < +product.avgreview) && (i > +product.avgreview)) else nohalf">
                </ng-template>
                <ng-template #nohalf>
                  <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star.png"
                       *ngIf="i > +product.avgreview">
                </ng-template>
              </ng-container>
              {{product.reviews.length}}
            </label>
          </div>
        </div>
        <app-product-other-information *ngIf="product.other_information" [otherInformation]="product.other_information"
                                       [onlySize]="true" [hideCustom]="price.type === '1'"
                                       [category]="product.idcategory"
                                       [sizeSelected]="sizeSelected" (sizeSelectedChange)="sizeSelected = ($event === sizeSelected) ? '' : $event"></app-product-other-information>
        <div>
          <app-product-prices [category]="product.idcategory" [(priceSelected)]="price" [prices]="product.prices"
                              [(quantity)]="quantity"></app-product-prices>

          <button
            [disabled]="(!product.other_information.customMisure && sizeSelected === '')
              || (price.type === '1' && !price.quantitydays)
              || (price.type === '0' && price.quantity <= '0')"
            class="btn btn-primary radius-0 mb-3"
            (click)="menuCartComponent.addItemToCart(product, price, quantity, sizeSelected); quantity = '1';"><i
            class="fa fa-cart-plus mr-1"></i> Aggiungi al carrello
          </button>
        </div>
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs"
            [ngClass]="{'nav-justified' : innerWidth < 768}">
          <li [ngbNavItem]="0">
            <a ngbNavLink [ngClass]="{'p-0' : innerWidth < 768}">Dettaglio</a>
            <ng-template ngbNavContent>
              <app-product-other-information *ngIf="product.other_information"
                                             [otherInformation]="product.other_information" [onlySize]="false"
                                             [category]="product.idcategory"></app-product-other-information>
            </ng-template>
          </li>
          <li [ngbNavItem]="1">
            <a ngbNavLink [ngClass]="{'p-0' : innerWidth < 768}">Descrizione</a>
            <ng-template ngbNavContent>
              <p>{{product.description}}</p>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink [ngClass]="{'p-0' : innerWidth < 768}">Spedizione e resi</a>
            <ng-template ngbNavContent>
              <label [innerHTML]="product.regole"></label>
            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="p-3 border border-top-0 mb-3">
        </div>

        <app-reviews-accordion [reviews]="product.reviews" [avgReview]="product.avgreview"
                               [sectors]="[product.macrocategory, product.category]"
                               [username]="product.username"></app-reviews-accordion>

      </div>
    </div>
  </div>

  <app-menu-cart #menuCartComponent></app-menu-cart>
</div>
