<div class="row">
  <div class="col-3 text-right">
    <img class="max-height" [src]="'https://www.weddingsmart.it/api/images/' + path + '/' + item.imgname">
  </div>
  <div class="col my-auto">
    <h5>{{item.title}}</h5>
    <i (click)="objVote.vote = i" *ngFor="let i of [1,2,3,4,5]" class="fa fa-star fa-2x"
       [ngClass]="{'yellow': i <= objVote.vote}"></i>
  </div>
</div>

<hr>
<h5>Aggiungi un titolo</h5>
<label for="title">Riassumi la tua recensione in una riga</label>
<input id="title" [(ngModel)]="objVote.title" class="form-control"
       placeholder="Quali sono le cose più importanti da sapere">
<hr>
<h5>Aggiungi una recensione scritta</h5>
<textarea id="desc" [(ngModel)]="objVote.desc" class="form-control" rows="4"
          placeholder="Che cosa ti è piaciuto e cosa non ti è piaciuto? Per cosa hai usato questo prodotto?"></textarea>
<hr>
