
<div class="card mt-3 cursor-pointer"
     [class.m-less]="isRow"
     [routerLink]="['/prodotto', product.code, product.title]">
  <div class="card-body is-small p-0" *ngIf="!isHorizontal" [ngClass]="{'height-grid': !isRow}">
    <div class="row">
      <div [ngClass]="{'col-4': isRow, 'col-12': !isRow}" style="display:table-cell; vertical-align:middle;">
        <img class="w-100"
             [class.h-100]="isRow"
             [src]="'https://www.weddingsmart.it/api/images/products/' + (product.imgname)">
      </div>
      <div class="col pt-1 pb-1 d-grid">
<!--        <span class="badge badge-warning">{{product.quantity}} Disponibili</span>-->
        <div class="row">
          <div class="col-12">

            <span class="auction-code color-primary font-weight-bold mt-0 float-right" [ngClass]="isRow ? 'mr-1' : ''">Cod. {{product.code}}</span>

            <h6 class="card-title pt-md-0 height-57 w-100" [ngClass]="{'height-less': isRow}"> {{product.title}}</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="text-black-50 mb-0">
              <ng-container *ngFor="let i of [1,2,3,4,5]">
                <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star-y.png" *ngIf="i <= +product.avgreview else half">
                <ng-template #half>
                  <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/half-star.png" *ngIf="((i - 0.5 < +product.avgreview) && (i > +product.avgreview)) else nohalf">
                </ng-template>
                <ng-template #nohalf>
                  <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star.png"  *ngIf="i > +product.avgreview">
                </ng-template>
              </ng-container>
              ({{product.reviews.length}})
            </label>
            <span class="float-right" *ngIf="isFavorite">
              <app-action-favorite class="mr-1" classButton="btn-info rounded-circle" [tableId]="product.id"
                                   tableName="products" [showConfirm]="true" (changeFavorite)="reloadFavorite.emit()"
                                   [idProprierty]="product.idmacro" [(isRed)]="product.isfavorite"
              ></app-action-favorite>
            </span>
            <div class="price" [innerHTML]="product.prices | getPrice:true:innerWidth > 768"></div>
          </div>
        </div>

        <button class="btn btn-outline-primary btn-sm" [ngClass]="{'mr-0 w-100': !isRow, 'mr-1': isRow}">
          Visualizza le opzioni di acquisto
        </button>
      </div>
    </div>
  </div>
  <div class="card-img-top text-center" *ngIf="isHorizontal">
    <img class="my-auto w-100" style="height: 189px;" *ngIf="product.imgnameor"
         [src]="'https://www.weddingsmart.it/api/images/products/' + (product.imgnameor)">
    <div class="background-lightgray text-center" style="height: 189px;" *ngIf="!product.imgnameor">
      <i class="fa fa-image fa-5x mt-5"></i>
    </div>
  </div>
  <div class="card-body p-1" *ngIf="isHorizontal">
    <span class="auction-code float-right color-primary font-weight-bold">Cod. {{product.code}}</span>
    <h6 class="card-title height-57 mt-4"> {{product.title}}</h6>
    <label class="text-black-50">
      <ng-container *ngFor="let i of [1,2,3,4,5]">
        <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star-y.png" *ngIf="i <= +product.avgreview else half">
        <ng-template #half>
          <img class="icon-half-star" src="https://www.weddingsmart.it/assets/icons/half-star.png" *ngIf="((i - 0.5 < +product.avgreview) && (i > +product.avgreview)) else nohalf">
        </ng-template>
        <ng-template #nohalf>
          <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star.png" *ngIf="i > +product.avgreview">
        </ng-template>
      </ng-container>
      ({{product.reviews.length}})
    </label>

    <div class="row mb-1">
      <div class="col-6 mt-auto">
        <label class="mb-0 price">{{product.prices | getPrice}} </label><br>
      </div>
      <div class="col-6 pl-0 text-right">
        <button class="btn btn-primary btn-sm font-sm">
          Visualizza le opzioni di acquisto
        </button>
      </div>
    </div>
  </div>
</div>
