import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByType'
})
export class FilterByTypePipe implements PipeTransform {

  transform(values: {id: string, label: string, type: string}[], typeSel: string, ...args: unknown[]): {id: string, label: string, type: string}[] {
    return values.filter(e => e.type === typeSel);
  }

}
