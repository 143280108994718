<ng-container *ngIf="showFooter$ | async as show">

  <div class="w-100" *ngIf="show">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 text-center">
          <h6>DI COSA HAI BISOGNO?</h6>
          <a routerLink="/faq/aiuto/weddingsmart-aiuto-supporto"> FAQ</a>
          <br>
          <a *ngIf="!window || !window.location.href.includes('welcome')" routerLink="/termini-e-condizioni-generali-del-servizio">Termini e condizioni</a>
          <button
            *ngIf="window && window.location.href.includes('welcome')"
            class="btn btn-link p-0"
            (click)="openTermini()"
          >Termini e condizioni</button>
        </div>
        <div class="col text-center">
          <h6>SEGUI WEDDING SMART SU </h6>
          <i class="fa-brands fa-facebook fa-3x"></i>
          <i class="fa-brands fa-linkedin fa-3x"></i>
          <i class="fa-brands fa-twitter fa-3x"></i>
          <h6>METODI DI PAGAMENTO</h6>
          <img class="img" [src]="url + '/assets/images/footer_pagamenti.png'">
        </div>

        <div class="col-md-4 text-center d-none d-md-block">
          <h6>Contattaci</h6>
          <i class="fa-brands fa-whatsapp"></i> (+39) 324 778 8526 (solo messaggi)<br>
          <i class="fa fa-envelope"></i> info@weddingsmart.it
        </div>
      </div>
      <div class="row">
        <div class="col-12 ool-md-6 text-center text-md-left">
          © 2016 All Rights Reserved by Wedding Smart.
        </div>
        <div class="col text-center text-md-right">
          <a *ngIf="!window || !window.location.href.includes('welcome')" routerLink="/cookie-policy">Cookie policy</a>

          <button
            *ngIf="window && window.location.href.includes('welcome')"
            class="btn btn-link p-0"
            (click)="openCookie()"
          >Cookie policy</button>
          /
          <a  *ngIf="!window || !window.location.href.includes('welcome')" routerLink="/privacy-policy">Privacy policy</a>

          <button
            *ngIf="window && window.location.href.includes('welcome')"
            class="btn btn-link p-0"
            (click)="openPrivacy()"
          >Privacy policy</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

