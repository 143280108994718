import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalProducts'
})
export class TotalProductsPipe implements PipeTransform {

  transform(order: any, ...args: unknown[]): {bonus: number, total: number,  totalBonus: number, shipping: number, percentageTotal: string} {
    if(!order) {
      return null;
    }
    const total = order['products'].reduce((a,b) => a += +b.total, 0);
    const bonus = (order['products'].reduce((a,b) => a += +b.bonus_buyer, 0)) / order['products'].length;
    let shipping = (order['costshipping'] != null && order['costshipping'] != 0) ? order['costshipping'] : 'Gratuito';
    let percentageTotal = bonus;
    if(order['costshipping']) {
      percentageTotal = 100 - (+order['total'] * 100 / (total));
    }
    if (percentageTotal < 0) {
      percentageTotal = -percentageTotal;
    }
    let totalBonus = order.products.reduce((a,b) => a += +b.tax_payed, 0);
    // for (const prod of order.products) {
    //   totalBonus += (+prod.bonus_buyer / 100) * +prod.total;
    // }
    totalBonus = +totalBonus.toFixed(2);

    return {bonus, total, totalBonus, shipping, percentageTotal: percentageTotal.toFixed(2)};
  }

}
