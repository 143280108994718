<div class="w-100 pl-3 pr-3 background-lightgray pt-2 pb-2 position-relative">
  <div class="square-dropdown"></div>
  <ul ngbNav #nav="ngbNav" class="nav-pills nav-justified">
    <li ngbNavItem>
      <a ngbNavLink>
        <small class="sm-size">Notifiche</small>
      </a>
      <ng-template ngbNavContent>
        <div class="overflow-auto">
          <h5 class="mt-2 pl-2">Notifiche
<!--            <button class="btn btn-sm float-right background-lightgray rounded-circle"><i-->
<!--              class="fa fa-1x fa-search color-primary"></i>-->
<!--            </button>-->
          </h5>
          <ng-container *ngIf="notifications | getOnlyNew as notificationsNew">
            <ng-container *ngIf="notificationsNew.length > 0">
              <h6 class="mt-3 pl-2">Nuove notifiche</h6>
              <app-notification-item *ngFor="let not of notificationsNew" [notification]="not"
                                     (deleteEmitter)="deleteNotification(not, notificationsNew)"
                                     (refreshNotification)="refreshNotification.emit()"
                                      (closeNotifications)="closeNotifications.emit()"
              ></app-notification-item>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="notifications | getOnlyNew:false:maxNotifications as notificationsOld">
            <ng-container *ngIf="notificationsOld.length > 0">
              <h6 class="mt-3 pl-2">Notifiche precedenti
                <button class="btn btn-link p-0 float-right mr-2" (click)="showNotifications = !showNotifications">
                  <small *ngIf="showNotifications">Nascondi</small>
                  <small *ngIf="!showNotifications">Mostra</small>
                </button>
              </h6>
              <div *ngIf="showNotifications">

                <app-notification-item *ngFor="let not of notificationsOld" [notification]="not"
                                       (deleteEmitter)="deleteNotification(not, notificationsOld)"
                                       (refreshNotification)="refreshNotification.emit()"
                                       (closeNotifications)="closeNotifications.emit()"
                ></app-notification-item>
                <button class="btn w-100 background-lightgray" (click)="maxNotifications = (maxNotifications === 6) ? 0 : 6">
                  <small *ngIf="maxNotifications === 6">Mostra tutte</small>
                  <small *ngIf="maxNotifications === 0">Mostra meno</small>
                </button>
              </div>

            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>
        <small class="sm-size">I miei messaggi</small>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngIf="communicator.checkUser$ | async as user">
          <div class="overflow-auto" *ngIf="messages$ | async as messages">
            <h5 class="mt-2 pl-2">Messaggi
            </h5>
            <ng-container *ngIf="messages | getOnlyNewMessages as messagesNew">
              <ng-container *ngIf="messagesNew.length > 0">
                <h6 class="mt-3 pl-2">Nuovi messaggi</h6>
                <app-message-item *ngFor="let mess of messagesNew" [message]="mess" [user]="user"
                                  (closeNotifications)="closeNotifications.emit()"
                ></app-message-item>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="messages | getOnlyNewMessages:false:maxMessages as messagesOld">
              <ng-container *ngIf="messagesOld.length > 0">
                <h6 class="mt-3 pl-2">Messaggi precedenti
                  <button class="btn btn-link p-0 float-right mr-2" (click)="showNotifications = !showNotifications">
                    <small *ngIf="showNotifications">Nascondi</small>
                    <small *ngIf="!showNotifications">Mostra</small>
                  </button>
                </h6>
                <div *ngIf="showNotifications">

                  <app-message-item *ngFor="let mess of messagesOld" [message]="mess" [user]="user"
                                    (closeNotifications)="closeNotifications.emit()"></app-message-item>
                  <button class="btn w-100 background-lightgray"
                          (click)="maxMessages = (maxMessages === 6) ? 0 : 6">
                    <small *ngIf="maxMessages === 6">Mostra tutte</small>
                    <small *ngIf="maxMessages === 0">Mostra meno</small>
                  </button>
                </div>

              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-template>
    </li>
  </ul>
</div>
<div [ngbNavOutlet]="nav"></div>
