import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpService} from "../../services/http.service";
import {PopupComponent} from "../../components/popup/popup/popup.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-consegna-esito',
  templateUrl: './consegna-esito.component.html',
  styleUrls: ['./consegna-esito.component.scss']
})
export class ConsegnaEsitoComponent implements OnInit {
  isPositive: boolean;
  choose = '';
  badChoose = [];
  type: string;
  idOrder = null;
  constructor(private activatedRoute: ActivatedRoute,
              private httpService: HttpService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.isPositive = this.activatedRoute.snapshot.paramMap.get('esito') === '1';
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    this.idOrder = this.activatedRoute.snapshot.paramMap.get('idorder');
  }

  sendInfo() {
    const esito = (this.isPositive) ? this.choose : this.badChoose.join(', ');
    const body = {
      idorder: (this.type === 'order') ? this.idOrder : null,
      idorderauction: (this.type !== 'order') ? this.idOrder : null,
      esito
    };
    this.httpService.postEsito(body).subscribe(resp => {
      if (resp && resp.result) {
        const mr = this.modalService.open(PopupComponent);
        mr.componentInstance.title = 'Attenzione';
        mr.componentInstance.body = 'Esito inviato con successo!';
      }
    });
  }

  checkOrDelete(value: string) {
    const index = this.badChoose.findIndex(e => e === value);
    if (index > -1) {
      this.badChoose.splice(index, 1);
    } else {
      this.badChoose.push(value);
    }
  }
}
