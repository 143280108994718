import {Component, OnInit} from '@angular/core';
import {CommunicatorService} from '../../services/communicator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TerminiComponent} from "../termini/termini.component";
import {CookiePolicyComponent} from "../cookie-policy/cookie-policy.component";
import {PrivacyPolicyComponent} from "../privacy-policy/privacy-policy.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpService} from "../../services/http.service";

@Component({
  templateUrl: './result-activation.component.html',
  styleUrls: ['./result-activation.component.scss']
})
export class ResultActivationComponent implements OnInit {
  result = '';
  macro = {regulation: '0', cookie: '0', privacy: '0', personal: '0', advertising: '0'};
  showAll = true;
  constructor(
    public communicator: CommunicatorService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private httpService: HttpService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.result = params.get('result');
      const token = params.get('token');
      if (this.result === 'success' && token) {
        localStorage.setItem('bypassall', '1');
        this.httpService.getFlags(token).subscribe(resp => {
          this.macro = resp;
          this.showAll = resp.regulation === '0' || resp.cookie === '0' || resp.privacy === '0' || resp.personal === '0';
          const authorization = localStorage.getItem('authorization');
          if (!authorization || authorization !== token) {
            localStorage.setItem('authorization', token);
            window.location.reload();
          }
        });
      }
    });

  }

  openWeddingsmart() {
    const rf = this.modalService.open(TerminiComponent, {size: 'lg', backdrop: 'static'});
    rf.componentInstance.isModal = true;

  }

  openCookie() {
    const rf = this.modalService.open(CookiePolicyComponent, {size: 'lg', backdrop: 'static'});
    rf.componentInstance.isModal = true;
  }

  openPrivacy() {
    const rf = this.modalService.open(PrivacyPolicyComponent, {size: 'lg', backdrop: 'static'});
    rf.componentInstance.isModal = true;
  }

  sendUpdate() {
    this.httpService.sendUpdateFlags(this.macro).subscribe(() => {
      this.communicator.checkUser$.subscribe(user => {
        if (user.isOperator === '1') {
          window.location.href = 'https://www.weddingsmart.it/portal/operator-paypal';
        } else {
          this.router.navigateByUrl('/profile');
        }
      });
    });
  }
}
