import { Pipe, PipeTransform } from '@angular/core';
import {IProduct} from '../objects/IProduct';

@Pipe({
  name: 'filterByCategory'
})
export class FilterByCategoryPipe implements PipeTransform {

  transform(values: IProduct[], search: string, idmacrocategory: string): IProduct[] {
    return values.filter(e =>
      e.title.toLowerCase().includes(search.toLowerCase()) &&
      e.description.toLowerCase().includes(search.toLowerCase()) &&
      (idmacrocategory.length > 0 ? e.idmacrocategory === idmacrocategory : true));
  }

}
