import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../services/http.service';
import {ActivatedRoute} from '@angular/router';
import {IOfferDetail} from './IOffer';
import {CommunicatorService} from '../../services/communicator.service';

@Component({
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailComponent implements OnInit {
  offerDetail: IOfferDetail = null;
  percent = null;
  constructor(private httpService: HttpService, private activatedRoute: ActivatedRoute, public communicator: CommunicatorService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.httpService.getOfferDetail(params.get('id')).subscribe(resp => {
        this.offerDetail = resp;
        this.percent = (((+resp.offer_num * 100) / +resp.budget_num) - 100).toFixed(2);
      })
    })
  }

  setVotes(vote: number) {
    this.communicator.checkUser$.subscribe(user => {
      if (user.isUser === '1' && user.username === this.offerDetail.username) {
        if (vote === +this.offerDetail.vote) {
          vote = 0;
        }
        this.httpService.setOfferVote(this.offerDetail.offer_detail, vote).subscribe(() => {
          this.offerDetail.vote = vote + '';
        })
      }
    });
  }

  acceptOffer() {
    this.httpService.sendAcceptOffer(+this.offerDetail.offer_num, this.offerDetail.id_auction).subscribe(() => {
      window.location.reload();
    });
  }
}
