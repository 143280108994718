import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {CommunicatorService} from '../../services/communicator.service';
import {ActivatedRoute} from '@angular/router';
import {finalize, map, switchMap, take} from 'rxjs/operators';
import {HttpService} from '../../services/http.service';
import {forkJoin, Observable} from 'rxjs';
import {IAuction, IOffer, IQuestion} from '../../objects/IAuction';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {isPlatformBrowser} from '@angular/common';
import {tap} from 'rxjs/internal/operators/tap';
import {CircleActionEnum} from '../../objects/CircleActionEnum';
import {PopupInputComponent} from '../../components/popup/popup-input/popup-input.component';
import {OrderSummaryComponent} from '../../components/popup/order-summary/order-summary.component';
import {OfferCardComponent} from './offer-card/offer-card.component';
import {PaypalResultComponent} from '../../components/popup/paypal-result/paypal-result.component';
import {ConfirmComponent} from '../../components/popup/confirm/confirm.component';

@Component({
  selector: 'app-auction-detail',
  templateUrl: './auction-detail.component.html',
  styleUrls: ['./auction-detail.component.scss']
})
export class AuctionDetailComponent implements OnInit, OnDestroy {
  auction$: Observable<{ auction: IAuction, offers: IOffer[], questions: IQuestion[] }>;
  newOffer: Partial<IOffer> = {
    description: '',
    model: '',
    offer: '',
    offer_number: '',
    days: '',
    detail: '',
    invalidOffer: false
  };
  images: { data: string }[] = [];
  showErrorOffer = false;
  countdown = '';
  interval$;
  intervalExpire$;
  expireChoose: Date;
  filterVote = '0';
  orderOffer = '0';
  activeTab = 0;

  constructor(public communicator: CommunicatorService, private activatedRoute: ActivatedRoute, private httpService: HttpService,
              private modalService: NgbModal, @Inject(PLATFORM_ID) private platformId) {
  }

  ngOnInit(): void {
    this.auction$ = this.activatedRoute.paramMap.pipe(
      map(e => e.get('id')),
      take(1),
      switchMap(id =>
        forkJoin(this.httpService.getAuctionById(id),
          this.httpService.getOffersByAction(id, null),
          this.httpService.getQuestions(id))
      ),
      map(result => ({auction: result[0], offers: result[1], questions: result[2]})),
      tap(result => {
        if (result.auction && result.auction.imgname) {
          result.auction.imgname = result.auction.imgname.replace('.jpeg', '.webp');
          result.auction.imgname = result.auction.imgname.replace('.jpg', '.webp');
          result.auction.imgname = result.auction.imgname.replace('.png', '.webp');
        }
      }),
      tap((resp => this.initCountdown(resp.auction)))
    );
    if (window.location.href.includes('tab=2')) {
      this.activeTab = 2;
    }
  }

  showModalOrder(offer: IOffer, auction: IAuction) {
    this.httpService.getBonus(offer.username).subscribe(resp => {
      if (resp) {
        let bonus = resp.bonus.find(e => e.idcategory == auction.id_sector);
        let percent;
        let value;
        if (bonus) {
          if (bonus.limit_down && (+offer.offer_num) > +bonus.limit_down) {
            percent = bonus.bonus_up + '%';
            value = (+bonus.bonus_up * (+offer.offer_num) / 100).toFixed(2);
          } else {
            percent = bonus.bonus_down + '%';
            value = (+bonus.bonus_down * (+offer.offer_num) / 100).toFixed(2);
          }
        }
        const modalRef2 = this.modalService.open(OrderSummaryComponent);
        modalRef2.componentInstance.auction = auction;
        modalRef2.componentInstance.offer = offer;
        modalRef2.componentInstance.store = resp.store;
        modalRef2.componentInstance.percent = percent;
        modalRef2.componentInstance.value = value;
        modalRef2.componentInstance.successPay.subscribe(resp => {
          modalRef2.dismiss();
          const modalRef3 = this.modalService.open(PaypalResultComponent, {size: 'lg'});
          modalRef3.componentInstance.isSuccess = resp;
          if (resp) {
            window.location.reload();
          }
        })
      }
    })
  }


  showModalOffer(user, idAuction: string, modalOffer, auction: IAuction) {
    if (user.isOperator && user.isOperator === '1') {
      this.httpService.canOperator(idAuction).subscribe(response => {

        if (response['id_auction'] != null) {
          let stringArray = [];
          if (response['is_cf'] !== '1') {
            stringArray.push('<br>Codice fiscale della persona di riferimento');
          }
          if (response['is_mobile'] !== '1') {
            stringArray.push('<br>Numero REA');
          }
          if (response['is_business_name'] !== '1') {
            stringArray.push('<br>Ragione sociale');
          }
          if (response['is_iva'] !== '1') {
            stringArray.push('<br>Partita IVA');
          }
          if (response['is_pec'] !== '1') {
            stringArray.push('<br>Indirizzo PEC');
          }
          if (response['is_sdi'] !== '1') {
            stringArray.push('<br>Codice SDI');
          }
          if (response['is_phone'] !== '1') {
            stringArray.push('<br>Numero di telefono');
          }
          if (response['is_fullname'] !== '1') {
            stringArray.push('<br>Persona di riferimento');
          }
          if (stringArray.length > 0) {
            let string = 'E\' richiesto il completamento dei seguenti campi: ' + stringArray.join(',');

            const mr = this.modalService.open(PopupComponent);
            mr.componentInstance.title = 'Attenzione';
            mr.componentInstance.body = string;
            mr.componentInstance.isHtml = true;
            mr.result.then(() => {
              window.location.href = '/operator-paypal?tab=2&showError=1';
            });
          } else {
            this.auction$.subscribe(macroAuction => {
              if (response['count_auctions'] === '0' && macroAuction.offers.findIndex(e => e.business_name === user.business_name) === -1) {
                const mr = this.modalService.open(PopupComponent);
                mr.componentInstance.title = 'Attenzione';
                mr.componentInstance.isHtml = true;
                mr.componentInstance.body = 'Hai terminato il numero degli annunci che puoi partecipare, per aumentare tale possibilità, puoi acquistare ' +
                  'un pacchetto "Smart Pass" al seguente <a href="https://www.weddingsmart.it/portal/operator-paypal?tab=4">link</a>, ' +
                  'oppure cliccando sulla voce "I miei abbonamenti" sul menu laterale';
              } else {
                const offersCount = macroAuction.offers.filter(e => e.business_name === user.business_name).length;
                if (offersCount < 5) {
                  this.newOffer = {description: '', model: '', offer: '', offer_number: '', days: '', detail: ''};
                  this.modalService.open(modalOffer, {size: 'xl'});
                } else {

                  const mr = this.modalService.open(PopupComponent);
                  mr.componentInstance.title = 'Attenzione';
                  mr.componentInstance.body = 'Hai superato il numero massimo di proposte possibili per questo annuncio';
                }
              }
            });
          }
        } else {
          const message = 'Non puoi inviare offerte per questo annuncio perché non rientra nel tuo settore di attività prescelto durante la registrazione.';
          const mr = this.modalService.open(PopupComponent);
          mr.componentInstance.title = 'Attenzione';
          mr.componentInstance.body = message;
        }
      });
    } else {
      const mr = this.modalService.open(PopupComponent);
      mr.componentInstance.title = 'Attenzione';
      mr.componentInstance.body = 'Solo i fornitori della categoria "' + auction.macrosector + ' - ' +
        auction.sector + '" possono inviare offerte su questo annuncio. \n' +
        'Prima però devi effettuare l\' accesso o registrarti';
      mr.result.then(() => {
        this.communicator.openLogin$.next(Math.random());
      }, () => {
        this.communicator.openLogin$.next(Math.random());
      });
    }
  }

  sendOffer(budgetNum: number, idAuction: string) {
    this.showErrorOffer = true;
    if (this.newOffer.offer && +this.newOffer.offer < budgetNum && this.newOffer.description && !this.newOffer.invalidOffer
      && this.newOffer.days && this.newOffer.detail && this.newOffer.model && this.images.length > 0) {
      const body = {...this.newOffer};

      body['images'] = this.images;
      body['id_auction'] = idAuction;
      body['offer_num'] = body.offer.split('.').join('').replace(',', '.');
      this.httpService.postOffers(body).subscribe(resp => {
        window.location.reload();

        const modalRef2 = this.modalService.open(PopupComponent);
        modalRef2.componentInstance.title = 'Attenzione';
        modalRef2.componentInstance.body = 'Nuova offerta creata con successo!';
      })
    }
  }

  private initCountdown(auction: IAuction) {
    if (isPlatformBrowser(this.platformId)) {
      // let dateExpire = auction.expired_at.split(' ');
      let startDay = new Date();
      let endDay = new Date(auction.expire);
      if (auction.is_expired === '0') {
        let millisBetween = endDay.getTime() - startDay.getTime();
        let seconds = millisBetween / (1000);
        let difference = this.secondsDifference(seconds);
        if (difference > 0) {
          this.interval$ = setInterval(() => {
            seconds -= 1;
            this.secondsDifference(seconds)
          }, 1000);
        }
      } else {
        endDay.setDate(endDay.getDate() + 7);
        this.expireChoose = endDay;
        let millisBetween2 = endDay.getTime() - startDay.getTime();
        let seconds2 = millisBetween2 / (1000);
        let difference2 = this.secondsDifference(seconds2);
        if (difference2 > 0) {
          this.intervalExpire$ = setInterval(() => {
            seconds2 -= 1;
            this.secondsDifference(seconds2)
          }, 1000);
        }
      }
    }
  }

  secondsDifference(seconds) {
    if (seconds < 0) {
      return -1;
    }
    let days = Math.floor(seconds / 60 / 60 / 24);
    let labelDay = (days > 0) ? days + 'g' : '';
    seconds -= days * 24 * 3600;
    let hours = Math.floor(seconds / 60 / 60);
    let labelHours = (hours === 0 && days === 0) ? '' : hours + 'h';
    seconds -= hours * 3600;
    let minutes = Math.floor(seconds / 60);
    let labelMinutes = (minutes === 0 && labelHours.length === 0) ? '' : minutes + 'm';
    seconds -= minutes * 60;
    seconds = parseInt(seconds);
    let labelSeconds = 's';
    this.countdown = (labelDay + ' ' + labelHours + ' ' + labelMinutes + ' ' + seconds + labelSeconds);
    return seconds;
  }

  ngOnDestroy(): void {
    if (this.interval$) {
      clearInterval(this.interval$);
      this.interval$ = null;
    }
  }

  sendQuestions(text: string, idAuction: string) {
    this.httpService.postQuestions({text, idAuction}).pipe(
      switchMap(questions => this.auction$.pipe(
        map(el => ({...el, questions})
        ))
      )
    ).subscribe();
  }

  sendAnswerQuestions(body: { text: string; idQuestion: string }) {
    this.httpService.sendAnswer(body).pipe(
      switchMap(() => this.auction$)
    ).subscribe();
  }

  actionMenu(action: number, auction: IAuction) {
    switch (action) {
      case CircleActionEnum.preferiti:

        this.httpService.postFavorite(+auction.id, 'auctions', null).subscribe(resp => {
        });
        break;
      case CircleActionEnum.segnala:

        const rf = this.modalService.open(PopupInputComponent);
        rf.componentInstance.title = 'Descrivi il motivo della segnalazione';
        rf.componentInstance.closeDescription.subscribe((text: string) => {
          rf.close();
          if (text.length > 0) {
            this.httpService.postWarning({idref: +auction.id, text, table: 'auctions'}).subscribe(resp => {
              if (resp && resp.result) {
                const mr2 = this.modalService.open(PopupComponent);
                mr2.componentInstance.title = 'Attenzione';
                mr2.componentInstance.body = 'Segnalazione effettuata con successo';
              }
            });
          }
        });
        break;
      case CircleActionEnum.condividi:
        // window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href, '_blank');
        this.communicator.sharePage$.next({});
        break;
    }
  }

  onlyPrice(event: any) {
    let key = event.key;
    if (key >= 0 && key <= 9 || (key === ',' && !event.target.value.includes(',')) ||
      (key === '.' && !event.target.value.includes('.')) || key === 'Backspace') {

    } else {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  checkPriceOffer(auction: IAuction) {
    let values = this.newOffer.offer.split(',');
    let int = values[0].split('.').join('');
    let decimal = '';
    if (int.length > 3) {
      int = int.substring(0, int.length - 3) + '.' + int.substring(int.length - 3);
    }
    if (this.newOffer.offer.includes(',')) {
      if (values[1]) {
        decimal = values[1];
        if (decimal.length > 2) {
          decimal = decimal.substring(0, 2);
        }
      }
      this.newOffer.offer = int + ',' + decimal;
    } else {
      this.newOffer.offer = int;
    }
    let number = Number(values[0].split('.').join('') + ((decimal.length > 0) ? '.' + decimal : ''));
    this.newOffer.invalidOffer = number > +auction.budget_num;
  }

  acceptOffer(numberOffer: number, idAuction: string) {

    const mr2 = this.modalService.open(ConfirmComponent);
    mr2.componentInstance.title = 'Attenzione';
    mr2.componentInstance.description = 'Sei sicuro di accettare questa proposta?';
    mr2.componentInstance.confirm.subscribe(() => {
      mr2.close();
      this.httpService.sendAcceptOffer(numberOffer, idAuction).subscribe(() => {
        window.location.reload();
      });
    });
  }

  getSeller(macroAuction: { auction: IAuction; offers: IOffer[]; questions: IQuestion[] }): IOffer {
    return macroAuction.offers.find(e => e.offer_number === macroAuction.auction.id_offer_winner);
  }

  getMultiply4(length: number): number[] {
    let array = [];
    let i = 4;
    while (i % 4 === 0) {
      array.push(i);
      if (i > length) {
        break;
      }
      i += 4;
    }
    return array;
  }

  showPreview(auction: IAuction, user) {
    this.newOffer.offer_num = this.newOffer.offer.split('.').join('').replace(',', '.');
    const modal = this.modalService.open(OfferCardComponent, {windowClass: 'width-content'});
    modal.componentInstance.offer = this.newOffer;
    modal.componentInstance.user = user;
    modal.componentInstance.auction = auction;
    modal.componentInstance.images = this.images;
    modal.componentInstance.isModal = true;
    modal.componentInstance.closePreview.subscribe(() => {
      modal.close();
    });
  }

  refusedAuction(auction: IAuction) {

    const mr2 = this.modalService.open(ConfirmComponent);
    mr2.componentInstance.title = 'Attenzione';
    mr2.componentInstance.description = 'Sei sicuro di voler scartare tutte le offerte per questo annuncio ?';
    mr2.componentInstance.confirm.subscribe(() => {
      mr2.close();
      this.httpService.refusedAuction(auction.id).subscribe(() => {
        auction.refusedall = '1';
      });
    });
  }
}
