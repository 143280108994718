<section>

  <div class="position-relative first">
    <picture>
      <source
        media="(min-width: 780px)"
        srcset="/assets/images/home/bannerpc.webp">
      <img class="w-100" src="/assets/images/home/banner.webp">
    </picture>
    <h1>
      <b>Sposi</b> <br class="d-block d-md-none"> e <br class="d-block d-md-none"><b>Fornitori</b><br>
      tutto su un solo
      <br class="d-none d-md-block"> <b>Marketplace!</b>
    </h1>
    <h5 class="subtitle"><b>Wedding Smart<span class="copyright">&#174;</span> </b><br>il primo <b>marketplace</b><br>
      al <b>mondo</b> sul <b>matrimonio</b></h5>
    <div class="subtitle-final">
      <ng-container *ngIf="communicator.checkUser$ | async as user else registrati2">
        <h5 class="text-center" *ngIf="user && user.isOperator === '1' else sposi">Inizia a guadagnare</h5>
        <ng-template #sposi>
          <h5 class="text-center">Ti Sposi?</h5>
        </ng-template>
        <button *ngIf="user && user.isOperator === '1' else sposiB" class="btn btn-primary"
                (click)="goTo('https://www.weddingsmart.it/portal/')">CREA GRATIS IL TUO STORE</button>

        <ng-template #sposiB>
          <button *ngIf="user && user.isUser === '1' else registrati" class="btn btn-primary"
                  routerLink="/new-auction">CREA IL TUO ANNUNCIO</button>
        </ng-template>

        <ng-template #registrati>
          <button class="btn btn-primary"
                  [routerLink]="(user && user.isUser === '1') ? '/new-auction' : '/register'"
          >REGISTRATI ORA GRATIS</button>

        </ng-template>
      </ng-container>
      <ng-template #registrati2>
        <button class="btn btn-primary"
                routerLink="/register"
        >REGISTRATI ORA GRATIS</button>

      </ng-template>
      <div class="text-center mt-md-1">
        <label (click)="scrollTo('discover')">Scopri come funziona</label>
      </div>
    </div>
  </div>
</section>
<section class="container-fluid second">
  <div class="row">
    <div class="col-12 col-md-5 text-center text-md-right">

      <picture>
        <source
          media="(min-width: 780px)"
          srcset="/assets/images/home/scontipc.webp">
        <img class="w-100" src="/assets/images/home/sconti.webp">
      </picture>
    </div>
    <div class="col-12 col-md-6 position-relative">
      <div class="d-none d-md-block"><img class="arrow" src="/assets/images/home/arrow.png"></div>

      <h4 class="mt-2 position-relative">Bonus Sposi Wedding Smart<span class="copyright" >&#174;</span></h4>
      <label class="text-black-50"><span class="position-relative mr-1"> Wedding Smart <span class="copyright fix" style="font-size: 11px;">&#174;</span></span>
        offre
        <mark class="text-black-50">a tutti i futuri sposi</mark>
        dei Bonus sconti fruibili all'interno della piattaforma.</label>
      <label class="text-black-50">Ogni qualvolta che si acquista un prodotto, un servizio o si accetta una proposta da
        parte del fornitore, al checkout, vi verrà applicata una percentuale di sconto.</label>
      <label class="text-black-50">Più acquisti si fanno all'interno della piattaforma, più sconti riceverete, fino a
        <mark class="text-black-50 price">2.500€</mark>
        e più.</label>
      <label class="text-black-50">Crea i tuoi annunci, sfrutta le potenzialità che ti offre <span
        class="font-weight-500">"gratis"</span>
        <span class="position-relative mr-1"> Wedding Smart<span class="copyright fix" style="right:-12px!important; font-size: 11px;">&#174;</span> </span> .</label><br>
      <label class="text-black-50 font-weight-500">Cosa aspetti? <br>Comincia a risparmiare!</label><br>
      <label class="text-black-50">Organizzare con noi il tuo matrimonio non è stato mai così facile. </label>
      <div class="div-button">

        <ng-container *ngIf="communicator.checkUser$ | async as user">
          <button *ngIf="user && user.isUser === '1' else annunci" class="btn btn-primary d-block mx-auto rounded-pill pl-4 pr-4"
                  routerLink="/new-auction">Crea il tuo annuncio</button>
          <ng-template #annunci>
            <button *ngIf="user && user.isOperator === '1' else registrati" class="btn btn-primary d-block mx-auto rounded-pill pl-4 pr-4"
                    routerLink="/auctions">Annunci sposi</button>
          </ng-template>
          <ng-template #registrati>
            <button class="btn btn-primary d-block mx-auto rounded-pill pl-4 pr-4" *ngIf="!user || user.isOperator !== '1'"
                    routerLink="/register"
                   >Registrati ora gratis</button>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</section>
<section class="container-fluid third mt-3" id="discover">
  <div class="row">
    <div class="col-12 position-relative">
      <div class="text-center"><img class="arrow" src="/assets/images/home/arrow.png" width="20" height="10"></div>
      <blockquote>
        <i class="fa fa-quote-left left"></i>
        <h4 class="text-center" >Cos'è e come funziona <br class="d-block d-md-none"> <span
          class="position-relative mr-3">Wedding Smart<span class="copyright">&#174;</span></span>?</h4>
        <p class="text-center"><span class="position-relative mr-2">Wedding Smart<span
          class="copyright fix" style="font-size: 11px;">&#174;</span></span> è il primo marketplace al mondo sul matrimonio, <br class="d-none d-lg-block"> ideato per far
          "incontrare" in modo <br class="d-block d-md-none">innovativo, trasparente, sicuro <br class="d-none d-lg-block"> ed intelligente<br class="d-block d-md-none"> futuri sposi e aziende del settore</p>
        <i class="fa fa-quote-right float-right right"></i>
      </blockquote>
    </div>
  </div>
  <hr class="hr-primary mt-0 mt-md-5">
</section>

<section class="container-fluid fourth mt-3 mt-md-5">
  <h4 class="text-center">Come funziona sposi</h4>
  <div class="row">
    <div class="col-12 col-md-4">
      <img class="mx-auto d-block mt-2 mt-md-0" src="/assets/images/home/step1.png">
      <h5 class="text-center">Crea i tuoi annunci</h5>
      <p class="text-center mx-md-3">Indica cosa hai bisogno in pochi semplici click. Crea il tuo annuncio personalizzato,
        inserisci il tuo budget e pubblicalo.</p>
    </div>
    <div class="col-12 col-md-4">
      <div style="padding-right: 0.7rem;">
        <img class="mx-auto d-block" src="/assets/images/home/step2.png">
      </div>
      <h5 class="text-center">Confronta le proposte</h5>
      <p class="text-center mx-md-3">Ricevi proposte direttamente sul tuo annuncio generate mediante un sistema di negoziazione
        creato da una "competizione" tra i fornitori.</p>
    </div>
    <div class="col-12 col-md-4">
      <img class="mx-auto d-block" src="/assets/images/home/step3.png">
      <h5 class="text-center">Accelera e semplifica gli acquisti</h5>
      <p class="text-center mx-md-3">Accetta la proposta del professionista che più si avvicina alle tue necessità dopo aver
        confrontato le varie offerte e consultato le loro vetrine.</p>
    </div>
  </div>
</section>

<section class="container-fluid fiveth mt-3 d-block d-lg-none">
  <h4 class="text-center">Come funziona aziende</h4>
  <img class="d-block" src="/assets/images/home/fornitori1.webp">
  <h5>Registrazione, creazione store e dashboard dedicata</h5>
  <p>Registrati in pochi semplici click e inizia a creare <b>GRATIS</b> il tuo Store. Avrai una dashboard dedicata dove
    potrai gestire al meglio
    la tua vetrina ed il tuo store, monitorare l'inventario, visualizzare statistiche, SEO, controllare quanto hai
    venduto e guadagnato giornalmente e tanto altro ancora</p>


  <img class="ml-auto d-block" src="/assets/images/home/fornitori2.webp">
  <h5 class="text-right">Invia proposte direttamente nell'annuncio sposi</h5>
  <p class="text-right">Potrai inviare le tue proposte personalizzate direttamente nell'annuncio degli sposi ottenendo
    così una risposta immediata.
    <br>Basta inviare inutili preventivi, da oggi vendi direttamente </p>

  <img class="d-block" src="/assets/images/home/fornitori3.webp">
  <h5>Accelera e semplifica i processi di vendita</h5>
  <p>Con questo marketplace innovativo potrai accelerare e semplificare tutti i tuoi processi di vendita. I futuri
    sposi potranno visionare direttamente nel tuo store i servizi
    o prodotti e procedere direttamente all'acquisto. Nello stesso tempo potrai anche inviare le tue proposte
    personalizzate direttamente negli annunci creati dai futuri sposi, così
    facendo avrai più opportunità di vendita</p>

  <div class="row">
    <div class="col-6 text-right">

      <ng-container *ngIf="communicator.checkUser$ | async as user">
        <button class="btn btn-primary rounded-0"
                [routerLink]="(user && user.isUser === '1') ? '/new-auction' : ((user && user.isOperator === '1') ? '/auctions' : '/register')"
        >Provalo subito</button>
      </ng-container>
    </div>
    <div class="col-6">
      <button class="btn btn-secondary" routerLink="/discover">Scopri di più</button>
    </div>
  </div>
</section>
<section class="fivethmd mt-5 d-none d-lg-block container-fluid">

  <h4 class="text-center">Come funziona aziende</h4>
  <img src="/assets/images/home/fornitoripc.webp">
  <div class="first">
    <h5>Registrazione, creazione store e dashboard dedicata</h5>
    <p>Registrati in pochi semplici click e inizia a creare <b>GRATIS</b> il tuo Store. Avrai una dashboard dedicata
      dove potrai gestire al meglio
      la tua vetrina ed il tuo store, monitorare l'inventario, visualizzare statistiche, SEO, controllare quanto hai
      venduto e guadagnato giornalmente e tanto altro ancora</p>
  </div>

  <div class="second">
    <h5 class="text-right">Invia proposte direttamente nell'annuncio sposi</h5>
    <p class="text-right">Potrai inviare le tue proposte personalizzate direttamente nell'annuncio degli sposi ottenendo
      così una risposta immediata.
      <br>Basta inviare inutili preventivi, da oggi vendi direttamente </p>
  </div>

  <div class="third">
    <h5>Accelera e semplifica i processi di vendita</h5>
    <p>Con questo marketplace innovativo potrai accelerare e semplificare tutti i tuoi processi di vendita. I futuri
      sposi potranno visionare direttamente nel tuo store i servizi
      o prodotti e procedere direttamente all'acquisto. Nello stesso tempo potrai anche inviare le tue proposte
      personalizzate direttamente negli annunci creati dai futuri sposi, così
      facendo avrai più opportunità di vendita</p>
  </div>


  <div class="row">
    <div class="col-6 text-right" *ngIf="communicator.checkUser$ | async as user">
      <button class="btn btn-lg btn-primary rounded-0"
              [routerLink]="(user && user.isUser === '1') ? '/new-auction' : ((user && user.isOperator === '1') ? '/auctions' : '/register')"
      >Provalo subito</button>
    </div>
    <div class="col-6">
      <button class="btn btn-lg btn-secondary" routerLink="/discover">Scopri di più</button>
    </div>
  </div>
</section>

<section class="container-fluid sixth pb-3 mt-5 position-relative">
  <h5>Aziende in vetrina
    <a routerLink="/vetrine-fornitori" class="color-primary d-none d-md-block float-right">Vedi tutte le aziende</a>
  </h5>
  <div class="overflow-x" *ngIf="vetrine$ | async as vetrine">
    <div class="pr-3">
      <app-vetrina-element *ngFor="let vetr of vetrine | sortByKey:'minprice':'desc'" [vetrina]="vetr"
                           [isHome]="true"></app-vetrina-element>

    </div>
  </div>
  <div class="text-center mt-2 d-block d-md-none">
    <button class="btn btn-secondary" routerLink="/vetrine-fornitori">Visualizza tutte le aziende</button>
  </div>
  <div class="d-none d-md-block background-lightgray"></div>
</section>

<section class="container-fluid seventh pt-3 pb-3">
  <h5 class="mb-1">Annunci sposi<br class="d-block d-md-none"> gli ultimi pubblicati</h5>

  <ng-container *ngIf="auctions$ | async as auctions">
    <div class="overflow-x d-flex d-md-none">
      <div class="item" *ngFor="let i of multipleAuctions">
        <app-auction-item [auctions]="(auctions | sortByKey:'created':'desc') | slice:i - 2:i" [isVisualRow]="false" [isHome]="true"></app-auction-item>
      </div>
    </div>
    <div class="d-none d-md-flex">
      <div class="item">
        <app-auction-item [auctions]="(auctions | sortByKey:'created':'desc') | slice:0:12" [isVisualRow]="false" [isHome]="true"></app-auction-item>
      </div>
    </div>
  </ng-container>
  <div class="text-center mt-1">
    <button class="btn btn-secondary"
            routerLink="/auctions">Visualizza tutti gli
      annunci sposi
    </button>
  </div>
</section>

<section class="container-fluid eighth pt-3 pb-3" *ngIf="reviews$ | async as reviews">
  <h5 class="text-center">Migliaia di professionisti e aziende a disposizione per soddisfare i tuoi desideri</h5>
  <div class="position-relative">
    <div class="arrow arrow-left" (click)="carousel.prev()">
      <i class="fas fa-chevron-left"></i>
    </div>
    <div class="arrow arrow-right" (click)="carousel.next()">
      <i class="fas fa-chevron-right"></i>
    </div>
    <div class="d-block d-md-none">
      <ngb-carousel *ngIf="reviews" [showNavigationArrows]="false" [showNavigationIndicators]="false" #carousel>
        <ng-template ngbSlide *ngFor="let review of reviews.offers">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <img class="img rounded-circle" [src]="review.imageuser ?
                  'https://www.weddingsmart.it/api/images/users/' + review.imageuser + '?random=' + random :
                  'https://www.weddingsmart.it/assets/images/user.png'"
                     width="70">
              </div>
              <h5 class="text-center">{{review.business_name}}</h5>
              <label class="text-black-50 text-center w-100">{{review.title}}</label>
              <div class="text-center">
                <ng-container *ngFor="let i of [1,2,3,4,5]">
                  <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/star-y.png"
                       *ngIf="i <= +review.vote else half">
                  <ng-template #half>
                    <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/half-star.png"
                         *ngIf="((i - 0.5 < +review.vote) && (i > +review.vote)) else nohalf">
                  </ng-template>
                  <ng-template #nohalf>
                    <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/star.png"
                         *ngIf="i > +review.vote">
                  </ng-template>
                </ng-container>
              </div>
              <label class="mt-3 text-black-50 text-center w-100">scritto da {{review.from | truncFullname}}
                il {{review.timestamp | date:'dd/MM/yyyy'}}</label>
              <div class="position-relative">

                <i class="fa fa-quote-left left"></i>
                <label class="w-100 text-center pr-4 pl-4">{{review.description}}</label>
                <i class="fa fa-quote-right right"></i>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="d-none d-md-block">
      <ngb-carousel *ngIf="reviews" [showNavigationArrows]="false" [showNavigationIndicators]="false" #carousel>
        <ng-template ngbSlide>
          <div class="page">
            <div class="md" *ngFor="let review of reviews.offers | slice:0:3">

              <div class="card w-100">
                <div class="card-body">
                  <div class="text-center">
                    <img class="img rounded-circle" [src]="review.imageuser ?
                    'https://www.weddingsmart.it/api/images/users/' + review.imageuser + '?random=' + random :
                    'https://www.weddingsmart.it/assets/images/user.png'"
                         width="70">
                  </div>
                  <h5 class="text-center">{{review.business_name}}</h5>
                  <label class="text-black-50 text-center w-100">{{review.title}}</label>
                  <div class="text-center">
                    <ng-container *ngFor="let i of [1,2,3,4,5]">
                      <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/star-y.png"
                           *ngIf="i <= +review.vote else half">
                      <ng-template #half>
                        <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/half-star.png"
                             *ngIf="((i - 0.5 < +review.vote) && (i > +review.vote)) else nohalf">
                      </ng-template>
                      <ng-template #nohalf>
                        <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/star.png"
                             *ngIf="i > +review.vote">
                      </ng-template>
                    </ng-container>
                  </div>
                  <label class="mt-3 text-black-50 text-center w-100">scritto da {{review.from | truncFullname}}
                    il {{review.timestamp | date:'dd/MM/yyyy'}}</label>
                  <div class="position-relative">

                    <i class="fa fa-quote-left left"></i>
                    <label class="w-100 text-center pr-4 pl-4">{{review.description}}</label>
                    <i class="fa fa-quote-right right"></i>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </ng-template>
        <ng-container *ngIf="reviews.offers.length > 3">
          <ng-template ngbSlide>
            <div class="page">
              <div class="md" *ngFor="let review of reviews.offers | slice:3:6">

                <div class="card w-100">
                  <div class="card-body">
                    <div class="text-center">
                      <img class="img rounded-circle" [src]="review.imageuser ?
                        'https://www.weddingsmart.it/api/images/users/' + review.imageuser + '?random=' + random:
                        'https://www.weddingsmart.it/assets/images/user.png'"
                           width="70">
                    </div>
                    <h5 class="text-center">{{review.business_name}}</h5>
                    <label class="text-black-50 text-center w-100">{{review.title}}</label>
                    <div class="text-center">
                      <ng-container *ngFor="let i of [1,2,3,4,5]">
                        <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/star-y.png"
                             *ngIf="i <= +review.vote else half">
                        <ng-template #half>
                          <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/half-star.png"
                               *ngIf="((i - 0.5 < +review.vote) && (i > +review.vote)) else nohalf">
                        </ng-template>
                        <ng-template #nohalf>
                          <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/star.png"
                               *ngIf="i > +review.vote">
                        </ng-template>
                      </ng-container>
                    </div>
                    <label class="mt-3 text-black-50 text-center w-100">scritto da {{review.from | truncFullname}}
                      il {{review.timestamp | date:'dd/MM/yyyy'}}</label>
                    <div class="position-relative">

                      <i class="fa fa-quote-left left"></i>
                      <label class="w-100 text-center pr-4 pl-4">{{review.description}}</label>
                      <i class="fa fa-quote-right right"></i>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-template>
        </ng-container>
      </ngb-carousel>
    </div>
  </div>


</section>
