import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {Review} from '../../objects/IVetrina';
import {Utility} from '../../objects/Utility';
import {isPlatformBrowser} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  @Input() reviews: Review[];
  @Input() isExtended = false;
  @Input() showItemBuyed = true;
  utility = Utility;
  maxLengthDesc = 100;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private router: Router) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > 768) {
        this.maxLengthDesc = 300;
      }
    }
  }

  getDescription(rev: Review): string {
    if (rev.description.length > this.maxLengthDesc) {
      if (!rev.showOther) {
        return rev.description.substr(0, this.maxLengthDesc) + ' ...';
      }
    }
    return rev.description;
  }

  goToDetail(rev: Review) {
    if (rev.idoffer) {
      this.router.navigateByUrl('/offer-detail/' + rev.offer);
    } else {
      this.router.navigateByUrl('/prodotto/' + rev.code + '/' + rev.prodotto);
    }
  }
}
