import { Pipe, PipeTransform } from '@angular/core';
import {IVetrina} from '../objects/IVetrina';
import {IAuction} from '../objects/IAuction';

@Pipe({
  name: 'sortByKey'
})
export class SortByKeyPipe implements PipeTransform {

  transform(values: any[], key: string, order: 'asc' | 'desc', ...args: unknown[]): any[] {
    const sortFunc = (a, b) => {
      if(key === 'budget_num') {
        if(order === 'asc') {
          return (+a[key] > +b[key]) ? 1 : ((+b[key] > +a[key]) ? -1 : 1)
        } else {
          return +a[key] > +b[key] ? -1 : ((+b[key] > +a[key]) ? 1 : -1)
        }
      } else {
        if ('wedding_date' in a) {
          if (a.is_expired === '0' || b.is_expired === '0') {
            if(order === 'asc') {
              return (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 1)
            } else {
              return a[key] > b[key] ? -1 : ((b[key] > a[key]) ? 1 : -1)
            }
          } else {
            if(a.id_offer_winner != null && b.id_offer_winner == null) {
              return -1;

            } else if(a.id_offer_winner == null && b.id_offer_winner != null) {
              return 1;

            } else if(a.is_expired === '1' && a.offers > '0') {
              return (b.is_expired === '1' && b.offers > '0') ? 1 : -1;

            } else {
              return (a.is_expired === '1' && a.offers === '0') ? 1 : -1;
            }
          }
        } else {
          if(order === 'asc') {
            return (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 1)
          } else {
            return a[key] > b[key] ? -1 : ((b[key] > a[key]) ? 1 : -1)
          }
        }
      }
    };
    return values.sort(sortFunc);
  }

}
