import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getWebp'
})
export class GetWebpPipe implements PipeTransform {

  transform(value: string | null, ...args: unknown[]): string {
    if (value) {
      value = value.replace('.jpeg', '.webp');
      value = value.replace('.jpg', '.webp');
      value = value.replace('.png', '.webp');
    }
    return value;
  }

}
