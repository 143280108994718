<div class="modal-body position-relative">
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
  <h4>Riepilogo ordine</h4>

  <h6>Cod. {{auction.code}} - <label class="text-black-50 mb-0"><b>{{auction.title}}</b></label></h6>
  <ng-container *ngIf="auction.other_information as oi">
    <label class="text-black-50 w-100 mb-0" *ngIf="oi.howDressLong as cond">Condizione: &nbsp;<b
      class="text-dark">{{cond}}</b></label>
    <label class="text-black-50 w-100 mb-0" *ngIf="oi.widthPerson as widthPerson">Taglia: &nbsp;<b
      class="text-dark">{{widthPerson}}</b></label>
    <label class="text-black-50 w-100 mb-0" *ngIf="oi.whatColor as whatColor">Colore: &nbsp;<b
      class="text-dark">{{whatColor}}</b></label>
  </ng-container>

  <label class="text-right w-100 text-black-50"><b style="font-size: 1.1rem;"> {{offer.offer}} € </b></label>

  <div class="row" *ngIf="percent">
    <div class="col text-black-50 font-weight-bold pr-0">
      <small>Bonus sposi Wedding Smart</small>
    </div>
    <div class="col-auto text-right text-black-50 font-weight-bolder">
      <span class="text-success font-weight-bold">-{{percent}}</span> - {{value | convertPrice:false:true}}
    </div>
  </div>

  <div class="row border-top mb-3 pt-2">
    <div class="col-12">
      <h5>Consegna prevista tra</h5>
      <span class="font-weight-bolder">Consegna standard: </span> <span>Me. 09.12 - Ve. 18.12</span>
    </div>
  </div>

  <div class="row border-top mb-3 pt-2">
    <div class="col-12">
      <div class="row">
        <div class="col text-black-50 font-weight-bolder text-right" style="font-size: .9rem;">
          Totale parziale
        </div>
        <div class="col-5 text-right text-black-50 font-weight-bolder">
          {{totalPartial + '' | convertPrice:false:true}}
        </div>
      </div>
      <div class="row">
        <div class="col text-black-50 font-weight-bolder text-right" style="font-size: .9rem;">
          Spedizione (standard)
        </div>
        <div class="col-5 text-right text-black-50 font-weight-bolder">
          {{store.shippingcost | convertPrice:false:true}}
        </div>
      </div>
    </div>
  </div>
  <div class="row border-top">
      <div class="col text-right color-blue">
          <b>Totale</b> <span> (Iva inc.)</span>
        </div>
      <div class="col-5 text-right my-auto">
          <label class="price mb-0">{{total+'' | convertPrice:false:true}}</label>
        </div>
    </div>
  <h5 class="mt-3">Modalità di pagamento disponibili</h5>
  <div id="paypal-button-container" *ngIf="payPalConfig else loader">
    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
  </div>

  <ng-template style="height: 260px;" class="text-center pt-5" #loader>
    <div class="spinner-border color-primary mt-5" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </ng-template>
</div>
