<ng-container *ngIf="isModal else body">
  <div class="modal-header">
    <h2 class="modal-title">Privacy Policy di Wedding Smart</h2>
    <button type="button" class="close" (click)="modalService.dismissAll()">&times;</button>
  </div>
  <div class="modal-body">
    <ng-template [ngTemplateOutlet]="body"></ng-template>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="modalService.dismissAll()">Chiudi</button>
  </div>
</ng-container>

<ng-template #body>

  <div [class.container]="showContainer" id="elementHtml">
    <h2 *ngIf="!isModal"><strong>Privacy Policy di Wedding Smart </strong></h2>
    <h4><strong>Ecco la nostra Privacy Policy</strong></h4>
    <div class="card" *ngIf="!isPrinting">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <h5 class="color-primary" (click)="print()">PDF-Version</h5>
            <label>Aggiornamento:<br>26/10/2021</label>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-link p-0" (click)="showVersions = true"><small>Versione precedenti</small></button>
          </div>
        </div>
        <div *ngIf="showVersions" class="background-lightgray p-1">
          <b>Versioni precedenti PDF</b>
          <button class="btn p-0 float-right" (click)="showVersions = false;"><i class="fa fa-times"></i></button>
          <br>
          <small>Nessuno</small>
        </div>
      </div>
    </div>
    <p>Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti.</p>
    <h4><strong>Titolare del Trattamento dei Dati</strong></h4>
    <p>xLoma S.r.l.s.&nbsp;<br/> Via Anguillara,8&nbsp;<br/> 00048 Nettuno (Roma)&nbsp;<br/> Italia P.Iva: IT14873011002
    </p>
    <p><strong>Indirizzo email del Titolare:&nbsp;</strong><a href="mailto:privacy@xloma.com">privacy@xloma.com</a>&nbsp;
    </p>
    <h4><strong>&nbsp;</strong></h4>
    <h4><strong>Tipologie di Dati raccolti</strong></h4>
    <p>Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o tramite terze parti, ci
      sono:&nbsp;<strong>Cookie, Dati di Utilizzo, Nome, Cognome, Email, Numero di Telefono, Partita IVA, Indirizzo,
        Citt&agrave;,
        CAP, Provincia, Ambito, Motivazione Richiesta,&nbsp;varie tipologie di Dati</strong>.</p>
    <p>Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy
      policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi. I Dati Personali
      possono essere liberamente forniti dall&rsquo;Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente
      durante l&rsquo;uso di questa Applicazione.</p>
    <p>Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l&rsquo;Utente
      rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio. Nei casi in cui
      questa Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali
      Dati, senza che ci&ograve; abbia alcuna conseguenza sulla disponibilit&agrave; del Servizio o sulla sua operativit&agrave;.</p>
    <p>Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare.
      L&rsquo;eventuale
      utilizzo di Cookie &ndash; o di altri strumenti di tracciamento &ndash; da parte di questa Applicazione o dei
      titolari dei servizi terzi utilizzati da questa Applicazione, ove non diversamente precisato, ha la finalit&agrave;
      di fornire il Servizio richiesto dall&rsquo;Utente, oltre alle ulteriori finalit&agrave; descritte nel presente
      documento e nella Cookie Policy, se disponibile.</p>
    <p>L&rsquo;Utente si assume la responsabilit&agrave; dei Dati Personali di terzi ottenuti, pubblicati o condivisi
      mediante questa Applicazione e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare
      da
      qualsiasi responsabilit&agrave; verso terzi.</p>
    <h4><strong>Modalit&agrave; e luogo del trattamento dei Dati raccolti</strong></h4>
    <h5><strong>Modalit&agrave; di trattamento</strong></h5>
    <p>Il Titolare adotta le opportune misure di sicurezza volte ad impedire l&rsquo;accesso, la divulgazione, la
      modifica
      o la distruzione non autorizzate dei Dati Personali. Il trattamento viene effettuato mediante strumenti
      informatici
      e/o telematici, con modalit&agrave; organizzative e con logiche strettamente correlate alle finalit&agrave;
      indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell&rsquo;organizzazione
      di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema)
      ovvero
      soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, societ&agrave;
      informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del
      Titolare. L&rsquo;elenco aggiornato dei Responsabili potr&agrave; sempre essere richiesto al Titolare del
      Trattamento.</p>
    <h5><strong>Base giuridica del trattamento</strong></h5>
    <ul>
      <li>Il Titolare tratta Dati Personali relativi all&rsquo;Utente in caso sussista una delle seguenti condizioni:
      </li>
      <li>l&rsquo;Utente ha prestato il consenso per una o pi&ugrave; finalit&agrave; specifiche; Nota: in alcuni
        ordinamenti il Titolare pu&ograve; essere autorizzato a trattare Dati Personali senza che debba sussistere il
        consenso dell&rsquo;Utente o un&rsquo;altra delle basi giuridiche specificate di seguito, fino a quando l&rsquo;Utente
        non si opponga (&ldquo;opt-out&rdquo;) a tale trattamento. Ci&ograve; non &egrave; tuttavia applicabile qualora
        il
        trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati
        Personali;
      </li>
      <li>il trattamento &egrave; necessario all&rsquo;esecuzione di un contratto con l&rsquo;Utente e/o all&rsquo;esecuzione
        di misure precontrattuali;
      </li>
      <li>il trattamento &egrave; necessario per adempiere un obbligo legale al quale &egrave; soggetto il Titolare; il
        trattamento &egrave; necessario per l&rsquo;esecuzione di un compito di interesse pubblico o per l&rsquo;esercizio
        di pubblici poteri di cui &egrave; investito il Titolare;
      </li>
      <li>il trattamento &egrave; necessario per il perseguimento del legittimo interesse del Titolare o di terzi.
        &nbsp;
      </li>
    </ul>
    <p>&Egrave; comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun
      trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o
      necessario per concludere un contratto.</p>
    <h5><strong>Luogo</strong></h5>
    <p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel
      trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare. I Dati Personali dell&rsquo;Utente
      potrebbero essere trasferiti in un paese diverso da quello in cui l&rsquo;Utente si trova. Per ottenere ulteriori
      informazioni sul luogo del trattamento l&rsquo;Utente pu&ograve; fare riferimento alla sezione relativa ai
      dettagli
      sul trattamento dei Dati Personali.</p>
    <p>L&rsquo;Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di
      fuori dell&rsquo;Unione Europea o ad un&rsquo;organizzazione internazionale di diritto internazionale pubblico o
      costituita da due o pi&ugrave; paesi, come ad esempio l&rsquo;ONU, nonch&eacute; in merito alle misure di
      sicurezza
      adottate dal Titolare per proteggere i Dati.</p>
    <p>L&rsquo;Utente pu&ograve; verificare se abbia luogo uno dei trasferimenti appena descritti esaminando la sezione
      di
      questo documento relativa ai dettagli sul trattamento di Dati Personali o chiedere informazioni al Titolare
      contattandolo agli estremi riportati in apertura.</p>
    <h5><strong>Periodo di conservazione</strong></h5>
    <p>I Dati sono trattati e conservati per il tempo richiesto dalle finalit&agrave; per le quali sono stati
      raccolti.</p>
    <p>Pertanto:</p>
    <ul>
      <li>I Dati Personali raccolti per scopi collegati all&rsquo;esecuzione di un contratto tra il Titolare e l&rsquo;Utente
        saranno trattenuti sino a quando sia completata l&rsquo;esecuzione di tale contratto.
      </li>
      <li>I Dati Personali raccolti per finalit&agrave; riconducibili all&rsquo;interesse legittimo del Titolare saranno
        trattenuti sino al soddisfacimento di tale interesse. L&rsquo;Utente pu&ograve; ottenere ulteriori informazioni
        in
        merito all&rsquo;interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o
        contattando il Titolare.
      </li>
    </ul>
    <p>Quando il trattamento &egrave; basato sul consenso dell&rsquo;Utente, il Titolare pu&ograve; conservare i Dati
      Personali pi&ugrave; a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere
      obbligato a conservare i Dati Personali per un periodo pi&ugrave; lungo in ottemperanza ad un obbligo di legge o
      per
      ordine di un&rsquo;autorit&agrave;.</p>
    <p>Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale
      termine
      il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilit&agrave; dei Dati non potranno
      pi&ugrave;
      essere esercitati.</p>
    <h5><strong>Finalit&agrave; del Trattamento dei Dati raccolti</strong></h5>
    <p>I Dati dell&rsquo;Utente sono raccolti per consentire al Titolare di fornire i propri Servizi, cos&igrave; come
      per
      le seguenti finalit&agrave;: Statistica, Contattare l&rsquo;Utente, Interazione con social network e piattaforme
      esterne, Gestione dei pagamenti, Gestione contatti e invio di messaggi, Gestione di landing page e pagine di
      invito,
      Registrazione ed autenticazione, Interazione con piattaforme di raccolta dati e altre terze parti, Protezione
      dallo
      SPAM e Remarketing e behavioral targeting.</p>
    <p>Per ottenere ulteriori informazioni dettagliate sulle finalit&agrave; del trattamento e sui Dati Personali
      concretamente rilevanti per ciascuna finalit&agrave;, l&rsquo;Utente pu&ograve; fare riferimento alle relative
      sezioni di questo documento.</p>
    <h5><strong>Dettagli sul trattamento dei Dati Personali</strong></h5>
    <p>I Dati Personali sono raccolti per le seguenti finalit&agrave; ed utilizzando i seguenti servizi:</p>
    <h4><strong>Contattare l&rsquo;Utente</strong></h4>
    <h5><strong>Mailing list o newsletter (questa Applicazione)</strong></h5>
    <p>Con la registrazione alla mailing list o alla newsletter, l&rsquo;indirizzo email dell&rsquo;Utente viene
      automaticamente inserito in una lista di contatti a cui potranno essere trasmessi messaggi email contenenti
      informazioni, anche di natura commerciale e promozionale, relative a questa Applicazione. L&rsquo;indirizzo email
      dell&rsquo;Utente potrebbe anche essere aggiunto a questa lista come risultato della registrazione a questa
      Applicazione o dopo aver effettuato un acquisto.</p>
    <p>Dati Personali raccolti: cognome; Cookie; email; nome.</p>
    <h5><strong>Modulo di contatto (questa Applicazione)</strong></h5>
    <p>L&rsquo;Utente, compilando con i propri Dati il modulo di contatto, acconsente al loro utilizzo per rispondere
      alle
      richieste di informazioni, di preventivo, o di qualunque altra natura indicata dall&rsquo;intestazione del
      modulo.</p>
    <p>Dati Personali raccolti: cognome; email; nome.</p>
    <h5><strong>SumoMe Mailing List or Newsletter (Sumo Group Inc.)</strong></h5>
    <p>Con la registrazione alla mailing list o alla newsletter, l&rsquo;indirizzo email dell&rsquo;Utente viene
      automaticamente inserito in una lista di contatti a cui potranno essere trasmessi messaggi email contenenti
      informazioni, anche di natura commerciale e promozionale, relative a questa Applicazione. Il tuo indirizzo email
      potrebbe anche essere aggiunto a questa lista come risultato della registrazione a questa Applicazione o dopo aver
      effettuato un acquisto.</p>
    <p>Dati Personali raccolti: cognome; email; nome; numero di telefono. Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://appsumo.com/privacy/">Privacy Policy</a>.</p>
    <h5><strong>&nbsp;</strong></h5>
    <h4><strong>Gestione contatti e invio di messaggi</strong></h4>
    <p>Questo tipo di servizi consente di gestire un database di contatti email, contatti telefonici o contatti di
      qualunque altro tipo, utilizzati per comunicare con l&rsquo;Utente. Questi servizi potrebbero inoltre consentire
      di
      raccogliere dati relativi alla data e all&rsquo;ora di visualizzazione dei messaggi da parte dell&rsquo;Utente,
      cos&igrave;
      come all&rsquo;interazione dell&rsquo;Utente con essi, come le informazioni sui click sui collegamenti inseriti
      nei
      messaggi.</p>
    <p><strong>Mailchimp (The Rocket Science Group, LLC.)</strong></p>
    <p>Mailchimp &egrave; un servizio di gestione indirizzi e invio di messaggi email fornito da The Rocket Science
      Group,
      LLC.</p>
    <p>Dati Personali raccolti: cognome; Cookie; email; nome; varie tipologie di Dati.Luogo del trattamento: Stati Uniti
      &ndash;&nbsp;<a href="https://mailchimp.com/legal/privacy/">Privacy Policy.</a>&nbsp;Soggetto aderente al Privacy
      Shield.</p>
    <h4><strong>&nbsp;</strong></h4>
    <h4><strong>Gestione dei pagamenti</strong></h4>
    <p>I servizi di gestione dei pagamenti permettono a questa Applicazione di processare pagamenti tramite carta di
      credito, bonifico bancario o altri strumenti. I dati utilizzati per il pagamento vengono acquisiti direttamente
      dal
      gestore del servizio di pagamento richiesto senza essere in alcun modo trattati da questa Applicazione.</p>
    <p>Alcuni di questi servizi potrebbero inoltre permettere l&rsquo;invio programmato di messaggi all&rsquo;Utente,
      come
      email contenenti fatture o notifiche riguardanti il pagamento.</p>
    <h5><strong>PayPal (Paypal)</strong></h5>
    <p>PayPal &egrave; un servizio di pagamento fornito da PayPal Inc., che consente all&rsquo;Utente di effettuare
      pagamenti online.</p>
    <p>Dati Personali raccolti: varie tipologie di Dati secondo quanto specificato dalla privacy policy del
      servizio.</p>
    <p>Luogo del trattamento: Consulta la privacy policy di Paypal &ndash;&nbsp;<a
      href="https://www.paypal.com/privacy">Privacy
      Policy</a>.</p>
    <h4><strong>&nbsp;</strong></h4>
    <h4><strong>Gestione di landing page e pagine di invito</strong></h4>
    <p>Questo tipo di servizi permette di costruire e gestire landing page e pagine di invito, ossia pagine di
      presentazione di un prodotto o servizio, che possono consentire l&rsquo;inserimento delle proprie informazioni di
      contatto, come l&rsquo;indirizzo email. La gestione di queste pagine comporta il trattamento, da parte di questi
      servizi, dei Dati Personali raccolti tramite tali pagine, inclusi quelli di i Dati di Utilizzo.</p>
    <h5><strong>Mailchimp Landing Page (The Rocket Science Group LLC)</strong></h5>
    <p>Mailchimp Landing Page &egrave; un servizio di gestione di landing page fornito da The Rocket Science Group LLC
      che
      permette a questa Applicazione di raccogliere indirizzi email degli Utenti interessati al proprio servizio.
      Mailchimp Landing Page permette al Titolare del Trattamento di tener traccia ed analizzare la risposta da parte
      dell&rsquo;Utente,
      in termini di traffico o di comportamento, in relazione a modifiche della struttura, del testo o di qualunque
      altro
      componente delle landing page create.</p>
    <p>Dati Personali raccolti: cognome; Cookie; email; nome; numero di telefono.Luogo del trattamento: Stati Uniti
      &ndash;&nbsp;<a href="https://mailchimp.com/legal/privacy/">Privacy Policy</a>. Soggetto aderente al Privacy
      Shield.
    </p>
    <h4><strong>&nbsp;</strong></h4>
    <h4><strong>Interazione con piattaforme di raccolta dati e altre terze parti</strong></h4>
    <p>Questo tipo di servizi consente agli Utenti di interagire con le piattaforme di raccolta dati o altri servizi
      direttamente dalle pagine di questa Applicazione allo scopo di salvare e riutilizzare i dati. Nel caso in cui sia
      installato uno di questi servizi, &egrave; possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo
      stesso raccolga Dati di Utilizzo relativi alle pagine in cui &egrave; installato.</p>
    <h5><strong>Mailchimp widget (The Rocket Science Group LLC)</strong></h5>
    <p>Il widget Mailchimp consente di interagire con il servizio di gestione degli indirizzi email e dell&rsquo;invio
      di
      messaggi Mailchimp fornito da The Rocket Science Group LLC.</p>
    <p>Dati Personali raccolti: cognome; Cookie; Dati di utilizzo; email; nome; numero di telefono. Luogo del
      trattamento:
      Stati Uniti &ndash;&nbsp;<a href="https://mailchimp.com/legal/privacy/">Privacy Policy</a>. Soggetto aderente al
      Privacy Shield.</p>
    <h4><strong>&nbsp;</strong></h4>
    <h4><strong>Interazione con social network e piattaforme esterne</strong></h4>
    <p>Questo tipo di servizi permette di effettuare interazioni con i social network, o con altre piattaforme esterne,
      direttamente dalle pagine di questa Applicazione. Le interazioni e le informazioni acquisite da questa
      Applicazione
      sono in ogni caso soggette alle impostazioni privacy dell&rsquo;Utente relative ad ogni social network.</p>
    <p>Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico per le pagine dove il servizio &egrave;
      installato, anche quando gli Utenti non lo utilizzano. Si raccomanda di disconnettersi dai rispettivi servizi per
      assicurarsi che i dati elaborati su questa Applicazione non vengano ricollegati al profilo dell&rsquo;Utente.</p>
    <h5><strong>Pulsante Mi Piace e widget sociali di Facebook (Facebook, Inc.)</strong></h5>
    <p>Il pulsante &ldquo;Mi Piace&rdquo; e i widget sociali di Facebook sono servizi di interazione con il social
      network
      Facebook, forniti da Facebook, Inc.</p>
    <p>Dati Personali raccolti: Cookie; Dati di utilizzo.Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://www.facebook.com/privacy/explanation">Privacy Policy.&nbsp;</a>Soggetto aderente al Privacy Shield.
    </p>
    <h4><strong>&nbsp;</strong></h4>
    <h4><strong>Protezione dallo SPAM</strong></h4>
    <p>Questo tipo di servizi analizza il traffico di questa Applicazione, potenzialmente contenente Dati Personali
      degli
      Utenti, al fine di filtrarlo da parti di traffico, messaggi e contenuti riconosciuti come SPAM.</p>
    <h5><strong>Google reCAPTCHA (Google Inc.)</strong></h5>
    <p>Google reCAPTCHA &egrave; un servizio di protezione dallo SPAM fornito da Google Inc. L&rsquo;utilizzo del
      sistema
      reCAPTCHA &egrave; soggetto alla privacy policy e ai termini di utilizzo di Google.</p>
    <p>Dati Personali raccolti: Cookie; Dati di utilizzo. Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://www.google.com/intl/it/policies/privacy/">Privacy Policy</a>. Soggetto aderente al Privacy Shield.
    </p>
    <h5><strong>VERSCaptcha (Versatility Werks)</strong></h5>
    <p>VERSCaptcha &egrave; un servizio di protezione dallo SPAM fornito da Versatility Werks.</p>
    <p>Dati Personali raccolti: varie tipologie di Dati secondo quanto specificato dalla privacy policy del
      servizio.</p>
    <p>Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a href="https://www.iubenda.com/privacy-policy/497669">Privacy
      Policy</a>.</p>
    <h5><strong>&nbsp;</strong></h5>
    <h5><strong>Akismet (Automattic Inc.)</strong></h5>
    <p>Akismet &egrave; un servizio di protezione dallo SPAM fornito da Automattic Inc.</p>
    <p>Dati Personali raccolti: varie tipologie di Dati secondo quanto specificato dalla privacy policy del
      servizio.</p>
    <p>Luogo del trattamento: Stati Uniti &ndash; <a href="https://automattic.com/privacy/">Privacy Policy</a></p>
    <p>&nbsp;</p>
    <h4><strong>Registrazione ed autenticazione</strong></h4>
    <p>Con la registrazione o l&rsquo;autenticazione l&rsquo;Utente consente all&rsquo;Applicazione di identificarlo e
      di
      dargli accesso a servizi dedicati. A seconda di quanto indicato di seguito, i servizi di registrazione e di
      autenticazione potrebbero essere forniti con l&rsquo;ausilio di terze parti. Qualora questo avvenga, questa
      applicazione potr&agrave; accedere ad alcuni Dati conservati dal servizio terzo usato per la registrazione o l&rsquo;identificazione.</p>
    <h5><strong>Mailchimp OAuth (The Rocket Science Group LLC)</strong></h5>
    <p>Mailchimp OAuth &egrave; un servizio di registrazione ed autenticazione fornito da The Rocket Science Group LLC e
      collegato al servizio Mailchimp.</p>
    <p>Dati Personali raccolti: varie tipologie di Dati secondo quanto specificato dalla privacy policy del
      servizio.</p>
    <p>Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a href="https://mailchimp.com/legal/privacy/">Privacy Policy</a>.
      Soggetto aderente al Privacy Shield.</p>
    <h4><strong>&nbsp;</strong></h4>
    <h4><strong>Remarketing e behavioral targeting</strong></h4>
    <p>Questo tipo di servizi consente a questa Applicazione ed ai suoi partner di comunicare, ottimizzare e servire
      annunci pubblicitari basati sull&rsquo;utilizzo passato di questa Applicazione da parte dell&rsquo;Utente. Questa
      attivit&agrave; viene effettuata tramite il tracciamento dei Dati di Utilizzo e l&rsquo;uso di Cookie,
      informazioni
      che vengono trasferite ai partner a cui l&rsquo;attivit&agrave; di remarketing e behavioral targeting &egrave;
      collegata.</p>
    <p>Alcuni servizi offrono un&rsquo;opzione di remarketing basata sulle liste di indirizzi email. In aggiunta alle
      possibilit&agrave; di effettuare l&rsquo;opt-out offerte dai servizi di seguito riportati, l&rsquo;Utente pu&ograve;
      optare per l&rsquo;esclusione rispetto alla ricezione dei cookie relativi ad un servizio terzo, visitando la&nbsp;<a
        href="http://optout.networkadvertising.org/">pagina di opt-out del Network Advertising Initiative</a>.</p>
    <h5><strong>&nbsp;</strong></h5>
    <h5><strong>Remarketing con Google Analytics (Google LLC)</strong></h5>
    <p>Remarketing con Google Analytics &egrave; un servizio di remarketing e behavioral targeting fornito da Google LLC
      che collega l&rsquo;attivit&agrave; di tracciamento effettuata da Google Analytics e dai suoi Cookie con il
      network
      di advertising Google Ads ed il Cookie Doubleclick.</p>
    <p>Dati Personali raccolti: Cookie; Dati di utilizzo. Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://policies.google.com/privacy">Privacy Policy&nbsp;</a>&ndash;&nbsp;<a
      href="https://adssettings.google.com/authenticated?hl=it">Opt Out</a>. Soggetto aderente al Privacy Shield.</p>
    <h5><strong>&nbsp;</strong></h5>
    <h5><strong>Remarketing Google Ads (Google LLC)</strong></h5>
    <p>Remarketing Google Ads &egrave; un servizio di remarketing e behavioral targeting fornito da Google LLC che
      collega
      l&rsquo;attivit&agrave; di questa Applicazione con il network di advertising Google Ads ed il Cookie
      DoubleClick.</p>
    <p>Gli Utenti possono scegliere di non utilizzare i cookie di Google per la personalizzazione degli annunci
      visitando
      le Impostazioni annunci di Google.</p>
    <p>Dati Personali raccolti: Cookie; Dati di utilizzo.</p>
    <p>Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a href="https://policies.google.com/privacy">Privacy
      Policy&nbsp;</a>&ndash;&nbsp;<a href="https://adssettings.google.com/authenticated?hl=it">Opt Out.</a>&nbsp;Soggetto
      aderente al Privacy Shield.</p>
    <h5><strong>AdRoll (AdRoll, Inc.)</strong></h5>
    <p>AdRoll &egrave; un servizio di advertising fornito da AdRoll, Inc. Dati Personali raccolti: Cookie; Dati di
      utilizzo. Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a href="https://www.adroll.com/about/privacy">Privacy
        Policy &ndash;</a><a href="https://app.adroll.com/optout/">&nbsp;Opt Out</a><a
        href="https://www.adroll.com/about/privacy">.</a></p>
    <h5><strong>&nbsp;</strong></h5>
    <h5><strong>Criteo Dynamic Retargeting (Criteo SA)</strong></h5>
    <p>Criteo Dynamic Retargeting &egrave; un servizio di remarketing e behavioral targeting fornito da Criteo SA., che
      collega l&rsquo;attivit&agrave; di questa Applicazione con il network di advertising Criteo.</p>
    <p>Dati Personali raccolti: Cookie; Dati di utilizzo. Luogo del trattamento: Francia &ndash;&nbsp;<a
      href="http://www.criteo.com/it/privacy/full-privacy-text/">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a
      href="http://www.criteo.com/it/privacy/">Opt Out</a>.</p>
    <h5><strong>&nbsp;</strong></h5>
    <h5><strong>Facebook Remarketing (Facebook, Inc.)</strong></h5>
    <p>Facebook Remarketing &egrave; un servizio di remarketing e behavioral targeting fornito da Facebook, Inc. che
      collega l&rsquo;attivit&agrave; di questa Applicazione con il network di advertising Facebook.</p>
    <p>Dati Personali raccolti: Cookie; Dati di utilizzo.Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://www.facebook.com/about/privacy/">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a
      href="https://www.aboutads.info/choices/">Opt Out</a>. Soggetto aderente al Privacy Shield.</p>
    <h4><strong>&nbsp;</strong></h4>
    <h4><strong>Statistica</strong></h4>
    <p>I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i
      dati
      di traffico e servono a tener traccia del comportamento dell&rsquo;Utente.</p>
    <h5><strong>Google Analytics (Google Inc.)</strong></h5>
    <p>Google Analytics &egrave; un servizio di analisi web fornito da Google Inc. (&ldquo;Google&rdquo;). Google
      utilizza
      i Dati Personali raccolti allo scopo di tracciare ed esaminare l&rsquo;utilizzo di questa Applicazione, compilare
      report e condividerli con gli altri servizi sviluppati da Google. Google potrebbe utilizzare i Dati Personali per
      contestualizzare e personalizzare gli annunci del proprio network pubblicitario.</p>
    <p>Dati Personali raccolti: Cookie; Dati di utilizzo. Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a
      href="https://www.google.com/intl/it/policies/privacy/">Privacy Policy</a>&nbsp;&ndash;&nbsp;<a
      href="https://tools.google.com/dlpage/gaoptout?hl=it">Opt Out</a>. Soggetto aderente al Privacy Shield.</p>
    <h5><strong>&nbsp;</strong></h5>
    <h5><strong>Google Analytics con IP anonimizzato (Google Inc.)</strong></h5>
    <p>Google Analytics &egrave; un servizio di analisi web fornito da Google Inc. (&ldquo;Google&rdquo;). Google
      utilizza
      i Dati Personali raccolti allo scopo di tracciare ed esaminare l&rsquo;utilizzo di questa Applicazione, compilare
      report e condividerli con gli altri servizi sviluppati da Google. Google potrebbe utilizzare i Dati Personali per
      contestualizzare e personalizzare gli annunci del proprio network pubblicitario.</p>
    <p>Questa integrazione di Google Analytics rende anonimo il tuo indirizzo IP. L&rsquo;anonimizzazione funziona
      abbreviando entro i confini degli stati membri dell&rsquo;Unione Europea o in altri Paesi aderenti all&rsquo;accordo
      sullo Spazio Economico Europeo l&rsquo;indirizzo IP degli Utenti. Solo in casi eccezionali, l&rsquo;indirizzo IP
      sar&agrave;
      inviato ai server di Google ed abbreviato all&rsquo;interno degli Stati Uniti.</p>
    <p>Dati Personali raccolti: Cookie; Dati di utilizzo. </p>
    <p>Luogo del trattamento: Stati Uniti &ndash;&nbsp;<a href="https://www.google.com/intl/it/policies/privacy/">Privacy
      Policy</a>&nbsp;&ndash;&nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=it">Opt Out.</a>&nbsp;Soggetto
      aderente al Privacy Shield.</p>
    <p>&nbsp;</p>
    <h4><strong>Ulteriori informazioni sui Dati Personali</strong></h4>
    <h5><strong>Analisi dei Dati dell&rsquo;Utente e previsioni (&ldquo;profilazione&rdquo;)</strong></h5>
    <p>Il Titolare potrebbe trattare i dati d&rsquo;utilizzo raccolti attraverso questa Applicazione per creare o
      aggiornare profili di utenza. Questo tipo di trattamento consente al Titolare di valutare scelte, preferenze e
      comportamento dell&rsquo;Utente per gli scopi specificati nelle rispettive sezioni di questo documento.</p>
    <p>I profili d&rsquo;utenza possono essere creati anche grazie a strumenti automatizzati, come algoritmi, che
      possono
      anche essere offerti da terze parti. Per ottenere ulteriori informazioni sull&rsquo;attivit&agrave; di
      profilazione
      l&rsquo;Utente pu&ograve; fare riferimento alle rispettive sezioni di questo documento.L&rsquo;Utente ha in ogni
      momento diritto ad opporsi a tale attivit&agrave; di profilazione. Per scoprire di pi&ugrave; sui diritti dell&rsquo;Utente
      e su come esercitarli, l&rsquo;Utente pu&ograve; fare riferimento alla sezione di questo documento relativa ai
      diritti degli Utenti.</p>
    <h5><strong>Dati Personali raccolti attraverso fonti diverse dall&rsquo;Utente</strong></h5>
    <p>Il Titolare di questa Applicazione potrebbe aver legittimamente raccolto Dati Personali relativi all&rsquo;Utente
      senza il suo coinvolgimento, attingendo a fonti fornite da terze parti, in conformit&agrave; con le basi
      giuridiche
      descritte nella sezione relativa alle basi giuridiche del trattamento.Qualora il Titolare avesse raccolto Dati
      Personali in tal modo, l&rsquo;Utente potr&agrave; trovare informazioni specifiche riguardo alle fonti nelle
      rispettive sezioni di questo documento o contattando il Titolare.</p>
    <h5><strong>Vendita di beni e servizi online</strong></h5>
    <p>I Dati Personali raccolti sono utilizzati per l&rsquo;erogazione di servizi all&rsquo;Utente o per la vendita di
      prodotti, inclusi il pagamento e l&rsquo;eventuale consegna. I Dati Personali raccolti per perfezionare il
      pagamento
      possono essere quelli relativi alla carta di credito, al conto corrente utilizzato per il bonifico o ad altri
      strumenti di pagamento previsti. I Dati di pagamento raccolti da questa Applicazione dipendono dal sistema di
      pagamento utilizzato.</p>
    <h4><strong>Diritti dell&rsquo;Utente</strong></h4>
    <p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare. In particolare,
      l&rsquo;Utente
      ha il diritto di:</p>
    <ul>
      <li><strong>revocare il consenso in ogni momento.&nbsp;</strong>L&rsquo;Utente pu&ograve; revocare il consenso al
        trattamento dei propri Dati Personali precedentemente espresso.
      </li>
      <li><strong>opporsi al trattamento dei propri Dati.&nbsp;</strong>L&rsquo;Utente pu&ograve; opporsi al trattamento
        dei propri Dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori dettagli sul diritto
        di
        opposizione sono indicati nella sezione sottostante.
      </li>
      <li><strong>accedere ai propri Dati.&nbsp;</strong>L&rsquo;Utente ha diritto ad ottenere informazioni sui Dati
        trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.
      </li>
      <li><strong>verificare e chiedere la rettificazione.&nbsp;</strong>L&rsquo;Utente pu&ograve; verificare la
        correttezza dei propri Dati e richiederne l&rsquo;aggiornamento o la correzione.
      </li>
      <li><strong>ottenere la limitazione del trattamento.&nbsp;</strong>Quando ricorrono determinate condizioni, l&rsquo;Utente
        pu&ograve; richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratter&agrave;
        i Dati per alcun altro scopo se non la loro conservazione.
      </li>
      <li><strong>ottenere la cancellazione o rimozione dei propri Dati Personali.&nbsp;</strong>Quando ricorrono
        determinate condizioni, l&rsquo;Utente pu&ograve; richiedere la cancellazione dei propri Dati da parte del
        Titolare.
      </li>
      <li><strong>ricevere i propri Dati o farli trasferire ad altro titolare.&nbsp;</strong>L&rsquo;Utente ha diritto
        di
        ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove
        tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa disposizione
        &egrave; applicabile quando i Dati sono trattati con strumenti automatizzati ed il trattamento &egrave; basato
        sul
        consenso dell&rsquo;Utente, su un contratto di cui l&rsquo;Utente &egrave; parte o su misure contrattuali ad
        esso
        connesse.
      </li>
      <li><strong>proporre reclamo.&nbsp;</strong>L&rsquo;Utente pu&ograve; proporre un reclamo all&rsquo;autorit&agrave;
        di controllo della protezione dei dati personali competente o agire in sede giudiziale.
      </li>
    </ul>
    <h5><strong>Dettagli sul diritto di opposizione</strong></h5>
    <p>Quando i Dati Personali sono trattati nell&rsquo;interesse pubblico, nell&rsquo;esercizio di pubblici poteri di
      cui
      &egrave; investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto
      ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</p>
    <p>Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalit&agrave; di marketing diretto,
      possono
      opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalit&agrave;
      di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.</p>
    <h5><strong>Come esercitare i diritti</strong></h5>
    <p>Per esercitare i diritti dell&rsquo;Utente, gli Utenti possono indirizzare una richiesta agli estremi di contatto
      del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e evase dal Titolare nel
      pi&ugrave; breve tempo possibile, in ogni caso entro un mese.</p>
    <h5><strong>Cookie Policy</strong></h5>
    <p>Questa Applicazione fa utilizzo di Cookie. Per saperne di pi&ugrave; e per prendere visione dell&rsquo;informativa
      dettagliata, l&rsquo;Utente pu&ograve; consultare la&nbsp;<a href="https://xloma.com/cookie-policy">Cookie
        Policy.</a></p>
    <h4><strong>Ulteriori informazioni sul trattamento</strong></h4>
    <h5><strong>Difesa in giudizio</strong></h5>
    <p>I Dati Personali dell&rsquo;Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi
      preparatorie alla sua eventuale instaurazione per la difesa da abusi nell&rsquo;utilizzo di questa Applicazione o
      dei Servizi connessi da parte dell&rsquo;Utente. L&rsquo;Utente dichiara di essere consapevole che il Titolare
      potrebbe essere obbligato a rivelare i Dati per ordine delle autorit&agrave; pubbliche.</p>
    <h5><strong>Informative specifiche</strong></h5>
    <p>Su richiesta dell&rsquo;Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa
      Applicazione potrebbe fornire all&rsquo;Utente delle informative aggiuntive e contestuali riguardanti Servizi
      specifici, o la raccolta ed il trattamento di Dati Personali.</p>
    <h5><strong>Log di sistema e manutenzione</strong></h5>
    <p>Per necessit&agrave; legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali servizi
      terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che
      possono contenere anche Dati Personali, quali l&rsquo;indirizzo IP Utente.</p>
    <h5><strong>Informazioni non contenute in questa policy</strong></h5>
    <p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi
      momento al Titolare del Trattamento utilizzando gli estremi di contatto.</p>
    <h5><strong>Risposta alle richieste &ldquo;Do Not Track&rdquo;</strong></h5>
    <p>Questa Applicazione non supporta le richieste &ldquo;Do Not Track&rdquo;. Per scoprire se gli eventuali servizi
      di
      terze parti utilizzati le supportino, l&rsquo;Utente &egrave; invitato a consultare le rispettive privacy
      policy.</p>
    <h5><strong>Modifiche a questa privacy policy</strong></h5>
    <p>Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in
      qualunque
      momento dandone informazione agli Utenti su questa pagina e, se possibile, su questa Applicazione nonch&eacute;,
      qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di
      contatto di cui &egrave; in possesso il Titolare . Si prega dunque di consultare regolarmente questa pagina,
      facendo
      riferimento alla data di ultima modifica indicata in fondo.</p>
    <p>Qualora le modifiche interessino trattamenti la cui base giuridica &egrave; il consenso, il Titolare provveder&agrave;
      a raccogliere nuovamente il consenso dell&rsquo;Utente, se necessario.</p>
    <h4><strong>Definizioni e riferimenti legali</strong></h4>
    <h5><strong>Dati Personali (o Dati)</strong></h5>
    <p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con
      qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o
      identificabile una persona fisica.</p>
    <h5><strong>Dati di Utilizzo</strong></h5>
    <p>Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti
      terze
      integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall&rsquo;Utente
      che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l&rsquo;orario
      della richiesta, il metodo utilizzato nell&rsquo;inoltrare la richiesta al server, la dimensione del file ottenuto
      in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di
      provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie
      connotazioni
      temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all&rsquo;itinerario
      seguito all&rsquo;interno dell&rsquo;Applicazione, con particolare riferimento alla sequenza delle pagine
      consultate, ai parametri relativi al sistema operativo e all&rsquo;ambiente informatico dell&rsquo;Utente.</p>
    <h5><strong>Utente</strong></h5>
    <p>L&rsquo;individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l&rsquo;Interessato.</p>
    <h5><strong>Interessato</strong></h5>
    <p>La persona fisica cui si riferiscono i Dati Personali.</p>
    <h5><strong>Responsabile del Trattamento (o Responsabile)</strong></h5>
    <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per
      conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
    <h5><strong>Titolare del Trattamento (o Titolare)</strong></h5>
    <p>La persona fisica o giuridica, l&rsquo;autorit&agrave; pubblica, il servizio o altro organismo che, singolarmente
      o
      insieme ad altri, determina le finalit&agrave; e i mezzi del trattamento di dati personali e gli strumenti
      adottati,
      ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il
      Titolare
      del Trattamento, salvo quanto diversamente specificato, &egrave; il titolare di questa Applicazione.</p>
    <h5><strong>Questa Applicazione</strong></h5>
    <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>
    <h5><strong>Servizio</strong></h5>
    <p>Il Servizio fornito da questa Applicazione cos&igrave; come definito nei relativi termini (se presenti) su questo
      sito/applicazione.</p>
    <h5><strong>Unione Europea (o UE)</strong></h5>
    <p>Salvo ove diversamente specificato, ogni riferimento all&rsquo;Unione Europea contenuto in questo documento si
      intende esteso a tutti gli attuali stati membri dell&rsquo;Unione Europea e dello Spazio Economico Europeo.</p>
    <h5><strong>Cookie</strong></h5>
    <p>Piccola porzione di dati conservata all&rsquo;interno del dispositivo dell&rsquo;Utente.</p>
    <h5><strong>Riferimenti legali</strong></h5>
    <p>La presente informativa privacy &egrave; redatta sulla base di molteplici ordinamenti legislativi, inclusi gli
      artt. 13 e 14 del Regolamento (UE) 2016/679.</p>
    <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.</p>
    <p><strong>Ultima modifica: 26 Ottobre 2021</strong></p>
    <div class="text-center mb-5" *ngIf="!isPrinting">
      <button *ngIf="showContainer" class="btn btn-secondary" (click)="showPrivacy()">Imposta preferenze Cookie</button>
    </div>

  </div>
</ng-template>
