import {Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {Foto, IVetrina, Review} from '../../../objects/IVetrina';
import {PopupInputComponent} from '../../../components/popup/popup-input/popup-input.component';
import {PopupComponent} from '../../../components/popup/popup/popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpService} from '../../../services/http.service';
import {CommunicatorService} from '../../../services/communicator.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-vetrina-element',
  templateUrl: './vetrina-element.component.html',
  styleUrls: ['./vetrina-element.component.scss']
})
export class VetrinaElementComponent implements OnInit {
  @Input() vetrina: IVetrina;
  @Input() foto: Foto[];
  @Input() reviews: Review[];
  @Input() isDetail = false;
  @Input() isHome = false;
  @Input() isRow = true;
  @Input() favoriteTable: '1' | '2' = null;
  @Output() reloadFavorite = new EventEmitter();
  random = Math.random() * 1000;
  innerWidth: number;
  constructor(private modalService: NgbModal, private httpService: HttpService,
              public communicator: CommunicatorService, @Inject(PLATFORM_ID) private platformId: object) { }

  ngOnInit(): void {
    if (this.vetrina.minprice == null) {
      this.vetrina.minprice = '0';
    }
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
  }

  segnala() {

    const rf = this.modalService.open(PopupInputComponent);
    rf.componentInstance.title = 'Descrivi il motivo della segnalazione';
    rf.componentInstance.closeDescription.subscribe((text: string) => {
      rf.close();
      if (text.length > 0) {
        this.httpService.postWarning({idref: +this.vetrina.id, text, table: 'vetrina'}).subscribe(resp => {
          if (resp && resp.result) {
            const mr2 = this.modalService.open(PopupComponent);
            mr2.componentInstance.title = 'Attenzione';
            mr2.componentInstance.body = 'Segnalazione effettuata con successo';
          }
        });
      }
    });
  }
}
