import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';
import {Observable} from 'rxjs';
import {IVetrina} from '../../objects/IVetrina';
import {map, switchMap} from 'rxjs/operators';
import { MENU_LINKS } from 'src/app/objects/Constants';
import {tap} from 'rxjs/internal/operators/tap';
import {CommunicatorService} from '../../services/communicator.service';
import {Utility} from '../../objects/Utility';
import {VetrineService} from '../../services/vetrine.service';
import {SeoEnum} from '../../objects/SeoEnum';
import {SeoService} from '../../services/seo.service';
@Component({
  templateUrl: './vetrine.component.html',
  styleUrls: ['./vetrine.component.scss']
})
export class VetrineComponent implements OnInit {
  breadcrumbs: [string, string] = [null, null];
  isRow = true;
  MENU_LINKS = MENU_LINKS;
  vetrine$: Observable<IVetrina[]>;
  category: string;
  subcategories: {'Sposa': string[], 'Sposo': string[], 'Fornitori': string[], 'Ricevimento': string[], 'Pets Wedding': string[]} =
    {Sposa: [], Sposo: [], Fornitori: [], Ricevimento: [], 'Pets Wedding': []};
  order: {id: string, key: string, value: 'desc' | 'asc'} = {id: '1', key: 'minprice', value: 'desc'};
  city = '';
  classMenuLeft = '';
  classMenuRight = '';
  orders: {id: string, label: string, key: string, value: 'desc' | 'asc'}[] = [
    {id: '1', label: 'Prezzo descrescente', key: 'minprice', value: 'desc'},
    {id: '2', label: 'Prezzo crescente', key: 'minprice', value: 'asc'}
    ];
  constructor(@Inject(PLATFORM_ID) private platformId: object, private httpService: HttpService,
              private router: Router, private communicator: CommunicatorService,
              private vetrineService: VetrineService, private seoService: SeoService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.vetrine$ = this.activatedRoute.paramMap.pipe(
      map(params => ({category: params.get('category'), subcategory: params.get('subcategory'), city: params.get('city')})),
      tap(params => this.breadcrumbs = [params.category, params.subcategory]),
      tap(params => this.city = (params.city) ? params.city : ''),
      tap(params => this.category = (params.category) ? params.category : ''),
      tap(() => {
        if (!this.city && !this.category) {
          this.httpService.getSeo(SeoEnum.aziende).subscribe(resp => {
            this.seoService.setConfig(resp, SeoEnum.aziende);
          });
        }
      }),
      tap(() => this.subcategories = {Sposa: [], Sposo: [], Fornitori: [], Ricevimento: [], 'Pets Wedding': []}),
      tap(params => this.checkSubcategories(params)),
      switchMap(() => this.httpService.getVetrine({city: this.city, subcategories: this.subcategories})),
      tap(() => (this.classMenuLeft === '') ? this.communicator.backdropShow$.next(false) : this.communicator.backdropShow$.next(true))
    );
  }

  checkSubcategories(params: {category: string, subcategory: string}) {
    if (!params.subcategory) {
      if (params.category) {
        this.subcategories[params.category] = [...this.MENU_LINKS.find(e => e.label === params.category).links];
      } else {
        this.subcategories = this.vetrineService.subcategories;
      }
    } else {
      this.subcategories[params.category] = [params.subcategory];
    }
  }

  changeFilter(link: {'category': string, 'subcategory': string}) {
    if (link) {
      if (this.subcategories[link.category].includes(link.subcategory)) {
        this.subcategories[link.category] = this.subcategories[link.category].filter(e => e !== link.subcategory);
      } else {
        this.subcategories[link.category].push(link.subcategory);
      }
    }
    this.vetrineService.subcategories = this.subcategories;

    const total = this.subcategories.Fornitori.length + this.subcategories.Ricevimento.length + this.subcategories.Sposo.length + this.subcategories.Sposa.length
      + this.subcategories['Pets Wedding'].length;
    const subUrl = (this.city) ? '/city/' + this.city : '';
    if (total !== 1) {
      this.vetrine$ = this.httpService.getVetrine({city: this.city, subcategories: this.subcategories});
      this.router.navigateByUrl('/vetrine-fornitori' + subUrl);
    } else {
      const obj = this.getKeySub();
      this.router.navigateByUrl('/vetrine-fornitori' + subUrl + '/' + obj.key + '/' + obj.subcategory);
    }
  }

  getKeySub() {
    let key = '';
    let subcategory = '';
    if (this.subcategories) {
      const total = this.subcategories.Fornitori.length + this.subcategories.Ricevimento.length +
        this.subcategories.Sposo.length + this.subcategories.Sposa.length + this.subcategories['Pets Wedding'].length;
      if (total === 1) {
        if (this.subcategories.Sposa.length === 1) {
          key = 'Sposa';
          subcategory = this.subcategories.Sposa[0];
        } else if (this.subcategories.Sposo.length === 1) {
          key = 'Sposo';
          subcategory = this.subcategories.Sposo[0];
        } else if (this.subcategories.Ricevimento.length === 1) {
          key = 'Ricevimento';
          subcategory = this.subcategories.Ricevimento[0];
        } else if (this.subcategories.Fornitori.length === 1) {
          key = 'Fornitori';
          subcategory = this.subcategories.Fornitori[0];
        } else if (this.subcategories['Pets Wedding'].length === 1) {
          key = 'Pets Wedding';
          subcategory = this.subcategories['Pets Wedding'][0];
        }
      }
    }
    return (key.length === 0) ? null : {key, subcategory};
  }

  openLeftMenu(show: boolean) {
    if (show) {
      this.classMenuLeft = 'show';
    } else {
      this.classMenuLeft = '';
    }
    Utility.checkScrollBody(show);
    this.communicator.backdropShow$.next(show);
  }

  openRightMenu(show: boolean) {
    if (show) {
      this.classMenuRight = 'show';
    } else {
      this.classMenuRight = '';
    }
    Utility.checkScrollBody(show);
    this.communicator.backdropShow$.next(show);
  }

  selectCategory(event: { isSelect: boolean; macro: string }) {
    if (event.isSelect) {
      this.subcategories[event.macro] = [...this.MENU_LINKS.find(e => e.label === event.macro).links];
    } else {
      this.subcategories[event.macro] = [];
    }
    this.changeFilter(null);
  }

  checkValues(id: string) {
    const {key, value} = this.orders.find(e => e.id === id);
    this.order.key = key;
    this.order.value = value;
  }
}
