<div class="background-lightgray">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-8">

        <h4>Annunci sposi
          <ng-container *ngIf="getKeySub() as obj">
            - {{obj.subcategory}}
          </ng-container>
        </h4>
      </div>
      <div class="col text-right">
        <a routerLink="/">Home</a>
        <ng-container *ngIf="breadcrumbs[0]">
          / <a [routerLinkActive]="breadcrumbs[1] != null ? '' : 'disabled'"
               [routerLink]="'/auctions/' + breadcrumbs[0]">{{breadcrumbs[0]}}</a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid px-1 pl-md-0 overflow-hidden">

  <div [class]="classMenuLeft">
    <div id="menu-left" [class]="classMenuLeft">
      <app-filter-left  [subcategories]="subcategories" [(city)]="city" (close)="openLeftMenu(false)"
                        (selectAll)="selectCategory($event);"
                        [(typeAuctions)]="typeAuctions"
                        (typeAuctionsChange)="changeFilter(null);"
                        (cityChange)="changeFilter(null);" (subcategoryChange)="changeFilter($event);">

      </app-filter-left>
    </div>
  </div>
  <div [class]="classMenuRight">
    <div id="menu-right" [class]="classMenuRight">
      <app-order-right [orders]="orders" [(order)]="order"
                       (orderChange)="openRightMenu(false);" (close)="openRightMenu(false);">

      </app-order-right>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="d-none d-md-block col-md-3">
      <app-filter-left [subcategories]="subcategories" [(city)]="city" (cityChange)="changeFilter(null)"
                       (selectAll)="selectCategory($event)"
                       [(typeAuctions)]="typeAuctions"
                       (typeAuctionsChange)="changeFilter(null);"
                       (subcategoryChange)="changeFilter($event)"></app-filter-left>
    </div>
    <div class="col pl-1">
      <div class="row no-gutters mt-1 d-none d-md-flex">
        <div class="col col-md-3">
          <select class="form-control btn btn-tertiary" [(ngModel)]="order.id" (ngModelChange)="checkValues(order.id)">
            <option value="" disabled selected>Ordina per:</option>
            <option [value]="item.id" *ngFor="let item of orders">{{item.label}}</option>
          </select>
        </div>
        <div class="col-auto col-md text-right">
          <i class="fa fa-bars mr-1 fa-2x" (click)="changeViewType(true)"
             [class.color-primary]="isRow" [class.color-lightpink]="!isRow"></i>

          <i class="fa fa-th-large fa-2x" (click)="changeViewType(false)"
             [class.color-primary]="!isRow" [class.color-lightpink]="isRow"></i>
        </div>
      </div>
      <div class="row no-gutters mt-1 d-flex d-md-none">
        <div class="col-5 pr-2">
          <button class="btn btn-tertiary w-100" (click)="openLeftMenu(true)">Filtra per:</button>
        </div>
        <div class="col text-center p-0" style="font-size: 1.3em;">
          <i class="fa fa-bars mr-1"
             [class.color-primary]="isRow" [class.color-lightpink]="!isRow"
             (click)="changeViewType(true)"></i>

          <i class="fa fa-th-large"
             [class.color-primary]="!isRow" [class.color-lightpink]="isRow"
             (click)="changeViewType(false)"></i>
        </div>
        <div class="col-5 pl-2 text-right">
          <button class="btn btn-tertiary w-100" (click)="openRightMenu(true)">Ordina per:</button>
        </div>
      </div>

      <ng-container *ngIf="auctions$ | async as auctions else loading">
        <div *ngIf="auctions.length > 0 else noRecords" class="mb-3">
          <app-auction-item
            [auctions]="auctions | sortByKey:order.key:order.value"
            [isVisualRow]="isRow"
            [isHome]="false">

          </app-auction-item>
        </div>
        <ng-template #noRecords>

          <div class="alert alert-warning mt-3" role="alert">
            Siamo spiacenti, non sono presenti annunci
          </div>
        </ng-template>
      </ng-container>
      <ng-template #loading>
        <app-vetrina-loading></app-vetrina-loading>
      </ng-template>
    </div>
  </div>

</div>
