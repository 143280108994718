<div class="list-grid row mt-2 ml-0 mr-0">
    <div *ngFor="let auction of auctions" class="col-12 col-md-6 mt-2 px-0">
      <div class="card border-black height-card"
           [ngClass]="{'border-red': auction.id_offer_winner == null && auction.is_expired === '1' && (auction.offers === '0' || auction.refusedall === '1'),
           'border-orange' : auction.id_offer_winner == null && auction.is_expired === '1' && auction.offers !== '0' && auction.refusedall === '0'}">
        <div class="row no-gutters">
          <div class="col-5 col-md-4">
            <img class="w-100 height-card" [src]="'https://www.weddingsmart.it/api/images/auctions/' + auction.imgname">
          </div>
          <div class="col">
            <div class="card-body position-relative pt-1 pr-0">
              <span class="badge badge-success">{{auction.offers}} Proposte</span>
              <span class="auction-code">Cod. {{auction.code}}</span>
              <div class="d-none d-md-block">
                <h5 class="card-title">{{auction.title}}
                  <button *ngIf="auction.is_expired === '0' && !auction.id_offer_winner"
                          class="btn p-0" (click)="editAuction(auction)">
                    <i class="fa fa-edit"></i>
                  </button>
                </h5>
                <p class="card-text">Budget: {{auction.budget}} € </p>

                <ng-container *ngIf="auction.id_offer_winner == null else winnerOffer">
                  <ng-container *ngIf="auction.is_expired === '1' else noExpired">

                    <div class="row">
                      <div class="col-4 pl-0 pr-0 text-center au-closed">
                        <i class="text-orange far fa-check-circle fa-3x" *ngIf="auction.offers !== '0' && auction.refusedall === '0'"></i>
                        <i class="text-danger far fa-times-circle fa-3x" *ngIf="auction.offers === '0' || auction.refusedall === '1'"></i>
                        <h5 class="mt-2"
                            [ngClass]="{'text-orange': auction.offers !== '0' && auction.refusedall === '0',
                             'text-danger' : auction.offers === '0' || auction.refusedall === '1'}">
                          CONCLUSA</h5>
                      </div>
                      <div class="col text-center pl-0">
                        <label class="au-choose text-orange" *ngIf="auction.offers !== '0' && auction.refusedall === '0'">
                          Stai decidendo l'offerta vincente </label>

                        <label class="au-choose text-danger" *ngIf="auction.offers !== '0' && auction.refusedall === '1'">
                          Non hai accettato nessuna offerta </label>

                        <label class="au-choose text-danger" *ngIf="auction.offers === '0'">
                          Non hai ricevuto nessuna offerta </label>

                        <div class="text-center mt-3"  *ngIf="auction.offers === '0' || auction.refusedall === '1'">
                          <button class="btn btn-primary" (click)="republish(auction)">Ripubblica annuncio</button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #noExpired>
                    <div>
                      <small class="text-black-50 font-weight-bold">Scadenza:</small>
                      <small *ngIf="auction.expire"
                        class="font-weight-bold"> {{auction.expire}}</small>
                      <small *ngIf="!auction.expire"
                        class="font-weight-bold text-danger"> In approvazione </small>
                    </div>
                    <div>
                      <small class="text-black-50 font-weight-bold">Dove:</small>
                      <small class="font-weight-bold">
                        {{auction.city}}
                      </small>
                    </div>
                    <div>
                      <small class="text-black-50 font-weight-bold">Data nozze:</small>
                      <small class="font-weight-bold">
                        {{auction.wedding_date}}
                      </small>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-template #winnerOffer>
                  <div class="row">
                    <div class="col-4 pl-0 pr-0 text-center au-closed"><i
                      class="text-success far fa-check-circle fa-3x"></i>
                      <h5 class="text-success mt-2">CONCLUSA</h5></div>
                    <div class="col text-center pl-0">
                      <label class="au-choose">Hai scelto la proposta di <br>
                        <a href="">{{auction['business_name']}}</a>
                      </label>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="d-block d-md-none mt-body-1" [routerLink]="'/auctions/detail/' + auction.id">
                <h6 class="card-title">
                  {{auction.title}}
                  <button *ngIf="auction.is_expired === '0' && !auction.id_offer_winner"
                    class="btn p-0" (click)="editAuction(auction)">
                    <i class="fa fa-edit"></i>
                  </button>
                </h6>
                <small class="text-black-50 font-weight-bold">Budget:</small>
                <label class="font-weight-bold ml-1">
                  {{auction.budget}} €
                </label><br>
                <div class="row"
                     *ngIf="auction.id_offer_winner === null && auction.is_expired === '1' && auction.offers === '0'">
                  <div class="col-4 pl-0 pr-0 text-center au-closed">
                    <i class="text-danger far fa-times-circle fa-3x"></i>
                    <h5 class="text-danger mt-2">CONCLUSA</h5>
                  </div>
                  <div class="col text-center pl-0">
                    <label class="au-choose text-danger mb-0">Non hai ricevuto nessuna offerta </label>
                    <div class="text-center">
                      <button class="btn btn-primary p-0" style="font-size: 10px;" (click)="republish(auction)">Ripubblica annuncio</button>
                    </div>
                  </div>
                </div>
                <div class="row"
                     *ngIf="auction.id_offer_winner === null && auction.is_expired === '1' && auction.offers !== '0'">
                  <ng-container *ngIf="auction.refusedall === '0'">
                    <div class="col-4 pl-0 pr-0 text-center au-closed">
                      <i class="text-orange far fa-check-circle fa-3x"></i>
                      <h5 class="text-orange mt-2">CONCLUSA</h5>
                    </div>
                    <div class="col text-center pl-0">
                      <label class="au-choose text-orange">Stai decidendo l'offerta vincente </label>
                    </div>
                  </ng-container>
                  <ng-container  *ngIf="auction.refusedall === '1'">
                    <div class="col-4 pl-0 pr-0 text-center au-closed">
                      <i class="text-danger far fa-times-circle fa-3x"></i>
                      <h5 class="text-danger mt-2">CONCLUSA</h5>
                    </div>
                    <div class="col text-center pl-0">
                      <label class="au-choose text-danger mb-0">Non hai accettato nessuna offerta </label>

                      <div class="text-center">
                        <button class="btn btn-primary p-0" style="font-size: 10px;" (click)="republish(auction)">Ripubblica annuncio</button>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf="auction.id_offer_winner === null && auction.is_expired !== '1'">
                  <div>

                    <small class="text-black-50 font-weight-bold">
                      Scadenza:
                    </small>
                    <small class="font-weight-bold" *ngIf="auction.expire">
                      {{auction.expire}}
                    </small>
                    <small *ngIf="!auction.expire"
                           class="font-weight-bold text-danger"> In approvazione </small>
                  </div>
                  <div style="margin-top: -8px;">
                    <small class="text-black-50 font-weight-bold">Dove: </small><small class="font-weight-bold">
                    {{auction.city}}
                  </small>
                  </div>
                </ng-container>

                <div class="row" *ngIf="auction.id_offer_winner !== null">
                  <div class="col-4 pl-0 pr-0 text-center au-closed">
                    <i class="text-success far fa-check-circle fa-3x"></i>
                    <h5 class="text-success mt-2">CONCLUSA</h5></div>
                  <div class="col text-center pl-0">
                    <label class="au-choose">Hai scelto la proposta di
                      <br>
                      <a href="">{{auction.business_name}}</a>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</div>
