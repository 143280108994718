<div class="modal-body position-relative">
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
  <h4>{{title}}</h4>
  <label>{{body}}</label>
  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-primary mr-3" (click)="actionClicked.emit('0')">Continua acquisti</button>
      <button class="btn btn-primary" (click)="actionClicked.emit('1')">I miei acquisti</button>
    </div>
  </div>

</div>
