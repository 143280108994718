<ng-container *ngIf="isModal else body">
  <div class="modal-header">
    <h5 class="modal-title">TERMINI, CONDIZIONI GENERALI E D’USO DI WEDDING SMART</h5>
    <button type="button" class="close" (click)="modal.dismissAll()">&times;</button>
  </div>
  <div class="modal-body">
    <ng-template [ngTemplateOutlet]="body"></ng-template>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="modal.dismissAll()">Chiudi</button>
  </div>
</ng-container>
<ng-template #body>

  <div class="container">
    <h6>Vers. 1 - agg. al 27.06.2022</h6>

    <h4 *ngIf="!isModal">TERMINI, CONDIZIONI GENERALI E D’USO DI WEDDING SMART</h4>
    WEDDING SMART è un Portale Web innovativo, una WebApp.
    (da qui, per brevità, il PORTALE), è una piattaforma online,
    un nuovo tipo di marketplace orizzontale originale (B2C - C2B - B2B), dedicato a tutto il settore della Wedding
    Industry, dove
    mette in contatto imprese che offrono servizi e/o prodotti legati alla celebrazione delle nozze: ricevimento, wedding
    planner,
    abiti ed accessori sposa e sposo, autonoleggi, fotografi, etc… con i futuri sposi.
    Il PORTALE è gestito ad ogni effetto operativo e di legge da xLoma S.r.l.s. - Società con Socio Unico - Capitale
    Sociale 100.00
    €. i.v., (da quì, per brevità, GESTORE), con sede legale in Nettuno (RM), Via Anguillara, 8, P. IVA 14873011002 - REA
    RM - 1552315 in qualità di licenziataria.
    La registrazione al PORTALE è assolutamente GRATUITA, ed offre l’opportunità ai propri Utenti registrati (futuri Sposi e
    Aziende operanti nel settore dei matrimoni con regolare p.iva) di mettersi in contatto fra loro, chiedendo ed offrendo
    servizi/prodotti inerenti il matrimonio mediante un sistema di annunci con offerte al ribasso, nei limiti ed alle
    condizioni di cui
    al presente “Regolamento d’uso Wedding Smart” (di seguito, per brevità, Regolamento).<br>
    Premesso che:
    <ul style="list-style-type: lower-alpha">
      <li>
        Per accedere agli Strumenti e dalle Funzionalità del PORTALE (come meglio definiti più avanti, rispettivamente,
        art.
        1.2.4 e 1.2.5), è necessario registrare un Account personale.
      </li>
      <li>
        Con tale Registrazione, l’Utente dichiara di aver letto ed accettato il presente Regolamento (comprensivo degli
        adempimenti agli obblighi in materia di Privacy, di cui all’art. 1.2.14) e, conseguentemente, di impegnarsi
        formalmente
        e senza riserve al rispetto delle disposizioni ivi contenute; in difetto, l’Utente non sarà autorizzato ad
        utilizzare il
        PORTALE e tutti gli Strumenti ad esso collegati.
      </li>
      <li>
        Gli Utenti registrati, cliccando sul tasto accetta proposta o acquista, quindi in caso di
        accordo/acquisto/vendita,
        stipuleranno autonomamente un Contratto Telematico anche virtuale (da qui, per brevità, Contratto), congruente alla
        natura delle parti e dell’oggetto, dove saranno indicati i modi e le condizioni per fruire della prestazione di
        servizi richiesti
        o per l’acquisto di uno o più prodotti.
      </li>
      <li>
        Le parti riconoscono sin d’ora che, in ragione della natura meramente strumentale del PORTALE e dei servizi ivi
        offerti,
        il GESTORE e/o le sue società controllanti e/o controllate o comunque collegate, sono estranee al Contratto di cui
        al
        punto c della premessa e non potranno essere coinvolte, ad alcun titolo, nelle vicende relative alla sua
        formazione e/o
        esecuzione e/o cessazione per qualsiasi causa.
      </li>
      <li>
        In generale, il GESTORE e/o le sue società controllanti e/o controllate o comunque collegate, non interferiranno
        in alcun
        modo nel rapporto tra gli Utenti “Sposi ed Operatori del settore Wedding”, fatto salvo quanto previsto dai vari
        Art. e
        subalterni,così come meglio specificati al Titolo X° - Segnalazioni di Anomalie e/o Criticità.
      </li>
      <li>
        Il GESTORE si riserva la facoltà discrezionale di:
        <ul style="list-style-type: decimal">
          <li>
            sospendere/o cancellare gli Account in caso di Inadempimenti e Comportamenti considerati GRAVI, così come
            meglio specificato al Titolo I° - Aspetti Generali e Definizioni art. 1.2.16;
          </li>
          <li>
            di modificare la disponibilità, la natura, le condizioni d’uso ed il contenuto del PORTALE e degli Strumenti
            ivi offerti;
          </li>
          <li>
            di modificare le condizioni del presente Regolamento;
          </li>
          <li>
            di modificare le condizioni della Privacy Policy;
          </li>

        </ul>
      </li>
      <li>
        In caso di modifiche non meramente descrittive, il GESTORE provvederà a trasmettere agli Utenti un messaggio di
        posta
        elettronica all'indirizzocomunicato in occasione della registrazione al PORTALE e/o una notifica mediante pop-up
        in
        seguito al primo login successivo a tali modifiche, con cui inviterà ciascun Utente aprendere visione del nuovo
        Regolamento e/o delle nuove condizioni relative alla Privacy, comunicandogli la possibilità, in caso di mancata
        accettazione delle modifiche, di recedere dal rapporto con il GESTORE e quindi rinunciare all’accesso al PORTALE,
        tramite la cancellazione del proprio Account.
      </li>
      <li>
        La mancata cancellazione dell’Account entro 7 giorni dallaricezione della e-mail e/o della comunicazione pop-up
        previste
        alla lettera g della premessa) costituirà, a tutti gli effetti, accettazione delle modifiche apportate al presente
        Regolamento
        e/o alle condizioni relative alla Privacy come, peraltro, verrà espressamente ribadito in tale e-mail e/o pop-up.
      </li>
      <li>
        Le modifiche apportate dal GESTORE, fermo quanto sopra e fatto salvo il diritto di recesso, saranno efficaci dal
        terzo
        giorno successivo alla pubblicazione sul presente PORTALE. Il GESTORE è responsabile della gestione e della
        manutenzione del PORTALE, il cui sviluppo e funzionamento dipendono necessariamente dall’uso, dalle licenze, dal
        know-how e dalle opere dell’ingegno che possono essere anche di terze parti le quali saranno responsabili ognuno
        per la
        propria parte di conseguenza, e non attribuibili in nessun caso all’esclusivista licenziataria.
      </li>
    </ul>

    si conviene quanto segue:
    <h6>TITOLO I° - ASPETTI GENERALI E DEFINIZIONI</h6>
    <ul style="list-style-type: none">
      <li>
        1.1 Le premesse formano parte integrante ed essenziale del presente Regolamento.
      </li>
      <li>
        1.2 Le parole e le espressioni indicate nei seguenti subalterni hanno il significato ivi convenzionalmente
        definito:
        <ul style="list-style-type: none">
          <li>
            1.2.1 Account: si intende il sistema di autenticazione e/o autorizzazione, necessario per accedere agli
            Strumenti, creato
            da ogni singolo Utente con la registrazione e protetto da credenziali personalizzate.
            <ul style="list-style-type: none">
              <li>
                1.2.1.1 L’Account deve essere gestito esclusivamente dall’Utente stesso e non è, comunque, direttamente o
                indirettamente, cedibile a e/o usufruibile da terzi.
              </li>
              <li>

                1.2.1.2 Gli Account degli Utenti possono essere alternativamente di due tipi:
                <ul style="list-style-type: none">
                  <li>

                    a)Sposi (clienti): si intende l’Utente che, al momento della registrazione ha specificato di volersi
                    servire degli Strumenti e delle Funzionalità messe a disposizione dal portale per chiedere dei
                    servizi/prodotti agli Operatori del settore Wedding, i quali li offriranno al prezzo più basso mediante
                    un sistema di annunci con offerte al ribasso. Con la registrazione al portale, gli utenti sposi
                    (clienti)
                    dichiareranno la veridicità dei propri dati anagrafici inseriti, i quali dovranno essere ulteriormente
                    avallati da una copia di un doc. di identità personale qualora il gestore lo riterrà necessario. In
                    nessun
                    caso il GESTORE potrà ritenersi responsabile della veridicità di tali dati a lui inviati, in quanto ha
                    adottato tutti gli strumenti necessari in suo possesso per garantire la reale esistenza dell’utente;
                  </li>
                  <li>
                    b)Operatore del settore Wedding (azienda titolare di P.iva):si intende l’Utente che, al momento della
                    Registrazione ha specificato di volersi servire degli Strumenti e delle Funzionalità messe a
                    disposizione dal PORTALE, per poter partecipare agli annunci con offerte al ribasso pubblicati dai
                    futuri sposi (Clienti) inerenti richieste di servizi/prodotti per il matrimonio, i quali saranno
                    offerti a
                    quest’ultimi al prezzo più basso. Con la registrazione ad operatore del settore Wedding, il soggetto
                    dichiara di essere in possesso di tutte le autorizzazioni, licenze o concessioni necessarie allo
                    svolgimento del servizio che si propone di offrire ai futuri sposi. In nessun caso il GESTORE potrà
                    ritenersi responsabile dell’assenza delle predette autorizzazioni, concessioni o licenze in capo
                    all’operatore che non gli consentano di svolgere il servizio offerto;
                  </li>
                </ul>
              </li>
              <li>

                1.2.1.3
                Il GESTORE,anche ai fini dell’amministrazione del PORTALE, gestirà vari Account di tipo “Staff”, quali
                ad esempio quello del “Équipe di Wedding Smart®”, nei termini e per i fini previsti dal presente
                Regolamento.
              </li>
              <li>

                1.2.1.4
                In ogni caso, è espressamente vietata la registrazione e/o l’utilizzo di Account da parte di soggetti di
                età
                minore di anni 18. Qualora i futuri sposi fossero minorenni, la registrazione al sito deve essere
                effettuata
                dal tutore legale o chi ne esercita la patria potestà.
              </li>
            </ul>
          </li>
          <li>

            1.2.2
            Registrazione: si intende la procedura con la quale ciascun Utente:
            <ul style="list-style-type: none">
              <li>
                1.2.2.1 sceglie, alternativamente, se registrarsi come Sposi (cliente) o Operatore del settore wedding
                (Azienda);
              </li>
              <li>

                1.2.2.2 conferisce i propri dati per la creazione di un Account;
              </li>
              <li>

                1.2.2.3 sceglie le proprie credenziali d’autenticazione e/o autorizzazione per la protezione di tale
                Account, tramite
                il quale potrà accedere agli Strumenti e/o modificare i dati ogni volta che vorrà e in maniera protetta;
              </li>
              <li>

                1.2.2.4 convalida i dati conferiti, seguendo le istruzioni contenute in un sms e/o in una e-mail ricevuti
                in risposta
                all’invio di tali dati e alla contestuale accettazione del presente regolamento e della Privacy. In tale
                sms
                e/o e-mail saranno altresì riportati gli stessi dati conferiti, nonché le credenziali d’autenticazione e/o
                autorizzazione dell'Account.
              </li>
            </ul>
          </li>
          <li>

            1.2.3 Dati:si intendono le informazioni trasmesse da ciascun Utente al PORTALE nelle varie fasi della
            registrazione,
            quali, a titolo esemplificativo:
            <ul style="list-style-type: none">
              <li>

                1.2.3.1 nome e cognome; denominazione o ragione sociale; eventuale forma societaria e/o regime
                fiscale/previdenziale applicabile, foto;
              </li>
              <li>

                1.2.3.2 residenza e/o domicilio; indirizzo; e-mail; numero di telefono; credenziali personalizzate di
                autenticazione e/o autorizzazione;
              </li>
              <li>

                1.2.3.3 codice fiscale/partita iva;
              </li>
              <li>

                1.2.3.4 qualunque altra informazione o documentazione integrativa, richiesta anche successivamente dal
                GESTORE, che possa ulteriormente avallare la veridicità personale e/o aziendale, quali altri recapiti,
                copie di doc. di identità, iscr. cc., p.iva ecc..;
              </li>
            </ul>
          </li>
          <li>
            1.2.4 Strumenti: si intendono, anche congiuntamente, il PORTALE e tutti i servizi ivi offerti mediante
            interfaccia web
            e/o mobile, necessari e/o utili, a titolo meramente esemplificativo, perla pubblicazione di Contenuti,
            Servizi,
            Contest, annunci con offerte al ribasso, invio e ricezione di dati, nel rispetto e nei limiti del presente
            Regolamento
            e di qualunque altra normativa comunque applicabile.
          </li>
          <li>

            1.2.5 Funzionalità: si intendono, singolarmente o congiuntamente, le applicazioni, i servizi e/o gli strumenti
            di
            partecipazione alle iniziative pubblicate o che saranno pubblicate sul PORTALE come, di volta in volta
            disciplinate nelle Condizioni Generali di Utilizzo delle Funzionalità, di cui sia i futuri Sposi che gli
            Operatori del
            settore Wedding possono usufruire, nel rispetto e previa accettazione delle previsioni di cui alle medesime
            Condizioni Generali di Utilizzo delle Funzionalità come meglio definite nel sub 1.2.15.
          </li>
          <li>
            1.2.6 Servizi/prodotti: si intendono tutto ciò che è collegato con il settore del wedding.
          </li>
          <li>

            1.2.7 Descrizione Servizio: si intende la descrizione delle attività e/o dei servizi richiesti dai futuri Sposi
            (clienti) e
            offerto dagli Operatori del settore Wedding nei modi e nei tempi da loro indicati tramite gli Strumenti messi a
            disposizione dal PORTALE e, in particolar modo,mediante un sistema di annunci con offerte al ribasso, da
            effettuarsi utilizzando le Funzionalità di cui alle Condizioni Generali di Utilizzo delle Funzionalità
            sub1.2.15, e
            consultabili al seguente link https://www.weddingsmart.it/termini-e-condizioni-generali-del-servizio/
          </li>
          <li>
            1.2.8 Annuncio con offerta al ribasso: processo di compravendita mediante offerte al ribasso che si conclude
            con la
            vendita dell'oggetto al migliore offerente (importo uguale o più basso rispetto al budget indicato dai futuri
            sposi).
            Questa procedura viene interamente gestita dai futuri Sposi (clienti) tramite gli Strumenti forniti dal
            PORTALE,
            così come meglio specificato al Titolo III°- Annunci (Creazione, Partecipazione, Funzionamento e Durata)
            art. 3.1 e seguenti.
          </li>
          <li>
            1.2.9 Budget: somma in denaro in possesso dei futuri sposi da poter spendere per un tipo di servizio/prodotto
            di loro
            interesse.
          </li>
          <li>
            1.2.10 Abbonamento (solo per gli Operatore del settore Wedding):
            pagamento anticipato ed obbligatorio, senza il quale
            non è possibile prendere parte agli annunci con offerte al ribasso, (generalmente con uno sconto, non
            obbligatorio
            da parte del GESTORE) e ripartito nel tempo per cui, pagando una determinata somma, si può, per un tempo
            determinato, usufruire dei servizi messi a disposizione del Portale.
            Vi sono 4 tipi di abbonamenti: Bronze; Silver; Golde Platinum, ognuno dei quali con durata, importo e servizi
            differenti come meglio specificato nella sessione Abbonamenti, raggiungibile dopo avere effettuato l’accesso
            al
            proprio account dal seguente link <a href="https://www.weddingsmart.it/portal/operator-paypal">https://www.weddingsmart.it/portal/operator-paypal</a>.
          </li>
          <li>
            1.2.11 Fee (fissa e variabile):commissione in “ % ”, differente da abbonamento ad abbonamento, calcolata su
            l’importo
            finale di chiusura annuncio ed indicata sul riepilogo dei pagamenti da corrispondere al GESTORE e sulla
            propria
            dashboard dal menu--> Store --> Vendite --> Commissioni di vendita. La stessa non è rimborsabile e dovrà
            essere versare dall’ Operatore del settore Wedding, al GESTORE, tramite appositi Strumenti da lui di volta in
            volta indicati o tramite sistemi di pagamenti pre-autorizzati e/o canalizzati, per i servizi e strumenti messi
            a
            disposizione con il proprio PORTALE.
          </li>
          <li>
            1.2.12 T.A.S.A. (Tassa Aggiuntiva Servizio Aziende) Servizio NON ATTIVO per gli Operatori del settore Wedding:
            corrispettivo extra non rimborsabile, da versare al GESTORE tramite appositi Strumenti per l’acquisto di
            servizi
            aggiuntivi extra e non obbligatori offerti nel PORTALE come meglio specificato nella sessione Gestione e
            Riepilogo Abbonamenti e servizi, raggiungibile dopo avere effettuato l’accesso al proprio account dal seguente
            link: <a href="https://www.weddingsmart.it/portal/operator-paypal">https://www.weddingsmart.it/portal/operator-paypal</a>.
            Gli stessi possono essere acquistati anche
            separatamente dall’abbonamento e anche per brevi periodi, a titolo di pubblicità sul portale o per farsi
            trovare
            prima dagli sposi.
          </li>
          <li>

            1.2.13 T.A.S.C (Tassa Aggiuntiva Servizio Clienti) Servizio NON ATTIVO per i futuri Sposi suddivise in:
            <ul style="list-style-type: none">
              <li>

                1.2.13.1 corrispettivo rimborsabile: applicabile solo su “Riservato Base e Top”, “Al Buio Base e Top” e “Al
                Buio Riservato” come meglio specificato nella sessione “Gestione e Riepilogo Servizi” raggiungibile
                dal pannello di gestione personale, da versare al GESTORE tramite appositi Strumenti per l’acquisto di
                servizi aggiuntivi extra e non obbligatori offerti nel Portale.
              </li>
              <li>

                1.2.13.2 corrispettivo non rimborsabile: applicabile solo su “Prolunga Scadenza” come meglio specificato
                nella
                sessione “Gestione e Riepilogo Servizi” raggiungibile dal pannello di gestione personale, da versare al
                GESTORE tramite appositi Strumenti per l’acquisto di servizi aggiuntivi extra e non obbligatori offerti
                nel Portale.
              </li>
            </ul>
          </li>
          <li>
            1.2.14 Privacy: si intendono congiuntamente e indistintamente:
            <ul style="list-style-type: none">
              <li>
                1.2.14.1 l'informativa sulla Privacy
              </li>
              <li>

                1.2.14.2 Privacy Policy.
              </li>
            </ul>
          </li>
          <li>

            1.2.15 Condizioni Generali di Utilizzo delle Funzionalità: si intendono i termini e le condizioni di
            partecipazione ed
            utilizzo delle Funzionalità (art. 1.2.5), di volta in volta, applicabili e da utilizzarsi per la
            partecipazione agli
            Annunci e all’esecuzione delle attività connesse alle stesse e pubblicate alla pagina del Sito di cui al
            seguente link:
            <a href="https://www.weddingsmart.it/termini-e-condizioni-generali-del-servizio">https://www.weddingsmart.it/termini-e-condizioni-generali-del-servizio</a>.
          </li>
          <li>

            1.2.16 Inadempimenti e Comportamenti considerati GRAVI: si intendono, tutti quelli indicati ai vari Art. e
            subalterni,
            così come meglio specificati al Titolo X° - Segnalazioni di Anomalie e/o Criticità, al Titolo XI° - Contenuti e
            al Titolo XII° - Uso dell’Account e degli Strumenti, che possano offendere la dignità e la moralità altrui a
            prescindere che siano utenti registrati al PORTALE o meno, o danneggiare e compromettere l’integrità e la
            credibilità del PORTALE pregiudicando anche il normale e corretto utilizzo.
            Rientrano negli Inadempimenti e Comportamenti considerati GRAVI anche:
            <ul style="list-style-type: lower-alpha">
              <li>
                i feedback (qualora ne venisse fatto un uso improprio o un’abuso);
              </li>
              <li>
                contrattare direttamente o indirettamente, al di fuori del PORTALE, i servizi/prodotti oggetto di
                un’annuncio
                concluso o che stia per concludersi, così come meglio specificati all’art. 3.8 del Titolo III° - Annunci
                (creazione, partecipazione, funzionamento e durata);
              </li>
              <li>
                il mancato pagamento dei relativi servizi offerti dal GESTORE e delle Fee;
              </li>
              <li>
                tutti i pagamenti tra gli Utenti.
              </li>
            </ul>

            L’ inosservanza di cui alle lettere precedenti del predetto art. 1.2.16, potrebbero generare un danno sia
            materiale
            che d’immagine al PORTALE e si potrebbe verificare la non più credibilità dello stesso con conseguenti
            ripercussioni sugli utenti finali.
          </li>
          <li>
            1.2.17 Feedback: si intende un indice di reputazione associato a ciascun Utente Social Wedding Smart (Op.
            Wedding e
            futuro Sposo) nell’ambito del PORTALE stesso ed è visibile solamente a tutti gli utenti registrati al PORTALE.
          </li>
          <li>
            1.2.18 Contratto Telematico: si intende lo scambio tra proposta e accettazione operato a distanza attraverso
            la rete
            internet anche solamente con l’acquisto on line senza materialmente riceverne una copia scritta.
          </li>
        </ul>
      </li>
    </ul>

    <h6>TITOLO II° - OGGETTO E DURATA</h6>
    <ul style="list-style-type: none">
      <li>
        2.1 Il Regolamento è finalizzato a disciplinare le condizioni d'uso degli Strumenti del PORTALE.
      </li>
      <li>
        2.2 Il Regolamento è efficace a tempo indeterminato. Ciascun Utente potrà recedere in qualsiasi momento, mediante
        cancellazione del proprio Account, che avrà effetto immediato, fatti salvi i rapporti contrattuali eventualmente in
        corso
        alla stregua di quanto previsto dall’ art. 3.1 e seguenti del Titolo III° - Annunci (Creazione, Partecipazione,
        Funzionamento e Durata) che, data la loro autonomia, continueranno a vincolare le parti rispettivamente stipulanti
        alle
        condizioni e nei termini ivi previsti.
      </li>
      <li>
        2.3 Tutti gli Strumenti sono accessibili esclusivamente ai soggetti maggiorenni dotati di Account personale.
        <ul style="list-style-type: none">
          <li>
            2.3.1 Gli Utenti (Op. Wedding e futuri Sposi), all'atto della Registrazione:
            <ul style="list-style-type: lower-alpha">
              <li>

                garantiscono la correttezza, completezza e veridicità dei dati inseriti, dei quali si assumono la piena
                responsabilità, per quanto di ragione, ai sensi e pergli effetti degli artt. 2,46, 47, 75 e 76 del D.P.R.
                20
                dicembre 2000, n. 445;
              </li>
              <li>
                certificano, in particolare (i futuri sposi), agli stessi sensi di cui sopra e sotto la propria
                responsabilità, anche
                di avere compiuto il diciottesimo anno di età;
              </li>
              <li>
                si impegnano, alla stregua della normativa e delle condizioni sulla Privacy, a mantenere aggiornati i
                propri
                dati, modificandoli in caso di variazione, ed assicurandola segretezza delle proprie credenziali di
                autenticazione;
              </li>
              <li>
                garantiscono in particolare gli Op. Wedding sotto la propria responsabilità, di essere titolare di tutte
                le
                autorizzazioni, licenze, concessioni richieste per lo svolgimento del servizio o la vendita del prodotto
                offerto
                ai clienti.
              </li>
            </ul>
          </li>
          <li>

            2.3.2 Il GESTORE non potrà essere considerato in alcun modo responsabile nel caso di utilizzo dei Dati da
            partedi terzi
            non autorizzati e, in ogni caso, ferme le previsioni dell’art. 31 del D. Lgs. 196/03, non è tenuto a
            predisporre
            ulteriori misure per prevenire un eventuale accesso abusivo, non essendo previsto il conferimento di dati
            sensibili
            e/o giudiziari.
          </li>
          <li>
            2.3.3 Escluso qualsiasi obbligo/onere di controllo a suo carico, qualora il GESTORE venisse a conoscenza del
            fatto che
            un Utente è in realtà un soggetto minore di anni 18 (pervenute tramite segnalazione) e/o, in generale, della
            falsità
            e/o erroneità dei Dati contenuti in un Account, potrà cancellare tale Account senza preavviso.
          </li>
        </ul>
      </li>
      <li>
        2.4 Non è ammessa la creazione di più Account ne da parte della stessa persona fisica (futuro Sposo) ne da parte
        delle Aziende
        e/o persone giuridiche.
      </li>
      <li>
        2.5 Qualora un Utente sia registrato come Cliente (futuro Sposo) eintenda partecipare ad un annuncio con offerte
        al ribasso
        come operatore del settore Wedding (Azienda) dovrà creare un altro Account come azienda informando il GESTORE.
      </li>
      <li>
        2.6 Ogni Utente è responsabile dell’uso del proprio Account ed è tenuto a segnalare allo Staff, ogni anomalia e/o
        uso non
        autorizzato di tale Account, anche da parte di terzi.
      </li>
      <li>
        2.7 Gli Operatori settore Wedding(Aziende), interessati a servirsi degli Strumenti del PORTALE, sono tenuti a
        completare
        la procedura di Registrazione, creando il proprio Account, così accettando integralmente ed incondizionatamente il
        presente Regolamento e le previsioni relative alla Privacy.
      </li>
      <li>
        2.8 In aggiunta a quanto previsto dall’art. 2.7 che precede, i futuri Sposi(Clienti) interessati a servirsi delle
        Funzionalità e ad
        accedere agli annunci con offerte al ribasso, sono tenuti a completare la procedura di Registrazione creando il
        proprio
        Account accettando integralmente ed incondizionatamente il presente Regolamento e le previsioni relative alla
        Privacy,
        nonché le Condizioni Generali di Utilizzo delle Funzionalità.
      </li>
      <li>
        2.9 Il GESTORE, si riserva la facoltà di concedere sconti e/o svolgere qualsiasi tipo di attività promozionale sul
        Sito, i cui
        termini e condizioni saranno comunicati tramite gli Strumenti del PORTALE.
      </li>
    </ul>
    <h6>TITOLO III° - ANNUNCI (CREAZIONE, PARTECIPAZIONE, FUNZIONAMENTO E DURATA)</h6>
    <ul style="list-style-type: none">
      <li>

        3.1 Con il sistema degli annunci con offerte al ribasso, il Cliente ottiene il servizio/prodotto richiesto, il
        quale verrà fornito
        dall’operatore che avrà effettuato l’offerta di loro gradimento uguale o più bassa rispetto al budget indicato.
      </li>
      <li>
        3.2 Le creazioni degli annunci con offerte al ribasso sono assolutamente gratuite e servono per reperire al minor
        prezzo
        possibile, servizi/prodotti necessari ai futuri Sposi (Clienti) per lo svolgimento del proprio matrimonio.
      </li>
      <li>
        3.3 Creare un’ Annuncio con offerte al ribasso:
        <ul style="list-style-type: none">
          <li>
            3.3.1 Per poter creare un’annuncio, bisogna avere un Account come Sposi (Clienti);
          </li>
          <li>
            3.3.2 Per avere un’ Account, bisogna registrarsi “gratuitamente” al PORTALE e completare il proprio profilo
            con tutti
            i dati richiesti,avendo cura di inserire anche il proprio Codice Fiscale, questo servirà per poter poi generare
            il
            Contratto vero e proprio tra Voi e i vari Operatori del settore Wedding che si aggiudicheranno gli annunci da
            voi
            creati;
          </li>
          <li>
            3.3.3 una volta aver generato il vostro Account, potrete iniziare a creare un Annuncio, raggiungibile dal
            proprio profilo
            personale o dal link Crea Annuncio e dovrete:
            a) sceglierne la categoria di interesse;
            b) Sceglierne il servizio di interesse;
            c) Definirne in modo chiaro e conciso l’oggetto ed il contenuto;
            d) Inserirne una descrizione, la più dettagliata possibile, per il servizio/prodotto di proprio interesse;
            e) Indicarne il prezzo massimo che sarete intenzionati a spendere per quel tipo di servizio/prodotto;
            f) Inserire delle immagini di esempio di cosa e/o come vorreste il servizio/prodotto;
            g) Indicare il termine di realizzazione/consegna del servizio/prodotto inserendo la data di quando e dove
            dovrà
            essere fornito/effettuato.
          </li>
          <li>
            3.3.4 In seguito alla sua pubblicazione, gli Operatori del settore Wedding potranno, in totale autonomia,
            aderire a tutti
            o solo ad alcuni degli annunci con offerte al ribasso che saranno pubblicati e pubblicizzati sul PORTALE; Ora è
            tutto pronto per la pubblicazione, infatti, con la creazione dell’ annuncio non avete fatto altro che generare
            una
            domanda di richiesta servizi e/o prodotti di vostro interesse a cui gli Operatori del settore Wedding potranno
            aderire effettuando delle offerte a ribasso, entro un termine di tempo da Voi prestabilito in partenza.
          </li>
          <li>
            3.3.5 I futuri Sposi (clienti) durante tutta la durata dell’annuncio, possono acquistare dei servizi
            aggiuntivi extra e
            non obbligatori detti (T.A.S.C. servizio non attivo), che gli permetteranno di poter prolungarne anche la
            durata,
            così come meglio specificato all’ art. 1.2.13e seguenti del Titolo I° - Aspetti Generali e Definizioni;
          </li>
          <li>
            3.3.6 Dopo aver perfezionato un annuncio o un acquisto presso lo store dagli Operatori del settore Wedding
            (Aziende),
            entrambi gli utenti futuri Sposi (Clienti) e Operatori del settore Wedding(Aziende), restano vincolati nel
            portare
            a termine l’operazione di acquisto/vendita e concludere il Contratto (lettera “c” della premessa e dall’ art.
            1.2.10),
            accettandone integralmente ed incondizionatamente le condizioni, impegnandosi qualora previsto, al versamento
            della/e caparra/e confirmatoria/e o al pagamento in unica soluzione, mediante gli strumenti messi a
            disposizione
            dal PORTALE.
          </li>
          <li>
            3.3.7 I futuri Sposi (clienti) una volta pubblicato l’ annuncio, non potranno chiedere alcuna modifica della
            stessa salvo
            in quei casi eccezionali in cui:
            a) ci si accorgesse di errori che potrebbero anche pregiudicare l’ annuncio stesso (esempio: errore di
            battitura
            che potrebbe verificarsi “inserimento Budget sbagliato”: si sarebbe voluto indicare un’ importo di €.
            1.000,00 ed invece si è scritto €. 10.000,00), le richieste di modifica devono essere immediatamente
            comunicate, pena l’accettazione incondizionata del Budget inserito.
            b) La stessa cosa potrebbe verificarsi agli Operatori del settore Wedding, i quali, potrebbero distrattamente
            digitare un importo differente da quello di loro intenzione (esempio: errore di battitura che potrebbe
            verificarsi “inserimento importo sbagliato”: si sarebbe voluto indicare un’ importo minimo di €. 1.000,00
            ed invece si è scritto €. 100,00), le richieste di modifica devono essere immediatamente comunicate, pena
            l’accettazione incondizionata dell’importo inserito.
            c) Il GESTORE a questo punto, solo per i casi di cui alla lettera a e b del predetto art. 3.3.7 e su esplicita
            richiesta
            degli Utenti, potrà apportare le modifiche richieste. Se l’errore fosse segnalato ad annuncio concluso, il
            GESTORE non potrà più apportare alcuna modifica e la stessa sarà ritenuta valida.
          </li>
          <li>
            3.3.8 Gli Utenti riconoscono la totale estraneità del GESTORE alle vicende dell’ annuncio e/o del Contratto,
            fatta
            esclusiva eccezione per quanto previsto sub 7 e subalterni in materia di segnalazioni di anomalie e/o criticità
            e
            riconoscono, in particolare, che il GESTORE non ha alcun onere di eseguire verifiche su eventuali anteriorità
            relative ai servizi/prodotti richiesti ed offerti.

          </li>
        </ul>
      </li>
      <li>
        3.4 Partecipare ad un’ annuncio con offerte al ribasso:
        <ul style="list-style-type: none">
          <li>

            3.4.1 Per poter prendere parte ad un’ annuncio, bisogna avere un Account come Operatore del Settore Wedding
            (Azienda
            titolare di P. iva);
            <ul style="list-style-type: none">
              <li>

                3.4.1.1 per avere un’ Account, bisogna registrarsi “gratuitamente” al PORTALE e completare il proprio
                profilo
                con tutti i dati richiesti, avendo cura di inserire anche i propri dati aziendali compreso la P. iva, questa
                servirà per poter poi generare il Contratto veroe proprio tra Voi e i futuri sposi che hanno creato l’
                annuncio a cui avete partecipato e che vi siete aggiudicati al prezzo più basso;
              </li>
              <li>
                3.4.1.2 una volta aver completato il proprio profilo personale, potrete cliccare sul tasto Annunci in
                Corso, lì
                potrete visionare tutti gli Annunci presenti nel PORTALE e filtrando la località e/o categoria potrete
                visionare solo quelli di vostro interesse;

              </li>
              <li>
                3.4.1.3 per poter prendere parte ad un’annuncio, l’ Operatore del Settore Wedding (Azienda) dovrà aver
                acquistato un abbonamento come meglio indicato al Titolo I° - Aspetti Generali e Definizioni art.
                1.2.10;
              </li>
              <li>
                3.4.1.4 Alla scadenza del termine indicato dai futuri sposi l’ annuncio sarà concluso e i futuri sposi
                potranno
                scegliere o meno l’ Operatore del Settore Wedding che al loro insindacabile giudizio rientra nei canoni
                di preferenza, senza precludere che l’offerta fatta possa essere più alta delle altre ma rientrante sempre
                nel budget indicato dai futuri sposi (clienti) nel proprio annuncio. La chiusura di un’annuncio sarà
                opportunamente segnalata.
              </li>
              <li>
                3.4.1.5 L’adesione e la partecipazione dell’ Operatore del Settore Wedding (azienda) ad una o più annunci è
                subordinata alla previa integrale ed incondizionata accettazione da parte di questi ultimi anche delle
                Condizioni Generali di Utilizzo delle Funzionalità, le quali, unitamente al Regolamento edalla Privacy,
                costituiscono l’intero accordo tra le parti in merito agli annunci. In ogni caso, il presente Regolamento
                e
                le Condizioni Generali di Utilizzo delle Funzionalità costituiscono l’unico ed esclusivo accordo tra
                GESTORE, futuri Sposi (clienti) e Operatori del Settore Wedding(aziende), per quanto di rispettiva
                competenza, in merito agli annunci.
              </li>
              <li>
                3.4.1.6 Fermo il divieto di cessione a terzi del proprio Account, così come meglio specificato al Titolo
                I° - Aspetti
                Generali e Definizioni art. 1.2.1 e seguenti, nessun Utente potrà, in alcun modo, trasferire a terzi alcun
                tipo di diritto e/o obbligo derivante da un’ annuncio.
              </li>
              <li>
                3.4.1.7 In particolare, salvo il preventivo consenso scritto della controparte interessata, nessun Utente
                potrà:
                a) fare eseguire a terzi (sub appalto), in tutto o in parte, il servizio richiesto in un annuncio, salvo
                successivi accordi diversi tra le parti;
                b) cedere a terzi, in tutto o in parte, un Contratto e/o i diritti oggetto dello stesso, compresi i
                relativi
                crediti (anche futuri), salvo successivi accordi diversi tra le parti.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>

        3.5 Gli Utenti riconoscono la totale estraneità del GESTORE alle vicende dell’ annuncio, fatta esclusiva eccezione
        di quanto
        previsto dai vari Art. e subalterni, così come meglio specificati al Titolo X° - Segnalazioni di Anomalie e/o
        Criticità e
        riconoscono, in particolare, che il GESTORE non ha alcun onere di eseguire verifiche su eventuali anteriorità
        relative ai
        servizi.
      </li>
      <li>
        3.6 L’Operatore del Settore Wedding, in caso di partecipazione ad un annuncio, si impegna parimenti, pena
        l’esclusione
        dalla stessa e/o la sospensione / cancellazione del proprio Account:
        <ul style="list-style-type: none">
          <li>
            3.6.1 a partecipare ad un’eventuale procedura di verifica supplementare dei requisiti oggettivi e/o
            soggettivi, richiesti
            all’atto della registrazione al PORTALE;
          </li>
          <li>
            3.6.2 ad accettare integralmente le condizioni dell’ annuncio, nonché a sottoscrivere, in caso di
            Aggiudicazione, un
            congruo ed autonomo Contratto ad hoc, con il Cliente futuro sposo, stipulato tramite appositi Strumenti, che
            determinerà:
            <ul style="list-style-type: none">
              <li>
                3.6.2.1 i rispettivi diritti, obblighi e doveri relativi alla prestazione contrattuale;
              </li>
              <li>
                3.6.2.2 termini e modalità di consegna del servizio/prodotto;
              </li>
              <li>
                3.6.2.3 ogni eventuale adempimento fiscale e/o previdenziale a carico delle parti, rispetto ai quali il
                GESTORE
                rimarrà estraneo e declinato da ogni responsabilità.
              </li>
              <li>
                3.6.2.4 Inoltre, ferma l’estraneità del GESTORE ai rapporti tra leparti, siccome “premessa alla lettera e”
                e al
                Titolo III° - Annunci(Creazione, Partecipazione, Funzionamento e Durata) art. 3 e seguenti,
                costituiscono il quadro normativo per la disciplina del Contratto, del quale fissano preventivamente il
                contenuto generale.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>

        3.7 L’ annuncio potrà essere aggiudicato ad un solo Operatore del Settore Wedding. Nel caso in cui, il vincitore
        dovesse
        essere escluso dall’ annuncio a causa di una delle violazioni del presente regolamento, la sua aggiudicazione
        ricadrà sulla
        nuova facoltà di scelta o meno da parte dei futuri Sposi (Clienti). I futuri Sposi verranno immediatamente
        avvisati tramite
        gli appositi strumenti rimanendo invariate le disposizioni di cui all’art. 3.3.6 del Titolo III° -
        Annunci (Creazione,
        Partecipazione, Funzionamento e Durata).
      </li>
      <li>
        3.8 In nessun caso i futuri Sposi (Clienti) e gli Operatori del settore Wedding (Azienda) potranno
        contrattare direttamente o
        indirettamente al di fuori del PORTALE e, comunque, senza l’ausilio degli Strumenti, la fornitura di un servizio o
        l’acquisto di un prodotto già oggetto di un annuncio concluso o in procinto di esserlo, ove ciò si verifichi, sarà
        comunque
        dovuta al GESTORE la Fee da parte dell’ Operatore del Settore Wedding (Azienda) con conseguente diffida, sospensione
        o chiusura del proprio Account per violazione del presente regolamento edell’ art. 5.4 del Titolo V° - Pagamenti
        al
        Gestore e tra gli utenti.
      </li>
    </ul>
    <h6>TITOLO IV° - DIRITTI E DOVERI</h6>
    <ul style="list-style-type: none">
      <li>

        4.1 L’ Operatore del Settore Wedding (Azienda), ha il dovere di garantire la conformità del servizio/prodotto agli
        standard
        richiesti nell’ annuncio aggiudicato.

      </li>
      <li>
        4.2 E’ dirito dei futuri Sposi (Clienti) di denunciare per iscritto al GESTORE, entro 7 giorni, eventuali
        difformità e/o vizi,
        difetti ed anomalie comunque riscontrati, successivamente all’esecuzione del servizio o alla consegna del
        prodotto;
      </li>
      <li>
        4.3 L’ Operatore del Settore Wedding (Azienda), ha il dovere di rimuovere tali difformità e/o vizi, difetti ed
        anomalie a
        proprie spese, compreso tutte quelle di ritiro e rispedizione;
      </li>
      <li>
        4.4 In ogni caso, il presente Regolamento costituisce l’unico ed esclusivo accordo tra futuri Sposi (Clienti) e
        Operatori del
        Settore Wedding (Aziende) sulle condizioni dell’ annuncio, sulla fornitura del servizio esulla conclusione dei
        successivi
        contratti.
      </li>
    </ul>
    <h6>TITOLO V° - PAGAMENTI AL GESTORE E TRA GLI UTENTI</h6>
    <ul style="list-style-type: none">
      <li>
        5.1 A conclusione dell’ annuncio inerente un servizio richiesto, i futuri Sposi (Clienti) verseranno all’
        Operatore del Settore
        Wedding (Azienda) aggiudicataria della stessa:
        <ul style="list-style-type: none">
          <li>
            5.1.1 la caparra confirmatoria come acconto pari al 25% del prezzo di chiusura annuncio (iva compresa).
          </li>
          <li>
            5.1.2 Il saldo verrà effettuato dai futuri Sposi direttamente all’Azienda nei tempi e nei modi indicati nel
            contratto
            stipulato dalle parti (es. fine ricevimento, fine servizio o alla consegna del Servizio/Prodotto).
          </li>
        </ul>
      </li>
      <li>
        5.2 Qualora l’oggetto dell’ annuncio fosse un prodotto, i futuri Sposi (Clienti) verseranno all’ Operatore del
        Settore Wedding
        (Azienda) in un’unica soluzione l’importo totale di chiusura annuncio.
      </li>
      <li>
        5.3 Ad annuncio concluso, l’ Operatore del Settore Wedding aggiudicatario dello stesso, verserà al GESTORE
        l’intera % Fee
        + iva (solo su fee) indicata nel tipo di abbonamento prescelto e sul prezzo totale di acquisto.
      </li>
      <li>
        5.4 Qualora non venissero rispettati gli Artt. 5.1; 5.3 e subalterni del predetto Titolo (Inadempimenti e
        Comportamenti
        considerati GRAVI) così come meglio specificato all’ art.1.2.16 delTitolo I° - Aspetti Generali e Definizioni,il
        GERSTORE potrà provvedere a suo insindacabile giudizio a:
        <ul style="list-style-type: none">
          <li>

            5.4.1 sospendere temporaneamente l’Account (fino a quando non verrà effettuato il pagamento);
          </li>
          <li>
            5.4.2 chiudere permanentemente l’Account;
          </li>
          <li>
            5.4.3 segnalare, a tutela della Community, tramite “feedback negativo” il comportamento scorretto e la
            violazione del
            presente regolamento, riservandosi di chiedere il maggior danno subito.
          </li>
        </ul>
      </li>
    </ul>

    <h6>TITOLO VI° - TUTELA TRA GLI UTENTI (RISOLUZIONI E PENALI)</h6>
    <ul style="list-style-type: none">
      <li>

        6.1 Nel caso in cui i futuri Sposi (Clienti), dopo aver concluso il contrato e versato la caparra confirmatoria
        pari al 25% del
        prezzo di chiusura annuncio (iva compresa), disdicano il servizio aggiudicato dall’ Operatore del Settore Wedding
        (Azienda), perderanno la caparra confirmatoria precedentemente versata e dovranno corrispondere le eventuali
        penali nei
        tempi e nei modi indicati incontratto. Viceversa se la parte inadempiente fosse l’Operatore del Settore Wedding,
        quest’ultimo dovrà versare il doppio della caparra già percepita, salvo quanto espressamente indicato nelle leggi
        vigenti.
      </li>
      <li>
        6.2 Risoluzione - Gli Utente (futuri Sposi (Clienti) e Operatori del Settore Wedding (Aziende) potranno risolvere
        unilateralmente i vari contratti qualora l’altra parte si sia resa inadempiente anche ad una soltanto delle
        pattuizioni
        previste, con effetto ai sensi dell’art. 1456 c.c., mediante comunicazione, inviata a mezzo raccomandata A.R. o
        altro
        mezzo idoneo per legge, che dia certezza della data.
      </li>
      <li>
        6.3 Penali - Nel caso in cui gli Utente (futuri Sposi (Clienti) e Operatori del Settore Wedding (Aziende) dovessero
        disdire un
        contrato precedentemente conclusosi a seguito di un annuncio, perderanno la caparra confirmatoria o il doppio
        della stessa
        precedentemente versata (dipende dalla parte inadempiente) e dovranno corrispondere le eventuali penali nei tempi
        e nei
        modi indicati in contratto, nonché poter richiedere un risarcimento per il maggior danno subito.
      </li>
      <li>
        6.4 Gli Utenti riconoscono la totale estraneità del GESTORE alle vicende nei punti sopra riportati ai fini
        dell’annuncio e/o
        del Contratto, il quale non sarà ritenuto responsabile per eventuali controversie che potrebbero insorgere tra gli
        Utenti
        registrati, e i pagamenti effettuati al GESTORE non potranno essere in alcun modo rimborsati.
      </li>
    </ul>
    <h6>TITOLO VII° - DIRITTO DI RECESSO O AL RIPENSAMENTO</h6>
    <ul style="list-style-type: none">
      <li>

        7.1 I futuri Sposi (Clienti), così come indicato nella
        direttiva europea sui diritti dei consumatori
        del 2011/83/UE e
        disciplinata nel decreto legislativo n. 21/2014, potranno avvalersi del diritto di ripensamento nei confronti dell’
        Operatori del Settore Wedding (Aziende), inviando una Raccomandata con R/R all’azienda aggiudicataria dell’
        annuncio
        e al GESTORE per conoscenza, nei tempi e nei modi indicati nella predetta direttiva.
      </li>
      <li>
        7.2 Ad eccezione per:
        <ul style="list-style-type: none">
          <li>

            7.2.1 i contratti di servizi dopo la completa prestazione del servizio se l’esecuzione è iniziata con
            l’accordo espresso del
            consumatore e con l’accettazione della perdita del diritto di recesso a seguito della piena esecuzione del
            contratto
            da parte del professionista;
          </li>
          <li>
            7.2.2 la fornitura di beni o servizi il cui prezzo è legato a fluttuazioni nel mercato finanziario che il
            professionista non è
            in grado di controllare e che possono verificarsi durante il periodo di recesso;
          </li>
          <li>
            7.2.3 la fornitura di beni confezionati su misura o chiaramente personalizzati;
          </li>
          <li>
            7.2.4 la fornitura di beni che rischiano di deteriorarsi o scadere rapidamente;
          </li>
          <li>
            7.2.5 la fornitura di beni sigillati che non si prestano adessere restituiti per motivi igienici o connessi
            alla protezione
            della salute e sono stati aperti dopo la consegna;
          </li>
          <li>
            7.2.6 la fornitura di beni che, dopo la consegna, risultano, per loro natura, inscindibilmente mescolati con
            altri beni;
          </li>
          <li>
            7.2.7 la fornitura di bevande alcoliche, il cui prezzo sia stato concordato al momento della conclusione del
            contratto di
            vendita, la cui consegna possa avvenire solo dopo trenta giorni e il cui valore effettivo dipenda da
            fluttuazioni sul
            mercato che non possono essere controllate dal professionista;
          </li>
          <li>
            7.2.8 i contratti in cui il consumatore ha specificamente richiesto una visita da parte del professionista ai
            fini
            dell’effettuazione di lavori urgenti di riparazione o manutenzione. Se, in occasione di tale visita, il
            professionista
            fornisce servizi oltre a quelli specificamente richiesti dal consumatore o beni diversi dai pezzi di ricambio
            necessari
            per effettuare la manutenzione o le riparazioni, il diritto di recesso si applica a tali servizi o beni
            supplementari;
          </li>
          <li>
            7.2.9 la fornitura di registrazioni audio o videosigillate o di software informatici sigillati che sono stati
            aperti dopo la
            consegna;
          </li>
          <li>
            7.2.10 la fornitura di giornali, periodici e riviste ad eccezione dei contratti di abbonamento per la fornitura
            di tali
            pubblicazioni;
          </li>
          <li>
            7.2.11 i contratti conclusi in occasione di un’asta pubblica;
          </li>
          <li>
            7.2.12 la fornitura di contenuto digitale mediante un supporto non materiale se l’esecuzione è iniziata con
            l’accordo
            espresso del consumatore e con la sua accettazione del fatto che in tal caso avrebbe perso il diritto di
            recesso.
          </li>
        </ul>
      </li>
      <li>
        7.3 Gli Utenti futuri Sposi (Clienti) e gli Operatori del Settore Wedding (Aziende) riconoscono la totale
        estraneità del
        GESTORE alle eventuali controversie che potrebbero insorgere tra di loro e sono consapevoli del fatto che i
        pagamenti
        effettuati al GESTORE a conclusione degli annunci non potranno essere in alcun modo rimborsati.
      </li>
    </ul>

    <h6>TITOLO VIII° - FUNZIONAMENTO DEL PORTALE</h6>
    <ul style="list-style-type: none">
      <li>
        8.1 In caso di offerte ritenute anomale per condizioni economiche e/o tecniche e/o temporali, nonché in caso di
        esito negativo
        delle eventuali procedure di identificazione degli Operatori del Settore Wedding, il GESTORE potrà interrompere
        un’
        annuncio, controllarne eventuali malfunzionamenti dei software, ripristinare il normale funzionamento.
        Qualora riscontrasse delle anomalie sulle funzionalità dei software, il GESTORE potrà a suo insindacabile giudizio
        sospendere/annullare un’annuncio fino a quando non verrà ripristinato il normale funzionamento, escludendo il
        GESTORE da qualsiasi responsabilità.
      </li>
    </ul>
    <h6>TITOLO IX° - FEEDBACK</h6>
    <ul style="list-style-type: none">
      <li>

        9.1 Ciascun Utente, Operatore del Settore Weddingo futuro sposo, accetta espressamente che la propria esecuzione
        del
        Contratto e/o, più in generale, la propria condotta nella partecipazione e/o pubblicazione di un annuncio sia
        sottoposta
        ad un’apposita procedura di feedback.
      </li>
      <li>
        9.2 Il complessivo punteggio di feedback di ciascun Utente è esclusivamente determinato dai voti espressi dagli
        Operatori
        del Settore Wedding(Aziende) e/o dai futuri sposi (Clienti) riguardo a:
        <ul style="list-style-type: none">
          <li>

            9.2.1 ciascun annuncio, sia alla partecipazione degli Operatori del Settore Wedding (Aziende) sia alla
            pubblicazione e/o
            gestione dei futuri sposi (Clienti);
          </li>
          <li>
            9.2.2 ciascun servizio/prodotto offerto, consegnato, in occasione di ciascuna annuncio.
          </li>
          <li>
            9.2.3 La reputazione serve a tutela delle parti e viene calcolata su una scala predeterminata di livelli da 1
            a 5 e illustrata
            con faccine, cuoricini e/o stelline, dove 5 (100%) è l’indice di gradimento massimo da poter attribuire
            all’utente
            finale, come da tabella sotto riportata:
            <img class="w-100" src="assets/images/privacytable.PNG">
          </li>
        </ul>
      </li>
      <li>
        9.3 Le condizioni e le modalità di espressione di tale feedback potranno variare e saranno specificate, di volta in volta, nel
        Portale.
      </li>
      <li>
        9.4 Ciascun Operatore consente che i dati sintetici relativi al proprio feedback siano resi accessibili agli altri Operatori del
        Settore Wedding (Aziende) e ai futuri sposi (Clienti) paradigmaticamente sotto forma di punteggio simbolico.
      </li>
      <li>
        9.5 Il GESTORE, in qualità di gestore e manutentore del PORTALE, non garantisce l’accuratezza e/o l’affidabilità di tali
        feedback e non potrà né dovrà, in ogni caso, modificare, in qualunque modo, il punteggio complessivo e/o un singolo
        feedback, salvo che non venga palesemente evidenziato l’errore.
      </li>
      <li>
        9.6 Ciascun Utente, pertanto, riconosce sin d’ora che in nessun caso il GESTORE potrà venire considerato responsabile per
        qualunque tipo di eventuale danno, diretto e/o indiretto, patrimoniale e/o non patrimoniale, riconducibile all’espressione,
        all’accessibilità e/o all’uso del feedback.
      </li>
      <li>
        9.7 In ogni caso, il GESTORE raccomanda agli Utenti la massima correttezza, buona fede e diligenza prima di esprimere un
        feedback, riservandosi di disporre la cancellazione di un Account in caso di abusi o commenti che pregiudichino e ledano
        l’integrità altrui.
      </li>
    </ul>
    <h6>TITOLO X° - SEGNALAZIONI DI ANOMALIE E/O CRITICITÀ</h6>
    <ul style="list-style-type: none">
      <li>
        10.1 Ciascun Utente potrà segnalare al GESTORE, tramite appositi Strumenti, una delle seguenti anomalie e/o criticità:
        <ul style="list-style-type: none">
          <li>
            10.1.1 violazione del presente Regolamento e/o di leggi;
          </li>
          <li>
            10.1.2 inadempimento e/o abuso relativo, a qualunque titolo, ad un’annuncio, all’esecuzione di un Contratto o
            pagamento;
          </li>
        </ul>

      </li>
      <li>
        10.2 Il GESTORE, in caso di una o più segnalazioni di cui all’ art. 10.1 e subalterni, si riserva la facoltà di:
        <ul>
          <li>
            10.2.1 richiamare via mail l’asserito responsabile dell’anomalia e/o criticità;
          </li>
          <li>
            10.2.2 sospendere e/o cancellare un annuncio;
          </li>
          <li>
            10.2.3 sospendere e/o escludere la pubblicazione e/o la partecipazione ad un annuncio;
          </li>
          <li>
            10.2.4 sospendere e/o cancellare un Account.
          </li>
        </ul>
      </li>
      <li>
        10.3 Le misure di cui sopra, di cui all’ 10.2 e subalterni, saranno adottate dal GESTORE in proporzione alla gravità della
        fattispecie oggettiva, all'atteggiamento dell'elemento soggettivo, all'eventuale ricorso di precedenti e ad ogni altra
        circostanza concreta rilevante.
      </li>
      <li>
        10.4 Gli Utenti, inoltre, riconoscono al GESTORE la facoltà di rendere visibile agli altri Utenti la pendenza di una segnalazione
        di cui all’ 10.1 e subalterni a tutela della Community.
      </li>
    </ul>
    <h6>TITOLO XI° - CONTENUTI</h6>
    <ul style="list-style-type: none">
      <li>
        11.1 Gli Utenti riconoscono di essere esclusivamente responsabili, sia nei confronti del GESTORE sia nei confronti degli altri
        Utenti e/o dei terzi, per i Contenuti pubblicati e/o gestiti tramite gli Strumenti a mezzo del proprio Account, impegnandosi
        rigorosamente affinché tali Contenuti non risultino, in generale, illeciti e/o, comunque, non violino il presente
        Regolamento.
      </li>
      <li>
        11.2 Fermo quanto sopra, sono comunque vietati, a titolo meramente esemplificativo, i seguenti Contenuti:
        <ul style="list-style-type: none">
          <li>

            11.2.1 illeciti, dannosi, minatori, abusivi, molesti, diffamatori e/o calunniosi, volgari, osceni, lesivi della privacy altrui,
            razzisti, xenofobi, classisti o comunque reprensibili;
          </li>
          <li>
            11.2.2 immagini, testi o quanto altro il cui contenuto è finalizzato allo sfruttamento sessuale o violento di persone,
            compresa la pubblicazione di foto pornografiche o pedopornografiche, offerte di prostituzione o altro invito a
            contenuto sessuale nonché link ai siti per soli adulti;
          </li>
          <li>
            11.2.3 propaganda, anche politico-sindacale, e/o l'utilizzo di simboli che siano considerati illegittimi dalle leggi italiane
            e/o del Paese dell’Utente;
          </li>
          <li>
            11.2.4 idonei ad arrecare danno, in qualsivoglia modo, a minori di età;
          </li>
          <li>
            11.2.5 volti a promuovere o porre in essere attività criminose tra cui la pedofilia, la truffa, i reati informatici, il traffico
            illecito di sostanze stupefacenti, comportamenti persecutori, gioco d'azzardo, riciclaggio, furto e commercio
            illecito, comprensivo di segreti industriali/commerciali, ecc.;
          </li>
          <li>
            11.2.6 pubblicità, materiale promozionale, "junk mail", "spam", catene di S. Antonio, piramidi, o qualsiasi altra forma
            di sollecitazione non autorizzate o non richieste;
          </li>
          <li>
            11.2.7 potenzialmente lesivi, in qualsiasi forma, della reputazione dello del GESTORE e/o di qualunque terzo, ad
            insindacabile giudizio dello stesso GESTORE;
          </li>
          <li>
            11.2.8 materiale che contenga virus, malware, trojan o qualsiasi altro codice, file o programma creato per interrompere,
            distruggere o limitare il funzionamento dei software, degli hardware o degli impianti di telecomunicazioni del
            GESTORE e/o di terzi;
          </li>
        </ul>

      </li>
      <li>
        11.3 Gli Utenti riconoscono che il GESTORE non è tenuto al controllo preventivo e/o successivo sui Contenuti inseriti dagli
        Utenti, ma ha comunque il diritto di prendere qualsiasi decisione riguardo l’adeguatezza di tali Contenuti, compresa la
        cancellazione degli stessi e/o degli Account ritenuti responsabili.
      </li>
      <li>
        11.4
        Fermo quanto sopra, il GESTORE non risponde in alcun modo degli eventuali danni diretti o indiretti, contrattuali e/o
        extracontrattuali, patrimoniali e non, causati dagli Utenti ad altri Utenti e/o a terzi in relazione ai Contenuti.
      </li>
      <li>
        11.5
        Il GESTORE,infine, non si assume alcuna responsabilità relativa alla divulgazione, duplicazione, modifica,
        rivisitazione,
        manomissione di Contenuti sensibili / privati / confidenziali relativi agli Utenti.
      </li>
    </ul>

    <h6>TITOLO XII° - USO DELL’ACCOUNT E DEGLI STRUMENTI</h6>
    <ul style="list-style-type: none">
      <li>

        12.1
        L'Utente, coerentemente con quanto previsto dal Titolo II° - Oggetto e durata art.2.3 e seguenti, dovrà modificare
        il
        proprio Account per garantire la correttezza, completezza e veridicità dei Dati ivi inseriti; dovrà, inoltre,
        modificare la
        propria password di autenticazione con le modalità e nei termini prescritti dal D. Lgs. 196/03 e comunque, potrà
        liberamente rinunciare all’uso degli Strumenti, tramite la cancellazione dell'Account stesso.
      </li>
      <li>
        12.2
        In caso di malfunzionamento del sistema di login, nonché del sospetto di utilizzo dei Dati e/o degli Account da
        parte di
        terzi, gli Utenti dovranno immediatamente inviare una segnalazione, al fine di consentire al GESTORE di risolvere
        il
        problema, ove del caso anche modificando o eliminando l'Account dell'Utente stesso, ferma la tutela, a cura e spesa
        dell’Utente, dei propri Dati, Contenuti e di qualunque altro diritto in qualsiasi modo riconducibile all’Account.
      </li>
      <li>
        12.3
        L’Utente dovrà attenersi strettamente alle modalità d’uso degli Strumenti, in qualsiasi forma determinate dal
        GESTORE.
      </li>
      <li>
        12.4
        Fermo quanto previsto al Titolo I° - Aspetti Generali e Definizioni art. 1.2.1 eseguenti, è esplicitamente vietato
        l’utilizzo di bot, spider, crawler e altri sistemi automatizzati analoghi. In particolare, è vietato:
        <ul style="list-style-type: none">
          <li>

            12.4.1
            utilizzare qualsiasi meccanismo automatico di raccolta o qualsiasi procedura manuale per monitorare o copiare
            le pagine web relative agli Strumenti, nonché ai Dati e/o ai Contenuti comunque pubblicati nel Sito;
          </li>
          <li>
            12.4.2
            è comunque vietato copiare, duplicare, modificare, adattare o comunque sfruttare a fini commerciali gli
            Strumenti, nonché i Contenuti e/o i Dati (in tutto o in parte), senza l’espressa autorizzazione del GESTORE.
          </li>
        </ul>
      </li>
      <li>

        12.5
        Gli Utenti non sono comunque autorizzati ad utilizzare gli Strumenti come una propria autonoma banca dati.
      </li>
      <li>
        12.6
        Gli Utenti non sono in alcun modo autorizzati ad utilizzare i Servizi con modalità che possano anche soltanto
        potenzialmente danneggiare, bloccare, appesantire o comunque pregiudicare le funzionalità dei server del GESTORE,
        e/o
        delle reti con cui le stesse sono connesse.
      </li>
      <li>
        12.7
        E’ rigorosamente vietata ogni attività di framing, hackering (tra cui, a titolo meramente esemplificativo:
        forzatura di
        credenziali, defacing, code injection,reverse engineering), phishing, spamming, harvesting e cloning sul PORTALE (e
        relativi Strumenti) e/o in relazione agli Account. Tale violazione comporterà l'immediata segnalazione alle
        Autorità
        competenti in sede civile, penale e amministrativa.
      </li>
      <li>
        12.8
        Fermo quanto previsto alla lettera “f” della premessa, dai vari Art. e subalterni, così come meglio specificati
        al Titolo
        X° - Segnalazioni di Anomalie e/o Criticità e dal Titolo XI° - Contenuti, il GESTORE potrà sospendere e/o chiudere
        e/o cancellare un Account, senza alcun preavviso, senza incorrere in penalità e/o sanzioni di sorta. A titolo
        esemplificativo,
        un Account potrà venire sospeso e/o cancellato nei seguenti casi, o analoghi:
        <ul style="list-style-type: none">
          <li>

            12.8.1
            violazione del Regolamento;
          </li>
          <li>
            12.8.2
            significativi indizi di violazione di norme, leggi e riciclaggio;
          </li>
          <li>
            12.8.3
            violazione e/o alterazione, in qualsiasi forma e con qualsiasi mezzo, delle procedure e/o dell’esito di
            un’annuncio;
          </li>
          <li>
            12.8.4
            qualora venisse fatto un uso improprio o un’abuso dei Feedback;
          </li>
          <li>
            12.8.5
            mancato pagamento dei relativi servizi offerti dal GESTORE e delle Fee;
          </li>
          <li>
            12.8.6
            mancati pagamenti tra gli Utenti.
          </li>
          <li>
            12.8.7
            inserimento di Contenuti non autorizzati, inadeguati o comunque illeciti, con particolare riferimento a quanto
            previsto dai vari Art. e subalterni del Titolo XI° - Contenuti;
          </li>
          <li>
            12.8.8
            violazione della netiquette, con particolare riferimento ad attività di flaming;
          </li>
          <li>
            12.8.9
            inserimento di Dati falsi, così come meglio indicato nell’ art. 2.3.2 e seguenti del Titolo II° - Oggetto
            edurata;
          </li>
          <li>
            12.8.10
            violazione dell’ art. 1.2.1 e seguenti del Titolo I° - Aspetti Generali e Definizioni;
          </li>
          <li>
            12.8.11
            inadempimenti e comportamenti considerati GRAVI dell’ art. 1.2.16 del Titolo I° - Aspetti Generali e
            Definizioni;
          </li>
          <li>
            12.8.12
            mancato utilizzo dell'Account e/o il mancato accesso (login) al PORTALE per un periodo superiorea sei (6)
            mesi.
          </li>
        </ul>
      </li>
    </ul>
    <h6>TITOLO XIII° - GARANZIE E RESPONSABILITÀ</h6>
    <ul style="list-style-type: none">
      <li>
        13.1
        Il presente Regolamento si riferisce esclusivamente agli Strumenti ed alle Funzionalità nello stato tecnologico e
        giuridico
        risultante al momento dell’accettazione dello stesso e delle sue successive modifiche. Il GESTORE e/o qualsiasi
        altra
        società a lui collegata non forniscono alcuna garanzia di idoneità per un particolare scopo, astenendosi dal
        promettere
        risultati specifici derivanti dall'uso degli Strumenti e delle Funzionalità.

      </li>
      <li>
        13.2
        In nessun caso il GESTORE e/o qualsiasi altra società a lui collegata potranno essere considerate responsabili per
        danni,
        oneri, e comunque pregiudizi di ogni tipo, anche indiretti, derivanti dall’uso, in qualsiasi forma, degli
        Strumenti da parte
        degli Utenti.
      </li>
      <li>
        13.3
        In considerazione delle caratteristiche degli Strumenti e delle Funzionalità, il GESTORE non garantisce che il
        loro
        funzionamento sia ininterrotto o privo di difetti (a titolo puramente esemplificativo: bug, incompatibilità con
        particolare
        software o hardware, ecc.). In particolare, il GESTORE non può essere ritenuto responsabile di problemi o guasti
        tecnici
        relativi a:
        <ul style="list-style-type: none">
          <li>
            13.3.1
            reti o linee telefoniche;
          </li>
          <li>
            13.3.2 sistemi informatici on-line, server o provider;
          </li>
          <li>
            13.3.3 apparecchiature informatiche;
          </li>
          <li>
            13.3.4 software, mancato funzionamento di programmi diposta elettronica odi riproduzione audio/videocausati da
            problemi tecnici o congestione del traffico su Internet.
          </li>
        </ul>
      </li>
      <li>
        13.4 Il GESTORE, inoltre - pur impegnandosi, perquanto di sua competenza, ad intervenire in tempi brevi per ovviare
        a
        qualsiasi malfunzionamento o disservizio degli Strumenti e/o delle Funzionalità personalmente rilevato e/o
        segnalato
        dagli Utenti - non può garantire che non si verifichino eventuali malfunzionamenti di tali Strumenti e/o
        Funzionalità tali
        da rendere gli stessi temporaneamente non usufruibili e da cagionare possibili errori, omissioni, interruzioni,
        cancellazioni, difetti, ritardi nel funzionamento o nella trasmissione, anomalie sulla linea, furto, distruzione,
        accesso non
        autorizzato o alterazione di comunicazioni degli Utenti, fermo quanto previsto dall’art. 31 del D. Lgs. 196/2003.
      </li>
      <li>
        13.5 In particolare, l’Utente non potrà ritenere responsabile il GESTORE a qualsiasi titolo, per un
        malfunzionamento degli
        Strumenti e/o delle Funzionalità anche qualora tale malfunzionamento possa determinare errori e/o ritardi in
        relazione
        agli annunci.
      </li>
      <li>
        13.6 Gli Utenti riconoscono la completa estraneità del GESTORE e/o di qualsiasi altra società collegata ad esso,
        inerente ai
        contenuti degli annunci, poiché l’attività del GESTORE consiste rispettivamente nell’implementare e nel gestire,
        mettere
        a disposizione degli Utenti e mantenere gli Strumenti e/o le Funzionalità nei termini e alle condizioni di cui al
        presente
        Regolamento e, limitatamente agli annunci, alle Condizioni Generali di Utilizzo delle Funzionalità. Per tale
        ragione, il
        GESTORE non può in alcun modo essere ritenuto responsabile per gli eventuali conflitti che dovessero insorgere fra
        due
        o più Utenti.
      </li>
    </ul>
    <h6>TITOLO XIV° - MANLEVA</h6>
    <ul style="list-style-type: none">
      <li>
        14.1 Ciascun Utente (anche comparendo in giudizio e consentendone l'estromissione ex art. 108 c.p.c.) manleverà il
        GESTORE, i loro amministratori, dipendenti e/o collaboratori, a qualsiasi titolo, da qualsiasi richiesta di
        risarcimento,
        indennizzo e/o altre responsabilità, compresi gli oneri di difesa legale in misura ragionevole, avanzati da altri
        Utenti e/o
        terzi in relazione all’uso degli Strumenti e/o delle Funzionalità e/o ai Contenuti pubblicati e/o alla
        partecipazione agli
        annunci nonché alla esecuzione delle prestazioni e alle vendite di prodotti.
      </li>
      <li>
        14.2 Ciascun Utente riconosce di beneficiare di un autonomo e congruo vantaggio e valore aggiunto con la
        Registrazione al
        PORTALE e/o l’uso degli Strumenti e/o delle Funzionalità, anche in virtù delle spese sostenute per la loro
        creazione dal
        GESTORE e per la manutenzione e la gestione degli stessi. Perciò l’Utente dichiara e garantisce al GESTORE il
        proprio
        interesse all'assunzione di tali manleve.
      </li>
    </ul>
    TITOLO XV° - MISCELLANEA
    <ul style="list-style-type: none">
      <li>
        15.1 Fermo restando quanto precisato al Titolo III° - Annunci (Creazione, Partecipazione, Funzionamento e Durata)
        art.
        3.4.1.6, il presente Regolamento e la Privacy - anche eventualmente di volta in volta modificati come indicato nel
        Regolamento stesso - costituiscono l'unico ed esclusivo accordo tra il GESTORE e gli Utenti relativo all’utilizzo
        degli
        Strumenti.
      </li>
      <li>
        15.2 Qualsiasi uso, di qualsivoglia durata e natura, degli Strumenti, verrà considerato come un consenso
        esplicito al
        Regolamento e alla Privacy.
      </li>
      <li>
        15.3 L'eventuale invalidità o comunque inefficacia di una qualsiasi delle previsioni del presente Regolamento, in
        ogni caso,
        lascerà intatte le altre pattuizioni giuridicamente e funzionalmente indipendenti, salvo comunque quanto previsto
        dell'art.
        1419, I° comma, Cod. Civ..
      </li>
      <li>
        15.4 Qualora una previsione del presente Regolamento fosse dichiarata o ritenuta illecita, inapplicabile o nulla,
        lo stesso dovrà
        essere corretto modificando tale previsione al fine di renderla legalmente valida e applicabile ed al contempo
        preservandone l’intento, o qualora ciò non fosse possibile, sostituendola con un’altra che sia valida e
        applicabile e che
        raggiunga il medesimo obiettivo.
      </li>
      <li>
        15.5 L'eventuale omissione di far valere uno o più dei diritti previsti dal presente Regolamento non potrà
        comunque essere
        intesa come definitiva rinuncia a tali diritti e non impedirà quindi di esigerne in qualsiasi altro momento il
        puntuale e
        rigoroso adempimento.
      </li>
      <li>

        15.6 Il presente Regolamento continuerà a produrre effetti in capo agli Utenti, anche successivamente alla
        cancellazione
        dell'Account, per tutte quelle clausole da cui sorgono obbligazioni destinate a sopravvivere alla cessazione della
        sua
        efficacia tra le parti.
      </li>
      <li>
        15.7 Qualsiasi comunicazione tra le parti richiesta o consentita dal presente Regolamento, ove non previsto
        diversamente, sarà
        eseguita, con i corrispondenti mezzi di comunicazione scritta, ai seguenti recapiti:
        Via Anguillara, 8 - 00048 Nettuno (RM)
        info@xloma.com
        xloma@pec.it
        15.8 Tutte le eventuali modifiche oggettive e/o soggettive del presente Regolamento, così come le sue eventuali
        integrazioni,
        dovranno essere espressamente convenute per iscritto e rese pubbliche tramite gli appositi strumenti.
      </li>
    </ul>

    <h6>TITOLO XVI° -LEGGE APPLICABILE, GIURISDIZIONE E FORO COMPETENTE</h6>
    <ul style="list-style-type: none">
      <li>

        16.1 Il presente Regolamento è regolato dalla Legge Italiana. Eventuali controversie che dovessero insorgere in
        relazione al
        contenuto del presente Regolamento, ovvero ogni questione relativa allasua esistenza, validità e/o efficacia, alla
        sua
        interpretazione, alla sua esecuzione, allasua risoluzione o cessazione per qualsiasi causa, ... ecc.
        e/o all'utilizzo degli
        Strumenti, nonché a qualunque elemento ricollegabile agli stessi, saranno devolute esclusivamente all’Autorità
        giudiziaria
        ordinaria e, specificamente, alla competenza esclusiva del Foro di Velletri, con esclusione di qualsiasi altro foro
        altrimenti
        competente.
      </li>
      <li>
        16.2 Qualora gli Utenti intendano a dire, tra di loro, all’Autorità giudiziaria ordinaria, il Foro competente è
        quello del luogo di
        residenza o di domicilio elettivo del Consumatore, inderogabile ai sensi dell’art. 33, comma 2, lett. u) del
        d.lgs. n. 206
        del 2005 e successive modifiche.
      </li>
    </ul>
    <h6>TITOLO XVII° - DISPOSIZIONI FINALI</h6>
    <ul style="list-style-type: none">
      <li>
        17.1 Perquanto non esplicitamente previsto da questo Regolamento, si applicano le disposizioni del Codice Civile e
        delle
        Leggi speciali in materia.
      </li>
    </ul>
    Nettuno, 27 giugno 2022

  </div>
</ng-template>
