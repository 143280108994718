import {Component, Input, OnInit} from '@angular/core';
import {IProduct} from '../../objects/IProduct';

@Component({
  selector: 'app-product-cart-item',
  templateUrl: './product-cart-item.component.html',
  styleUrls: ['./product-cart-item.component.scss']
})
export class ProductCartItemComponent implements OnInit {
  @Input() product: IProduct;
  @Input() isCart = false;
  @Input() iva: string;
  constructor() { }

  ngOnInit(): void {
  }

  calcPay(product: IProduct): string {
    let price = +product.pay * +product.quantity;
    if (product.useAdvance) {
      price = price * 0.3;
    }
    return price.toFixed(2);
  }

  calcIva(pay: string, iva: string) {
    return +pay * +iva / 100;
  }
}
