<div class="container">
  <div class="text-center mb-3">
    <b>FAQs</b>
  </div>
  <h4 class="text-center mb-3">
    Domande frequenti
  </h4>
  <h6 class="text-center mb-3">
    Hai domande? Siamo qui per aiutare
  </h6>

  <div class="row mb-5">
    <div class="col-12 col-md-8 offset-md-2">
      <input class="form-control" [(ngModel)]="search" placeholder="Cerca tra le domande">
    </div>
  </div>
  <ng-container *ngIf="questions | filter:'q':search as questionsFiltered">
    <ngb-accordion #a="ngbAccordion" [closeOthers]="true" *ngIf="questionsFiltered.length > 0 else noQuestions">
      <ngb-panel [id]="'panel-' + i" *ngFor="let question of questionsFiltered; let i = index">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between"
               (click)="a.toggle('panel-' + i)">
            <b>{{question.q}}</b>
            <button class="btn">
              <i class="fa fa-chevron-down" *ngIf="!opened"></i>
              <i class="fa fa-chevron-up" *ngIf="opened"></i>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
        <span [innerHTML]="question.a">
        </span>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-container>
  <ng-template #noQuestions>
    <div class="text-center">
      <label>Nessuna domanda trovata nei nostri archivi</label>
    </div>
  </ng-template>
  <br><br>
</div>
