<div class="row">
  <div class="col-auto py-3">
    <button class="btn btn-sm"  (click)="changeYear(false)">
      <i class="fa fa-arrow-left">

      </i>
    </button>
  </div>
  <div class="col text-center py-3">
    {{yearCurrent}}
  </div>
  <div class="col-auto py-3">

    <button class="btn btn-sm" (click)="changeYear(true)">
      <i class="fa fa-arrow-right">

      </i>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-3 py-3 text-center"
       [class.active]="month === monthSelected"
       [class.disabled]="checkDate(month)"
       *ngFor="let month of [0,1,2,3,4,5,6,7,8,9,10,11]" (click)="changeDate(month)" >
    {{monthNames[month]}}
  </div>
</div>
