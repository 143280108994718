import {Component, Input, OnInit} from '@angular/core';
import {PrivacyCookieComponent} from '../../components/privacy-cookie/privacy-cookie.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {jsPDF} from 'jspdf';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  @Input() showContainer = true;
  @Input() isModal = false;
  showVersions = false;
  isPrinting = false;
  constructor(public modalService: NgbModal,
              private httpService: HttpService
              ) {
  }

  ngOnInit(): void {
  }

  showPrivacy() {

    const rf = this.modalService.open(PrivacyCookieComponent, {size: 'lg', backdrop: 'static'});
    rf.componentInstance.onlyCookie = true;
    rf.componentInstance.saveEmitter.subscribe(save => {
      if (save) {
        this.httpService.saveCookie(JSON.parse(localStorage.getItem('cookieAccepted')))
          .subscribe();
      }
    });
  }

  print() {

    this.isPrinting = true;
    // setTimeout(() => {
      const doc = new jsPDF('p', 'px', 'a2');
      const stringHtml = document.getElementById('elementHtml');
      // html2canvas(stringHtml, {width: 1080, windowWidth: 1080, height: 4000, windowHeight: 4000}).then((result) => {
        doc.html(stringHtml.innerHTML, {windowWidth: 880, width: 880}).then(resp => {
          this.isPrinting = false;
          doc.save('WeddingSmart Informativa Privacy.pdf');
        });
      // });
    // }, 0);
  }
}
