import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {isPlatformBrowser} from "@angular/common";
import {CommunicatorService} from "./communicator.service";

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {

  constructor(private communicator: CommunicatorService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: object
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const paths = route.url.map(e => e.path);
    if (paths.includes('welcome')) {
      if (isPlatformBrowser(this.platformId) && window.location.href.includes('welcome/4rbh974g4fbf439')) {
        localStorage.setItem('bypassall', '1');
        this.communicator.showAll = true;
        return this.router.parseUrl('/');
      }
      return true;
    } else if (isPlatformBrowser(this.platformId)) {
      // if (window.location.href.includes('localhost:4200')) {
      //   this.communicator.showAll = true;
      // }
      if (!this.communicator.showAll && !localStorage.getItem('bypassall')) {
        console.log('redirection to welcome from gaurd service');
        return this.router.parseUrl('/welcome');
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
