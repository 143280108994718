<!--<div class="background-lightgray" *ngIf="user$ | async as user">-->
<!--  <div class="container">-->
<!--    <button class="btn btn-sm" (click)="useName = !useName">-->
<!--      <i *ngIf="useName" class="fa fa-check-circle color-primary fa-2x"></i>-->
<!--      <i *ngIf="!useName" class="fa fa-circle color-primary fa-2x"></i>-->
<!--    </button>-->
<!--    <label>{{user.fullname}}</label>-->
<!--  </div>-->
<!--</div>-->
<div class="container mb-5 mt-1">
  <div *ngIf="products$ | async as prod">
    <ng-container *ngIf="isOrderStore(prod) as prodStore">
      <app-review-item *ngFor="let item of prodStore.products; let i = index" [item]="item" [votes]="votes" [index]="i"
                       [reviews]="prodStore.reviews">
      </app-review-item>
    </ng-container>
    <ng-container *ngIf="isOrderAuction(prod) as prodAuction">
      <div class="row">
        <div class="col-3 text-right">
          <img class="max-height" [src]="'https://www.weddingsmart.it/api/images/offers/' + prodAuction.order.imgname">
        </div>
        <div class="col my-auto">
          <h5>{{prodAuction.order.title}}</h5>
          <i (click)="objVote.vote = i" *ngFor="let i of [1,2,3,4,5]" class="fa fa-star fa-2x"
             [ngClass]="{'yellow': i <= objVote.vote}"></i>
        </div>
      </div>

      <hr>
      <h5>Aggiungi un titolo</h5>
      <label for="title">Riassumi la tua recensione in una riga</label>
      <input id="title" [(ngModel)]="objVote.title" class="form-control"
             placeholder="Quali sono le cose più importanti da sapere">
      <hr>
      <h5>Aggiungi una recensione scritta</h5>
      <textarea id="desc" [(ngModel)]="objVote.desc" class="form-control" rows="4"
                placeholder="Che cosa ti è piaciuto e cosa non ti è piaciuto? Per cosa hai usato questo prodotto?"></textarea>
      <hr>

    </ng-container>
    <small *ngIf="prod.reviews && prod.reviews[0]" class="text-black-50">Ultima recensione effettuata il: {{prod.reviews && prod.reviews[0].timestamp | date:'dd/MM/yyyy'}}</small>
    <button class="btn btn-primary w-100 mt-3" (click)="sendReview(prod)" [disabled]="checkReview()">
      {{prod.reviews && prod.reviews[0] ? 'Modifica recensione' : 'Invia'}}
    </button>

  </div>
</div>
