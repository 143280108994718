<div class="background-lightgray">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-8">

        <h4>I miei preferiti
        </h4>
      </div>
      <div class="col text-right">
        <a routerLink="/">Home</a> / <a>Preferiti</a>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" style="overflow-x: hidden">

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-fill mt-2">
    <li [ngbNavItem]="0">
      <a ngbNavLink class="text-center pl-0 pr-0">
        Vetrine
      </a>
      <ng-template ngbNavContent>
        <div *ngIf="vetrine && vetrine.length > 0 else noRecords">
          <app-vetrina-element *ngFor="let vetrina of vetrine" [vetrina]="vetrina" [isRow]="isRow" favoriteTable="1" (reloadFavorite)="ngOnInit()"></app-vetrina-element>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="1">
      <a ngbNavLink class="text-center pl-0 pr-0">
        Store
      </a>
      <ng-template ngbNavContent>
        <div *ngIf="store && store.length > 0 else noRecords">
          <app-vetrina-element *ngFor="let storeItem of store" [vetrina]="storeItem" [isRow]="isRow" favoriteTable="2" (reloadFavorite)="ngOnInit()"></app-vetrina-element>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink class="text-center pl-0 pr-0">
        Prodotti
      </a>
      <ng-template ngbNavContent>
        <div class="row" *ngIf="products && products.length > 0 else noRecords">
          <div class="col-6 pl-1 pr-1 col-md-2" *ngFor="let product of products">
            <app-product-store-item [product]="product" [isFavorite]="true" (reloadFavorite)="ngOnInit()">

            </app-product-store-item>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>

  <ng-template #noRecords>
    <div class="alert alert-warning mt-3" role="alert">
      Nessun preferito salvato
    </div>
  </ng-template>
</div>
