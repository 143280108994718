import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IAuction, IOffer} from '../../../objects/IAuction';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommunicatorService} from '../../../services/communicator.service';
import {HttpService} from '../../../services/http.service';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss']
})
export class OfferCardComponent implements OnInit {
  @Input() offer: IOffer;
  @Input() auction: IAuction;
  @Input() isModal = false;
  @Input() images: {data: string}[];
  @Input() user: {isAdmin: string, isOperator: string, isUser: string, username: string};
  @Output() showModalOrder = new EventEmitter();
  @Output() acceptOffer = new EventEmitter();
  @Output() closePreview = new EventEmitter();
  percentage = '';
  constructor(private communicator: CommunicatorService, private httpService: HttpService) { }

  ngOnInit(): void {

    let percentage = String((((+this.offer.offer_num * 100) / +this.auction.budget_num) - 100).toFixed(2));
    percentage = percentage.replace('-', '');
    if (percentage.includes('.')) {
      percentage = percentage.replace('.', ',');
    }
    if (percentage.length > 3) {
      percentage = percentage.substr(0, 4);
    }
    this.percentage = '-' + percentage;

    if (this.offer.imgname) {
      this.offer.imgname = this.offer.imgname.replace('.jpeg', '.webp');
      this.offer.imgname = this.offer.imgname.replace('.jpg', '.webp');
      this.offer.imgname = this.offer.imgname.replace('.png', '.webp');
    }
    if (this.offer.imgname2) {
      this.offer.imgname2 = this.offer.imgname2.replace('.jpeg', '.webp');
      this.offer.imgname2 = this.offer.imgname2.replace('.jpg', '.webp');
      this.offer.imgname2 = this.offer.imgname2.replace('.png', '.webp');
    }
  }

  setVotes(vote: number) {

    this.communicator.checkUser$.subscribe(user => {
      if (user.isUser === '1' && user.username === this.auction.username) {
        if (vote === +this.offer.vote) {
          vote = 0;
        }
        this.httpService.setOfferVote(this.offer.offer_detail, vote).subscribe(() => {
          this.offer.vote = vote + '';
        })
      }
    });
  }

}
