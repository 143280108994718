<div class="list-grid row mt-2 ml-0 mr-0" [ngClass]="{'row-home': isHome}">


  <ng-container *ngIf="isVisualRow else itemGrid">
    <div *ngFor="let auction of auctions" class="col-12 col-md-6 mt-2 px-0 d-block d-md-none">
      <div class="card border-black height-card" [ngClass]="{'border-red': auction.id_offer_winner == null && auction.is_expired === '1' && (auction.offers === '0' || auction.refusedall === '1'),
     'border-orange' : auction.id_offer_winner == null && auction.is_expired === '1' && auction.offers !== '0' && auction.refusedall === '0'}">
        <div class="row no-gutters">
          <div class="col-5 col-md-4">
            <img class="w-100 height-card" [src]="'https://www.weddingsmart.it/api/images/auctions/' + (auction.imgname | getWebp)">
          </div>
          <div class="col">
            <div class="card-body position-relative pt-1 pr-0">
              <span class="badge badge-success">{{auction.offers}} Proposte</span>
              <span class="auction-code">Cod. {{auction.code}}</span>
              <div class="d-none d-md-block">
                <h5 class="card-title">{{auction.title}}</h5>
                <p class="card-text">Prezzo di partenza: {{auction.budget}} € </p>

                <ng-container *ngIf="auction.id_offer_winner == null else winnerOffer">
                  <ng-container *ngIf="auction.is_expired === '1' else noExpired">

                    <div class="row">
                      <div class="col-4 pl-0 pr-0 text-center au-closed">
                        <i class="text-orange far fa-check-circle fa-3x" *ngIf="auction.offers !== '0' && auction.refusedall === '0'"></i>
                        <i class="text-danger far fa-times-circle fa-3x" *ngIf="auction.offers === '0' || auction.refusedall === '1'"></i>
                        <h5 class="mt-2"
                            [ngClass]="{'text-orange': auction.offers !== '0' && auction.refusedall === '0',
                             'text-danger' : auction.offers === '0' || auction.refusedall === '1'}">
                          CONCLUSA</h5>
                      </div>
                      <div class="col text-center pl-0">
                        <label class="au-choose text-orange" *ngIf="auction.offers !== '0' && auction.refusedall === '0'">Gli sposi stanno decidendo
                          l'offerta vincente </label>

                        <label class="au-choose text-danger" *ngIf="auction.offers !== '0' && auction.refusedall === '1'">
                          Gli sposi non hanno accettato nessuna <br>offerta </label>

                        <label class="au-choose text-danger" *ngIf="auction.offers === '0'">Gli sposi non hanno
                          ricevuto
                          alcuna offerta </label>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #noExpired>
                    <div>
                      <small class="text-black-50 font-weight-bold">Scadenza:</small>
                      <small
                        class="font-weight-bold"> {{auction.expire | date:'dd/MM/yyyy HH:mm'}}</small>
                    </div>
                    <div>
                      <small class="text-black-50 font-weight-bold">Dove:</small>
                      <small class="font-weight-bold">
                        {{auction.city}}
                      </small>
                    </div>
                    <div>
                      <small class="text-black-50 font-weight-bold">Data nozze:</small>
                      <small class="font-weight-bold">
                        {{auction.wedding_date}}
                      </small>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-template #winnerOffer>
                  <div class="row">
                    <div class="col-4 pl-0 pr-0 text-center au-closed"><i
                      class="text-success far fa-check-circle fa-3x"></i>
                      <h5 class="text-success mt-2">CONCLUSA</h5></div>
                    <div class="col text-center pl-0">
                      <label class="au-choose">Gli sposi hanno scelto la proposta di <br>
                        <a [routerLink]="['/store', auction.macrosector, auction.sector, auction.code_online || auction.business_name]">{{auction['business_name']}}</a>
                      </label>
                    </div>
                  </div>
                </ng-template>

<!--                <div class="row">-->
<!--                  <div class="col-12 text-center">-->
<!--                    <button class="btn btn-primary" [routerLink]="'/auctions/detail/' + auction.id">-->
<!--                      Dettaglio Annuncio-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <div class="d-block d-md-none mt-body-1" [routerLink]="'/auctions/detail/' + auction.id">
                <h6 class="card-title">{{auction.title}}</h6>
                <small class="text-black-50 font-weight-bold">Budget:</small>
                <label class="font-weight-bold ml-1">
                  {{auction.budget_num | convertPrice:false:true}}
                </label><br>
                <div class="row mt-2"
                     *ngIf="auction.id_offer_winner === null && auction.is_expired === '1' && auction.offers === '0'">
                  <div class="col-4 pl-0 pr-0 text-center au-closed">
                    <i class="text-danger far fa-times-circle fa-3x"></i>
                    <h5 class="text-danger mt-2">CONCLUSA</h5>
                  </div>
                  <div class="col text-center pl-0">
                    <label class="au-choose text-danger">Gli sposi non hanno ricevuto nessuna <br>offerta </label>
                  </div>
                </div>
                <div class="row mt-2"
                     *ngIf="auction.id_offer_winner === null && auction.is_expired === '1' && auction.offers !== '0' && auction.refusedall === '0'">
                  <div class="col-4 pl-0 pr-0 text-center au-closed">
                    <i class="text-orange far fa-check-circle fa-3x"></i>
                    <h5 class="text-orange mt-2">CONCLUSA</h5>
                  </div>
                  <div class="col text-center pl-0">
                    <label class="au-choose text-orange">Gli sposi stanno decidendo l'offerta vincente </label>
                  </div>
                </div>
                <div class="row mt-2"
                     *ngIf="auction.id_offer_winner === null && auction.is_expired === '1' && auction.offers !== '0' && auction.refusedall === '1'">
                  <div class="col-4 pl-0 pr-0 text-center au-closed">
                    <i class="text-danger far fa-times-circle fa-3x"></i>
                    <h5 class="text-danger mt-2">CONCLUSA</h5>
                  </div>
                  <div class="col text-center pl-0">
                    <label class="au-choose text-danger">Gli sposi non hanno accettato nessuna offerta </label>
                  </div>
                </div>
                <ng-container *ngIf="auction.id_offer_winner === null && auction.is_expired !== '1'">
                  <div>

                    <small class="text-black-50 font-weight-bold">
                      Scadenza:
                    </small>
                    <small class="font-weight-bold">
                      {{auction.expire | date:'dd/MM/yyyy HH:mm'}}
                    </small>
                  </div>
                  <div style="margin-top: -8px;">
                    <small class="text-black-50 font-weight-bold">Dove: </small><small class="font-weight-bold">
                    {{auction.city}}
                  </small>
                  </div>

                  <div style="margin-top: -8px;">
                    <small class="text-black-50 font-weight-bold">Data nozze: </small>
                    <small class="font-weight-bold">
                      {{auction.wedding_date}}
                    </small>
                  </div>
                </ng-container>

                <div class="row" *ngIf="auction.id_offer_winner !== null">
                  <div class="col-4 pl-0 pr-0 text-center au-closed">
                    <i class="text-success far fa-check-circle fa-3x"></i>
                    <h5 class="text-success mt-2">CONCLUSA</h5></div>
                  <div class="col text-center pl-0">
                    <label class="au-choose">Gli sposi hanno scelto la proposta di
                      <br>
                      <a [routerLink]="['/store', auction.macrosector, auction.sector, auction.code_online || auction.business_name]">{{auction.business_name}}</a>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 d-none d-md-block px-0">
      <table class="table">
        <thead>
        <tr>
          <td width="200">Categoria</td>
          <td>Titolo annuncio</td>
          <td width="110">Località nozze</td>
          <td width="130">Budget sposi</td>
          <td width="200">Miglior proposta</td>
          <td>Proposte</td>
          <td>Annuncio</td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let auction of auctions">
          <tr>
            <td class="text-center">
              <label class="color-auction-code">{{auction.macrosector}} - {{auction.sector}}</label>
              <img class="w-100" [src]="'https://www.weddingsmart.it/api/images/auctions/' + auction.imgname">
            </td>
            <td style="vertical-align: middle">

              <h6>{{auction.title}}</h6>
              <label class="color-auction-code font-weight-bold">Cod. {{auction.code}}</label>
            </td>
            <td class="text-center" style="vertical-align: middle">
              {{auction.city}}
            </td>
            <td class="text-center" style="vertical-align: middle">
              <label class="price" style="margin-left: 4px;"
                     [style.font-size.px]="16">
                {{auction.budget_num | convertPrice:false:true}}</label>
            </td>
            <td class="text-center" style="vertical-align: middle">
              <label *ngIf="auction.offers === '0'">Nessuna proposta</label>
              <label *ngIf="auction.offers !== '0'">{{auction.offer_min | convertPrice:false:true}}</label>
            </td>
            <td style="vertical-align: middle" class="text-center">
              {{auction.offers}}
            </td>
            <td style="vertical-align: middle" class="text-center" [colSpan]="auction.is_expired === '0' && auction.id_offer_winner == null ? '2' : '1'">
              <div class="text-center " *ngIf="(auction.is_expired === '1' || auction.id_offer_winner) != null else isOpen"
                   [class.text-success]="auction.id_offer_winner != null"
                   [class.text-danger]="auction.id_offer_winner == null && (auction.offers === '0' || auction.refusedall === '1')"
                   [class.text-orange]="auction.id_offer_winner == null && auction.offers !== '0' && auction.refusedall === '0'">
                <i class=" far fa-check-circle fa-3x"
                   *ngIf="(auction.id_offer_winner == null && auction.offers > '0' && auction.refusedall === '0' ) || auction.id_offer_winner != null"></i>
                <i class=" far fa-times-circle fa-3x"
                   *ngIf="auction.id_offer_winner == null && (auction.offers === '0' || auction.refusedall === '1')"></i>
                <br>
                CONCLUSA
              </div>
              <ng-template #isOpen>

                <small class="text-black-50 font-weight-bold"> Scade il <br></small>
                <small class="font-weight-bold">{{auction.expire | date:'dd/MM/yyyy'}}
                  alle {{auction.expire | date:'HH:mm'}}</small>
              </ng-template>
            </td>
            <td style="vertical-align: middle" class="text-center" *ngIf="auction.is_expired === '1' || auction.id_offer_winner != null">
              <div *ngIf="auction.id_offer_winner === null else winner">

                <label class="au-choose text-orange" *ngIf="auction.offers !== '0' && auction.refusedall === '0'">
                  Gli sposi stanno decidendo l'offerta vincente </label>

                <label class="au-choose text-danger" *ngIf="auction.offers === '0'">
                  Gli sposi non hanno ricevuto nessuna <br>offerta </label>

                <label class="au-choose text-danger" *ngIf="auction.offers !== '0'  && auction.refusedall === '1'">
                  Gli sposi non hanno accettato nessuna offerta </label>
              </div>
              <ng-template #winner>
                <label class="au-choose">Gli sposi hanno scelto la proposta di <br>
                  <a [routerLink]="['/store', auction.macrosector, auction.sector, auction.code_online || auction.business_name]">{{auction['business_name']}}</a>
                </label>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td colspan="1000" class="py-0 px-0">
              <div class="row no-gutters py-2" style="background: rgb(222, 226, 230)">
                <div class="col-3">
                  <button class="btn btn-link">
                    <i class="fa fa-eye"></i> Osserva
                  </button>
                </div>
                <div class="col-3 text-center my-auto">
                  <span>Data nozze: <span class="color-primary">{{auction.wedding_date}}</span></span>
                </div>
                <div class="col-3 text-center my-auto">
                  <span>Annuncio creato da {{auction.fullname}}</span>
                </div>
                <div class="col-3 text-right pr-2" [routerLink]="'/auctions/detail/' + auction.id">
                  <button class="btn btn-primary">Dettaglio annuncio</button>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>
  <ng-template #itemGrid>
    <ng-container *ngIf="!isHome">

      <div *ngFor="let auction of auctions" class="col-12 col-md-6 mt-2 px-0 d-none d-md-block">
        <div class="card border-black" [ngClass]="{'border-red': auction.id_offer_winner == null && auction.is_expired === '1' && (auction.offers === '0' || auction.refusedall === '1'),
     'border-orange' : auction.id_offer_winner == null && auction.is_expired === '1' && auction.offers !== '0' && auction.refusedall === '0'}">
          <div class="row no-gutters">
            <div class="col-5 col-md-4">
              <img class="w-100 height-card" [src]="'https://www.weddingsmart.it/api/images/auctions/' + auction.imgname">
            </div>
            <div class="col">
              <div class="card-body position-relative pt-1 pr-0">
                <span class="badge badge-success">{{auction.offers}} Proposte</span>
                <span class="auction-code">Cod. {{auction.code}}</span>
                <div class="d-none d-md-block">
                  <h5 class="card-title">{{auction.title}}</h5>
                  <p class="card-text">Prezzo di partenza: {{auction.budget}} € </p>

                  <ng-container *ngIf="auction.id_offer_winner == null else winnerOffer">
                    <ng-container *ngIf="auction.is_expired === '1' else noExpired">

                      <div class="row">
                        <div class="col-4 pl-0 pr-0 text-center au-closed">
                          <i class="text-orange far fa-check-circle fa-3x" *ngIf="auction.offers !== '0' && auction.refusedall === '0'"></i>
                          <i class="text-danger far fa-times-circle fa-3x" *ngIf="auction.offers === '0' || auction.refusedall === '1'"></i>
                          <h5 class="mt-2"
                              [ngClass]="{'text-orange': auction.offers !== '0' && auction.refusedall === '0',
                               'text-danger' : auction.offers === '0' || auction.refusedall === '1'}">
                            CONCLUSA</h5>
                        </div>
                        <div class="col text-center pl-0">
                          <label class="au-choose text-orange" *ngIf="auction.offers !== '0' && auction.refusedall === '0'">Gli sposi stanno decidendo
                            l'offerta vincente </label>
                          <label class="au-choose text-danger" *ngIf="auction.offers === '0'">Gli sposi non hanno
                            ricevuto
                            nessuna offerta </label>
                          <label class="au-choose text-danger" *ngIf="auction.offers !== '0' && auction.refusedall === '1'">Gli sposi non hanno
                            accettato
                            nessuna offerta </label>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #noExpired>
                      <small class="text-black-50 font-weight-bold">Scadenza:</small>
                      <small
                        class="font-weight-bold"> {{auction.expire | date:'dd/MM/yyyy HH:mm'}}</small>
                      <br>
                      <small class="text-black-50 font-weight-bold">Dove:</small>
                      <small class="font-weight-bold">
                        {{auction.city}}
                      </small>
                      <br>
                      <small class="text-black-50 font-weight-bold">Data nozze:</small>
                      <small class="font-weight-bold">
                        {{auction.wedding_date}}
                      </small>
                    </ng-template>
                  </ng-container>
                  <ng-template #winnerOffer>
                    <div class="row">
                      <div class="col-4 pl-0 pr-0 text-center au-closed"><i
                        class="text-success far fa-check-circle fa-3x"></i>
                        <h5 class="text-success mt-2">CONCLUSA</h5></div>
                      <div class="col text-center pl-0">
                        <label class="au-choose">Gli sposi hanno scelto la proposta di <br>
                          <a  [routerLink]="['/store', auction.macrosector, auction.sector, auction.code_online || auction.business_name]">{{auction.business_name}}</a>
                        </label>
                      </div>
                    </div>
                  </ng-template>

                  <div class="row">
                    <div class="col-12 text-center">
                      <button class="btn btn-primary" [routerLink]="'/auctions/detail/' + auction.id">
                        Dettaglio Annuncio
                      </button>
                    </div>
                  </div>
                </div>
                <div class="d-block d-md-none mt-body-1" [routerLink]="'/auctions/detail/' + auction.id">
                  <h6 class="card-title">{{auction.title}}</h6>
                  <small class="text-black-50 font-weight-bold">Budget:</small>
                  <label class="font-weight-bold ml-1">
                    {{auction.budget_num | convertPrice:false:true}}
                  </label><br>
                  <div class="row"
                       *ngIf="auction.id_offer_winner === null && auction.is_expired === '1' && auction.offers === '0'">
                    <div class="col-4 pl-0 pr-0 text-center au-closed">
                      <i class="text-danger far fa-times-circle fa-3x"></i>
                      <h5 class="text-danger mt-2">CONCLUSA</h5>
                    </div>
                    <div class="col text-center pl-0">
                      <label class="au-choose text-danger">Gli sposi non hanno ricevuto nessuna<br> offerta </label>
                    </div>
                  </div>
                  <div class="row"
                       *ngIf="auction.id_offer_winner === null && auction.is_expired === '1' && auction.offers !== '0' && auction.refusedall === '0'">
                    <div class="col-4 pl-0 pr-0 text-center au-closed">
                      <i class="text-orange far fa-check-circle fa-3x"></i>
                      <h5 class="text-orange mt-2">CONCLUSA</h5>
                    </div>
                    <div class="col text-center pl-0">
                      <label class="au-choose text-orange">Gli sposi stanno decidendo l'offerta vincente </label>
                    </div>
                  </div>
                  <div class="row"
                       *ngIf="auction.id_offer_winner === null && auction.is_expired === '1' && auction.offers === '0' && auction.refusedall === '1'">
                    <div class="col-4 pl-0 pr-0 text-center au-closed">
                      <i class="text-danger far fa-times-circle fa-3x"></i>
                      <h5 class="text-danger mt-2">CONCLUSA</h5>
                    </div>
                    <div class="col text-center pl-0">
                      <label class="au-choose text-danger">Gli sposi non hanno accettato nessuna offerta </label>
                    </div>
                  </div>
                  <ng-container *ngIf="auction.id_offer_winner === null && auction.is_expired !== '1'">

                    <small class="text-black-50 font-weight-bold">
                      Scadenza:
                    </small>
                    <small class="font-weight-bold">
                      {{auction.expire | date:'dd/MM/yyyy HH:mm'}}
                    </small><br>
                    <small class="text-black-50 font-weight-bold">Dove: </small><small class="font-weight-bold">
                    {{auction.city}}
                  </small><br>
                    <small class="text-black-50 font-weight-bold">Data nozze: </small>
                    <small class="font-weight-bold">
                      {{auction.wedding_date}}
                    </small>
                  </ng-container>

                  <div class="row" *ngIf="auction.id_offer_winner !== null">
                    <div class="col-4 pl-0 pr-0 text-center au-closed">
                      <i class="text-success far fa-check-circle fa-3x"></i>
                      <h5 class="text-success mt-2">CONCLUSA</h5></div>
                    <div class="col text-center pl-0">
                      <label class="au-choose">Gli sposi hanno scelto la proposta di
                        <br>
                        <a [routerLink]="['/store', auction.macrosector, auction.sector, auction.code_online || auction.business_name]">{{auction.business_name}}</a>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngFor="let auction of auctions"
         class="col-6 col-sm-4 col-md-3 col-lg-2 mt-1" [ngClass]="{'pr-1 pl-0': !isHome, 'px-1': isHome}"
         [class.d-block]="!isHome" [class.d-md-none]="!isHome" [class.min-width]="isHome"
    >
      <div class="card w-100 is-card border-black"
           [ngClass]="{'border-red': !isHome &&  auction.id_offer_winner == null && auction.is_expired === '1' && (auction.offers === '0' || auction.refusedall === '1'),
            'border-orange' : !isHome && auction.id_offer_winner == null && auction.is_expired === '1' && auction.offers !== '0' && auction.refusedall === '0',
            'card-home': isHome}"
           [routerLink]="'/auctions/detail/' + auction.id">
        <div class="row my-1" *ngIf="isHome">
          <div class="col-4 my-auto pr-1">
            <img class="img rounded-circle w-100" [src]="auction.imguser ?
               'https://www.weddingsmart.it/api/images/users/' + (auction.imguser | getWebp) + '?id=' + idRandom : 'https://www.weddingsmart.it/assets/images/user.png'"
                 width="70">
          </div>
          <div class="col-8 pl-0 my-auto">
            <span style="font-size: 14px;">{{auction.fullname | truncFullname}}</span>
          </div>
        </div>
        <div class="card-img-top text-center">
          <img class="my-auto" [src]="'https://www.weddingsmart.it/api/images/auctions/' + (auction.imgname | getWebp)">
        </div>
        <div class="card-body position-relative pl-1 pr-1 pt-1 pt-md-3 flex-grow-0"
             [ngClass]="{'pb-0': isHome, 'pb-1': !isHome}">
          <span class="badge badge-success">{{auction.offers}} Proposte</span>
          <span class="auction-code mr-0" *ngIf="!isHome">Cod. {{auction.code}} </span>
          <div class="d-block mt-body-1">
            <h6 class="card-title"> {{auction.title}} </h6>
            <div class="row " [ngClass]="{'height-body-card': !isHome}">
              <div class="col-12">
                <small class="text-black-50 font-weight-bold priceLabel">Budget:</small>
                <label class="font-weight-bold mb-0 price" style="margin-left: 4px;"
                       [style.font-size.px]="isHome ? '18' : '16'">
                  {{auction.budget_num | convertPrice:false:true}}</label>
                <ng-container *ngIf="!isHome">
                  <br>
                  <ng-container *ngIf="auction.id_offer_winner == null else winnerOffer">
                    <ng-container *ngIf="auction.is_expired === '1' else noExpired">
                      <div class="row mt-4">

                        <div class="col-5 pr-0 text-right au-closed">
                          <i class="text-orange far fa-check-circle fa-3x" *ngIf="auction.offers !== '0' && auction.refusedall === '0'"></i>
                          <i class="text-danger far fa-times-circle fa-3x" *ngIf="auction.offers === '0' || auction.refusedall === '1'"></i>
                        </div>
                        <div class="col-7 my-auto au-closed">
                          <h5 class="mt-2"
                              [ngClass]="{'text-orange': auction.offers !== '0'  && auction.refusedall === '0',
                               'text-danger' : auction.offers === '0' || auction.refusedall === '1'}">
                            CONCLUSA</h5>
                        </div>
                        <div class="col-12 text-center">
                          <label class="au-choose text-orange" *ngIf="auction.offers !== '0' && auction.refusedall === '0'">Gli sposi stanno
                            decidendo
                            l'offerta vincente </label>
                          <label class="au-choose text-danger" *ngIf="auction.offers === '0'">Gli sposi non hanno
                            ricevuto
                            nessuna offerta </label>
                          <label class="au-choose text-danger" *ngIf="auction.offers !== '0' && auction.refusedall === '1'">Gli sposi non hanno
                            accettato
                            nessuna offerta </label>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #noExpired>
                      <small class="text-black-50 font-weight-bold">Dove: </small>
                      <small class="font-weight-bold">{{auction.city}}</small>
                      <br>
                      <small class="text-black-50 font-weight-bold">Data nozze: </small>
                      <small class="font-weight-bold"> {{auction.wedding_date}} </small>
                      <div class="row">
                        <div class="col-12 text-center">
                          <small class="text-black-50 font-weight-bold"> Scade il <br></small>
                          <small class="font-weight-bold">{{auction.expire | date:'dd/MM/yyyy'}}
                            alle {{auction.expire | date:'HH:mm'}}</small>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-template #winnerOffer>
                    <div class="row">
                      <div class="col-5 pr-0 text-right au-closed">
                        <i class="text-success far fa-check-circle fa-3x"></i>
                      </div>
                      <div class="col-7 my-auto au-closed">
                        <h5 class="text-success mt-2">CONCLUSA</h5>
                      </div>
                      <div class="col-12 text-center">
                        <label class="au-choose">Gli sposi hanno scelto la proposta di <br>
                          <a [routerLink]="['/store', auction.macrosector, auction.sector, auction.code_online || auction.business_name]">{{auction.business_name}}</a></label>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
