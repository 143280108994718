<div class="container-fluid mb-5">
  <div id="card-info">
    <h2 class="text-center">Le mie nozze</h2>
    <!-- Progress bar 1 -->
    <ng-container *ngIf="[0,2].includes(activeId)">

      <h4 class="text-center" id="text-0">Il mio budget
        <i *ngIf="!showEditBudget" id="icon-pen" class="color-primary fa fa-edit" (click)="showEditBudget = true;"></i>
      </h4>

      <div class="progress" *ngIf="budget && !showEditBudget">
        <round-progress [current]="budget.budget" [max]="budget.budget" color="#E442BA" [stroke]="8"
                        [style.width.px]="(isMobile) ? 150 : 200"
        ></round-progress>
        <div class="progressbar-text">{{(budget.budget + '') | convertPrice:false:true}}</div>
      </div>
      <div id="row-budget" class="input-group" *ngIf="showEditBudget">
        <input class="form-control" placeholder="Inserisci qui il tuo budget" id="budget-saved"
               [value]="transformBudget()"
               #element
               (keydown)="onlyPrice($event)" (keyup)="checkPrice(element)">
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" (click)="saveBudget(element.value)"><i class="fa fa-save"> </i>
            Salva
          </button>
        </div>
      </div>
      <div class="row text-center mt-4">
        <div class="col-6">
          <h6 class="text-center" id="text-1">Budget consumato</h6>
          <div class="progress2" *ngIf="budget">
            <round-progress [current]="budget.budget_used" [max]="budget.budget" color="#E442BA" [stroke]="8"
                            [style.width.px]="(isMobile) ? 100 : 150"
            ></round-progress>
            <div class="progressbar-text">{{(budget.budget_used + '') | convertPrice:false:true}}</div>
          </div>
        </div>
        <div class="col-6">
          <h6 class="text-center" id="text-2">Budget rimasto</h6>
          <div class="progress3" *ngIf="budget">
            <round-progress [current]="budget.budget - budget.budget_used" [max]="budget.budget" color="#E442BA"
                            [stroke]="8"
                            [style.width.px]="(isMobile) ? 100 : 150"
            ></round-progress>
            <div class="progressbar-text">{{(budget.budget - budget.budget_used + '') | convertPrice:false:true}}</div>
          </div>
        </div>
      </div>

      <div class="row text-center mt-4" id="row-annunci" *ngIf="(auctions | counterAuctions) as counter">
        <div class="col-4 text-center p-1 cursor-pointer"
             (click)="filterTypeAuctions = (filterTypeAuctions === 0) ? null : 0"
             [class.color-primary]="filterTypeAuctions === 0">
          <div class="h4 font-weight-bold mb-0">{{counter.inCorso}}</div>
          <span class="small text-gray">Annunci In corso</span>
        </div>
        <div class="col-4 text-center p-1 cursor-pointer"
             (click)="filterTypeAuctions = (filterTypeAuctions === 1) ? null : 1"
             [class.color-primary]="filterTypeAuctions === 1">
          <div class="h4 font-weight-bold mb-0" id="annunciSP">{{counter.senzaProposte}}</div>
          <span class="small text-gray">Annunci Conclusi Senza Proposte</span>
        </div>
        <div class="col-4 text-center p-1 cursor-pointer"
             (click)="filterTypeAuctions = (filterTypeAuctions === 2) ? null : 2"
             [class.color-primary]="filterTypeAuctions === 2">
          <div class="h4 font-weight-bold mb-0" id="annunciPA">{{counter.conclusi}}</div>
          <span class="small text-gray">Annunci Conclusi Proposte Accettate</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activeId === 1 && auctionsFinal">
      <div class="position-relative">

        <h4 class="text-center">Hai risparmiato
          <i class="color-primary fa fa-info-circle" (click)="showRisparmio = true"></i>
        </h4>
        <div class="progress" *ngIf="(auctionsFinal.auctions | counterAcquisti:auctionsFinal.orders) as counter">
          <round-progress [current]="budget.budget" [max]="budget.budget" color="#E442BA" [stroke]="8"
                          [style.width.px]="(isMobile) ? 150 : 200"
          ></round-progress>
          <div class="progressbar-text">
            <label class="percentProgress">{{+budget.budget !== 0 ? (-(((counter.costTotal * 100) / budget.budget) - 100)).toFixed(0) : 0}}
              %</label>
            <label
              class="textProgress text-dark width-120">
              {{((budget.budget - counter.costTotal).toFixed(2)) | convertPrice:false:true}}
            </label>
          </div>
        </div>
        <div class="row text-center mt-4">
          <div class="col-6">
            <h6 class="text-center">Il mio budget</h6>
            <div class="progress2" *ngIf="budget">
              <round-progress [current]="budget.budget" [max]="budget.budget" color="#E442BA" [stroke]="8"
                              [style.width.px]="(isMobile) ? 100 : 150"
              ></round-progress>
              <div class="progressbar-text">{{(budget.budget + '') | convertPrice:false:true}}</div>
            </div>
          </div>
          <div class="col-6">
            <h6 class="text-center">Totale acquisti</h6>
            <div class="progress3" *ngIf="(auctionsFinal.auctions | counterAcquisti:auctionsFinal.orders) as counter">
              <round-progress [current]="counter.costTotal" [max]="counter.costTotal" color="#E442BA"
                              [stroke]="8"
                              [style.width.px]="(isMobile) ? 100 : 150"
              ></round-progress>
              <div class="progressbar-text">{{(counter.costTotal + '') | convertPrice:false:true}}</div>
            </div>
          </div>
        </div>
        <div class="row text-center mt-4" id="row-acquisti"
             *ngIf="(auctionsFinal.auctions | counterAcquisti:auctionsFinal.orders) as counter">
          <div class="col-4 text-center p-1" id="filter0" (click)="filterType = (filterType === 0) ? null : 0"
               [class.color-primary]="filterType === 0">
            <div class="h4 font-weight-bold mb-0 cursor-pointer">{{counter.daPagare}}</div>
            <span class="small text-gray">Da pagare</span>
          </div>
          <div class="col-4 text-center p-1 cursor-pointer" id="filter1"
               (click)="filterType = (filterType === 1) ? null : 1"
               [class.color-primary]="filterType === 1">
            <div class="h4 font-weight-bold mb-0">{{counter.pagati}}</div>
            <span class="small text-gray">Pagati</span>
          </div>
          <div class="col-4 text-center p-1 cursor-pointer" id="filter2"
               (click)="filterType = (filterType === 2) ? null : 2"
               [class.color-primary]="filterType === 2">
            <div class="h4 font-weight-bold mb-0">{{counter.spediti}}</div>
            <span class="small text-gray">Spediti</span>
          </div>
        </div>
        <div id="modal-risparmio" *ngIf="showRisparmio">
          <div class="container p-0">
            <div class="border rounded">

              <div class="modal-header p-1">
                <h5 class="text-center w-100">DETTAGLI <i (click)="showRisparmio = false"
                                                          class="fa fa-times color-primary float-right mt-1"></i></h5>
              </div>
              <div class="modal-body bg-lightgrey"
                   *ngIf="(auctionsFinal.auctions | counterAcquisti:auctionsFinal.orders) as counter">
                <div class="row">
                  <div class="col pl-1 pr-0 text-left">
                    <label class="text-black-50">Il mio budget iniziale</label>
                  </div>
                  <div class="col-6 text-right pl-0 pr-1">
                    <label class="font-weight-bolder"
                           id="info-1">{{budget.budget + '' | convertPrice:false:true}}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col pl-1 pr-0 text-left">
                    <label class="text-black-50">Acquisti senza sconto</label>
                  </div>
                  <div class="col-6 text-right pl-0 pr-1">
                    <label class="font-weight-bolder text-danger"
                           id="info-2">-{{counter.costTotal + counter.bonusTotal + '' | convertPrice:false:true}}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5 offset-7 pl-0 pr-1">
                    <hr>
                  </div>
                </div>
                <div class="row">
                  <div class="col pl-1 pr-0 text-left">
                    <label class="text-black-50">Budget rimasto</label>
                  </div>
                  <div class="col-6 text-right pl-0 pr-1">
                    <label class="font-weight-bolder"
                           id="info-3">{{budget.budget - (counter.costTotal + counter.bonusTotal) + '' | convertPrice:false:true}}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col pl-1 pr-0 text-left">
                    <label class="text-black-50">Bonus Sposi W.S.</label>
                  </div>
                  <div class="col-6 text-right pl-0 pr-1">
                    <label class="font-weight-bolder text-success"
                           id="info-4">{{counter.bonusTotal + '' | convertPrice:false:true }}</label>
                  </div>
                </div>

                <h5 class="text-center">Hai risparmiato il</h5>
                <div class="progress">

                  <round-progress [current]="budget.budget" [max]="budget.budget" color="#E442BA" [stroke]="8"
                                  [style.width.px]="(isMobile) ? 150 : 200"
                  ></round-progress>
                  <div class="progressbar-text">
                    <label class="percentProgress">{{(-(((counter.costTotal * 100) / budget.budget) - 100)).toFixed(0)}}
                      %</label>
                    <label
                      class="textProgress text-dark width-120">{{(budget.budget - counter.costTotal).toFixed(2) | convertPrice:false:true}}</label>
                  </div>
                </div>
                <h5 class="text-center">Rispetto al tuo budget iniziale</h5>
              </div>
            </div>

          </div>
        </div>

      </div>
    </ng-container>

  </div>
  <div class="row mt-3">
    <div class="col">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-fill mt-2">
        <li [ngbNavItem]="0">
          <a ngbNavLink class="text-center pl-0 pr-0">
            Miei annunci
          </a>
          <ng-template ngbNavContent>
            <h6 id="numAnnunci" class="cursor-pointer color-primary"
                (click)="filterTypeAuctions = null">{{auctions.length}}</h6>
            <div class="row">
              <div class="col text-center my-auto">
                <a class="btn color-primary font-weight-bold" routerLink="/new-auction">INSERISCI ANNUNCIO</a>
              </div>
            </div>
            <div id="list-auctions" class="w-100">
              <app-my-auction-item [auctions]="auctions | filterAuctions:filterTypeAuctions">

              </app-my-auction-item>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="1">
          <a ngbNavLink class="text-center pl-0 pr-0">
            Miei acquisti
          </a>
          <ng-template ngbNavContent>
            <div class="container-acquisti">

              <div class="container p-0 pr-md-3 pl-md-3">

              <ng-container *ngIf="auctionsFinal">

                <h6 id="numAcquisti" (click)="filterType = null"
                    class="text-center cursor-pointer color-primary">{{auctionsFinal.orders.length + auctionsFinal.auctions.length}}</h6>
                <ng-container *ngIf="auctionsFinal.orders | filterOrder:filterType as orders">

                  <div *ngIf="orders && orders.length > 0" class="mb-3">

                    <ngb-accordion #a="ngbAccordion" [closeOthers]="true" class="panel-acquisti">
                      <ngb-panel id="store-panel-1">
                        <ng-template ngbPanelHeader let-opened="opened">
                          <div class="d-flex align-items-center justify-content-between"
                               (click)="a.toggle('store-panel-1')">
                            Ordini STORE
                            <button class="btn">
                              <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                              <i class="fa fa-chevron-up" *ngIf="opened"></i>
                            </button>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <ng-container *ngFor="let order of orders; let i = index">
                            <app-acquisto-item [item]="order" [isFirst]="i === 0"></app-acquisto-item>
                          </ng-container>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>
                </ng-container>
                <ng-container *ngFor="let item of auctionsFinal.auctions | groupByCategory:filterType; let i = index">
                  <div class="mb-3">

                    <ngb-accordion #a="ngbAccordion" [closeOthers]="true"
                                   class="panel-acquisti">
                      <ngb-panel [id]="'auction-panel-' + i">
                        <ng-template ngbPanelHeader let-opened="opened">
                          <div class="d-flex align-items-center justify-content-between"
                               (click)="a.toggle('auction-panel-' + i)">
                            {{item.category}}
                            <button class="btn">
                              <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                              <i class="fa fa-chevron-up" *ngIf="opened"></i>
                            </button>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <app-auction-order-item [items]="item.items"></app-auction-order-item>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="text-center pl-0 pr-0">
            Miei pagamenti
          </a>
          <ng-template ngbNavContent>
            <ul ngbNav #nav2="ngbNav" [(activeId)]="activeIdPagamenti" class="nav-pills nav-fill">
              <li [ngbNavItem]="0">
                <a class="text-dark nav-link" ngbNavLink>Portafoglio </a>
                <ng-template ngbNavContent>
                  <div id="porta" class="container">
                    <h4 class="mt-3">Portafoglio</h4>
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <i class="far fa-credit-card color-primary mt-1"></i>
                          <label>Buono regalo Wedding Smart</label>
                          <label>0,00 €</label>
                        </div>
                        <small><a href="">Ricarica il tuo account</a> | <a href="">Aggiungi un buono regalo</a></small>
                      </div>
                    </div>
                    <h4 class="mt-3">Offerte</h4>
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <div>
                            <h6>Metti da parte il budget per i tuoi futuri acquisti:</h6>
                            <label>scopri i vantaggi di Wedding Smart Ricarica</label>
                          </div>
                          <i class="fas fa-hand-holding-usd fa-3x color-primary"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="1">
                <a class="text-dark nav-link" ngbNavLink>Impostazioni</a>

                <ng-template ngbNavContent>
                  <div id="impo" class="container">
                    <h4 class="mt-3">Impostazioni</h4>
                    <div class="card">
                      <div class="card-body">
                        <h6>La tua preferenza di acquisto</h6>
                        <label class="text-black-50">Indirizzo di spedizione</label><br>
                        <div id="list-address">
                          <div class="mt-1 address" *ngFor="let address of addresses">
                            <button class="btn p-0 mr-1" (click)="showAddress(modalAddress, address)">
                              <i class="fa fa-edit color-primary">
                              </i>
                            </button>

                            <button class="btn p-0 mr-1" (click)="deleteAddress(address)">
                              <i class="fa fa-trash color-primary">
                              </i>
                            </button>
                            {{address.address}} {{address.number}}, {{address.cap}}, {{address.city}}
                          </div>
                        </div>
                        <button class="btn color-primary w-100" (click)="showAddress(modalAddress)"><i
                          class="fas fa-plus-circle"></i> Aggiungi indirizzo
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a class="text-dark nav-link" ngbNavLink>Transazioni</a>
                <ng-template ngbNavContent>
                  <div id="trans" class="container">
                    <h4 class="mt-3">Transazioni</h4>
                    <label>Per visualizzare tutte le transazioni dei Buoni Regalo, inclusi i rimborsi, visualizza
                      l'attività
                      e il saldo Buoni Regalo</label>
                    <div id="list-transactions">
                      <h5>Completato</h5>
                      <div class="item-transaction mb-1 mt-1" *ngFor="let transaction of transactions">
                        <div class="card">
                          <div class="card-body p-1">
                            <h6>{{transaction.timestamp}}</h6>
                            <div class="d-flex">
                              <div class="w-md-100">
                                <b>Modalità di pagamento:</b> <label class="text-black-50 ml-1">Paypal</label><br>
                                <b>Codice transazione:</b> <label
                                class="text-black-50 ml-1">{{transaction.paypalbuyer}}</label><br>
                                <a [routerLink]="
                                  transaction.auctionid ?
                                  '/order-detail/auction/'+transaction.auctionid+'/riepilogo' :
                                '/order-detail/order/'+transaction.id+'/riepilogo'">Ordine
                                  n.{{transaction.id}}</a><br>
                                <label class="mb-0">Venduto da:
                                  <a [routerLink]="'/store/' + transaction.desc_sector + '/'+ transaction.sector + '/'
                                   + transaction.seller">{{transaction.seller}}</a>
                                </label>
                                <div class="text-right d-block d-md-none">
                                  <b>- {{transaction.total | convertPrice:false:true}}</b>
                                </div>
                              </div>
                              <b
                                class="ml-auto my-auto d-none d-md-block">- {{transaction.total | convertPrice:false:true}}</b>
                            </div>
                            <br>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav2"></div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>


  <ng-template #modalAddress let-c="close" let-d="dismiss">
    <div class="modal-body position-relative">
      <h6>Inserisci indirizzo di spedizione</h6>
      <div class="row">
        <div class="col-12 form-group">
          <label for="address">Indirizzo</label>
          <input class="form-control" type="text" id="address" [(ngModel)]="editAddress.address">
        </div>
        <div class="col-6 form-group">
          <label for="number">Numero civico</label>
          <input class="form-control" type="text" id="number" [(ngModel)]="editAddress.number">
        </div>
        <div class="col-6 form-group">
          <label for="cap">Cap</label>
          <input class="form-control" type="text" id="cap" maxlength="5" [(ngModel)]="editAddress.cap">
        </div>
        <div class="col-12 form-group">
          <label for="city">Città</label>
          <input class="form-control" type="text" id="city" [(ngModel)]="editAddress.city">
        </div>
      </div>
      <button class="btn btn-primary float-right" (click)="saveAddress(d)">Salva</button>
    </div>
  </ng-template>
</div>
