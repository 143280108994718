import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-auction-order-item',
  templateUrl: './auction-order-item.component.html',
  styleUrls: ['./auction-order-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuctionOrderItemComponent implements OnInit {
  @Input() items: {sector: string, auctions: any[]}[];
  @Input() isFirst = false;
  constructor() { }

  ngOnInit(): void {
  }

}
