
<div class="modal-header">
  <h6 class="modal-title">Accedi</h6>
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
</div>
<div class="modal-body position-relative">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 window-login">
          <label class="w-100 text-center m-2">Se sei già registrato, inserisci i tuoi dati </label>
          <form class="needs-validation" novalidate id="form-login">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fa fa-user"></i></span>
              </div>
              <input type="text" class="form-control" name="username" placeholder="Nome utente*" required
                     minlength="5" autocomplete="username" [(ngModel)]="bodyLogin.username">
              <div class="invalid-feedback">Inserisci almeno 5 caratteri</div>
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fa fa-key"></i></span>
              </div>
              <input type="password" class="form-control" name="password" placeholder="Password*" required
                     minlength="8" autocomplete="current-password" [(ngModel)]="bodyLogin.password">
              <div class="invalid-feedback">Inserisci almeno 8 caratteri</div>
            </div>
            <div class="row">
              <div class="col-12 text-center col-md-6">
                <button class="btn btn-link font-italic small" (click)="rememberPassword.emit()">Password dimenticata?</button>
              </div>
              <div class="col-12 col-md-6 text-center mt-2 mt-md-0">
                <button type="button" class="btn btn-primary w-100" (click)="login.emit(bodyLogin)">Accedi</button>
              </div>
            </div>
          </form>
<!--          <div class="row mt-3 mb-3">-->
<!--            <div class="col-12 text-center">-->
<!--              <label class="small text-black-50"> o accedi con: </label>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="row">-->
<!--            <div class="col-sm-12 col-md-6 text-center">-->
<!--              <button class="btn btn-facebook" (click)="signInWithFB()">-->
<!--                <i class="fab fa-facebook-f mr-3"></i>-->
<!--                Accedi con facebook-->
<!--              </button>-->
<!--            </div>-->
<!--            <div class="col-sm-12 col-md-6 text-center mt-3 mt-md-0">-->
<!--              <button class="btn btn-outline-dark bg-white text-dark" (click)="signInWithGoogle()">-->
<!--                <i class="fab fa-google mr-3"></i>-->
<!--                Accedi con google-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
          <div class="row mt-2">
            <div class="col-12">
              <label class="mr-1">
                Sei un nuovo utente?
              </label>
              <a routerLink="/register" (click)="goRegisterPage.emit(); activeModal.dismiss()">Registrati gratis</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="position-absolute loader-login text-white text-center" *ngIf="utilityService.isLoading$ | async">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
