import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-paypal-result',
  templateUrl: './paypal-result.component.html',
  styleUrls: ['./paypal-result.component.scss']
})
export class PaypalResultComponent {

  @Input() isSuccess: boolean;
  constructor(public activeModal: NgbActiveModal) { }

}
