import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {publishReplay} from 'rxjs/internal/operators/publishReplay';
import {map, refCount} from 'rxjs/operators';
import {IAuction} from '../objects/IAuction';
import {ICheckUser} from '../objects/ICheckUser';

@Injectable({
  providedIn: 'root'
})
export class CommunicatorService {
  checkUser$: Observable<ICheckUser>;
  backdropShow$ = new BehaviorSubject(false);
  sharePage$ = new BehaviorSubject<any>(null);
  images$ = new BehaviorSubject({photos: null, select: null});
  openLogin$ = new Subject();
  auctionRepublish: IAuction;
  showAll = false;
  constructor(private httpService: HttpService) { 
    this.checkUser$ = this.httpService.checkUser().pipe(
      publishReplay(),
      refCount()
    );
  }

  reinitUser() {
    this.checkUser$ = this.httpService.checkUser().pipe(
      publishReplay(),
      refCount()
    );
  }

}
