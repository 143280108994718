import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {ActivatedRoute} from '@angular/router';
import {IProduct, IProductPrice} from '../../objects/IProduct';
import {Meta, Title} from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';
import {map, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CommunicatorService} from '../../services/communicator.service';
import {PopupInputComponent} from '../../components/popup/popup-input/popup-input.component';
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-product-store',
  templateUrl: './product-store.component.html',
  styleUrls: ['./product-store.component.scss']
})
export class ProductStoreComponent implements OnInit {
  innerWidth = 769;
  product$: Observable<IProduct>;
  user$: Observable<{isAdmin: string, isOperator: string, isUser: string, username: string}>;

  quantity = '1';
  sizeSelected: string;
  price: IProductPrice;
  active = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private httpService: HttpService, public communicator: CommunicatorService,
              private activatedRoute: ActivatedRoute, private title: Title, private meta: Meta, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    this.user$ = this.communicator.checkUser$;
    this.product$ = this.activatedRoute.paramMap.pipe(
      map(e => e.get('code')),
      switchMap(code => this.httpService.getProductByCode(code).pipe(
        tap(resp => {
          this.title.setTitle('Wedding Smart Store: ' + resp.username + ', ' + resp.title);
          this.meta.updateTag({
            description: resp.description
          });
          this.price = resp.prices[0];
        }),
        map(prod => ({...prod, regole: prod.regole.replace(/\n/g, '<br>'), other_information: JSON.parse(prod.other_information)})),
        tap(prod => {
          this.sizeSelected = (prod.other_information.sizeAddress || prod.other_information.sizeShoes) ? '' : null;
        })
      ))
    );
  }

  segnala(idProduct: number) {

    const rf = this.modalService.open(PopupInputComponent);
    rf.componentInstance.title = 'Descrivi il motivo della segnalazione';
    rf.componentInstance.closeDescription.subscribe((text: string) => {
      rf.close();
      if (text.length > 0) {
        this.httpService.postWarning({idref: idProduct, text, table: 'products'}).subscribe(resp => {
          if (resp && resp.result) {
            const mr2 = this.modalService.open(PopupComponent);
            mr2.componentInstance.title = 'Attenzione';
            mr2.componentInstance.body = 'Segnalazione effettuata con successo';
          }
        });
      }
    });
  }

  scrollBottom() {
    window.scrollTo({behavior: 'smooth', top: window.innerHeight});
  }
}
