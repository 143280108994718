<nav class="navbar navbar-expand-md navbar-light bg-white py-0"
     [class.bb-1]="!urlState.url.includes('welcome')"
     *ngIf="url$ | async as urlState">

  <div class="background-lightpink padding-7 d-block d-md-none"
       *ngIf="!urlState.url.includes('welcome')">
    <button class="navbar-toggler background-lightpink border-pink pt-2 pb-2" type="button" (click)="showMenuLeft(true)"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fa fa-bars font3"></i>
    </button>
  </div>
  <a class="navbar-brand mr-md-2 not-active mr-auto"
     [class.ml-3]="!(communicator.checkUser$ | async) || !(communicator.checkUser$ | async).username"
     [class.ml-auto]="!urlState.url.includes('welcome') && (communicator.checkUser$ | async) && (communicator.checkUser$ | async).username">
    <img src="https://www.weddingsmart.it/assets/images/logoheader.png" height="80" alt="" routerLink="/">
  </a>
  <div class="collapse navbar-collapse d-none d-md-block" id="navbarSupportedContent" *ngIf="!urlState.url.includes('welcome')">
    <ul class="navbar-nav mr-auto mt-ul">
      <div class="menu-list" [class]="((communicator.checkUser$ | async) ? 'd-flex' : '')">
        <li class="nav-item active text-center">
          <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"> Home
            <span class="sr-only">(corrente)</span></a>
        </li>
        <li class="nav-item text-center">
          <a class="nav-link" routerLink="/come-funziona" routerLinkActive="active">Come funziona</a>
        </li>
        <li class="nav-item dropdown text-center">
          <a class="nav-link dropdown-toggle" routerLink="/vetrine-fornitori/" routerLinkActive="active">
            Aziende settore wedding
          </a>
        </li>
        <li class="nav-item text-center">
          <a class="nav-link dropdown-toggle" routerLink="/auctions" routerLinkActive="active" >Annunci Sposi</a>
        </li>
        <ng-container *ngIf="communicator.checkUser$ | async as user">
          <li class="nav-item text-center" style="padding-top: 6px;">
            <a class="nav-link annunciButton" routerLink="/my-auctions" routerLinkActive="active" (click)="showMenuRight(false)" *ngIf="user.isUser === '1'">
              I miei annunci
            </a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link dropdown-toggle" routerLink="/my-auctions/1" routerLinkActive="active" (click)="showMenuRight(false)" *ngIf="user.isUser === '1'">
              I miei acquisti
            </a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link dropdown-toggle" routerLink="/new-auction" routerLinkActive="active" (click)="showMenuRight(false)" *ngIf="user.isUser === '1'">
              Inserisci annuncio
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="communicator.checkUser$ | async as user">
          <li class="nav-item text-center">
            <a class="nav-link dropdown-toggle" routerLinkActive="active" (click)="goTo('https://www.weddingsmart.it/portal/store')" *ngIf="user.isOperator === '1'">
              Il mio store
            </a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link dropdown-toggle" routerLinkActive="active" (click)="goTo('https://www.weddingsmart.it/portal/offers-operator')" *ngIf="user.isOperator === '1'">
              Le mie proposte
            </a>
          </li>
          <li class="nav-item text-center" style="padding-top: 6px;">
            <a class="nav-link dropdown-toggle annunciButton" routerLinkActive="active" (click)="goTo('/portal/orders')" *ngIf="user.isOperator === '1'">
              Vendite
            </a>
          </li>
        </ng-container>
      </div>
    <!-- </ul>
    <ul>     -->
      <li class="nav-item active li-accedi"
          *ngIf="!(communicator.checkUser$ | async) || !(communicator.checkUser$ | async).username">
        <fieldset>
          <legend>Area riservata a Sposi e Aziende</legend>
          <div class="row no-gutters">
            <div class="col-3 text-center">
              <img class="img" src="https://www.weddingsmart.it/assets/images/user.png" width="70">
            </div>
            <div class="col my-auto" style="padding-right:3px;">
              <div class="li-accedi text-right pr-3">
                <a class="color-primary not-active cursor-pointer" (click)="loginClick()">
                  Accedi</a>
                o <a class="color-primary not-active"
                     (click)="showMenuLeft(false)"
                     routerLink="/register">Registrati gratis</a>
              </div>
            </div>
          </div>
        </fieldset>
      </li>
    </ul>
  </div>
  <ng-container *ngIf="!urlState.url.includes('welcome') && !urlState.url.includes('result-activation')">
    <div class="append-header" *ngIf="(communicator.checkUser$ | async) && (communicator.checkUser$ | async).username">
      <div class="navbar-brand text-right display-content d-inline-flex mr-2" style="padding-top: 3px!important;">

        <div #dropNotification="ngbDropdown" ngbDropdown class="position-static"
             *ngIf="notifications$ | async as notifications" (openChange)="utility.checkScrollBody($event)">
          <button class="btn" ngbDropdownToggle style="padding-top: 12px!important">
            <span class="position-relative">
              <img *ngIf="!dropNotification.isOpen()" width="32"
                   src="https://www.weddingsmart.it/assets/icons/bell.png">
              <img *ngIf="dropNotification.isOpen()" width="32"
                   src="https://www.weddingsmart.it/assets/icons/bell-full.png">

              <ng-container *ngIf="notifications | getOnlyNew:true:0:countRefreshNotification as notificationsNew">
                <ng-container *ngIf="notificationsNew.length > 0">
                  <span class="number-notifications">{{notificationsNew.length}}</span>
                </ng-container>
              </ng-container>
            </span>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-notification pt-0">
            <app-notifications [notifications]="notifications"
                               (refreshNotification)="countRefreshNotification = countRefreshNotification +1;"
                               (closeNotifications)="dropNotification.toggle()"></app-notifications>
          </div>
        </div>
        <div *ngIf="communicator.checkUser$ | async as user">
          <button class="btn" (click)="showMenuRight(true)">
            <img *ngIf="!user.image" width="37" src="https://www.weddingsmart.it/assets/images/user.png">
            <img class="rounded-circle border-1" *ngIf="user.image" width="37"
                 [src]="'https://www.weddingsmart.it/api/images/users/'+user.image+ '?id=' + idRandom">
          </button>
        </div>
      </div>
    </div>
    <div [class]="classMenu">
      <div id="menu-left" [class]="'d-block d-md-none ' + classMenu">
        <div class="shadow pb-2 mb-2">
          <button class="close font-2 pr-1" (click)="showMenuLeft(false)">&times;</button>
          <div class="w-100 text-center pt-2" routerLink="/">
            <img class="img" height="62" src="https://www.weddingsmart.it/assets/images/logoheader.png">
          </div>

          <div class="pl-3 pr-3 mt-3"
               *ngIf="!(communicator.checkUser$ | async) || !(communicator.checkUser$ | async).username">
            <fieldset>
              <legend>Area riservata a Sposi e Aziende</legend>
              <div class="row no-gutters">
                <div class="col-3 text-center">
                  <img class="img" src="https://www.weddingsmart.it/assets/images/user.png" width="70">
                </div>
                <div class="col my-auto" style="padding-right:3px;">
                  <div class="li-accedi text-right pr-3">
                    <a class="color-primary not-active" (click)="loginClick()">
                      Accedi</a>
                    o <a class="color-primary not-active" (click)="showMenuLeft(false)"
                         routerLink="/register">Registrati gratis</a>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div
            [class]="'row ' + ((!(communicator.checkUser$ | async)  || !(communicator.checkUser$ | async).username) ? 'mt-3' : 'mt-5 mb-3')">
            <div class="col-12 text-center">
              <a class="w-75 btn btn-primary annunciButton2"
                 (click)="showMenuLeft(false)"
                 routerLink="/auctions">
                ANNUNCI SPOSI</a>
            </div>
          </div>
        </div>

        <!--      <div class="shadow pb-1 mb-4">-->
        <!--        <div class="row">-->
        <!--          <div class="col-auto offset-1 pl-1 pr-1">-->
        <!--            <img class="max-width-40" [src]="url + '/WeddingSmart/img/icon.png'">-->
        <!--          </div>-->
        <!--          <div class="col my-auto pl-0">-->
        <!--            <a class="text-dark" routerLink="/"> <b>Home</b></a>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <div class="shadow pb-3 mb-4">

          <div class="row mt-2" routerLink="/"
               (click)="showMenuLeft(false)">
            <div class="col-3 text-right pr-0">
              <img class="max-width-40" [src]="url + '/assets/icons/icon.png'">
            </div>
            <div class="col-9 my-auto">
              <a data-toggle="collapse" role="button" class="text-dark w-100 text-decoration-none"
                 aria-expanded="false">
                <div class="w-100 pr-3">Home
                </div>
              </a>
            </div>
          </div>
          <div *ngFor="let menu of MENU_LINKS; let i = index" [ngbCollapse]="macroCollaped">

            <div class="row mt-2">
              <div class="col-3 text-right pr-0">
                <img class="max-width-40" [src]="url + menu.img">
              </div>
              <div class="col-9 my-auto" (click)="resetCollapsed(i);"
                   [attr.aria-expanded]="!isCollapsed[i]">
                <a data-toggle="collapse" role="button" class="text-dark w-100 text-decoration-none"
                   aria-expanded="false">
                  <div class="w-100 pr-3">{{menu.label}}
                    <i class="mt-1 fa fa-1x float-right"
                       [ngClass]="{'fa-chevron-up': !isCollapsed[i], 'fa-chevron-down': isCollapsed[i]}"></i>
                  </div>
                </a>
              </div>
            </div>
            <div class="row background-lightgray">
              <div class="col offset-3">
                <div [ngbCollapse]="isCollapsed[i]">
                  <div class="row mb-3">
                    <div class="col-12 mt-2"
                         (click)="showMenuLeft(false)"
                         *ngFor="let link of menu.links"
                         [routerLink]="['vetrine-fornitori', menu.label, link]">
                      {{link}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="row mt-2">
            <div class="col-11 offset-1 pl-1" (click)="resetCollapsed(5)" [attr.aria-expanded]="!isCollapsed[5]">
              <a data-toggle="collapse" role="button"
                 class="text-dark w-100 text-decoration-none" id="linkCollapse"
                 aria-expanded="false" aria-controls="collapseRicevimento">
                <div class="w-100 pr-3 font-weight-bold">Link utili
                  <i class="mt-1 fa fa-1x float-right"
                     [ngClass]="{'fa-chevron-up': !isCollapsed[5], 'fa-chevron-down': isCollapsed[5]}"></i>
                </div>
              </a>
            </div>
          </div>
          <div class="row background-lightgray">
            <div class="col">
              <div [ngbCollapse]="isCollapsed[5]">
                <div class="row mb-2">
                  <div class="col-9 offset-3 mt-2" *ngFor="let link of MENU_LINKS_UTILS" [routerLink]="link.link"
                       (click)="showMenuLeft(false)">
                    {{link.label}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-10 offset-1">
              <hr>
            </div>
          </div>
          <div class="row mt-2 mb-3">
            <div class="col-11 offset-1 pl-1">
              <span class="font-weight-bold">Contattaci</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div [class]="classMenuRight">
      <div id="menu-right" [class]="classMenuRight">
        <button class="close font-2 pr-1" (click)="showMenuRight(false)">&times;</button>
        <div *ngIf="communicator.checkUser$ | async as user" class="position-relative">
          <div class="row no-gutters mt-4">
            <div class="col-3 text-center offset-1">
              <img class="img rounded-circle border-1"
                   [src]="user.image ? 'https://www.weddingsmart.it/api/images/users/' + user.image + '?id=' + idRandom
               : 'https://www.weddingsmart.it/assets/images/user.png'" width="70">
            </div>
            <div class="col my-auto ml-2">
              <span class="text-black-50">Ciao</span>
              <p class="mb-0">{{(user.business_name) ? user.business_name : user.fullname}}</p>
            </div>
          </div>
          <div class="row mt-3" *ngIf="user.isOperator === '1'">
            <div class="col-10 offset-1">
              <fieldset>
                <legend>Il tuo bilancio giornaliero</legend>
                <div class="row no-gutters pb-1 pt-1" *ngIf="orders$ | async as orders">
                  <div class="col-5 pl-2">
                    <span>Vendite</span>
                    <p class="seller mb-2"><b>{{orders.length}}</b></p>
                  </div>
                  <div class="col">
                    <span>Entrate <i (click)="showInfoEntrate()"
                                     class="fa fa-info-circle info-button position-absolute text-black-50"></i> </span>
                    <p class="seller text-success mb-2"><b>{{calcTotal(orders) | convertPrice}}</b></p>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-5 offset-1">
              <hr>
            </div>
          </div>
          <div class="overflow-auto">
            <div style="margin-bottom: 56px;" class="list-menu-right">
              <div class="row no-gutters" *ngIf="user.isOperator === '1' || user.isAdmin === '1'">
                <div class="col-1 offset-1">
                  <i class="fa fa-bezier-curve color-primary"></i>
                </div>
                <div class="col">
                <span (click)="goTo('/portal/dashboard')">
                  Dashboard
                </span>
                </div>
              </div>
              <ng-container *ngIf="user.isAdmin === '1'">
                <div class="row no-gutters">
                  <div class="col-1 offset-1">
                    <i class="fa fa-users color-primary"></i>
                  </div>
                  <div class="col">
                  <span (click)="goTo('/portal/users')">
                    Sposi
                  </span>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-1 offset-1">
                    <i class="fa fa-users color-primary"></i>
                  </div>
                  <div class="col">
                  <span (click)="goTo('/portal/operators')">
                    Fornitori
                  </span>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-1 offset-1">
                    <i class="fa fa-list color-primary"></i>
                  </div>
                  <div class="col">
                  <span (click)="goTo('/portal/auctions')">
                    Annunci
                  </span>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-1 offset-1">
                    <i class="fa fa-euro-sign color-primary"></i>
                  </div>
                  <div class="col">
                  <span (click)="goTo('/portal/payments')">
                    Versamenti FEE
                  </span>
                  </div>
                </div>
              </ng-container>
              <div class="row no-gutters"  *ngIf="user.isOperator === '1'">
                <div class="col-1 offset-1">
                  <i class="fas fa-user-edit color-primary"></i>
                </div>
                <div class="col">
                    <span
                      (click)="goTo('https://www.weddingsmart.it/portal/operator-paypal')">
                    Il mio profilo
                  </span>
                </div>
              </div>
              <div class="row no-gutters"  *ngIf="user.isUser === '1' || user.isOperator === '1'">
                <div class="col-1 offset-1">
                  <i *ngIf="user.isUser === '1'" class="fas fa-user-edit color-primary"></i>
                  <i *ngIf="user.isOperator === '1'" class="fas fa-store color-primary"></i>
                </div>
                <div class="col">
                  <span routerLink="/profile" *ngIf="user.isUser === '1'"
                        (click)="showMenuRight(false)">
                    Il mio profilo
                  </span>
                    <span *ngIf="user.isOperator === '1'"
                          (click)="goTo('https://www.weddingsmart.it/portal/store')">
                    Il mio store
                  </span>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-1 offset-1">
                  <i *ngIf="user.isOperator === '1'" class="fa fa-clipboard-check color-primary"></i>
                  <i *ngIf="user.isUser === '1'" class="fas fa-clipboard-list color-primary"></i>
                </div>
                <div class="col">
                <span (click)="goTo('https://www.weddingsmart.it/portal/offers-operator')" *ngIf="user.isOperator === '1'">
                  Le mie proposte
                </span>
                  <span routerLink="/my-auctions" (click)="showMenuRight(false)" *ngIf="user.isUser === '1'">
                  I miei annunci
                </span>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-1 offset-1">
                  <i *ngIf="user.isOperator === '1'" class="fa fa-project-diagram color-primary"></i>
                  <i *ngIf="user.isUser === '1'" class="fas fa-shopping-cart color-primary"></i>
                </div>
                <div class="col">
                <span (click)="goTo('/portal/operator-paypal?tab=4')" *ngIf="user.isOperator === '1'">
                  I miei abbonamenti
                </span>
                  <span routerLink="/my-auctions/1" (click)="showMenuRight(false)" *ngIf="user.isUser === '1'">
                  I miei acquisti
                </span>
                </div>
              </div>
              <ng-container *ngIf="user.isUser === '1'">
                <div class="row no-gutters">
                  <div class="col-1 offset-1">
                    <i class="fa fa-heart color-primary"></i>
                  </div>
                  <div class="col">
                  <span routerLink="/favorites" (click)="showMenuRight(false)">
                    I miei preferiti
                  </span>
                  </div>
                </div>
              </ng-container>
              <div class="row no-gutters">
                <div class="col-1 offset-1">
                  <i *ngIf="user.isOperator === '1'" class="fa fa-money-check-alt color-primary"></i>
                  <i *ngIf="user.isUser === '1'" class="fas fa-plus-circle color-primary"></i>
                </div>
                <div class="col">
                <span (click)="goTo('/portal/orders')" *ngIf="user.isOperator === '1'">
                  Vendite
                </span>
                  <span routerLink="/new-auction" *ngIf="user.isUser === '1'" (click)="showMenuRight(false)">
                  Inserisci annuncio
                </span>
                </div>
              </div>
              <ng-container *ngIf="user.isOperator === '1'">
                <div class="row no-gutters">
                  <div class="col-1 offset-1">
                    <i class="fa fa-clipboard color-primary"></i>
                  </div>
                  <div class="col">
                  <span (click)="goTo('/portal/products')">
                    Prodotti
                  </span>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-1 offset-1">
                    <i class="fa fa-clipboard-list color-primary"></i>
                  </div>
                  <div class="col">
                  <span (click)="goTo('/portal/reviews')">
                    Recensioni
                  </span>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-1 offset-1">
                    <i class="fa fa-chart-line color-primary"></i>
                  </div>
                  <div class="col">
                  <span (click)="goTo('/portal/statics')">
                    Statistiche
                  </span>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-1 offset-1">
                    <i class="fa fa-check-circle color-primary"></i>
                  </div>
                  <div class="col">
                  <span (click)="goTo('/portal/operator-paypal?tab=5')">
                    Consensi privacy
                  </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <button (click)="logout()" class="btn btn-primary rounded-0 logout">
            ESCI
          </button>
        </div>
      </div>
    </div>
  </ng-container>

</nav>
