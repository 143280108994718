<div class="modal-body position-relative">
  <button type="button" class="close" (click)="close.emit(); activeModal.dismiss()">&times;</button>
  <h4>{{title}}</h4>
  <label *ngIf="!isHtml">{{body}}</label>
  <span *ngIf="isHtml" [innerHTML]="body"></span>
  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-primary" (click)="close.emit(); activeModal.dismiss()">OK</button>
    </div>
  </div>

</div>
