import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CommunicatorService} from '../../services/communicator.service';

@Component({
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqComponent implements OnInit {
  questionsAll = [
    {
      q: 'Come posso registrarmi?',
      a: 'Basta andare sulla pagina di <a href="https://www.weddingsmart.it/register">registrazione </a> e compilare tutti i campi'
    },
    {
      q: 'Come posso accedere?',
      a: 'Basta cliccare sulla voce "Accedi" in alto a destra, ed inserire le proprie credenziali'
    },
    {
      q: 'Come posso creare e pubblicare un annuncio?',
      a: 'Dopo aver esguito l\'accesso, basta andare sul seguente <a href="https://www.weddingsmart.it/new-auction">link</a>,' +
        ' oppure cliccare in alto a destra, sulla propria immagine, e selezionare "Inserisci annuncio"'
    },
    {
      q: 'Cosa devo fare una volta che l\'annuncio è stato aggiundicato ad un fornitore?',
      a: 'Basterà tornare sull\'annuncio, e procedere con il pagamento'
    },
    {
      q: 'Come posso effettuare il reso?',
      a: 'Andare sull\'ordine su cui effettuare il reso, e selezionare la voce "Restituisci articoli",' +
        ' ATTENZIONE: questa voce è visibile solo dopo' +
        ' che il pacco è stato ricevuto'
    },
    {
      q: 'Dove posso tenere traccia del mio buget del mio matrimonio?',
      a: 'Basta andare sul seguente <a href="https://www.weddingsmart.it/my-auctions"> link</a>, oppure cliccare sulla propria' +
        ' immagine in alto a destra ' +
        'e selezionare "Miei annunci"'
    },
    {
      q: 'Come posso cambiare la scelta di un fornitore per un annuncio?',
      a: 'Non è possibile effettuare tale operazione'
    },
    {
      q: 'Come posso cambiare la mia password?',
      a: 'Basta andare al seguente <a href="https://www.weddingsmart.it/profile">link</a>, oppure cliccare sulla propria icona in alto' +
        ' a destra e selezionare la voce "Il mio profilo", e cliccare su modifica'
    },
    {
      q: 'Come posso scrivere una recensione?',
      a: 'Dopo aver ricevuto il pacchetto dell\'ordine, basta andare sul dettaglio ordine e selezionare la voce: "Scrivi una recensione"'
    },
    {
      q: 'Come posso eliminare la mia registrazione?',
      a: 'Basta effettuare tale richiesta all\'indirizzo email "info@weddingsmart.it"'
    },
    {
      q: 'Come posso filtrare gli annunci pubblicati degli sposi?',
      a: 'Basta andare sulla lista degli annunci, e sulla voce sinistra, selezionare/deselezionare le categorie o' +
        ' scrivere la città di interesse, il sistema in automatico, effettuerà il filtro'
    },
    {
      q: 'Come posso cambiare la mia immagine profilo?',
      a: 'Basta andare al seguente <a href="https://www.weddingsmart.it/profile">link</a>, oppure cliccare sulla propria icona in alto' +
        ' a destra e selezionare la voce "Il mio profilo", e cliccare sull\'icona della macchinetta fotografica'
    },
  ];
  questions: {q: string, a: string}[] = [];
  search = '';
  constructor(private communicator: CommunicatorService) {
  }

  ngOnInit(): void {
    window.scrollTo({left: 0, top: 0});
    this.questions = this.questionsAll;
    this.communicator.checkUser$.subscribe(user => {
      if (user && user.isOperator === '1') {
        this.questionsAll.splice(2, 1);
        this.questionsAll.splice(4, 1);
        this.questionsAll.splice(5, 1);
        this.questionsAll.splice(8, 1);
      }
      this.questions = this.questionsAll;
    });
  }

  filterQuestions() {
    this.questions = this.questionsAll.filter(e => (e.q.toLowerCase().includes(this.search.toLowerCase())));
  }
}
