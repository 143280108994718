<div class="modal-body position-relative" *ngIf="!isSent">
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
  <h4>Password dimenticata</h4>
  <label for="email">Inserisci la tua email, ti invieremo un link per resettare la tua password</label>
  <input id="email" class="form-control" type="email" placeholder="esempio@live.it" (input)="clearEmailValidation()" [(ngModel)]="email">
  <div class="invalid-feedback invalid-email">
    Campo obbligatorio
  </div>
  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-primary" (click)="sendEmail.emit(email)">Fatto</button>
    </div>
  </div>
</div>

<div class="modal-body position-relative" *ngIf="isSent">
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
  <h4>Hai dimenticato la password?</h4>

  <div class="background-lightgray p-3">
    <div class="row">
      <div class="col-auto">
        <i class="fa fa-check-circle text-success"></i>
      </div>
      <div class="col">
        Le istruzioni per ripristinare la password sono state inviate a <b>{{email}}</b>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <b>Non hai ricevuto il link?</b><br>
    <ol>
      <li>Controlla la cartella di posta indesiderata</li>
      <li>Controlla lo spelling</li>
      <li>Riprova tra 15 minuti, la tua richiesta potrebbe impiegare tempo per essere rielaborata</li>
    </ol>
  </div>
  <hr>
  <h6>Sei un nuovo utente?</h6>
  <div>
      <a class="w-100 btn btn-outline-primary" routerLink="/register">Registrati gratis</a>
  </div>

</div>
