import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {Observable} from 'rxjs';
import {IAuction, IFilterAuctions} from '../../objects/IAuction';
import { MENU_LINKS } from 'src/app/objects/Constants';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunicatorService} from '../../services/communicator.service';
import {Meta, Title} from '@angular/platform-browser';
import {map, switchMap, tap} from 'rxjs/operators';
import {Utility} from '../../objects/Utility';
import {AuctionsService} from '../../services/auctions.service';
import {SeoEnum} from '../../objects/SeoEnum';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-auctions',
  templateUrl: './auctions.component.html',
  styleUrls: ['./auctions.component.scss']
})
export class AuctionsComponent implements OnInit {

  breadcrumbs: [string, string] = [null, null];
  isRow = true;
  MENU_LINKS = MENU_LINKS;
  auctions$: Observable<IAuction[]>;
  category: string;
  subcategories: {'Sposa' : string[], 'Sposo': string[], 'Fornitori': string[], 'Ricevimento': string[], 'Pets Wedding': string[]} =
    {'Sposa': [], 'Sposo': [], 'Fornitori': [], 'Ricevimento': [], 'Pets Wedding': []};
  order: {id: string, key: string, value: 'desc' | 'asc'} = {id: '1', key: 'created', value: 'desc'};
  city = '';
  typeAuctions = '';
  classMenuLeft = '';
  classMenuRight = '';
  orders: {id: string, label: string, key: string, value: 'desc' | 'asc'}[] = [
    {id: '1', label: 'Dal più recente', key: 'created', value: 'desc'},
    {id: '2', label: 'Dal più vecchio', key: 'created', value: 'asc'},
    {id: '3', label: 'Prezzo descrescente', key: 'budget_num', value: 'desc'},
    {id: '4', label: 'Prezzo crescente', key: 'budget_num', value: 'asc'}
  ];
  constructor(@Inject(PLATFORM_ID) private platformId: object, private httpService: HttpService,
              private router: Router, private communicator: CommunicatorService,
              private auctionsService: AuctionsService, private seoService: SeoService,
              private activatedRoute: ActivatedRoute, private title: Title, private meta: Meta) {
  }

  ngOnInit(): void {
    this.auctions$ = this.activatedRoute.paramMap.pipe(
      map(params => ({category: params.get('category'), subcategory: params.get('subcategory'), city: params.get('city')})),
      tap(params => this.breadcrumbs = [params.category, params.subcategory]),
      tap(params => this.city = (params.city) ? params.city : ''),
      tap(params => this.category = (params.category) ? params.category : ''),
      tap(() => {
        if (!this.city && !this.category) {
          this.httpService.getSeo(SeoEnum.annunci).subscribe(resp => {
            this.seoService.setConfig(resp, SeoEnum.annunci);
          });
        }
      }),
      tap(params => this.checkSubcategories(params)),
      switchMap(() =>
        this.httpService.getAuctions({
          city: this.city,
          subcategories: this.subcategories,
          type: this.typeAuctions
        })
      )
    );
  }

  checkSubcategories(params: {category: string, subcategory: string}) {
    if(!params.subcategory) {
      if(params.category) {
        this.subcategories[params.category] = [...this.MENU_LINKS.find(e => e.label === params.category).links];
      } else {
        this.subcategories = this.auctionsService.subcategories;
      }
    } else {
      this.subcategories[params.category] = [params.subcategory];
    }
  }


  changeViewType(isRow = true) {
    this.isRow = isRow;
  }

  changeFilter(link: {'category': string, 'subcategory': string}) {
    if(link) {
      if(this.subcategories[link.category].includes(link.subcategory)) {
        this.subcategories[link.category] = this.subcategories[link.category].filter(e => e !== link.subcategory)
      } else {
        this.subcategories[link.category].push(link.subcategory);
      }
    }
    this.auctionsService.subcategories = this.subcategories;

    const total = this.subcategories.Fornitori.length + this.subcategories.Ricevimento.length + this.subcategories.Sposo.length + this.subcategories.Sposa.length;
    const subUrl = (this.city) ? '/city/' + this.city : '';
    if(total !== 1) {
      this.auctions$ = this.httpService.getAuctions({city: this.city, subcategories:this.subcategories, type: this.typeAuctions});
      this.router.navigateByUrl('/auctions' + subUrl);
    } else {
      const obj = this.getKeySub();
      this.router.navigateByUrl('/auctions' + subUrl + '/' + obj.key + '/' + obj.subcategory);
    }
  }

  getKeySub() {
    let key = '';
    let subcategory = '';
    if(this.subcategories) {
      const total = this.subcategories.Fornitori.length + this.subcategories.Ricevimento.length + this.subcategories.Sposo.length + this.subcategories.Sposa.length;
      if (total === 1) {
        if(this.subcategories.Sposa.length === 1) {
          key = 'Sposa';
          subcategory = this.subcategories.Sposa[0];
        } else if(this.subcategories.Sposo.length === 1) {
          key = 'Sposo';
          subcategory = this.subcategories.Sposo[0];
        } else if(this.subcategories.Ricevimento.length === 1) {
          key = 'Ricevimento';
          subcategory = this.subcategories.Ricevimento[0];
        } else if(this.subcategories.Fornitori.length === 1) {
          key = 'Fornitori';
          subcategory = this.subcategories.Fornitori[0];
        }
      }
    }
    return (key.length === 0) ? null : {key, subcategory};
  }

  openLeftMenu(show: boolean) {
    if (show) {
      this.classMenuLeft = 'show';
    } else {
      this.classMenuLeft = '';
    }
    Utility.checkScrollBody(show);
    this.communicator.backdropShow$.next(show);
  }

  openRightMenu(show: boolean) {
    if (show) {
      this.classMenuRight = 'show';
    } else {
      this.classMenuRight = '';
    }
    Utility.checkScrollBody(show);
    this.communicator.backdropShow$.next(show);
  }

  selectCategory(event: { isSelect: boolean; macro: string }) {
    if(event.isSelect) {
      this.subcategories[event.macro] = [...this.MENU_LINKS.find(e => e.label === event.macro).links];
    } else {
      this.subcategories[event.macro] = [];
    }
    this.changeFilter(null);
  }

  checkValues(id: string) {
    const {key, value} = this.orders.find(e => e.id === id);
    this.order.key = key;
    this.order.value = value;
  }
}
