import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {Foto, IMacroVetrina, IVetrina} from '../../../objects/IVetrina';
import {ActivatedRoute} from '@angular/router';
import {filter, map, switchMap} from 'rxjs/operators';
import {HttpService} from '../../../services/http.service';

@Component({
  selector: 'app-vetrina-fornitore',
  templateUrl: './vetrina-fornitore.component.html',
  styleUrls: ['./vetrina-fornitore.component.scss']
})
export class VetrinaFornitoreComponent implements OnInit {
  vetrina$: Observable<IMacroVetrina>;
  constructor(private activedRoute: ActivatedRoute, private httpService: HttpService) { }

  ngOnInit(): void {
    this.vetrina$ = this.activedRoute.paramMap.pipe(
      map(params => params.get('fornitore')),
      filter(fornitore => !!fornitore),
      switchMap(fornitore => this.httpService.getVetrina(fornitore)),
      map(el => ({...el, reviews: el.reviews.sort((a, b) => a.id > b.id ? -1 : 1)}))
    )
  }

}
