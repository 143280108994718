import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-privacy-cookie',
  templateUrl: './privacy-cookie.component.html',
  styleUrls: ['./privacy-cookie.component.scss']
})
export class PrivacyCookieComponent implements OnInit {
  @Input() onlyCookie = false;
  @Output() saveEmitter = new EventEmitter<boolean>();
  showOptions = false;
  showCookie = false;
  cookieAccepted = {pubbl: false, prest: false, ass: false, essential: true};
  constructor(private activateModal: NgbActiveModal) { }

  ngOnInit(): void {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (cookieAccepted) {
      this.cookieAccepted = JSON.parse(cookieAccepted);
    }
    if (this.onlyCookie) {
      this.showOptions = true;
    }
  }


  dismiss(save = false) {
    if (save) {
      localStorage.setItem('cookieAccepted', JSON.stringify(this.cookieAccepted));
      this.saveEmitter.emit(save);
    }
    this.activateModal.close();
  }

  acceptAll() {
    this.cookieAccepted.pubbl = true;
    this.cookieAccepted.prest = true;
    this.cookieAccepted.ass = true;
    this.dismiss(true);
  }
}
