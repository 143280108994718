import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-acquisto-item',
  templateUrl: './acquisto-item.component.html',
  styleUrls: ['./acquisto-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AcquistoItemComponent implements OnInit {
  @Input() item: any;
  @Input() isFirst = false;
  constructor() { }

  ngOnInit(): void {
  }

}
