import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {IAuction} from '../../../objects/IAuction';

@Component({
  selector: 'app-auction-item',
  templateUrl: './auction-item.component.html',
  styleUrls: ['./auction-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuctionItemComponent implements OnInit {
  @Input() auctions: IAuction[];
  @Input() isVisualRow: boolean;
  @Input() isHome = false;
  idRandom = Math.random() * 1000;
  constructor() { }

  ngOnInit(): void {
  }
}
