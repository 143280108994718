<div class="text-center h-100">

  <span *ngIf="label">{{label}}</span>
  <input class="form-control" [ngClass]="{'d-none': label || !isEdit || !showInput}" type="file" min="0" max="100" (change)="readUrl($event)"
         accept="image/*" #fileHidden>
  <image-cropper *ngIf="useCropper && imageChangedEvent"
                 [imageChangedEvent]="imageChangedEvent"
                 [maintainAspectRatio]="true"
                 [aspectRatio]="aspectRatio"
                 format="jpeg"
                 (imageCropped)="imageCropped($event)"
  ></image-cropper>
  <button *ngIf="useCropper && imageChangedEvent" class="btn btn-primary" (click)="finishCropped()">Salva immagine
  </button>

  <div *ngIf="!imageChangedEvent" class="h-100">
    <button class="btn text-center btn-image w-100 p-0" (click)="(isEdit) ? fileHidden.click() : null" *ngIf="!changeImage.data && !model" [style.height.%]="(aspectRatio < 1) ? '100' : '50'">
      <i class="fa fa-image">
      </i>
    </button>
    <img class="w-100 btn p-0" [src]="baseUrl + '/api/images/' + path + '/' + model"
         *ngIf="model && model.length > 0 && changeImage.data.length === 0" (click)="(isEdit) ? fileHidden.click() : null">
    <img class="w-100 btn p-0" [src]="changeImage.data" *ngIf="changeImage.data && changeImage.data.length > 0" (click)="(isEdit) ? fileHidden.click() : null">
  </div>

</div>
