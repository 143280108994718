import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {CommunicatorService} from '../../services/communicator.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  @Input() sharePage: any;
  show = false;
  links = ['fa-facebook', 'messenger', 'fa-twitter', 'whatsapp', 'instagram', 'fa-linkedin'];
  constructor(private communicator: CommunicatorService) {
  }

  ngOnInit(): void {
    this.communicator.backdropShow$.next(true);
    // setTimeout(() => {
      this.show = true;
    // }, 0);
  }

  close() {
    this.show = false;

    // setTimeout(() => {
      this.communicator.backdropShow$.next(false);
      this.communicator.sharePage$.next(null);
    // }, 400);
  }

  share(link: string) {
    const linkFinal = encodeURIComponent(window.location.href);
    switch (link) {
      case this.links[0]:
        window.open('https://www.facebook.com/sharer.php?u=' + linkFinal, '_blank');
        break;
      case this.links[1]:
        window.open('http://www.facebook.com/dialog/send?&link=' + linkFinal + '&redirect_uri=' +  linkFinal, '_blank');
        break;
      case this.links[2]:
        window.open('https://twitter.com/intent/tweet?text=' + linkFinal, '_blank');
        break;
      case this.links[3]:
        window.open('https://wa.me/?text=' + linkFinal, '_blank');
        break;
      case this.links[4]:
        window.open('https://www.instagram.com/?url=' + linkFinal, '_blank');
        break;
      case this.links[5]:
        window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + linkFinal, '_blank');
        break;
    }
  }
}
