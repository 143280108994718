import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IAuction, IOffer} from '../../../objects/IAuction';
import {Store} from '../../../objects/BonusDTO';
import {ICreateOrderRequest, IPayPalConfig, IUnitAmount} from 'ngx-paypal';
import {HttpService} from '../../../services/http.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  @Input() offer: IOffer;
  @Input() auction: IAuction;
  @Input() store: Store;
  @Input() percent: string;
  @Input() value: string;
  @Output() successPay = new EventEmitter<boolean>();
  totalPartial: number;
  total: number;
  amount: IUnitAmount = {
    currency_code: 'EUR',
    value: '0',
    breakdown: {
      item_total: {
        currency_code: 'EUR',
        value: '0'
      }
    }
  };
  public payPalConfig: IPayPalConfig;
  constructor(public activeModal: NgbActiveModal, private httpService: HttpService) { }

  ngOnInit(): void {
    this.auction.other_information = JSON.parse(this.auction.other_information);
    this.totalPartial = +this.offer.offer_num - (this.percent ? +this.value : 0);
    this.total = this.totalPartial + +this.store.shippingcost;
    this.initPaypal(this.store.paypal);
  }

  initPaypal(merchant_id: string) {
    this.amount.value = this.total + '';
    this.amount.breakdown.item_total.value = this.total + '';

    const items = [{
      sku: this.offer.id_auction + '',
      name: this.offer.description,
      quantity: '1',
      category: 'DIGITAL_GOODS',
      unit_amount: {
        currency_code: 'EUR',
        value: this.total.toFixed(2)
      }
    }];
    this.payPalConfig = {
      currency: 'EUR',
      clientId: environment.clientPaypal,
      createOrderOnClient: (data) => ({
        intent: 'CAPTURE',
        purchase_units: [{
          payee: {
            merchant_id
          },
          amount: this.amount,
          items,
          // shipping
        }]
      }) as ICreateOrderRequest,
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });

      },
      onClientAuthorization: (data) => {

        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        // this.showSuccess = true;
        //
        this.sendTransactions(data.id);
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.successPay.emit(false);
        // this.showCancel = true;
      },
      onError: err => {
        console.log('OnError', err);
        this.successPay.emit(false);
        // this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        // this.resetStatus();
      }
    };
  }

  private sendTransactions(paypalcode: string) {
    const body = {
      auction: this.auction,
      offer: this.offer,
      paypalcode,
      totalOrder: this.total,
      costshipping: this.store.shippingcost
    };
    this.httpService.sendOrderAuction(body).subscribe(resp => {
      this.successPay.emit(resp.result);
    });
  }
}
