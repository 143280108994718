import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter} from '../../services/datepicker/custom-adapter.service';
import {CustomDateParserFormatter} from '../../services/datepicker/custom-date-parser-formatter.service';
import {I18n} from '../../services/datepicker/i8n.service';
import {CustomDatepickerI18n} from '../../services/datepicker/custom-datepicker-i18n.service';
import {IProductPrice} from '../../objects/IProduct';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class DateRangeComponent implements OnInit {
  @Input() duration: {rangedate: string, quantitydays: string, choisestartdate: string, choiseenddate: string};
  hoveredDate: NgbDate | null = null;

  minDate: NgbDate;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  innerWidth = 769;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    if (this.duration.rangedate && this.duration.rangedate.length > 0) {
      const dates = this.duration.rangedate.split('*');
      const startDate = dates[0].split('-');
      const finalDate = dates[1].split('-');
      this.minDate = new NgbDate(+startDate[0], +startDate[1], +startDate[2]);
      // this.maxDate = new NgbDate(+finalDate[0], +finalDate[1], +finalDate[2]);
      if (this.minDate.before(this.calendar.getToday())) {
        this.minDate = this.calendar.getToday();
      }
    } else {
      this.minDate = this.calendar.getToday();
      // this.maxDate = this.calendar.getNext(this.calendar.getToday(), 'm', 3);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.duration.quantitydays = '';
      this.duration.choisestartdate = '';
      this.duration.choiseenddate = '';
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      const date1 = new Date(this.fromDate.year, this.fromDate.month, this.fromDate.day);
      const date2 = new Date(date.year, date.month, date.day);
      const diffTime = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this.duration.quantitydays =  diffDays + '';
      this.duration.choisestartdate = this.formatter.format(this.fromDate);
      this.duration.choiseenddate = this.formatter.format(this.toDate);
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.duration.quantitydays = '';
      this.duration.choisestartdate = '';
      this.duration.choiseenddate = '';
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
