import { Pipe, PipeTransform } from '@angular/core';
import {IAuction} from '../../objects/IAuction';

@Pipe({
  name: 'extractDescription'
})
export class ExtractDescriptionPipe implements PipeTransform {

  transform(auction: IAuction, ...args: unknown[]): unknown {
    let textOtherInfo = '';
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    let descr = auction.description;
    if(!auction.other_information) {
      return;
    }
    let other = JSON.parse(auction.other_information);
    let keys = Object.keys(other);
    keys.reverse();
    console.log(keys);
    for (let key of keys) {
      let text = other[key];
      let label = '';
      switch (key) {
        case 'manyPersonHotel':label = 'Ho bisogno delle camere per il seguente numero di persone complessive';break;
        case 'manyRoomsHotel':label = 'Ho bisogno delle seguenti camere';break;
        case 'whatNeed':label = 'Ho bisogno di';break;
        case 'whatStyle':label = 'Con stile';break;
        case 'whatColor':label = 'Con colore';break;
        case 'whatType':label = 'Tessuto';break;
        case 'addDetail':label = 'Con aggiunte particolari';break;
        case 'howDressLong':label = 'Il prodotto lo vorrei';break;
        case 'condition':label = 'Il prodotto deve essere nella condizione';break;
        case 'when':
          let dates = other['date'].split('T')[0];
          dates = dates.split('-');
          let date = new Date(dates[0], Number(dates[1]) - 1, dates[2]);
          if (other[key].includes('indicare il mese')) {
            label = 'Per il mese di';
            text = months[date.getMonth()];
          } else {
            label = 'Per il giorno';
            text = date.toLocaleDateString();
          }
          break;
        case 'heightPerson':label = 'La mia altezza è';break;
        case 'widthPerson':label = 'La mia taglia è';break;
        case 'widthPerson2':label = 'Misure';break;
        case 'widthThing':label = 'Misura dell\'oggetto desiderato';break;
        case 'destination':label = 'Destinazione desiderata';break;
        case 'numberPerson':label = 'Numero indicativo degli invitati';break;
        case 'timeOfService':label = 'Tempo desiderato del servizio';break;
        case 'WithPerson':label = 'Con o senza conducente';break;
        case 'addServices':label = 'Vorrei anche i seguenti servizi aggiuntivi';break;
        case 'timeNeed':label = 'Ho bisogno di questo servizio per';break;
        case 'genreMusic':label = 'Ho bisogno di questi generi di musica';break;
        case 'yearsPerson':label = 'Durante il servizio, l\'età media indicativa è';break;
        case 'serviceType':label = 'Vorrei il seguente servizio';break;
        case 'typeMenu':label = 'Vorrei il seguente menu';break;
        case 'momentEvent':label = 'Per questo momento della giornata';break;
        case 'whereEvent':label = 'L\'evento si svolgerà';break;
        case 'manyPerson':label = 'Gli invitati sono orientativamente';break;
        case 'quantityCars':label = 'Mi occorrono questo numero di mezzi';break;
        case 'look':label = 'Vorrei questo look';break;
        case 'whichService':
          let textSposo = (auction['gender'] === 'M') ? 'interessato' : 'interessata';
          label = 'Sono '+textSposo+' ai seguenti servizi';break;
        case 'sizeShoes':label = 'Misura';break;
        case 'whatAccessories':label = 'Sto cercando questi accessori';break;
        case 'manyPhoto':
          label = 'Vorrei il seguente numero di fotografie post prodotte';break;
        case 'timePhoto':label = 'Ho bisogno del fotografo per questo tempo';break;
        case 'stylePhoto':label = 'Vorrei questo stile fotografico';break;
        case 'distancePhoto':label = 'La distanza tra una location e l\'altra è';break;
        case 'locationPhoto':label = 'Il servizio fotografico si svolgerà per queste location';break;
        case 'ageChildren':label = 'L\'età media dei bambini presenti è';break;
        case 'whatWedding':label = 'Vorrei che il professionista si occupasse dei seguenti servizi';break;
        case 'serviceWedding':label = 'Vorrei i seguenti servizi aggiuntivi';break;
        case 'needMusic':label = 'Ho bisogno del seguente intrattenimento musicale';break;
        case 'whereFlower':label = 'Vorrei gli allestimenti floreali nei seguenti luoghi';break;
        case 'whereWedding':label = 'Il ricevimento si terrà';break;
        case 'siteWedding':label = 'Il luogo della cerimonia sarà';break;
        case 'riteWedding':label = 'Il rito della cerimonia sarà';break;
        case 'styleWedding':label = 'Lo stile del mio matrimonio sarà';break;
        case 'numberPlates':
          label = 'Vorrei il seguente numero di portate per il menu';
          if(text.includes('3 portate')) {
            let labelText = '<div class="portate-div d-inline-flex">&nbsp;</div>' +
              '<div class="portate-ul">2 assaggi di primi piatti<br>1 assaggio di secondo piatto</div><br>';
            let labelReplace = '3 portate';
            if(text.includes('3 portate,')) {
              labelReplace = '3 portate,';
            }
            text = text.replace(labelReplace, '<br>3 portate ' +labelText);
          }
          if(text.includes('4 portate')) {
            let labelText = '<div class="portate-div d-inline-flex">&nbsp;</div>' +
              '<div class="portate-ul">2 assaggi di primi piatti<br>2 assaggio di secondo piatto</div><br>';
            let labelReplace = '4 portate';
            if(text.includes('4 portate,')) {
              labelReplace = '4 portate,';
            }
            text = text.replace(labelReplace, '<br>4 portate ' + labelText);
          }
          if(text.includes('5 portate')) {
            let labelText = '<div class="portate-div d-inline-flex">&nbsp;</div>' +
              '<div class="portate-ul">3 assaggi di primi piatti<br>2 assaggio di secondo piatto</div><br>';
            let labelReplace = '5 portate';
            if(text.includes('5 portate,')) {
              labelReplace = '5 portate,';
            }
            text = text.replace(labelReplace, '<br>5 portate ' +labelText);
          }
          break;
        case 'whereTravel': label = 'Vorrei soggiornare'; break;
        case 'howTravel': label = 'Vorrei effettuare i spostamenti con'; break;
        case 'typeTravel': label = 'Vorrei fare un viaggio tipo'; break;
        case 'timeTravel': label = 'Il viaggio durerà orientativamente'; break;
        case 'childrenTravel':
          if(!text.includes('No')) {
            label = 'La presenza dei bambini e la loro età è';
          }
          break;
        case 'personTravel': label = 'Le persone che viaggeranno saranno'; break;
        case 'locationTravel': label = 'Vorrei raggiungere le seguenti località';
          if(other['locationTravel'].includes('Si, ho in mente')) {
            text = other['moreLocationTravel'];
          }
          break;
      }
      if (label.length > 0) {
        textOtherInfo += '<p class="mt-2 mb-0 font-weight-bold">' + label + '</p><span class="w-100">' + text + '</span>';
      }
    }
    if (descr && descr.length > 0) {
      return textOtherInfo + '<p class="mt-2 mb-0 font-weight-bold">Altre informazioni</p><span class="w-100">' + descr + '</span>';
    }
  }

}
