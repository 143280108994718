<app-header [style.opacity]="isServer ? 0 : 1"></app-header>


<div
  [style.opacity]="isServer ? 0 : 1"
  [@.disabled]="isFirstLoad"
  [class]="!['new-auction', 'store-detail', 'product-detail'].includes(getState(o)) ? 'router-wrapper' : ''"
  [@moveFromBottomFade]="getState(o)"
  [@moveFromRightFade]="getState(o)"
  [@moveFromLeftFade]="getState(o)"
  >

  <div [ngClass]="{'backdrop' : communicatorService.backdropShow$ | async}">
  </div>

  <div [style.opacity]="isServer ? 0 : 1">
    <router-outlet #o="outlet"></router-outlet>
  </div>
</div>

<app-footer></app-footer>

<ng-container *ngIf="communicatorService.images$ | async as images">

  <app-photos-detail *ngIf="images.photos" [photos]="images.photos" [select]="images.select"></app-photos-detail>
</ng-container>

<ng-container *ngIf="communicatorService.sharePage$ | async as sharePage">
  <app-share *ngIf="sharePage" [sharePage]="sharePage"></app-share>
</ng-container>


<ng-container *ngIf="eventSubscription$ | async as scrollY">
  <app-scroll-up *ngIf="scrollY > 0"></app-scroll-up>
</ng-container>
