<div class="background-lightgray">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-8">

        <h4>Fornitori
          <ng-container *ngIf="getKeySub() as obj">
            - {{obj.subcategory}}
          </ng-container>
        </h4>
      </div>
      <div class="col text-right">
        <a routerLink="/">Home</a>
        <ng-container *ngIf="breadcrumbs[0]">
          / <a [routerLinkActive]="breadcrumbs[1] != null ? '' : 'disabled'"
               [routerLink]="'/vetrine-fornitori/' + breadcrumbs[0]">{{breadcrumbs[0]}}</a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid pl-md-0">

  <div [class]="classMenuLeft">
    <div id="menu-left" [class]="classMenuLeft">
      <app-filter-left  [subcategories]="subcategories" [(city)]="city" (close)="openLeftMenu(false)"
                        (selectAll)="selectCategory($event);"
                        (cityChange)="changeFilter(null);" (subcategoryChange)="changeFilter($event);">

      </app-filter-left>
    </div>
  </div>
  <div [class]="classMenuRight">
    <div id="menu-right" [class]="classMenuRight">
      <app-order-right [orders]="orders" [(order)]="order"
                       (orderChange)="openRightMenu(false);" (close)="openRightMenu(false);">

      </app-order-right>
    </div>
  </div>
  <div class="row">
    <div class="d-none d-md-block col-md-3">
      <app-filter-left [subcategories]="subcategories" [(city)]="city" (cityChange)="changeFilter(null)"
                       (selectAll)="selectCategory($event)"
                       (subcategoryChange)="changeFilter($event)"></app-filter-left>
    </div>
    <div class="col">
      <div class="row mt-1 d-none d-md-flex">
        <div class="col col-md-3 pl-1">
          <select class="form-control btn btn-tertiary" [(ngModel)]="order.id" (ngModelChange)="checkValues(order.id)">
            <option value="" disabled selected>Ordina per:</option>
            <option [value]="item.id" *ngFor="let item of orders">{{item.label}}</option>
          </select>
        </div>
        <div class="col-auto col-md text-right">
          <i class="color-primary fa fa-bars mr-1 fa-2x" [class.color-lightpink]="!isRow" [class.color-primary]="isRow"
             (click)="isRow = true"></i>
          <i class="color-lightpink fa fa-th-large fa-2x" [class.color-lightpink]="isRow" [class.color-primary]="!isRow"
             (click)="isRow = false"></i>
        </div>
      </div>
      <div class="row mt-1 d-flex d-md-none">
        <div class="col-5">
          <button class="btn btn-tertiary w-100" (click)="openLeftMenu(true)">Filtra per:</button>
        </div>
        <div class="col-5 text-right offset-2">
          <button class="btn btn-tertiary w-100" (click)="openRightMenu(true)">Ordina per:</button>
        </div>
      </div>

      <ng-container *ngIf="vetrine$ | async as vetrine else loading">
        <div *ngIf="vetrine.length > 0 else noRecords">
          <app-vetrina-element *ngFor="let vetr of vetrine | sortByKey:order.key:order.value" [vetrina]="vetr" [isRow]="isRow"></app-vetrina-element>
        </div>
        <ng-template #noRecords>

          <div class="alert alert-warning mt-3" role="alert">
            Siamo spiacenti, non sono presenti fornitori
          </div>
        </ng-template>
      </ng-container>
      <ng-template #loading>
        <app-vetrina-loading></app-vetrina-loading>
      </ng-template>
    </div>
  </div>

</div>
