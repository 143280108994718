
<div class="container">
  <div class="card my-3">
    <div class="card-header background-lightpink">Profilo personale</div>
    <div class="card-body" *ngIf="userInfo as user">
        <div class="row">
          <div class="col-12 col-sm-4">
            <div class="image position-relative">
              <img *ngIf="image.data || userInfo.image else noImage" id="imgCropped" class="w-100"
                   [src]="image.data || 'https://www.weddingsmart.it/api/images/users/' + userInfo.image + '?id=' + cacheClear">
              <ng-template #noImage>
                <img class="w-100" src="https://www.weddingsmart.it/assets/images/user.png">
              </ng-template>

              <button class="btn rounded" type="button" (click)="showModal(modalImage)"><i class="fas fa-camera"></i>
              </button>
            </div>
          </div>
          <div class="col-12 col-sm-8">
              <span class="text-black-50">Nome utente:</span>
              <span class="ml-1 font-weight-bold">{{user.username}}</span>
            <br>
              <span class="text-black-50">Password:</span>
            <button class="ml-1 btn btn-sm btn-primary" type="button" (click)="showModal(modalPassword)">Modifica</button>
            <br>
              <span class="text-black-50"><i class="fas fa-envelope"></i> Email:</span>
              <span class="ml-1 font-weight-bold">{{user.email}}</span>
            <br>
              <span class="text-black-50"><i class="far fa-calendar-check"></i> Creato il :</span>
              <span class="ml-1 font-weight-bold">{{user.created}}</span>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-header background-lightpink" id="headingOne">
            <label class="mb-0">
              Completa il tuo profilo per poter inserire annunci
            </label>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="namesurname">Nome e cognome:</label>
                  <input type="text" class="form-control" [(ngModel)]="user.fullname" required
                         [class.valid]="showError && user.fullname" [class.novalid]="showError && !user.fullname"
                         placeholder="Inserisci il tuo nome e cognome" id="namesurname">
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="phone">Telefono:</label>
                  <input type="tel" class="form-control" required
                         [class.valid]="showError && user.phone" [class.novalid]="showError && !user.phone"
                         placeholder="Inserisci il tuo numero di telefono"
                         id="phone" [(ngModel)]="user.phone">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="location">Indirizzo di residenza:</label>
                  <input type="text" [(ngModel)]="user.address" class="form-control valid" required
                         [class.valid]="showError && user.address" [class.novalid]="showError && !user.address"
                         placeholder="Inserisci la tua residenza" id="location">
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="person">Sposo o sposa:</label>
                  <select class="custom-select" [(ngModel)]="user.gender" required
                          [class.valid]="showError && user.gender" [class.novalid]="showError && !user.gender"
                          id="person">
                    <option value="M">Sposo</option>
                    <option value="F">Sposa</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="date">Data di nozze:</label>
                  <div class="input-group">

                    <input id="date" class="form-control bg-white" readonly
                           (click)="d.toggle()" [(ngModel)]="weddingDate" (ngModelChange)="setDate(weddingDate)" ngbDatepicker #d="ngbDatepicker"
                           [class.valid]="showError && weddingDate" [class.novalid]="showError && !weddingDate">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="wedding-locality">Luogo delle nozze:</label>
                  <input type="text" [(ngModel)]="user.wedding_locality" class="form-control" required
                         [class.valid]="showError && user.wedding_locality" [class.novalid]="showError && !user.wedding_locality"
                         placeholder="Inserisci il luogo delle nozze" id="wedding-locality" autocomplete="off">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <p>Puoi aggiornare i tuoi dati in qualsiasi momento:</p>
          </div>

          <div class="col-12">
            <div class="custom-control custom-checkbox mb-3">
              <input type="checkbox" class="custom-control-input" value="1"
                     [checked]="user.advertising === '1'"
                     (change)="user.advertising = $event.target['checked'] ? '1' : '0'"
                     id="checkAdv">
              <label class="custom-control-label" for="checkAdv">Acconsento al trattamento dei miei dati per
                finalità di marketing</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button type="button" class="btn btn-primary" (click)="save()">Salva</button>
          </div>
        </div>
    </div>
  </div>

  <ng-template #modalImage let-modal>
    <div class="modal-body">
      <h4>Seleziona l'immagine e scegli con cura le dimensioni</h4>
      <div class="row">
        <div class="col-12 mt-3">
          <input class="form-control" type="file" accept="image/*" (change)="imageChangedEvent = $event">
        </div>
        <div class="col-12 mt-3">
          <image-cropper *ngIf="imageChangedEvent"
                         [imageChangedEvent]="imageChangedEvent"
                         [maintainAspectRatio]="true"
                         [roundCropper]="true"
                         format="png"
                         (imageCropped)="imageCropped($event)"
          ></image-cropper>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary" (click)="modal.dismiss()">Annulla</button>
      <button class="btn btn-primary" (click)="finishCropped(modal); ">Ho finito</button>
    </div>
  </ng-template>

  <ng-template #modalPassword let-modal>
    <div class="modal-body">
      <h4>Cambio password</h4>
      <div class="row">
        <div class="col-12 mt-3 col-p">
          <label class="col-form-label" for="current-password">Password corrente:</label>
          <input class="form-control" type="password" id="current-password" [(ngModel)]="password.current">
          <div class="invalid-feedback d-block" *ngIf="showErrorPass && !password.current">
            Campo obbligatorio
          </div>
          <div class="invalid-feedback d-block" *ngIf="showErrorPass && notCurrentPassValid">
            La password corrente è diversa dalla password dell'accesso
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3 col-np">
          <label class="col-form-label" for="password">Nuova password:</label>
          <input class="form-control" type="password" id="password" autocomplete="new-password" [(ngModel)]="password.new">
          <div class="invalid-feedback d-block" *ngIf="showErrorPass && !password.new">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3 col-rnp">
          <label class="col-form-label" for="password2">Ripeti nuova password:</label>
          <input class="form-control" type="password" id="password2" [(ngModel)]="password.new2">
          <div class="invalid-feedback d-block" *ngIf="showErrorPass && !password.new2">
            Campo obbligatorio
          </div>
          <div class="invalid-feedback d-block" *ngIf="showErrorPass && password.new2 !== password.new">
            Le due password non corrispondono
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary" (click)="modal.dismiss()">Annulla</button>
      <button class="btn btn-primary" (click)="changePassword(modal);">Salva</button>
    </div>
  </ng-template>
</div>
