
<div class="item-order">
  <h6 class="pl-2 title-group" *ngIf="isFirst"></h6>
  <div class="position-relative">
    <label class="title-item mb-0">Ordine #{{item.id}}</label>
    <a class="float-right title-item mb-0 mr-3" [routerLink]="'/effettua-reso/order/' + item.id" *ngIf="item.reso as reso">{{
        (reso.paypaltransaction) ? 'Rimborsato' : 'Restituito'
      }}</a>
    <span class="float-right title-item mb-0 mr-3" *ngIf="!item.reso">
      {{item.states[0].state_order}}
    </span>
    <br>
    <label class="pl-2 mt-subtitle small-font budget text-black-50 font-600">
      Store: <a [routerLink]="'/store/' + item.macrosector + '/' + item.sector + '/' + item.seller">{{item.seller}}</a>
    </label>
    <br>
  </div>
  <div class="row mt-less-5">
    <div class="col-6 pr-0">
      <label class="pl-2 small-font budget text-black-50 mb-0">Prodotti acquistati</label>
    </div>
    <div class="col-6 text-right">
      <b style="font-size: 1rem;" class="pl-2 text-black-50 mr-3"> {{item.products.length}}</b>
    </div>
  </div>
  <ng-container *ngIf="item | totalProducts as totals">
    <div class="row mt-less-5">
      <div class="col-6 pr-0">
        <label class="pl-2 small-font budget text-black-50 mb-0">Importo</label>
      </div>
      <div class="col-6 text-right"><b style="font-size: 1rem;" class="pl-2 mr-3"> {{totals.total + '' | convertPrice:false:true}}</b></div>
    </div>
    <div class="row mt-less-5">
      <div class="col"><label class="pl-2 small-font budget text-black-50 mb-0">Bonus Sposi Wedding
        Smart</label>
      </div>
      <div class="col-auto text-right"><b style="font-size: 1rem;" class="text-black-50 mr-3">
        <b style="font-size: 0.8rem;" class="text-success">-{{totals.bonus}}%</b> - {{totals.totalBonus + '' | convertPrice:false:true}}</b>
      </div>
    </div>
    <div class="row mt-less-5">
      <div class="col">
        <label class="pl-2 small-font budget text-black-50 mb-0">Costo di spedizione</label>
      </div>
      <div class="col-auto text-right"><b style="font-size: 1rem;" class="text-black-50 mr-3">{{totals.shipping + '' | convertPrice:false:true}}</b>
      </div>
    </div>
  <hr class="hr-total">
  <div class="row mt-less-5">
    <div class="col text-right total-text">
      <div class="col text-right color-blue">
        <b>Totale</b> <span> (Iva inc.)</span>
      </div>
    </div>
    <div class="col-auto text-right mt-auto"><b style="font-size: 1.4rem;" class="mr-3">{{item.total + '' | convertPrice:false:true}} </b>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <div class="position-relative d-inline-block mb-less-5">
        <img src="https://www.weddingsmart.it/assets/icons/perc_discount.png" width="90">
        <label class="sum_percentage" [style.left.px]="totals.percentageTotal.length === 4 ? 19 : 17">
          <span class="less" [style.left.px]="totals.percentageTotal.length === 4 ? -3 : -7">-</span>{{totals.percentageTotal}}
          <span class="perc">%</span>
        </label>
      </div>
      <small class="sum_perc_desc">su questo ordine</small>
    </div>
    <div class="col-auto mt-auto text-right pr-4">
      <button class="btn btn-success pr-1 pl-1" [routerLink]="'/order-detail/order/' + item.id">Dettaglio ordine</button>
    </div>
  </div>
  </ng-container>
  <hr class="divisor mt-1">
</div>
