import { Pipe, PipeTransform } from '@angular/core';
import {Review} from '../../../objects/IVetrina';

@Pipe({
  name: 'reviewsByVote'
})
export class ReviewsByVotePipe implements PipeTransform {

  transform(reviews: Review[], vote: string, ...args: unknown[]): Review[] {
    console.log(vote);
    return reviews.filter(e => vote === '0' || e.vote === vote);
  }

}
