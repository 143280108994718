import {Component, Inject, OnInit, PLATFORM_ID, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpService} from '../../services/http.service';
import {CommunicatorService} from '../../services/communicator.service';
import {IBudget} from './IBudget';
import {IAuction} from '../../objects/IAuction';
import {IAddress} from './IAddress';
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {ConfirmComponent} from '../../components/popup/confirm/confirm.component';
import {ActivatedRoute} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
@Component({
  templateUrl: './my-auctions.component.html',
  styleUrls: ['./my-auctions.component.scss'],
})
export class MyAuctionsComponent implements OnInit {
  budget: IBudget;
  auctions: IAuction[] = [];
  transactions: any[];
  auctionsFinal: {auctions: any[], orders: any[]};
  addresses: IAddress[];
  showEditBudget = false;
  activeId = 0;
  activeIdPagamenti = 0;
  showRisparmio = false;
  editAddress: IAddress;
  filterTypeAuctions: number = null;
  filterType: number = null;
  isMobile = true;
  constructor(private httpService: HttpService, private activatedRoute: ActivatedRoute,
              @Inject(PLATFORM_ID) private platformId: object,
              public communicator: CommunicatorService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 769;
      const tab = this.activatedRoute.snapshot.paramMap.get('tab');
      if (tab) {
        this.activeId = +tab;
      }
      this.getBudget();
      this.getAuctions();
      this.getAuctionsFinal();
      this.getAddresses();
    }
  }

  private getBudget() {
    this.httpService.getBudget().subscribe(resp => {
      this.budget = resp;
      if (!this.budget.budget) {
        this.budget.budget = 0;
      }
      if (this.budget.budget_used === null) {
        this.budget.budget_used = 0;
      }
    });
  }

  private getAuctions() {
    this.httpService.getMyAuctions().subscribe(resp => {
      this.auctions = resp;
    });
  }
  private getAuctionsFinal() {
    this.httpService.getMyAuctionsFinal().subscribe(resp => {
      this.auctionsFinal = resp;
      this.processTransactions(resp.auctions, resp.orders);
    });
  }

  private getAddresses() {
    this.httpService.getAddresses().subscribe(resp => {
      this.addresses = resp;
    });
  }

  saveAddress(dismiss) {
    this.httpService.saveAddress(this.editAddress).subscribe(resp => {
      dismiss();
      const mr2 = this.modalService.open(PopupComponent);
      mr2.componentInstance.title = 'Attenzione';
      mr2.componentInstance.body = 'Segnalazione effettuata con successo';
      this.getAddresses();
    });
  }
  deleteAddress(addr: IAddress = null) {
    const mr2 = this.modalService.open(ConfirmComponent);
    const address = addr.address + ' ' + addr.number + ', ' + addr.cap + ', ' + addr.city;
    mr2.componentInstance.title = 'Attenzione';
    mr2.componentInstance.description = 'Sei sicuro di voler eliminare l\'indirizzo ' + address + ' ?';
    mr2.componentInstance.confirm.subscribe(() => {
      mr2.dismiss();
      this.httpService.deleteAddress(addr.id).subscribe(() => {
        this.getAddresses();
      });
    });
  }

  showAddress(modalAddress: TemplateRef<any>, address: IAddress = null) {
    if (address) {
      this.editAddress = JSON.parse(JSON.stringify(address));
    } else {
      this.editAddress = {id: null, city: '', cap: '', number: '', address: ''};
    }
    this.modalService.open(modalAddress);
  }

  private processTransactions(auctions, orders) {
    const auctionsOrder = auctions.filter(e => e.orderid).map(e => ({...e, auctionid: e.id, id: e.orderid}));
    const ordersOrder = orders.map(e => ({...e, expired_at: e.timestamp, timestamp: e.timestamp_order}));
    this.transactions = [...auctionsOrder, ...ordersOrder];

    this.transactions.sort((a, b) => a.expired_at > b.expired_at ? -1 : 1);
  }

  onlyPrice(event: any) {

    const key = event.key;
    if (key >= 0 && key <= 9 || (key === ',' && !event.target.value.includes(',')) ||
      (key === '.' && !event.target.value.includes('.')) ||  key === 'Backspace') {
    } else {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  checkPrice(element: any) {

    const values = element.value.split(',');
    let int = values[0].split('.').join('');
    let decimal = '';
    if (int.length > 3) {
      int = int.substring(0, int.length - 3) + '.' + int.substring(int.length - 3);
    }
    if (element.value.includes(',')) {
      if (values[1] ) {
        decimal = values[1];
        if (decimal.length > 2) {
          decimal = decimal.substring(0, 2);
        }
      }
      element.value = int + ',' + decimal;
    } else {
      element.value = int;
    }
  }

  saveBudget(value: string) {
    value = value.split('.').join('').replace(',', '.');
    this.httpService.postBudget(value).subscribe(resp => {
      this.budget.budget = +value;
      this.showEditBudget = false;
    });
  }

  transformBudget() {
    return (this.budget.budget + '').replace('.', ',');
  }
}
