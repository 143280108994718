import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(values: any[], key: string, value: string, ...args: unknown[]): any[] {
    return values.filter(e => e[key].toLowerCase().includes(value.toLowerCase()));
  }

}
