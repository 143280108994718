import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbCarousel, NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {CommunicatorService} from '../../services/communicator.service';

@Component({
  selector: 'app-photos-detail',
  templateUrl: './photos-detail.component.html',
  styleUrls: ['./photos-detail.component.scss']
})
export class PhotosDetailComponent implements AfterViewInit {
  @Input() photos: string[];
  @Input() select: string;

  constructor(private communicator: CommunicatorService) {
  }

  ngAfterViewInit(): void {
    let el = document.getElementById(this.select);
    el.scrollIntoView();
  }

  closeCarousel() {
    this.communicator.images$.next({photos: null, select: null});
  }
}
