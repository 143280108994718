import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampDiff'
})
export class TimestampDiffPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const now = new Date();
    const timestamp = new Date(value);
    const days = ((now.getTime() - timestamp.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0);
    if (days === '0') {
      const hours =  ((now.getTime() - timestamp.getTime()) / (1000 * 60 * 60)).toFixed(0);
      if (hours === '0') {
        const minutes =  ((now.getTime() - timestamp.getTime()) / (1000 * 60)).toFixed(0);
        let minutesString = minutes === '1' ? 'minuto' : 'minuti';
        return minutes + ' ' + minutesString + ' fa';
      }else {
        let hoursString = hours === '1' ? 'ora' : 'ore';
        return hours + ' ' + hoursString + ' fa';
      }
    }else {
      let daysString = days === '1' ? 'giorno' : 'giorni';
      return days + ' ' + daysString + ' fa';
    }
  }

}
