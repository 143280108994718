import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MENU_LINKS} from '../../objects/Constants';
import {environment} from '../../../environments/environment';
import {Observable, of, OperatorFunction} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap, toArray} from 'rxjs/operators';
import {HttpService} from '../../services/http.service';
import {VetrineService} from '../../services/vetrine.service';

@Component({
  selector: 'app-filter-left',
  templateUrl: './filter-left.component.html',
  styleUrls: ['./filter-left.component.scss']
})
export class FilterLeftComponent implements OnInit {
  MENU_LINKS = MENU_LINKS;
  url = environment.urlApi;
  searching = false;
  searchFailed = false;
  @Input() subcategories: {'Sposa' : string[], 'Sposo': string[], 'Fornitori': string[], 'Ricevimento': string[]};
  @Output() subcategoryChange = new EventEmitter<{'category': string, 'subcategory': string}>();
  @Input() city: string;
  @Input() typeAuctions: string = null;
  @Output() cityChange = new EventEmitter<string>();
  @Output() typeAuctionsChange = new EventEmitter<string>();
  @Output() close = new EventEmitter();
  @Output() selectAll = new EventEmitter<{isSelect: boolean, macro: string}>();

  constructor(private httpService: HttpService, public vetrineService: VetrineService) { }

  ngOnInit(): void {
  }
  resetCollapsed(index) {
    const value = this.vetrineService.isCollapsed[index];
    this.vetrineService.isCollapsed = [true, true, true, true, true];
    this.vetrineService.isCollapsed[index] = !value;
  }
  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(term => term.length > 2),
      tap(() => this.searching = true),
      switchMap(term =>
        this.httpService.searchCity(term).pipe(
          tap((response) => this.searchFailed = response.length === 0),
          switchMap(array => array),
          map(el => el.city),
          toArray(),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )


  check(item: string) {
    this.city = item.split(' (')[0];
  }
}
