<div class="container pt-5 pb-5" id="container-esito">

  <div class="alert alert-success" role="alert" *ngIf="result === 'success'">
    <h4>Congratulazioni!</h4><br>
    <label>Hai confermato la tua email con successo, ora puoi usare tutti i nostri servizi
      <ng-container *ngIf="showAll">
        <br>
        Prima di andare avanti però devi flagare le caselle sotto e leggere i Termini e Condizioni del portale e le varie
        informative.
        <br>
        Facendo click sul tasto "Completa profilo"
      </ng-container>
      <br>
    </label>
    <div class="row" *ngIf="showAll">
      <div class="col-12">
        <div class="custom-control custom-checkbox mb-3">
          <input type="checkbox" (change)="macro.regulation = $event.target['checked'] ? '1' : '0'"
                 class="custom-control-input"
                 [checked]="macro.regulation === '1'"
                 id="checkRegolamento" required>
          <label class="custom-control-label" for="checkRegolamento">Dichiaro di aver preso visione dei
            Termini e Condizioni di
            <button class="btn btn-link p-0" (click)="openWeddingsmart()"> Weddingsmart.it</button>
            *</label>
        </div>
      </div>
      <div class="col-12">
        <div class="custom-control custom-checkbox mb-3">
          <input type="checkbox" (change)="macro.cookie = $event.target['checked'] ? '1' : '0'"
                 class="custom-control-input" value="1"
                 [checked]="macro.cookie === '1'"
                 id="checkCookie">
          <label class="custom-control-label" for="checkCookie">Dichiaro di aver preso visione
            <button class="btn btn-link p-0" (click)="openCookie()">dell'informativa cookies</button>
            *</label>
        </div>
      </div>
      <div class="col-12">
        <div class="custom-control custom-checkbox mb-3">
          <input type="checkbox" (change)="macro.privacy = $event.target['checked'] ? '1' : '0'"
                 class="custom-control-input" value="1"
                 id="checkPrivacy"
                 [checked]="macro.privacy === '1'">
          <label class="custom-control-label" for="checkPrivacy">
            Dichiaro di aver preso visione
            <button class="btn btn-link p-0" (click)="openPrivacy()">
              dell'informativa privacy*
            </button>
          </label>
        </div>
      </div>
      <div class="col-12">
        <div class="custom-control custom-checkbox mb-3">
          <input type="checkbox" class="custom-control-input" value="1"
                 [checked]="macro.personal === '1'"
                 (change)="macro.personal = $event.target['checked'] ? '1' : '0'"
                 id="checkProfi">
          <label class="custom-control-label" for="checkProfi">Acconsento al trattamento dei miei dati
            personali
            per lo svolgimento di attività di profilazione*</label>
        </div>
      </div>
      <div class="col-12">
        <div class="custom-control custom-checkbox mb-3">
          <input type="checkbox" class="custom-control-input" value="1"
                 [checked]="macro.advertising === '1'"
                 (change)="macro.advertising = $event.target['checked'] ? '1' : '0'"
                 id="checkAdv">
          <label class="custom-control-label" for="checkAdv">Acconsento al trattamento dei miei dati per
            finalità di marketing</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3" *ngIf="result === 'success'">
    <div class="col-12 text-center">
      <button class="btn btn-primary"
              [disabled]="macro.regulation === '0' || macro.cookie === '0' || macro.privacy === '0' || macro.personal === '0'"
              (click)="sendUpdate()"
      >Completa profilo
      </button>
    </div>
  </div>

  <div class="alert alert-danger" role="alert" *ngIf="result === 'failed'">
    <h4>Attenzione!</h4><br>
    <label>Purtroppo non è stato possibile confermare la tua email</label>
  </div>
</div>
