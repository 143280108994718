import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {INotification} from '../../../../objects/INotification';
import {NOTIFICATION_TYPES} from 'src/app/objects/Constants';
import {HttpService} from '../../../../services/http.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: INotification;
  @Output() refreshNotification = new EventEmitter();
  @Output() deleteEmitter = new EventEmitter();
  @Output() closeNotifications = new EventEmitter();
  NOTIFICATION_TYPES = NOTIFICATION_TYPES;
  constructor(private httpService: HttpService, private router: Router) { }

  ngOnInit(): void {
  }

  sendRead(not: INotification) {
    not.read = '1';
    this.httpService.sendRead(not.id).subscribe(() => {
      this.refreshNotification.emit();
      if (not.type === '9') {
        const text = not.text.split(' ');
        this.router.navigateByUrl('/effettua-reso/order/' + text[text.length - 1]);
        this.closeNotifications.emit();
      } else if (not.type === '4') {
        this.httpService.getProductById(not.idaction).subscribe(resp => {
          this.router.navigateByUrl('/prodotto/' + resp.code + '/' + resp.title);
        });
        this.closeNotifications.emit();
      } else if (not.type === '3') {
        window.location.href = '/portal/order-detail/' + not.idaction;
      } else if (not.type === '10') {
        window.location.href = '/portal/payments?id=' + not.idaction;
      } else if (not.type === '11') {
        window.location.href = '/portal/warnings/' + not.username + '/1';
      } else if (not.type === '12') {
        window.location.href = '/portal/subscriptions';
      } else if (not.type === '14' || not.type === '16') {
        window.location.href = '/portal/auctions/' + not.idaction;
      } else if (not.type === '15') {
        window.location.href = '/portal/offers/' + not.idaction;
      }
    });
  }
}
