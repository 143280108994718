
<div class="container pt-3 pb-3">
  <div *ngIf="!isPositive">

    <h5>Siamo spiacenti che si sia verificato un problema con la consegna</h5>
    <label>Facci sapere cosa non ha funzionato</label>
    <div class="card">
      <div class="card-body p-0">
        <div class="row no-gutters">
          <div class="col-12 col-md-6 p-2 border-bottom">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="check1" (click)="checkOrDelete('no-1')"  [class.check]="badChoose.includes('no-1')">
              <label class="custom-control-label" for="check1">L'autista non indossava la mascherina</label>
            </div>
          </div>
          <div class="col-12 col-md-6 p-2 border-bottom">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="check2" (click)="checkOrDelete('no-2')"  [class.check]="badChoose.includes('no-2')">
              <label class="custom-control-label" for="check2">La consegna è avvenuta prima del previsto</label>
            </div>
          </div>
          <div class="col-12 col-md-6 p-2 border-bottom">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="check3" (click)="checkOrDelete('no-3')"  [class.check]="badChoose.includes('no-3')">
              <label class="custom-control-label" for="check3">La consegna è avvenuta in ritardo</label>
            </div>
          </div>
          <div class="col-12 col-md-6 p-2 border-bottom">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="check4" (click)="checkOrDelete('no-4')"  [class.check]="badChoose.includes('no-4')">
              <label class="custom-control-label" for="check4">Il pacco è stato consegnato all'indirizzo sbagliato</label>
            </div>
          </div>
          <div class="col-12 col-md-6 p-2 border-bottom">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="check5" (click)="checkOrDelete('no-5')"  [class.check]="badChoose.includes('no-5')">
              <label class="custom-control-label" for="check5">L'autista non ha seguito le istruzioni per la
                consegna</label>
            </div>
          </div>
          <div class="col-12 col-md-6 p-2 border-bottom">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="check6" (click)="checkOrDelete('no-6')"  [class.check]="badChoose.includes('no-6')">
              <label class="custom-control-label" for="check6">Non ho mai ricevuto il pacco</label>
            </div>
          </div>
          <div class="col-12 col-md-6 p-2 border-bottom">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="check7" (click)="checkOrDelete('no-7')"  [class.check]="badChoose.includes('no-7')">
              <label class="custom-control-label" for="check7">Ero a casa ma l'autista non ha suonato il
                campanello</label>
            </div>
          </div>
          <div class="col-12 col-md-6 p-2 border-bottom">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="check8" (click)="checkOrDelete('no-8')"  [class.check]="badChoose.includes('no-8')">
              <label class="custom-control-label" for="check8">L'articolo era errato/il pacco era danneggatio</label>
            </div>
          </div>
          <div class="col-12 col-md-6 p-2 border-bottom">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="check9" (click)="checkOrDelete('no-9')"  [class.check]="badChoose.includes('no-9')">
              <label class="custom-control-label" for="check9">Il corriere non è stato professionale</label>
            </div>
          </div>
          <div class="col-12 col-md-6 p-2">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="check10" (click)="checkOrDelete('no-10')"  [class.check]="badChoose.includes('no-10')">
              <label class="custom-control-label" for="check10">Le informazioni di tracciabilità erano errate o
                incomplete</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isPositive">
    <h5>Ti ringraziamo per la valutazione</h5>
<!--    <a href="">Acquista dallo store del fornitore</a>-->
    <label>Puoi anche lasciare un complimento per chi ha effettuato la consegna:</label>
    <div class="row">
      <div class="col-6 text-center">
        <div class="rounded border pt-3 pb-3"(click)="choose = choose === 'si-1' ? '' : 'si-1'" id="si-1" [class.check]="choose === 'si-1'">
          <i class="fa fa-shipping-fast fa-2x color-primary">

          </i><br>
          <small>Consegna puntuale</small>
        </div>
      </div>
      <div class="col-6 text-center">
        <div class="rounded border pt-3 pb-3" (click)="choose = choose === 'si-2' ? '' : 'si-2'" id="si-2" [class.check]="choose === 'si-2'">
          <i class="fa fa-dolly fa-2x color-primary">

          </i><br>
          <small>Cura nella consegna</small>
        </div>
      </div>
      <div class="col-6 text-center mt-3">
        <div class="rounded border pt-3 pb-3"(click)="choose = choose === 'si-3' ? '' : 'si-3'" id="si-3" [class.check]="choose === 'si-3'">
          <i class="fa fa-user-graduate fa-2x color-primary">

          </i><br>
          <small>Corriere professionale</small>
        </div>
      </div>
      <div class="col-6 text-center mt-3">
        <div class="rounded border pt-3 pb-3" (click)="choose = choose === 'si-4' ? '' : 'si-4'" id="si-4" [class.check]="choose === 'si-4'">
          <i class="far fa-smile fa-2x color-primary">

          </i><br>
          <small>Corriere gentile</small>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-primary mt-3 w-100" [disabled]="choose === ''"
          id="sendButton" (click)="sendInfo()">Invia</button>
</div>
