import { Pipe, PipeTransform } from '@angular/core';
import {IMessage} from "./IMessage";

@Pipe({
  name: 'getOnlyNewMessages'
})
export class GetOnlyNewMessagesPipe implements PipeTransform {

  transform(messages: IMessage[], isNew = true, max = 0, ...args: unknown[]): IMessage[] {
    let array: IMessage[];
    if (isNew) {
      array = messages.filter(e => e.readed === '0');
    } else {
      array = messages.filter(e => e.readed === '1');
    }
    if (max !== 0) {
      return array.slice(0, max);
    }
    return array;
  }

}
