<ng-container *ngIf="offerDetail">

  <div class="background-lightgray">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-8">

          <h4>Dettaglio offerta
          </h4>
          <label>Venduto da:
            <a
              [routerLink]="'/store/' + offerDetail.business_macrosector + '/' + offerDetail.business_sector + '/' + offerDetail.code_online">
              {{offerDetail.business_name}}</a>
          </label>
        </div>
        <div class="col text-right">
          <a routerLink="/">Home</a>
          /
          <a routerLink="/auctions">Annunci in corso</a>
          /
          <a [routerLink]="'/auctions/detail/' + offerDetail.id_auction">{{offerDetail.auctiontitle}}</a>

        </div>
      </div>
    </div>
  </div>


  <div class="body py-3 background-lightgray">
    <div class="container-fluid" id="offerta-dettaglio">

      <div class="row m-0 m-md-3" [class.winner]="offerDetail['offer_winner'] === offerDetail['offer_number']">
        <div class="col-12 col-lg-6 text-center mt-auto mb-auto pt-1 px-1 p-lg-0 position-relative">
          <img *ngIf="offerDetail['offer_winner'] === offerDetail['offer_number']" class="textWinner"
               src="https://www.weddingsmart.it/assets/images/winner.png">
<!--          <div class="w-100 d-flex overflow-auto">-->
<!--            <div class="w-flex-100">-->
<!--              <img class="w-flex-100" [src]="'http://93.42.249.60/api/images/offers/' + offerDetail.imgname">-->
<!--            </div>-->
<!--            <div class="w-flex-100" *ngIf="offerDetail.imgname2" >-->
<!--              <img class="w-flex-100"-->
<!--                   [src]="'http://93.42.249.60/api/images/offers/' + offerDetail.imgname2">-->
<!--            </div>-->
<!--          </div>-->

          <app-offer-image-gallery [offer]="offerDetail"></app-offer-image-gallery>
          <small class="percentage">{{percent}}%</small>

        </div>
        <div class="col-12 px-1 d-block d-lg-none text-center" style="margin-top: -1px;" *ngIf="communicator.checkUser$ | async as user">

          <div [class]="'bg-white row-hearts mb-3 vote-' + offerDetail['vote']"
               [ngbTooltip]="(user && user.isUser === '1' && user.username === offerDetail.username) ?
                  'Aiuta i fornitori a offrirti il meglio per le tue preferenze mettendo un voto a questa offerta' :
                  'Questo voto indica il punteggio di quanto è di buon grado questa offerta, allo sposo, in base alle sue preferenze'">
            <i class="m-1 fas fa-heart" (click)="setVotes(1)"></i>
            <i class="m-1 fas fa-heart" (click)="setVotes(2)"></i>
            <i class="m-1 fas fa-heart" (click)="setVotes(3)"></i>
            <i class="m-1 fas fa-heart" (click)="setVotes(4)"></i>
            <i class="m-1 fas fa-heart" (click)="setVotes(5)"></i>
          </div>
        </div>
        <div class="col background-grey p-3"
             [class.winner]="offerDetail['offer_winner'] === offerDetail['offer_number']">
          <h3 class="card-title text-dark"><span
            class="text-white font-offer">Offerta: </span>{{offerDetail.offer | convertPrice:false:true}}</h3>

          <ng-container *ngIf="communicator.checkUser$ | async as user">
            <div [class]="'d-none d-lg-flex row row-hearts mb-3 vote-' + offerDetail['vote']"
                 *ngIf="user.isOperator === '1' else otherVote">
              <div class="col-4"><p class="card-text is-vote">Voto dello sposo</p></div>
              <div class="col pl-0 pr-0 mt-auto mb-auto">
                <i class="m-1 fas fa-heart"></i><i class="m-1 fas fa-heart"></i><i class="m-1 fas fa-heart"></i><i
                class="m-1 fas fa-heart"></i><i class="m-1 fas fa-heart"></i>
              </div>
            </div>
            <ng-template #otherVote>
              <div [class]="'d-none d-lg-flex row row-hearts mb-3 vote-' + offerDetail['vote']"
                   [ngbTooltip]="(user && user.isUser === '1' && user.username === offerDetail.username) ?
                  'Aiuta i fornitori a offrirti il meglio per le tue preferenze mettendo un voto a questa offerta' :
                  'Questo voto indica il punteggio di quanto è di buon grado questa offerta, allo sposo, in base alle sue preferenze'">
                <div class="col-5"><label class="col-form-label">Voto</label></div>
                <div class="col pl-0 pr-0 mt-auto mb-auto">
                  <i class="m-1 fas fa-heart" (click)="setVotes(1)"></i>
                  <i class="m-1 fas fa-heart" (click)="setVotes(2)"></i>
                  <i class="m-1 fas fa-heart" (click)="setVotes(3)"></i>
                  <i class="m-1 fas fa-heart" (click)="setVotes(4)"></i>
                  <i class="m-1 fas fa-heart" (click)="setVotes(5)"></i>
                </div>
              </div>
            </ng-template>

          </ng-container>
          <p class="card-text">Descrizione e tessuti</p>
          <label class="text-white">{{offerDetail.description}}</label>
          <p class="card-text">Condizioni</p>
          <label class="text-white">{{offerDetail.detail}}</label>

          <ngb-accordion #a="ngbAccordion" [closeOthers]="true">
            <ngb-panel id="panel-1">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between" (click)="a.toggle('panel-1')">
                  <h6 class="m-0">Tempi e costi di spedizione</h6>
                  <button class="btn">
                    <i class="fa fa-plus" *ngIf="!opened"></i>
                    <i class="fa fa-minus" *ngIf="opened"></i>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <label>{{offerDetail.days}}</label>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="panel-2">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between" (click)="a.toggle('panel-2')">
                  <h6 class="m-0">Politica resi, rimborsi, cambi e disdette</h6>
                  <button class="btn">
                    <i class="fa fa-plus" *ngIf="!opened"></i>
                    <i class="fa fa-minus" *ngIf="opened"></i>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <label>{{(offerDetail.regole_offer) ? offerDetail.regole_offer : 'Nessuna informazione politica resi inserita'}}</label>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="panel-3">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between" (click)="a.toggle('panel-3')">
                  <h6 class="m-0">Privacy policy fornitore</h6>
                  <button class="btn">
                    <i class="fa fa-plus" *ngIf="!opened"></i>
                    <i class="fa fa-minus" *ngIf="opened"></i>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <label>{{(offerDetail.policy_offer) ? offerDetail.policy_offer : 'Nessuna informazione policy inserita'}}</label>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

          <div class="row mt-3">
            <div class="col-12 text-center">
              <p class="card-text font-weight-normal">Proposta n.
                <span class="font-weight-bold offer-number">{{offerDetail.offer_number}}</span>
              </p>
              <small class="text-center font-weight-bold">Inserita il
                <span class="text-danger font-weight-bold">{{offerDetail.date_inserted}}</span></small>
            </div>
          </div>
          <ng-container *ngIf="communicator.checkUser$ | async as user">
            <div class="row rowAccept mt-3"
                 *ngIf="user.username === offerDetail.username && offerDetail.offer_winner === null">
              <div class="col-12 text-center">
                <button class="btn btn-success" (click)="acceptOffer()">Accetta proposta</button>
              </div>
            </div>
          </ng-container>


        </div>
      </div>


    </div>
  </div>
</ng-container>
