import {Component, AfterViewInit, Inject, PLATFORM_ID, Input} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {
  @Input() address: string;
  map: any;
  showMessage = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private httpService: HttpService) {
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  private initMap(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.httpService.getAddress(this.address).subscribe(resp => {
        if(resp.length === 0) {
          this.showMessage = true;
          return;
        }
        const {lat, lon} = resp[0];
        import('leaflet').then(L => {
          const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 17,
            minZoom: 3,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          });
          this.map = L.map('map', {
            center: [+lat, +lon],
            zoom: 15,
            zoomControl: true
          });
          const icon = L.icon({
            iconSize: [ 25, 41 ],
            iconAnchor: [ 13, 0 ],
            // specify the path here
            iconUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png',
            shadowUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png'
          });
          tiles.addTo(this.map);
          L.marker([+lat, +lon], {icon}).addTo(this.map);
        });
      })
    }
  }
}
