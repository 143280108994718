import { Injectable } from '@angular/core';
import {MENU_LINKS} from '../objects/Constants';

@Injectable({
  providedIn: 'root'
})
export class AuctionsService {
  subcategories: {'Sposa' : string[], 'Sposo': string[], 'Fornitori': string[], 'Ricevimento': string[], 'Pets Wedding': string[]} =
    {
      'Sposa': [...MENU_LINKS[0].links], 'Sposo': [...MENU_LINKS[1].links],
      'Fornitori': [...MENU_LINKS[2].links], 'Ricevimento': [...MENU_LINKS[3].links],
      'Pets Wedding': [...MENU_LINKS[4].links]
    };

  isCollapsed = [true, true, true, true, true];
  constructor() { }
}
