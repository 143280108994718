<label class="text-black-50 mt-3" *ngIf="prices.length > 1">Scegli l'opzione che desideri:</label>
<div class="row mb-2">
  <div class="col-12 col-md-6 mb-1 mt-1" *ngIf="prices.length > 1">

    <app-select-typeahead *ngIf="optionsAcquisto.length > 1" [options]="optionsAcquisto"
                          classesInput="rounded-0"
                          (optionChanged)="typeSelected = $event.id; changePriceByType($event.id)"></app-select-typeahead>

    <label class="w-100" *ngIf="optionsAcquisto.length === 1">
      {{optionsAcquisto[0].label}}
    </label>
  </div>
</div>
<div class="row mb-3">
  <div class="col-12 col-md-6 mb-1 mt-1" *ngIf="(optionsCondition | filterByType:typeSelected).length > 1">
    <app-select-typeahead [options]="optionsCondition | filterByType:typeSelected"
                          classesInput="rounded-0"
                          (optionChanged)="changePrice($event.id)"></app-select-typeahead>
  </div>
  <div class="col-12 col-md-6 mb-1 mt-1" *ngIf="optionsId.includes(category)">
    <label class="text-black-50">{{
      (category === '5' ? 'Pacchetto gioielli' :
        (category === '14' ? 'Servizi disponibili' :
          (category === '26' ? 'Menù disponibili' : '')))
      }}</label>
    <span class="badge badge-primary" *ngFor="let option of priceSelected.option1 | split:', '">{{option}}</span>
  </div>
  <div class="col-12 col-md-auto mb-1 mt-1" *ngIf="(optionsCondition | filterByType:typeSelected).length === 1 && !optionsId.includes(category)">
    <label class="w-100"><span class="text-black-50 mr-1">Condizione:</span>
      {{priceSelected.option1 + ((priceSelected.option2 && priceSelected.option2.length > 0) ? ', ' + priceSelected.option2 : '')}}
    </label>
  </div>
  <div class="col-12 mt-3"  *ngIf="priceSelected.type === '0'">
    <div style="width: 100px" *ngIf="priceSelected.quantity > '0'">
      <app-select-typeahead [options]="optionsQuantity" [classesInput]="'rounded-0'"
                            (optionChanged)="quantityChange.emit($event.id)"></app-select-typeahead>
    </div>
    <label *ngIf="priceSelected.quantity <= '0'" class="text-danger">Prodotto esaurito</label>
  </div>
  <div class="col-12 mt-3">
    <div class="mt-3" *ngIf="priceSelected.type === '0' && priceSelected.canAdvance === '1'">
      <div class="alert alert-info" role="alert">
        <i class="fa fa-info-circle fa-1x"></i> Il prodotto è acquistabile tramite un acconto di {{calculateTotalAdvance(priceSelected.price, quantity) | convertPrice}}
      </div>
      <div class="form-check">

        <label class="form-check-label form-check-toggle">
          <input class="form-check-input" type="checkbox"
                 (change)="priceSelected.useAdvance = $event.target['checked']"
          /><span>
            Paga con acconto</span>
        </label>
      </div>
    </div>
    <label class="w-100 mb-0" *ngIf="priceSelected.type === '0'">
      <ng-container *ngIf="!priceSelected.useAdvance">
        <span class="text-black-50 mr-1">Acquisto:</span><span class="price">{{calculateTotal(priceSelected.price, quantity) | convertPrice}}</span>
      </ng-container>

      <ng-container *ngIf="priceSelected.useAdvance">
        <span class="text-black-50 mr-1">Acquisto:</span><span class="price">{{calculateTotalAdvance(priceSelected.price, quantity) | convertPrice}}</span>
      </ng-container>


      <span class="text-black-50 ml-2">IVA inclusa</span>
    </label>


    <label class="w-100 mb-0" *ngIf="priceSelected.type === '1'">
      <span class="text-black-50 mr-1">Noleggio:</span><span class="price">{{priceSelected.price | convertPrice}} / giorno</span>
      <span class="text-black-50 ml-2">IVA inclusa</span>
    </label>
  </div>
  <div class="col-12 mt-3" *ngIf="priceSelected.type === '1'">

    <div class="row">
      <div class="col-12 col-md-auto">
        <label class="col-form-label">Date dell'utilizzo</label>
      </div>
      <div class="col">
        <app-date-range [duration]="priceSelected"></app-date-range>
      </div>
    </div>
  </div>
  <div class="col-12 mt-3" *ngIf="priceSelected.type === '1' && priceSelected.quantitydays">
    <div class="row">
      <div class="col-12 col-md-auto my-auto">
        <label><span class="text-black-50 mr-1">Giorni di utilizzo:</span> <span class="price">{{priceSelected.quantitydays}}</span></label>
      </div>
      <div class="col">
        <label class="w-100 mb-0"><span class="text-black-50 mr-1">Prezzo finale:</span><span
          class="price">{{calculateTotal(priceSelected.price, priceSelected.quantitydays) | convertPrice}}</span></label>
      </div>
    </div>
  </div>

</div>
