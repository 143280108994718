import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-reset',
  templateUrl: './confirm-reset.component.html',
  styleUrls: ['./confirm-reset.component.scss']
})
export class ConfirmResetComponent implements OnInit {
  @Input() widthProgress: number;
  @Output() confirm = new EventEmitter<boolean>();
  input = '';
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
