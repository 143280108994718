<div class="container-fluid background-lightpink pt-3">
  <span>FILTRI</span>
  <button (click)="close.emit();" class="d-block d-md-none close pr-1" style="font-size: 2.2rem; margin-top: -10px;">&times;</button>
  <hr>
  <div class="form-group">
    <label for="city"><i class="fas fa-map-marker-alt color-primary"></i> Città</label>
    <input id="city" class="form-control" placeholder="Inserisci almeno 2 caratteri" [(ngModel)]="city" [ngbTypeahead]="search"
           (selectItem)="check($event.item); cityChange.emit(city)" (change)="(city.length === 0) ? cityChange.emit('') : null">
    <small *ngIf="searching" class="form-text text-muted">Sto cercando...</small>
    <div class="invalid-feedback d-block" *ngIf="searchFailed && city !== ''">Spiacenti, nessun suggerimento è presente nella nostra banca dati.</div>
  </div>
  <div class="form-group" *ngIf="typeAuctions !== null">

    <app-select-typeahead label="Annunci"
                          (optionChanged)="typeAuctionsChange.emit($event.id);"
                          [options]="[{id: '3', label: 'Tutti'},{id: '0', label: 'In corso'},{id: '1', label: 'Conclusi'}]">

    </app-select-typeahead>
  </div>

  <div *ngFor="let menu of MENU_LINKS; let i = index">

    <div class="row mt-2">
      <div class="col my-auto" (click)="resetCollapsed(i);"
           [attr.aria-expanded]="!vetrineService.isCollapsed[i]">
        <a data-toggle="collapse" role="button" class="text-dark w-100 text-decoration-none"
           aria-expanded="false">
          <div class="w-100 pr-3">{{menu.label}}
            <i class="mt-1 fa fa-1x float-right"
               [ngClass]="{'fa-chevron-up': !vetrineService.isCollapsed[i], 'fa-chevron-down': vetrineService.isCollapsed[i]}"></i>
            <span class="badge badge-secondary float-right mt-1 mr-3">
            {{subcategories[menu.label].length === menu.links.length ? 'Tutti' : subcategories[menu.label].length}}
          </span>
          </div>
        </a>
      </div>
    </div>
    <div class="background-whitepink">
      <div [ngbCollapse]="vetrineService.isCollapsed[i]">
        <div class="row">
          <div class="col-6 text-center">
            <button class="btn btn-outline-light" (click)="selectAll.emit({isSelect: true, macro: menu.label})">Seleziona tutti</button>
          </div>
          <div class="col-6 text-center">
            <button class="btn btn-outline-light" (click)="selectAll.emit({isSelect: false, macro: menu.label})">Deseleziona tutti</button>
          </div>
        </div>
        <div class="row pb-2" *ngFor="let link of menu.links">
          <div class="col-2 text-center my-auto">
            <i class="fa fa-check" *ngIf="subcategories[menu.label] && subcategories[menu.label].includes(link)"></i>
          </div>
          <div class="col-10 cursor-pointer" (click)="subcategoryChange.emit({subcategory: link, category: menu.label})">
            {{link}}
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
