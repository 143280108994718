import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-typeahead',
  template: `
    <label [for]="id" [class]="classesLabel" *ngIf="label && label.length > 0">{{label}}</label>
    <div class="input-group position-relative">
      <input *ngIf="isEdit"
        [id]="id"
        type="text"
        [class]="'form-control bg-white ' + (classesInput)"
        [ngModel]="optionDefault"
        [ngbTypeahead]="search"
        [inputFormatter]="formatMatches"
        [resultTemplate]="rt"
        (selectItem)="optionChanged.emit($event.item)"
        (click)="click$.next($any($event).target.value)"
        [disabled]="disabled"
        #instance="ngbTypeahead"
        readonly
      />
        <i class="fa fa-chevron-down icon-select" (click)="click$.next($any($event).target.value)"></i>
      <ng-template #rt let-r="result" let-t="term">
        <ngb-highlight [result]="r.label" [term]="t"></ngb-highlight>
      </ng-template>
      <label *ngIf="!isEdit" [class]="classesValue">{{optionDefault.label}}</label>
    </div>
  `,
  styles: [
    `
          input {
              cursor: pointer;
          }
          .icon-select {
              position: absolute;
              cursor: pointer;
              right: 10px;
              bottom: 10px;
              color: var(--primary-color);
              z-index: 1000;
          }

      `
  ]
})
export class SelectTypeaheadComponent implements OnInit {

  @ViewChild('instance', {static: false}) instance: NgbTypeahead;
  @Input() options: Option[] = [];
  @Input() optionDefault: Option;
  @Input() id: string = 'select' + Math.random() * 1000;
  @Input() label = '';
  @Input() classesLabel = '';
  @Input() classesInput = '';
  @Input() classesValue = '';
  @Input() isEdit = true;
  @Input() disabled = false;
  @Output() optionChanged = new EventEmitter<{id: string, label: string}>();

  public search: (text$: Observable<string>) => void;
  public click$ = new Subject<string>();

  formatMatches = (el: {id: string, label: string}) => el.label;

  constructor() { }

  ngOnInit(): void {
    if (!this.optionDefault && this.options.length > 0) {
      this.optionDefault = this.options[0];
    }
    this.search = () => {
      return this.click$.pipe(
        filter(() => !this.instance.isPopupOpen()),
        map(() => this.options));
    };
  }
}
interface Option {
  id: string; label: string;
}
