import { Pipe, PipeTransform } from '@angular/core';
import {IAuction} from '../../objects/IAuction';

@Pipe({
  name: 'counterAuctions'
})
export class CounterAuctionsPipe implements PipeTransform {

  transform(values: IAuction[], ...args: unknown[]): {inCorso: number, senzaProposte: number, conclusi: number} {
    if(!values) {
      return null;
    }
    const inCorso = values.filter(e => (e.is_expired === '0' && !e.id_offer_winner) ||
      (e.is_expired === '1' && e.offers !== '0' && e.id_offer_winner === null && e.refusedall === '0')).length;
    const senzaProposte = values.filter(e => e.is_expired === '1' && (e.offers === '0' || e.refusedall === '1')).length;
    const conclusi = values.filter(e => e.id_offer_winner !== null).length;
    return {inCorso, senzaProposte, conclusi};
  }

}
