import { Pipe, PipeTransform } from '@angular/core';
import {IAuction} from '../../objects/IAuction';

@Pipe({
  name: 'filterAuctions'
})
export class FilterAuctionsPipe implements PipeTransform {

  transform(values: IAuction[], filterType: number, ...args: unknown[]): IAuction[] {
    if(!values) {
      return null;
    }
    if (filterType === 0) {
      values = values.filter(e => (e.is_expired === '0' && !e.id_offer_winner) || (e.is_expired === '1' && e.offers !== '0'
        && e.id_offer_winner === null && e.refusedall === '0'));
    } else if (filterType === 1) {
      values = values.filter(e => e.is_expired === '1' && (e.offers === '0' || e.refusedall === '1'));
    } else if (filterType === 2) {
      values = values.filter(e => e.id_offer_winner !== null);
    }
    return values;
  }

}
