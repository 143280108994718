<ng-container *ngIf="!showOptions else optionsTemp">

  <div class="modal-title text-center" #title>
    <img src="https://www.weddingsmart.it/assets/images/logoheader.png" style="outline: none" height="80">
  </div>
  <ng-container *ngIf="!showCookie else cookieTemp">

    <div class="modal-body">
      <label>
        Questo sito web utilizza i cookie

      </label>
      <label>
        Utilizziamo i cookie per migliorare la tua esperienza di navigazione, pubblicare annunci o contenuti personalizzati e analizzare il nostro traffico. Cliccando su "Accetta", acconsenti al nostro utilizzo dei cookie. Grazie
      </label>
      <label>Fai riferimento alla nostra <a class="color-primary cursor-pointer" (click)="showCookie = true;">Cookie Policy</a> per ulteriori informazioni.
      </label>


      <!-- <ngb-accordion #a="ngbAccordion" [closeOthers]="true">
        <ngb-panel id="panel-1">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between"
                 (click)="a.toggle('panel-1')">
              <b>Archiviare e/o accedere a informazioni su un dispositivo</b>
              <button class="btn">
                <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                <i class="fa fa-chevron-up" *ngIf="opened"></i>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            I cookie, gli identificatori del dispositivo o altre informazioni possono essere archiviati o reperiti sul
            dispositivo dell’utente per le finalità presentate.
          </ng-template>
        </ngb-panel>
        <ngb-panel id="panel-2">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between"
                 (click)="a.toggle('panel-2')">
              <b>Annunci e contenuti personalizzati, valutazione degli annunci e del contenuto, osservazioni del pubblico e
                sviluppo di prodotti</b>
              <button class="btn">
                <i class="fa fa-chevron-down" *ngIf="!opened"></i>
                <i class="fa fa-chevron-up" *ngIf="opened"></i>
              </button>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            Gli annunci e i contenuti possono essere personalizzati in base a un profilo.
            È possibile aggiungere ulteriori dati per personalizzare maggiormente annunci e contenuti. È possibile
            valutare le prestazioni di annunci e contenuti. È possibile ottenere informazioni sul pubblico che ha
            visualizzato gli annunci e i contenuti. I dati possono essere utilizzati per creare o migliorare l’esperienza
            utente, sistemi e software.
          </ng-template>
        </ngb-panel>
      </ngb-accordion> -->

    </div>
    <div class="modal-footer">
      <div class="text-center w-100">
        <button class="btn btn-secondary mr-3 min-width" (click)="showOptions = true; title.scrollIntoView()">Opzioni</button>
        <button class="btn btn-primary  min-width" (click)="acceptAll()">Accetto</button>
      </div>
    </div>
  </ng-container>
  <ng-template #cookieTemp>
    <div class="modal-body">
      <app-cookie-policy [showContainer]="false"></app-cookie-policy>
    </div>
    <div class="modal-footer">
      <div class="text-center w-100">
        <button class="btn btn-secondary mr-3 min-width" (click)="showCookie = false; title.scrollIntoView()">Indietro</button>
        <button class="btn btn-primary  min-width" (click)="acceptAll()">Accetto</button>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #optionsTemp>

  <div class="modal-body">
    <h4 #titleOptions>Le tue opzioni sulla privacy</h4>
    <label>Sul nostro sito vengono utilizzati alcuni strumenti, quali i cookie, per comprendere le modalità di utilizzo
    dei nostri servizi da parte degli utenti e migliorare la loro esperienza e la pertinenza degli annunci pubblicitari, Qui puoi disattivare tale strumenti.</label>

    <h5> Categoria</h5>
<!--    <div class="row">-->
<!--      <div class="col-12 col-lg-3 my-auto">-->
<!--        <b>Pubblicità</b>-->
<!--      </div>-->
<!--      <div class="col-12 col-lg-7">-->
<!--        Misura l'efficacia dei nostri annunci pubblicitari per aiutarci a renderli più pertinenti-->
<!--      </div>-->
<!--      <div class="col-12 col-lg-2 my-auto pl-0">-->
<!--        <div class="form-check">-->
<!--          <label class="form-check-label form-check-toggle">-->
<!--            <input class="form-check-input" type="checkbox" [checked]="cookieAccepted.pubbl"-->
<!--                   (change)="cookieAccepted.pubbl = $event.target['checked']"-->
<!--            /><span>-->
<!--            {{cookieAccepted.pubbl ? 'Attivato' : 'Disattivato'}}</span>-->
<!--          </label>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <hr>-->
    <div class="row">
      <div class="col-12 col-lg-3 my-auto">
        <b>Statistiche</b>
      </div>
      <div class="col-12 col-lg-9">
        Utilizziamo lo strumento di Google Analytics per raccogliere dati anonimi sulle modalità di utilizzo del sito da parte degli utenti,
        sulle prestazioni del sito e sulla navigazione.
        Utilizziamo questo strumento per migliorare i nostri prodotti, servizi ed esperienza utente.
      </div>
      <div class="col-12 my-auto pl-0">

        <div class="form-check">
          <label class="form-check-label form-check-toggle">
            <input class="form-check-input" type="checkbox" [checked]="cookieAccepted.prest"
                   (change)="cookieAccepted.prest = $event.target['checked']"
            /><span>
            {{cookieAccepted.prest ? 'Attivato' : 'Disattivato'}}</span>
          </label>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12 col-lg-3 my-auto">
        <b>Assistenza</b>
      </div>
      <div class="col-12 col-lg-9">
        Mette a disposizione servizi interattivi, ad esempio l'assistenza via chat e gli strumenti per il feedback dei clienti.
      </div>
      <div class="col-12 my-auto pl-0">

        <div class="form-check">
          <label class="form-check-label form-check-toggle">
            <input class="form-check-input" type="checkbox" [checked]="cookieAccepted.ass"
                   (change)="cookieAccepted.ass = $event.target['checked']"
            /><span>
            {{cookieAccepted.ass ? 'Attivato' : 'Disattivato'}}
          </span>
          </label>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12 col-lg-3 my-auto">
        <b>Essential*</b>
      </div>
      <div class="col-12 col-lg-9">
        Abilita servizi e funzionalità essenziali, incluse la verifica dell'identità, la continuità operativa del servizio e la sicurezza del sito.
        Qui di seguito, alcuni esempi delle funzionalità:
        <ul>
          <li>Riconoscerti quando effettui l'accesso per utilizzare tutti i nostri servizi</li>
          <li>Tenere traccia degli articoli salvati nel tuo carrello</li>
          <li>Prevenire attività fraudolente</li>
          <li>Migliorare la sicurezza</li>
          <li>Gestire i tuoi annunci</li>
          <li>Tenere traccia delle tue preferenze</li>
        </ul>
        La disattivazione non è disponibile
      </div>
      <div class="col-12 my-auto pl-0">

        <div class="form-check">
          <label class="form-check-label form-check-toggle">
            <input disabled class="form-check-input" type="checkbox" checked /><span class="text-black-50">Attivato</span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">

    <div class="text-center w-100">
      <button *ngIf="!onlyCookie"  class="btn btn-secondary mr-3 mb-3  min-width"
              (click)="titleOptions.scrollIntoView(); showOptions = false">Indietro</button>
      <button class="btn btn-secondary mr-3 mb-3  min-width" (click)="dismiss(true)">Salva & Esci</button>
      <button class="btn btn-primary mb-3  min-width" (click)="acceptAll()">Accetta Tutto</button>
    </div>
  </div>
</ng-template>
