import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpService} from '../../../services/http.service';
import {IAddress} from '../../../pages/my-auctions/IAddress';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @Input() addresses: IAddress[] = [];
  @Input() objAddress: IAddress = {id: null, address: '', cap: '', city: '', number: ''};
  @Output() confirm = new EventEmitter<{address: string, cap: string, city: string}>();
  isAdd = false;
  constructor(public activeModal: NgbActiveModal, private httpService: HttpService) { }

  ngOnInit(): void {
  }
  changeAddress(addr: IAddress) {
    const {address, cap, city, number, id} = addr;
    this.objAddress = {address, cap, city, number, id};
  }

  confirmAdd() {
    if (this.isAdd) {
      this.httpService.saveAddress(this.objAddress).subscribe(() => this.confirm.emit(this.objAddress));
    } else {
      this.confirm.emit(this.objAddress);
    }
  }
}
