
<div class="container pt-3" id="questions">
  <div class="row" id="question" *ngIf="user">
    <div class="col-12" *ngIf="user.isUser !== '1'">
      <label>Fai una domanda allo sposo</label>
      <textarea class="form-control" rows="2" id="textQuestion" maxlength="100" #element></textarea>
      <div class="row mt-1 mb-3">
        <div class="col-12 text-right">
          <button [disabled]="element.value.length < 5" class="btn btn-primary" (click)="sendQuestion.emit(element.value); element.value = ''">
            Invia
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="list-questions" *ngIf="questions.length > 0 else noRecords">
    <div class="row question background-lightgray" *ngFor="let qu of questions">
       <div class="col-12 col-md-3">
           <span class="text-question-by">Domanda del fornitore  {{qu['username']}} inviata il {{qu['date']}}:</span>
         </div>
        <div class="col">
           <label class="text-question w-100">{{qu['text']}}</label>
         </div>
         <ng-container *ngIf="qu['response'] && qu['response_data'] else answer">
           <div class="col-12">
             <div class="row">
             <div class="col-12 col-md-3">
                  <span class="text-response-by">{{((auction['gender'] === 'M') ? 'Lo sposo' : 'La sposa')}} ha risposto il {{qu['response_data']}}:</span>
                </div>
              <div class="col">
                  <label class="text-answer w-100">{{qu['response']}}</label>
                </div>
             </div>
           </div>
         </ng-container>
      <ng-template #answer>
        <div class="col-12" *ngIf="auction && auction.username === user.username">

          <button *ngIf="!qu.showAnswer else answerDiv" class="btn btn-sm btn-primary text-12" (click)="qu.showAnswer = true">Rispondi</button>

          <ng-template #answerDiv>
            <div class="text-right">
              <textarea maxlength="100" class="form-control" rows="2" placeholder="Scrivi la risposta" [(ngModel)]="qu.answerToSend"></textarea>
              <button class="btn btn-sm btn-primary" (click)="sendAnswer.emit({text: qu.answerToSend, idQuestion: qu.id})">Invia</button>
            </div>
          </ng-template>
        </div>
      </ng-template>
      </div>
  </div>
  <ng-template #noRecords>

    <div class="mt-3 alert alert-warning" role="alert">
      Nessuna domanda è ancora stata fatta allo sposo
    </div>
  </ng-template>
</div>
