<div class="modal-body position-relative">
  <button type="button" class="close" (click)="activeModal.dismiss()">&times;</button>
  <h4>Modifica indirizzo di spedizione</h4>
  <ng-container *ngIf="isAdd else list">

    <label for="input">Scrivi il tuo nuovo indirizzo</label>
    <input id="input" class="form-control" type="text" [(ngModel)]="objAddress.address" placeholder="Indirizzo">
    <div class="row mt-3">
      <div class="col-6">
        <input class="form-control" type="text" [(ngModel)]="objAddress.number" placeholder="Numero">
      </div>
      <div class="col-6">
        <input class="form-control" type="text" [(ngModel)]="objAddress.cap" placeholder="CAP" max="5" maxlength="5">
      </div>
      <div class="col-12 mt-3">
        <input class="form-control" type="text" [(ngModel)]="objAddress.city" placeholder="Città">
      </div>
    </div>
  </ng-container>
  <ng-template #list>
    <div class="row" *ngFor="let addr of addresses">
      <div class="col-12 mt-1 mb-1 cursor-pointer" (click)="changeAddress(addr); confirmAdd()">
          <i class="fa fa-check-circle color-primary" *ngIf="objAddress.address === addr.address && objAddress.number === addr.number && objAddress.city === addr.city"></i>
          <i class="fa fa-circle color-primary" *ngIf="objAddress.address !== addr.address || objAddress.number !== addr.number || objAddress.city !== addr.city" ></i>
        {{addr.address + ' ' + addr.number + ', ' + addr.cap + ', ' + addr.city}}
      </div>
    </div>
    <div class="text-center">
      <button class="btn btn-primary" (click)="changeAddress({id: null, address: '', cap: '', city: '', number: ''}); isAdd = true;">
        <i class="fa fa-plus"></i> Aggiungi nuovo indirizzo</button>
    </div>
  </ng-template>
  <div class="row mt-3" *ngIf="isAdd">
    <div class="col-12 text-right">
      <button class="btn btn-outline-primary mr-1" (click)="activeModal.dismiss()">Annulla</button>
      <button class="btn btn-primary" (click)="confirmAdd()">Fatto</button>
    </div>
  </div>

</div>
