<div class="body annuncio-dettaglio" style="margin-top: -5px;" *ngIf="auction$ | async as macroAuction">
  <div class="row no-gutters">
    <div class="col-12 background-lightgray">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-8">

            <h4 id="sectionAnnuncio">{{macroAuction.auction.sector}}</h4>
          </div>
          <div class="col text-right">
            <a routerLink="/">Home</a> / <a routerLink="/auctions">Annunci
            in corso</a> / <label>{{macroAuction.auction.macrosector}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container body-dettaglio-container">
    <div class="card mt-3">
      <div class="card-header p-1 p-md-3 background-lightgray position-relative">
        <div class="row">
          <div class="col-12 col-md-8  my-auto">
            <h6 id="auction-code" class="pl-1 pl-md-0">Cod. {{macroAuction.auction.code}}</h6>
            <h5 id="titleAnnuncio" class="pl-1 pl-md-0">{{macroAuction.auction.title}}</h5>
          </div>
        </div>
        <app-circle-menu (actionEmitter)="actionMenu($event, macroAuction.auction)" [user]="communicator.checkUser$ | async"></app-circle-menu>

      </div>
      <div class="card-body p-1 p-sm-3">
        <div class="row">
          <div class="col-12 col-sm-5 offset-sm-1 text-center">
            <img class="img" [src]="'https://www.weddingsmart.it/api/images/auctions/' + macroAuction.auction.imgname"
                 id="imageAnnuncio">
          </div>
          <div class="col">
            <h5 class="color-primary pl-1 pl-md-0" >Budget disponibile <a id="budgetAnnuncio">{{macroAuction.auction.budget}} €</a>
            </h5>
            <div class="card background-lightgray">
              <div class="card-body position-relative">
                <div class="row">
                  <div class="col">
                    <p>Annuncio Creato da:</p>
                    <p class="mb-1"><a class="text-black-50" id="fullnameText1">Sposo: </a>
                      <a class="fullnameAnnuncio">{{macroAuction.auction.fullname}}</a></p>

                    <p class="mb-1"><a class="text-black-50">Data nozze: </a><a
                      id="weddingDate">{{macroAuction.auction.wedding_date}}</a></p>
                    <p class="mb-1"><a class="text-black-50">Località: </a><a
                      id="weddingLocality">{{macroAuction.auction.wedding_locality}}</a></p>
                  </div>
                  <div class="col-12 col-sm-6 text-center" id="closedAnnuncio"
                       *ngIf="macroAuction.auction.id_offer_winner">
                    <i class="text-success far fa-check-circle fa-3x"></i>
                    <h5 class="text-success">Conclusa</h5>
                  </div>
                  <div class="col-12 col-sm-6 text-center" id="closedAnnuncioSenzaAnnunci"
                       *ngIf="!macroAuction.auction.id_offer_winner && macroAuction.auction.is_expired === '1'
                        && (macroAuction.auction.offers === '0' || macroAuction.auction.refusedall === '1')">
                    <i class="far fa-times-circle text-danger fa-3x"></i>
                    <h5 class="text-danger">Conclusa</h5>
                    <label class="text-danger" *ngIf="macroAuction.auction.refusedall === '1' && macroAuction.auction.offers !== '0'">
                      Lo sposo non ha accettato nessuna offerta</label>
                  </div>
                  <div class="col-12 col-sm-6 text-center"
                       *ngIf="!macroAuction.auction.id_offer_winner && macroAuction.auction.is_expired === '1'
                        && macroAuction.auction.offers !== '0' && macroAuction.auction.refusedall === '0'">
                    <i class="far fa-check-circle text-orange fa-3x"></i>
                    <h5 class="text-orange">Conclusa</h5>
                    <ng-container *ngIf="communicator.checkUser$ | async as user">

                      <button *ngIf="user.username === macroAuction.auction.username" class="btn btn-primary" (click)="refusedAuction(macroAuction.auction)">
                        Scarta tutte le offerte
                      </button>
                    </ng-container>
                  </div>
                </div>
                <div class="row" id="divProposta" *ngIf="communicator.checkUser$ | async as user">
                  <div class="col text-center"
                       *ngIf="user.isUser !== '1' && macroAuction.auction.is_expired === '0' && !macroAuction.auction.id_offer_winner">
                    <small class="text-black-50 font-weight-bold">Sei un operatore del settore?</small>
                    <br>
                    <button class="btn btn-primary" (click)="showModalOffer(user, macroAuction.auction.id,
                     modalOffer, macroAuction.auction)">Invia
                      una proposta
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p *ngIf="macroAuction.auction.id_offer_winner || macroAuction.auction.is_expired === '1' " class="text-black-50 mb-0 font-weight-bold" id="propusalCounter">
                      Proposte ricevute: <a
                      id="num-offers">{{macroAuction.offers.length}}</a></p>
                    <p class="text-black-50 font-weight-normal" id="winner"
                       *ngIf="macroAuction.auction.id_offer_winner && getSeller(macroAuction)">
                      La proposta scelta è la nr.: <a href=""
                                                      id="number-winner">{{macroAuction.auction.id_offer_winner}}</a>
                      di <a [routerLink]="'/store/' + (getSeller(macroAuction).code_online || getSeller(macroAuction).business_name)" id="name-winner">{{getSeller(macroAuction).business_name}}</a></p>
                  </div>
                </div>

              </div>
            </div>
            <div class="row" *ngIf="macroAuction.auction.is_expired === '0' && !macroAuction.auction.id_offer_winner">
              <div class="col-12 col-md-3">
                <p id="expired" class="pl-1 pl-md-0">Scade tra</p>
              </div>
              <div class="col text-center">
                <p>
                  <a id="expiredDate">{{countdown}}</a>
                  <br>
                  <a id="expiredDateFormat">({{macroAuction.auction.expire | date:'dd/MM/yyyy HH:mm'}})</a></p>
              </div>
            </div>
            <div class="row" *ngIf="macroAuction.auction.is_expired === '1' && macroAuction.offers.length !== 0 &&
             !macroAuction.auction.id_offer_winner && macroAuction.auction.refusedall === '0'">
              <div class="col-12">
                <p class="text-center" id="expiredChoose">Lo sposo ha il seguente tempo per decidere l'offerta vincente</p>
              </div>
              <div class="col-12 text-center">
                <p>
                  <a id="expiredChooseDate">{{countdown}}</a>
                  <br>
                  <a id="expiredChooseDateFormat">({{expireChoose | date:'dd/MM/yyyy HH:mm'}})</a></p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <ul ngbNav #nav="ngbNav" class="nav-tabs nav-fill" [activeId]="activeTab">
              <li [ngbNavItem]="0">
                <a class="text-dark nav-link"  ngbNavLink>Proposte <span
                  class="badge badge-danger" id="numoffers">{{macroAuction.offers.length}}</span></a>

                <ng-template ngbNavContent>
                  <div >
                    <div *ngIf="macroAuction.offers.length > 0 else noOffers">

                      <div class="row text-dark mt-3">
                        <div class="col-6 col-md-4">
                          <select class="form-control select-special" id="selectShow" [(ngModel)]="filterVote">
                            <option value="0">Mostra:</option>
                            <option value="1">Votate</option>
                            <option value="2">Non votate</option>
                          </select>
                        </div>
                        <div class="col-6 col-md-4">
                          <select class="form-control select-special" id="selectOrder" [(ngModel)]="orderOffer">
                            <option value="0">Ordina per:</option>
                            <option value="1">Voto</option>
                            <option value="2">Operatore</option>
                            <option value="3">Offerta crescente</option>
                            <option value="4">Offerta decrescente</option>
                          </select>
                        </div>
                        <div class="col-4 d-none d-md-block">
                          <select class="form-control" id="selectPage">
                            <option>20 per pagina</option>
                            <option>40 per pagina</option>
                          </select>
                        </div>
                      </div>

                      <div class="container-offers" *ngIf="macroAuction.offers | filterOffers:filterVote:orderOffer as offers">
                        <ng-container *ngIf="offers.length > 0 else noOffers">

                          <div class="d-box d-md-none ">
                            <div class="d-flex row no-gutters w-100" *ngFor="let index4 of getMultiply4(offers.length)">
                              <div class="col-6 pr-1" *ngIf="offers[index4 - 4] as offer">
                                <app-offer-card
                                  [user]="communicator.checkUser$ | async" [auction]="macroAuction.auction"
                                  [offer]="offer" (acceptOffer)="acceptOffer($event, macroAuction.auction.id)"
                                  (showModalOrder)="showModalOrder(offer, macroAuction.auction)"></app-offer-card>
                              </div>
                              <div class="col-6 pr-1" *ngIf="offers[index4 - 3] as offer">
                                <app-offer-card
                                  [user]="communicator.checkUser$ | async" [auction]="macroAuction.auction"
                                  [offer]="offer" (acceptOffer)="acceptOffer($event, macroAuction.auction.id)"
                                  (showModalOrder)="showModalOrder(offer, macroAuction.auction)"></app-offer-card>
                              </div>
                              <div class="col-6 pr-1" *ngIf="offers[index4 - 2] as offer">
                                <app-offer-card
                                  [user]="communicator.checkUser$ | async" [auction]="macroAuction.auction"
                                  [offer]="offer" (acceptOffer)="acceptOffer($event, macroAuction.auction.id)"
                                  (showModalOrder)="showModalOrder(offer, macroAuction.auction)"></app-offer-card>
                              </div>
                              <div class="col-6 pr-1" *ngIf="offers[index4 - 1] as offer">
                                <app-offer-card
                                  [user]="communicator.checkUser$ | async" [auction]="macroAuction.auction"
                                  [offer]="offer" (acceptOffer)="acceptOffer($event, macroAuction.auction.id)"
                                  (showModalOrder)="showModalOrder(offer, macroAuction.auction)"></app-offer-card>
                              </div>
                            </div>
                          </div>
                          <div class="d-none d-md-block">
                            <app-offer-card *ngFor="let offer of offers"
                                            [user]="communicator.checkUser$ | async" [auction]="macroAuction.auction"
                                            [offer]="offer" (acceptOffer)="acceptOffer($event, macroAuction.auction.id)"
                                            (showModalOrder)="showModalOrder(offer, macroAuction.auction)"></app-offer-card>
                          </div>
                        </ng-container>
                        <ng-template #noOffers>
                          <div class="mt-3 alert alert-warning" role="alert">
                            Nessuna offerta soddisfa i criteri di ricerca
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <ng-template #noOffers>
                      <div class="mt-3 alert alert-warning" role="alert">
                        Al momento, non sono presenti offerte per questo annuncio
                      </div>
                    </ng-template>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="1">
                <a class="text-dark nav-link"  ngbNavLink>Descrizione</a>
                <ng-template ngbNavContent>

                  <div class="container" id="descr">
                    <p class="mt-3"><a id="articleFullname"></a>
                      <a id="fullnameText2">{{(macroAuction.auction.gender === 'M' ? 'Lo sposo ' : 'La sposa ') + ' ' + macroAuction.auction.fullname}}</a>
                      ha aggiunto ulteriori informazioni:</p>
                    <div class="mt-3" id="auction-description" [innerHTML]="macroAuction.auction | extractDescription">
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a class="text-dark nav-link" ngbNavLink>Domande</a>
                <ng-template ngbNavContent>
                  <app-questions [questions]="macroAuction.questions"
                                 [auction]="macroAuction.auction"
                                 [user]="communicator.checkUser$ | async"
                                 (sendQuestion)="sendQuestions($event, macroAuction.auction.id)"
                                  (sendAnswer)="sendAnswerQuestions($event)"></app-questions>
                </ng-template>
              </li>
            </ul>

            <!-- Tab panes -->
            <div [ngbNavOutlet]="nav"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template let-modal #modalOffer>
    <div class="modal-header">
      <h5>Invia una proposta
        <span class="text-black-50">
          (di importo uguale o inferiore rispetto a quello indicato nell'annuncio sposi che è di
          <b class="text-dark"> {{macroAuction.auction.budget_num | convertPrice}}</b>
          )
        </span>
      </h5>
      <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
    </div>
    <div class="modal-body form-offer">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="offer">Offerta</label>
            <input type="text" class="form-control" [class.is-invalid]="showErrorOffer && (!newOffer.offer || newOffer.invalidOffer)" id="offer" [(ngModel)]="newOffer.offer" required max="40"
                   (keydown)="onlyPrice($event)" (keyup)="checkPriceOffer(macroAuction.auction)">
            <div class="invalid-feedback" id="feedbackPriceRequired" *ngIf="showErrorOffer && !newOffer.offer">Il campo Budget è obbligatorio</div>
            <div class="invalid-feedback" id="feedbackPrice"  *ngIf="showErrorOffer && newOffer.invalidOffer">
              Non puoi superare il budget indicato dagli sposi</div>
            <small class="form-text text-muted">
              Inserite un importo pari o inferiore rispetto a quello indicato dai futuri sposi
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="description">Descrizione</label>
            <small class="float-right text-black-50 pt-2">{{100 - newOffer.description.length}} / 100 caratteri</small>
            <textarea class="form-control" id="description"  [class.is-invalid]="showErrorOffer && !newOffer.description" [(ngModel)]="newOffer.description" rows="4" required
                      maxlength="100"></textarea>
            <div class="invalid-feedback" [ngClass]="{'d-block' : showErrorOffer && !newOffer.description}">Il campo Descrizione è obbligatorio</div>
            <small class="form-text text-muted">
              Descrivi brevemente la tua proposta. Puoi indicare i materiali con cui
              è stato realizzato il tuo prodotto oppure cosa offri nel tuo Servizio, facendo sempre attenzione a ciò
              che ha indicato il
              cliente nel campo descrizione e note aggiuntive dell'annuncio. Più sei in sintonia con cciò che chiede
              il cliente, più posibilità
              hai di essere scelto
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="days">Giorni effettivi (solari) stimati per la realizzazione e spedizione</label>
            <small class="float-right text-black-50 pt-2">{{45 - newOffer.days.length}} / 45 caratteri</small>
            <input type="text" class="form-control" [class.is-invalid]="showErrorOffer && !newOffer.days" id="days" [(ngModel)]="newOffer.days" required maxlength="45">
            <div class="invalid-feedback"  [ngClass]="{'d-block' : showErrorOffer && !newOffer.days}">Il campo Giorni effettivi è obbligatorio</div>
            <small class="form-text text-muted">
              Se partecipate all'annuncio con un prodotto, dovete indicare i giorni effettivi (solari) che ci
              vogliono per realizzarlo dopo
              che avete ricevuto la caparra o il pagamento a saldo (se previsto) compreso i tempi di spedizione ed
              il modo di spedizione
              (corriere, ritiro a mano, etc.). Se si tratta di un servizio indicare: SERVIZIO
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="detail">Dettaglio prodotto</label>
            <small class="float-right text-black-50 pt-2">{{45 - newOffer.detail.length}} / 45 caratteri</small>
            <input type="text" class="form-control"  [class.is-invalid]="showErrorOffer && !newOffer.detail"  id="detail" [(ngModel)]="newOffer.detail" required maxlength="45">
            <div class="invalid-feedback" [ngClass]="{'d-block' : showErrorOffer && !newOffer.detail}">Il campo dettaglio prodotto è obbligatorio</div>
            <small class="form-text text-muted">
              Se partecipate all'annuncio con un prodotto, indicate se si trata di un prodotto Nuovo, su misura, da
              campionario, da sfilata
              etc. Se di tratta di un servizio, indicate SERVIZIO PERSONALIZZATO
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="model">Modello / Brand / Altro</label>
            <small class="float-right text-black-50 pt-2">{{45 - newOffer.model.length}} / 45 caratteri</small>
            <input type="text" class="form-control"  [class.is-invalid]="showErrorOffer && !newOffer.model"  id="model" [(ngModel)]="newOffer.model" required maxlength="45">
            <div class="invalid-feedback" [ngClass]="{'d-block' : showErrorOffer && !newOffer.model}">Il campo Modello è obbligatorio</div>
            <small class="form-text text-muted">
              Se partecipate all'annuncio con un prodotto, indicate il modello, la marca, lo stilista, etc. Se si
              tratta di un servizio
              indicate il tipo di servizio che offrtie (se. Auto, Hotel, etc.)
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="model">Allegati</label>
            <div class="invalid-feedback"  [ngClass]="{'d-block' : showErrorOffer && images.length === 0}">E' obbligatorio inserire almeno 1 file</div>
            <small class="form-text text-muted">
              Potete caricare massimo 2 immagini del prodotto che offrite
            </small>
            <div class="row">
              <div class="col-12 col-md-6">
                <app-image [isEdit]="true" path="offers" (changeImageEmitter)="images[0] = $event"
                           [useCropper]="true" [aspectRatio]="145 / 173" label="Vista frontale"></app-image>
              </div>
              <div class="col-12 col-md-6">
                <app-image [isEdit]="true" path="offers" (changeImageEmitter)="images[1] = $event"
                           [useCropper]="true" [aspectRatio]="145 / 173" label="Vista laterale"></app-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" *ngIf="communicator.checkUser$ | async as user">
      <button type="button" class="btn btn-tertiary" (click)="showPreview(macroAuction.auction, user)">Mostra preview</button>
      <button type="button" class="btn btn-primary" (click)="sendOffer(+macroAuction.auction.budget_num, macroAuction.auction.id)">Invia</button>
    </div>
  </ng-template>
</div>
