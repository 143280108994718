import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './popup-input.component.html',
  styleUrls: ['./popup-input.component.scss']
})
export class PopupInputComponent implements OnInit {
  @Input() title = 'Segnala un problema';
  @Output() closeDescription = new EventEmitter<string>();
  description = '';
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
