import { Pipe, PipeTransform } from '@angular/core';
import {IAuction} from '../../objects/IAuction';

@Pipe({
  name: 'filterOrder'
})
export class FilterOrderPipe implements PipeTransform {

  transform(values: any[], filterType: number, ...args: unknown[]): any[] {
    if(!values) {
      return null;
    }
    if (filterType === 0) {
      values = [];
    } else if (filterType === 1) {
      values = values.filter(e => e.states && !e.states.map(a => a.state_order.toLowerCase()).includes('spedito'));
    } else if (filterType === 2) {
      values = values.filter(e => e.states && e.states.map(a => a.state_order.toLowerCase()).includes('spedito'))
    }
    return values;
  }

}
