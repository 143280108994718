import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-months-picker',
  templateUrl: './months-picker.component.html',
  styleUrls: ['./months-picker.component.scss']
})
export class MonthsPickerComponent implements OnInit {
  @Input() value: Date;
  @Output() valueChange = new EventEmitter<Date>();
  yearCurrent = new Date().getFullYear();
  monthNames = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
    "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
  ];
  monthSelected: number = null;
  constructor() { }

  ngOnInit(): void {
    if (this.value) {
      this.monthSelected = this.value.getMonth();
    }
  }

  changeYear(isAdd: boolean) {
    this.yearCurrent += (isAdd) ? 1 : -1;
    if(this.monthSelected !== null) {
      this.value = new Date(this.yearCurrent, this.monthSelected);
      this.valueChange.emit(this.value);
    }
  }

  changeDate(month: number) {
    this.monthSelected = month;
    this.value = new Date(this.yearCurrent, this.monthSelected);
    this.valueChange.emit(this.value);
  }

  checkDate(month: number): boolean {
    return new Date(this.yearCurrent, month).getTime() < new Date().getTime();
  }
}
