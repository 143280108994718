import { Pipe, PipeTransform } from '@angular/core';
import {IProduct} from '../objects/IProduct';

@Pipe({
  name: 'getProductByCodePipe'
})
export class GetProductByCodePipe implements PipeTransform {

  transform(values: IProduct[], code: string, ...args: unknown[]): IProduct {
    return values.find(e => e.code === code);
  }

}
