
<div class="menu">
  <input [hidden]="true" type="checkbox" id="toggle" [(ngModel)]="isChecked"/>
  <label id="show-menu" for="toggle">
    <div class="btn">
      <i class="fa not-background color-primary fa-ellipsis-v toggleBtn menuBtn"></i>
      <i class="fas not-background text-white fa-times-circle toggleBtn closeBtn fa-2x"></i>
    </div>
    <div class="btn" (click)="actionEmitter.emit(ACTION.condividi)">
      <div>
        <span class="text-white mr-2">Condividi</span>
        <i class="fa fa-share-alt" ></i>
      </div>
    </div>

    <div *ngIf="user && user.isOperator === '1'" class="btn"  (click)="actionEmitter.emit(ACTION.segnala)">
      <div>
        <span class="text-white mr-2">Segnala un problema</span>
        <i class="fa fa-exclamation"></i>
      </div>
    </div>
    <div class="btn"  (click)="actionEmitter.emit(ACTION.preferiti)" *ngIf="user && user.isOperator === '1'">
      <div>
        <span class="text-white mr-2">Preferiti</span>
        <i class="fa fa-heart" id="favorite"></i>
      </div>
    </div>
  </label>
</div>
<div class="mask" [style.opacity]="(isChecked) ? '1' : '0'" [style.height.%]="(isChecked) ? '100' : '0'"></div>

