import { Pipe, PipeTransform } from '@angular/core';
import {ISector} from '../../objects/ISector';

@Pipe({
  name: 'getBySector'
})
export class GetBySectorPipe implements PipeTransform {

  transform(values: ISector[], idmacro: string | number, ...args: unknown[]): ISector[] {
    return values.filter(e => e.idmacro === idmacro + '');
  }

}
