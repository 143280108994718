import {Component, Input, OnInit} from '@angular/core';
import {Product, Review} from '../../../objects/IOrderDetail';

@Component({
  selector: 'app-review-item',
  templateUrl: './review-item.component.html',
  styleUrls: ['./review-item.component.scss']
})
export class ReviewItemComponent implements OnInit {
  @Input() item: Product;
  @Input() reviews: Review[];
  @Input() path = 'products';
  @Input() votes: {id?: number, idProd: string, vote: number, title: string, desc: string}[];
  @Input() index: number;
  objVote = {idProd: null, vote: 0, title: '', desc: ''};
  constructor() { }

  ngOnInit(): void {
    if(!this.votes[this.index]) {
      const index = this.reviews.findIndex(e => +e.idproduct === +this.item.id);
      if(index > -1) {
        this.votes[this.index] = {...this.reviews[index], idProd: this.item.id, desc: this.reviews[index].description};
      } else {
        this.votes[this.index] = {idProd: this.item.id, vote: 0, title: '', desc: ''};
      }
      this.objVote = this.votes[this.index];
    }
  }

}
