<ng-container *ngIf="storeProducts$ | async as storeProducts">

  <div class="background-lightgray">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-8 pl-1">

          <h4>Store Fornitore</h4>
        </div>
        <div class="col text-right">
          <a routerLink="/">Home</a> /
          <a [routerLink]="['/vetrine-fornitori', storeProducts.store.macrosector]">{{storeProducts.store.macrosector}}</a>
          /
          <a
            [routerLink]="['/vetrine-fornitori', storeProducts.store.macrosector, storeProducts.store.sector]">{{storeProducts.store.sector}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="pb-3 pt-3"
       [style.background-color]="(storeProducts) ? storeProducts.store.color : 'white'">
    <img class="w-100 banner" src="https://www.weddingsmart.it/assets/images/bannerwedding.jpg"
         *ngIf="!storeProducts.store.imgbanner">
    <img
      *ngIf="storeProducts.store.imgbanner && (( innerWidth < 768 && !storeProducts.store.imgbannermobile) || innerWidth >= 768)"
      class="w-100 banner" [src]="'https://www.weddingsmart.it/api/images/store/' + storeProducts.store.imgbanner">
    <img *ngIf="storeProducts.store.imgbannermobile && innerWidth < 768"
         class="w-100 banner" [src]="'https://www.weddingsmart.it/api/images/store/' + storeProducts.store.imgbannermobile">
    <div class="container-fluid">

      <div class="card w-100 mb-2">
        <div class="card-body p-1 fornitore">
          <img class="imgFornitore" [src]="'https://www.weddingsmart.it/api/images/users/' + storeProducts.store.image">
          <div class="pl-100p">
            <h5 class="card-title mb-0">
              {{storeProducts.store.title}}</h5>
            <small class="text-black-50 text-capitalize" *ngIf="storeProducts.store.business_name as name">
              Ragione sociale: <span style="color: #4e4e4e">{{name}}</span>
            </small><br>
            <small class="text-black-50 text-capitalize" *ngIf="storeProducts.store.address as addr">
              <i class="fa fa-map-marker"></i> {{addr.toLowerCase()}}
            </small>
          </div>
          <div class="votes mt-3 row no-gutters">
            <div class="col-auto cursor-pointer" [routerLink]="['/', 'vetrine-fornitori', storeProducts.store.macrosector, storeProducts.store.sector ,storeProducts.store.username]"
                 [queryParams]="{tab: '3'}">
              <ng-container *ngFor="let i of [1,2,3,4,5]">
                <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star-y.png"
                     *ngIf="i <= +storeProducts.store.avgreview else half">
                <ng-template #half>
                  <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/half-star.png"
                       *ngIf="((i - 0.5 < +storeProducts.store.avgreview) && (i > +storeProducts.store.avgreview)) else nohalf">
                </ng-template>
                <ng-template #nohalf>
                  <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star.png"
                       *ngIf="i > +storeProducts.store.avgreview">
                </ng-template>
              </ng-container>
              {{storeProducts.store.reviews}}
            </div>
            <div class="col text-right">

              <app-action-favorite class="mr-1" classButton="btn-info rounded-circle"
                                   [tableId]="+storeProducts.store.id"
                                   tableName="store"
                                   [idProprierty]="+storeProducts.store.idmacro"
                                   [isRed]="storeProducts.store.isfavorite"
              ></app-action-favorite>
              <button class="btn btn-sm btn-info rounded-circle mr-1" (click)="communicator.sharePage$.next({})"><i
                class="fa fa-share-alt"></i></button>
              <ng-container *ngIf="communicator.checkUser$ | async as user">
                <button class="btn btn-sm btn-info rounded-circle mr-1"
                        *ngIf="user.isUser === '1'" (click)="segnala(storeProducts.store.id)"
                ><i
                  class="fa fa-exclamation"></i></button>
              </ng-container>

            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              <small class="color-purple">{{storeProducts.store.macrosector}} - {{storeProducts.store.sector}}</small>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col mt-auto">

              <p class="text-black-50 mb-1 mt-1" style="line-height: 1" *ngIf="storeProducts.store.minprice as price">
                Prezzi
                <br class="d-md-none"> a partire da: <b class="text-dark"
                                                        style="font-size: 18px">{{price | convertPrice:false:true}}</b>
              </p>
              <p class="mb-1 text-black-50">
                {{storeProducts.store.isservice === '0' ? 'Prodotti' : 'Servizi'}}:
                <b class="text-dark">{{storeProducts.products.length}}</b></p>
            </div>
            <div class="col-auto text-right mt-auto">
              <button class="btn btn-secondary btn-sm"
                      [routerLink]="['/vetrine-fornitori', storeProducts.store.macrosector, storeProducts.store.sector, storeProducts.store.username]">
                VEDI PROFILO
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3" [ngClass]="{'margin-15' : innerWidth < 768}">
        <div class="card-body">
          <h4>In primo piano</h4>
          <div [ngClass]="{'row': innerWidth >= 768, 'd-flex horizontal-x': innerWidth < 768}">
            <div class="col-10 col-md-3" *ngIf="storeProducts.store.code1" [ngClass]="{'pl-0': innerWidth < 768}">
              <app-product-store-item
                [product]="storeProducts.products | getProductByCodePipe:storeProducts.store.code1"
                [isHorizontal]="true"></app-product-store-item>
            </div>
            <div class="col-10 col-md-3" *ngIf="storeProducts.store.code2" [ngClass]="{'pl-0': innerWidth < 768}">
              <app-product-store-item
                [product]="storeProducts.products | getProductByCodePipe:storeProducts.store.code2"
                [isHorizontal]="true"></app-product-store-item>
            </div>
            <div class="col-10 col-md-3" *ngIf="storeProducts.store.code3" [ngClass]="{'pl-0': innerWidth < 768}">
              <app-product-store-item
                [product]="storeProducts.products | getProductByCodePipe:storeProducts.store.code3"
                [isHorizontal]="true"></app-product-store-item>
            </div>
            <div class="col-10 col-md-3" *ngIf="storeProducts.store.code4" [ngClass]="{'pl-0': innerWidth < 768}">
              <app-product-store-item
                [product]="storeProducts.products | getProductByCodePipe:storeProducts.store.code4"
                [isHorizontal]="true"></app-product-store-item>
            </div>
          </div>
        </div>
      </div>

<!--      <div class="row mt-3">-->
<!--        <div class="col-6 col-md-3 cursor-pointer" (click)="changeCategory('1')"-->
<!--             [ngClass]="filter.idmacrocategory === '1' ? 'col-border-primary' : ''">-->
<!--          <div class="card w-100 background-lightgray card-category">-->
<!--            <img class="card-img-top" src="http://93.42.249.60/WeddingSmart/img/homepage/adv-sposa.png"-->
<!--                 alt="Filtra prodotti per categoria Sposa">-->
<!--            <div class="card-img-overlay p-1 pl-3 pt-3">-->
<!--              <h6 class="card-title">Sposa</h6>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6 col-md-3 cursor-pointer" (click)="changeCategory('2')"-->
<!--             [ngClass]="filter.idmacrocategory === '2' ? 'col-border-primary' : ''">-->
<!--          <div class="card w-100 background-lightgray card-category">-->
<!--            <img class="card-img-top" src="http://93.42.249.60/WeddingSmart/img/homepage/adv-sposo.png"-->
<!--                 alt="Filtra prodotti per categoria Sposo" title="Filtra prodotti per categoria Sposo">-->
<!--            <div class="card-img-overlay p-1 pl-3 pt-3">-->
<!--              <h6 class="card-title">Sposo</h6>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6 col-md-3 cursor-pointer mt-1 mt-md-0" (click)="changeCategory('3')"-->
<!--             [ngClass]="filter.idmacrocategory === '3' ? 'col-border-primary' : ''">-->
<!--          <div class="card w-100 background-lightgray card-category">-->
<!--            <img class="card-img-top" src="http://93.42.249.60/WeddingSmart/img/homepage/adv-fornitori.png"-->
<!--                 alt="Filtra prodotti per categoria Fornitori">-->
<!--            <div class="card-img-overlay p-1 pl-3 pt-3">-->
<!--              <h6 class="card-title">Fornitori</h6>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-6 col-md-3 cursor-pointer mt-1 mt-md-0" (click)="changeCategory('4')"-->
<!--             [ngClass]="filter.idmacrocategory === '4' ? 'col-border-primary' : ''">-->
<!--          <div class="card w-100 background-lightgray card-category">-->
<!--            <img class="card-img-top" src="http://93.42.249.60/WeddingSmart/img/homepage/adv-ricevimento.png"-->
<!--                 alt="Filtra prodotti per categoria Ricevimento">-->
<!--            <div class="card-img-overlay p-1 pl-3 pt-3">-->
<!--              <h6 class="card-title">Ricevimento</h6>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="card mt-3 overflow-hidden" [ngClass]="{'margin-15' : innerWidth < 768}">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h4>{{storeProducts.store.isservice === '1' ? 'Servizi' : 'Prodotti'}}</h4>
            </div>
            <div class="col-auto my-auto">
              <button class="btn p-0">
                <i class="color-lightpink fa fa-bars font-view" [ngClass]="{'color-primary': isRow}"
                   (click)="changeViewType(true)"></i>
              </button>
              <button class="btn p-0">
                <i class="color-lightpink fa fa-th-large font-view" [ngClass]="{'color-primary': !isRow}"
                   (click)="changeViewType(false)"></i>
              </button>
            </div>

            <div class="col-12">
              <input class="form-control" [(ngModel)]="filter.search" placeholder="Cerca fra i prodotti">
            </div>

          </div>
          <div class="row">
            <div [ngClass]="{'col-6 pl-1 pr-1 col-md-2': !isRow, 'col-12 col-md-4' : isRow}"
                 *ngFor="let prod of storeProducts.products | filterByCategory:filter.search:filter.idmacrocategory">
              <app-product-store-item [product]="prod" [isRow]="isRow"></app-product-store-item>
            </div>
            <div class="col-12 mt-3"
                 *ngIf="(storeProducts.products | filterByCategory:filter.search:filter.idmacrocategory).length === 0">
              <div class="alert alert-warning" role="alert">
                Nessun prodotto è presente in questa lista
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-menu-cart></app-menu-cart>
  </div>

</ng-container>
