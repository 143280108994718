<div class="container-share" [class.height]="show">
  <div class="container pt-3">
    <div>
      <h6>Condividi su:
      <button class="btn float-right pt-0" (click)="close()"> <i class="fa fa-times fa-1x"></i></button>
      </h6>
    </div>
    <div class="row">
      <div class="col-3 col-md-2 mt-2" *ngFor="let link of links">
        <button (click)="share(link)" class="btn">
          <i *ngIf="link !== 'instagram' && link !== 'messenger' && link !== 'whatsapp'" [class]="'fab fa-3x ' + link"></i>
          <img width="49" *ngIf="link === 'instagram'" src="/assets/icons/share/instangram.jpg">
          <img width="49" *ngIf="link === 'messenger'" src="/assets/icons/share/messenger.png">
          <img width="49" *ngIf="link === 'whatsapp'" src="/assets/icons/share/whatsapp.svg">
        </button>
      </div>
    </div>
  </div>
</div>
