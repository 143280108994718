import {Component, Input, OnInit} from '@angular/core';
import {Review} from '../../../objects/IVetrina';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reviews-accordion',
  templateUrl: './reviews-accordion.component.html',
  styleUrls: ['./reviews-accordion.component.scss']
})
export class ReviewsAccordionComponent implements OnInit {
  @Input() sectors: string[];
  @Input() reviews: Review[];
  @Input() avgReview: number;
  @Input() showItemBuyed = false;
  @Input() showFilter = false;
  @Input() username: string;
  @Input() voteFilter = '0';

  constructor(private router: Router) { }

  ngOnInit(): void {

  }

  getCountPercent(vote: number) {
    const numbers = this.reviews.reduce((a,b) => a += ((+b.vote > (vote - 1) && +b.vote <= vote) ? 1 : 0), 0);
    return (numbers * 100) / this.reviews.length;
  }
  getCount(vote: number) {
    return this.reviews.filter(e => +e.vote === vote).length;
  }

  filterVote(vote: number) {
    if(this.showFilter) {
      this.voteFilter = vote + '';
    } else {
      this.router.navigate(['/vetrine-fornitori/' + this.sectors.join('/') + '/' + this.username], {queryParams: {vote, tab: '3'}})
    }
  }
}
