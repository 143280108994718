import { Pipe, PipeTransform } from '@angular/core';
import {IOffer} from "../../objects/IAuction";

@Pipe({
  name: 'filterOffers'
})
export class FilterOffersPipe implements PipeTransform {

  transform(values: IOffer[], filterVote = '0', orderOffer = '0', ...args: unknown[]): IOffer[] {
    return values.filter(e => {
      if (filterVote !== '0') {
        return (!!e.vote && filterVote === '1') || (!e.vote && filterVote === '2');
      }
      return true;
    }).sort((a, b) => {
      if (orderOffer !== '0') {
        if (orderOffer === '1') {
          return a.vote > b.vote ? -1 : 1;
        } else if (orderOffer === '1') {
          return a.business_name.toLowerCase() > b.business_name.toLowerCase() ? 1 : -1;
        } else if (orderOffer === '2') {
          return a.offer_num > b.offer_num ? 1 : -1;
        } else if (orderOffer === '3') {
          return a.offer_num < b.offer_num ? 1 : -1;
        }
      }
      return -1;
    });
  }

}
