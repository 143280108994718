import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-remember-password',
  templateUrl: './remember-password.component.html',
  styleUrls: ['./remember-password.component.scss']
})
export class RememberPasswordComponent implements OnInit {
  @Input() isSent = false;
  @Input() email = '';
  @Output() sendEmail = new EventEmitter<string>();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  clearEmailValidation() {

  }
}
