import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncFullname'
})
export class TruncFullnamePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if(value.includes(' ') && value !== 'Utente sposo') {
      value = value.split(' ')[0] + ' ' + value.split(' ')[1].charAt(0) + '.';
    }
    return value;
  }

}
