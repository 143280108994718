<div [ngClass]="{'m-less': !isHome, 'm-home': isHome}" [class.w-50]="!isHome && !isRow">

  <div class="card mt-2 mb-2 w-100" [ngClass]="{'detail': isDetail}" *ngIf="!isHome">
    <!--    <img class="card-img-top w-100" [src]="'http://93.42.249.60/api/images/vetrina/' + vetrina.banner + '?random=' + random"-->
    <!--         alt="Card image cap">-->

    <img
      *ngIf="vetrina.banner && (( innerWidth < 768 && !vetrina.bannermobile) || innerWidth >= 768)"
      class="w-100 card-img-top" [src]="'https://www.weddingsmart.it/api/images/vetrina/' + (vetrina.banner | getWebp)">
    <img *ngIf="vetrina.bannermobile && innerWidth < 768"
         class="w-100 card-img-top" [src]="'https://www.weddingsmart.it/api/images/vetrina/' + (vetrina.bannermobile | getWebp)">


    <div class="card-body p-1" [class.mt-4]="!vetrina.banner">
      <img class="imgFornitore"
           *ngIf="vetrina.image"
           [src]="'https://www.weddingsmart.it/api/images/users/' + (vetrina.image | getWebp) + '?random=' + random"
           [class.bg-transparent]="!vetrina.banner"
      >
      <div class="pl-100p">
        <h5 class="card-title mb-0 h-auto">{{vetrina.title}}</h5>
        <small class="text-black-50  text-capitalize" *ngIf="vetrina.address as addr"><i
          class="fa fa-map-marker"></i> {{addr.toLowerCase()}}</small>
      </div>
      <div class="votes mt-3 row no-gutters">
        <div class="col-auto cursor-pointer"
             [routerLink]="['/', 'vetrine-fornitori', vetrina.macrosector, vetrina.sector, vetrina.code_online || vetrina.username]"
             [queryParams]="{tab: '3'}">

          <ng-container *ngFor="let i of [1,2,3,4,5]">
            <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star-y.png"
                 *ngIf="i <= +vetrina.avgreview else half">
            <ng-template #half>
              <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/half-star.png"
                   *ngIf="((i - 0.5 < +vetrina.avgreview) && (i > +vetrina.avgreview)) else nohalf">
            </ng-template>
            <ng-template #nohalf>
              <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star.png"
                   *ngIf="i > +vetrina.avgreview">
            </ng-template>
          </ng-container>
          <label class="ml-1">{{vetrina.reviews}}</label>
        </div>
        <div class="col text-right text-black-50" *ngIf="!isDetail">
          <ng-container *ngIf="vetrina.minprice as price">
            <span class="text-black-50">Prezzo da: </span> {{price | convertPrice:false:true}}
          </ng-container>
        </div>
        <div class="col text-right" *ngIf="!isDetail && favoriteTable != null">
          <ng-container *ngIf="favoriteTable === '1'">
            <app-action-favorite class="mr-1" classButton="btn-info rounded-circle" [tableId]="vetrina.id"
                                 tableName="vetrina" [showConfirm]="true"
                                 [idProprierty]="vetrina.idmacro" [(isRed)]="vetrina.isfavorite"
                                 (changeFavorite)="reloadFavorite.emit()"
            ></app-action-favorite>
          </ng-container>
          <ng-container *ngIf="favoriteTable === '2'">
            <app-action-favorite class="mr-1" classButton="btn-info rounded-circle" [tableId]="vetrina.id"
                                 tableName="store" [showConfirm]="true"
                                 [idProprierty]="vetrina.idmacro" [(isRed)]="vetrina.isfavorite"
                                 (changeFavorite)="reloadFavorite.emit()"
            ></app-action-favorite>
          </ng-container>

        </div>
        <div class="col text-right" *ngIf="isDetail">

          <app-action-favorite class="mr-1" classButton="btn-info rounded-circle" [tableId]="vetrina.id"
                               tableName="vetrina"
                               [idProprierty]="vetrina.idmacro" [isRed]="vetrina.isfavorite"
          ></app-action-favorite>
          <button class="btn btn-sm btn-info rounded-circle mr-1" (click)="communicator.sharePage$.next({})"><i
            class="fa fa-share-alt"></i></button>
          <ng-container *ngIf="communicator.checkUser$ | async as user">
            <button class="btn btn-sm btn-info rounded-circle mr-1"
                    *ngIf="user.isUser === '1'"
                    (click)="segnala()"><i
              class="fa fa-exclamation"></i></button>
          </ng-container>
        </div>
      </div>
      <div class="row no-gutters" *ngIf="!isDetail">
        <div class="col-12">
          <small class="color-purple">{{vetrina.macrosector}} - {{vetrina.sector}}</small>
        </div>
      </div>
      <div class="row no-gutters" *ngIf="!isDetail">
        <div class="col-6 text-center">
          <button class="btn btn-secondary btn-sm"
                  [routerLink]="['/vetrine-fornitori', vetrina.macrosector, vetrina.sector, vetrina.code_online || vetrina.username]">VEDI
            PROFILO
          </button>
        </div>
        <div class="col-6 text-center">
          <button class="btn btn-secondary btn-sm"
                  [routerLink]="['/store', vetrina.macrosector, vetrina.sector, vetrina.code_online || vetrina.username]">VEDI STORE
          </button>
        </div>
      </div>
      <app-vetrina-detail [vetrina]="vetrina" [fotos]="foto" [reviews]="reviews" *ngIf="isDetail"></app-vetrina-detail>
    </div>
  </div>

  <div class="card mt-2 mb-2 w-100" *ngIf="isHome">
    <img class="card-img-top" style="height: 155px;"
         *ngIf="vetrina.banner"
         [src]="'https://www.weddingsmart.it/api/images/vetrina/' + (vetrina.banner | getWebp) + '?random=' + random"
         alt="Card image cap">
    <div *ngIf="!vetrina.banner" style="height: 155px;">
    </div>
    <div class="card-body p-1">
      <img class="imgFornitore"
           *ngIf="vetrina.image"
           [class.bg-transparent]="!vetrina.banner"
           [src]="'https://www.weddingsmart.it/api/images/users/' + (vetrina.image | getWebp) + '?random=' + random">
      <div class="pl-100p">
        <h5 class="card-title mb-0 h-auto"
            [style]="!vetrina.banner ? 'margin-top: -1rem' : null"
        >{{vetrina.title}}</h5>
        <small class="text-black-50 address text-capitalize" *ngIf="vetrina.address as addr"><i
          class="fa fa-map-marker"></i> {{addr.toLowerCase()}}</small>
      </div>
      <div class="votes mt-3 row no-gutters">
        <div class="col-auto cursor-pointer"
             [routerLink]="['/', 'vetrine-fornitori', vetrina.macrosector, vetrina.sector, vetrina.code_online || vetrina.username]"
             [queryParams]="{tab: '3'}">

          <ng-container *ngFor="let i of [1,2,3,4,5]">
            <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star-y.png"
                 *ngIf="i <= +vetrina.avgreview else half">
            <ng-template #half>
              <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/half-star.png"
                   *ngIf="((i - 0.5 < +vetrina.avgreview) && (i > +vetrina.avgreview)) else nohalf">
            </ng-template>
            <ng-template #nohalf>
              <img class="icon-star" src="https://www.weddingsmart.it/assets/icons/star.png"
                   *ngIf="i > +vetrina.avgreview">
            </ng-template>
          </ng-container>
          <label class="ml-1">{{vetrina.reviews}}</label>
        </div>
        <div class="col text-right">

          <app-action-favorite class="mr-1" classButton="btn-info rounded-circle" [tableId]="vetrina.id"
                               tableName="vetrina"
                               [idProprierty]="vetrina.idmacro" [isRed]="vetrina.isfavorite"
          ></app-action-favorite>
          <button class="btn btn-sm btn-info rounded-circle mr-1"><i
            class="fa fa-share-alt" (click)="communicator.sharePage$.next({})"></i></button>
          <ng-container *ngIf="communicator.checkUser$ | async as user">
            <button class="btn btn-sm btn-info rounded-circle mr-1"
                    *ngIf="user.isUser === '1'"
                    (click)="segnala()"><i
              class="fa fa-exclamation"></i></button>
          </ng-container>
        </div>
      </div>
      <small class="color-purple">{{vetrina.macrosector}} - {{vetrina.sector}}</small>
      <div class="row no-gutters">
        <div class="col">
          <label class="text-black-50 pricestarted">Prezzi <br>a partire da: </label> <b
          style="font-size: 19px; margin-left: 3px;">{{vetrina.minprice | convertPrice:false:true}}</b><br>
          <span class="text-black-50">{{vetrina.isservice === '1' ? 'Servizi' : 'Prodotti'}}: </span> <b>{{vetrina.products}}</b>
        </div>
        <div class="col-auto mt-auto text-right">
          <button class="btn btn-secondary btn-sm"
                  [routerLink]="['/vetrine-fornitori', vetrina.macrosector, vetrina.sector, vetrina.code_online || vetrina.username]">VEDI
            PROFILO
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
