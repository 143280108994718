import { Pipe, PipeTransform } from '@angular/core';
import {IProductPrice} from '../objects/IProduct';
import {Utility} from '../objects/Utility';

@Pipe({
  name: 'getPrice'
})
export class GetPricePipe implements PipeTransform {

  transform(prices: IProductPrice[], isRange = false, ...args: unknown[]): string {
    if (prices.length > 0) {
      let priceString = '';
      for (const price of prices) {
        if (priceString === '' || priceString > price.price) {
          priceString = price.price;
        }
      }
      if (!isRange) {
        if (prices.length > 1) {
          priceString = 'Da ' + Utility.convertPrice(priceString);
        } else {
          priceString = Utility.convertPrice(priceString);
        }
      } else {
        if (prices.length > 1) {
          let priceStringMax = '';
          for (const price of prices) {
            if (priceStringMax === '' || priceStringMax < price.price) {
              priceStringMax = price.price;
            }
          }
          priceString = priceString.split('.')[0] + ',<span class="decimal">' + priceString.split('.')[1] + ' €</span>' + ' - ' +
            priceStringMax.split('.')[0] + ',<span class="decimal">' + priceStringMax.split('.')[1] + ' €</span>';
        } else {
          priceString = Utility.convertPrice(priceString);
        }
      }
      return priceString;
    }
    return '';
  }

}
