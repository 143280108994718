<ng-container *ngIf="vetrina$ | async as macrovetr">

  <div class="background-lightgray">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-8 pl-1">

          <h4>Vetrina fornitore</h4>
        </div>
        <div class="col text-right">
          <a routerLink="/">Home</a> /
          <a [routerLink]="['/vetrine-fornitori', macrovetr.vetrina.macrosector]">{{macrovetr.vetrina.macrosector}}</a> /
          <a [routerLink]="['/vetrine-fornitori', macrovetr.vetrina.macrosector, macrovetr.vetrina.sector]">{{macrovetr.vetrina.sector}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="m-less">
      <app-vetrina-element [vetrina]="macrovetr.vetrina" [foto]="macrovetr.foto" [reviews]="macrovetr.reviews"
                           [isDetail]="true"></app-vetrina-element>
    </div>
  </div>
</ng-container>
