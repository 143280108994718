import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from './http.service';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  isLoading$ = new Subject<boolean>();
  permissions$;

  position = { lat: null, lng: null };
  authorization: string = null;

  constructor(private httpService: HttpService) {
    this.permissions$ = this.httpService.getPermissions().pipe(
      publishReplay(1),
      refCount()
    );
  }
}