import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {ActivatedRoute} from '@angular/router';
import {OrderDetail} from '../order-detail/orderDetail';

@Component({
  templateUrl: './order-riepilogo.component.html',
  styleUrls: ['./order-riepilogo.component.scss']
})
export class OrderRiepilogoComponent implements OnInit {
  type: string;
  detailObj: OrderDetail = null;
  ordered = null;

  constructor(private httpService: HttpService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.type === 'order') {
      this.httpService.getOrderDetail(id).subscribe(resp => {
        this.detailObj = resp;
        for (const prod of resp.products) {
          prod.other_information = JSON.parse(prod.other_information);
        }
        const state = resp.states.find(e => e.state_order.toUpperCase() === 'PAGATO');
        this.ordered = state.timestamp;
      })
    } else if (this.type === 'auction') {
      this.httpService.getOfferOrderDetail(id).subscribe(resp => {
        resp.order.other_information = JSON.parse(resp.order.other_information);
        // @ts-ignore
        this.detailObj = {...resp.order, states: resp.states, products: [resp.order]};
        const state = resp.states.find(e => e.state_order.toUpperCase() === 'PAGATO');
        this.ordered = state.timestamp;
      })
    }
  }

  getTotal(): string {
    return (+this.detailObj.total - +this.detailObj.costshipping) + '';
  }

  calcIva(pay: string, iva: string) {
    return +pay * +iva / 100;
  }
}
