import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {IAuction} from '../../../objects/IAuction';
import {CommunicatorService} from '../../../services/communicator.service';
import {Router} from '@angular/router';
import {PopupInputComponent} from "../../../components/popup/popup-input/popup-input.component";
import {PopupComponent} from "../../../components/popup/popup/popup.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpService} from "../../../services/http.service";

@Component({
  selector: 'app-my-auction-item',
  templateUrl: './my-auction-item.component.html',
  styleUrls: ['./my-auction-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyAuctionItemComponent implements OnInit {
  @Input() auctions: IAuction[];
  constructor(
    private communicator: CommunicatorService,
    private router: Router,
    private modalService: NgbModal,
    private httpService: HttpService
  ) { }

  ngOnInit(): void {
  }

  republish(auction: IAuction) {
    this.communicator.auctionRepublish = auction;
    this.router.navigateByUrl('/new-auction');
  }

  editAuction(auction: IAuction) {
    const rf = this.modalService.open(PopupInputComponent, {size: 'lg'});
    rf.componentInstance.title = 'Descrivi le modifiche da richiedere all\'amministratore per l\'annuncio: "' + auction.title + '"';
    rf.componentInstance.closeDescription.subscribe((text: string) => {
      rf.close();
      if (text.length > 0) {
        this.httpService.postEditAuction(auction, text).subscribe(resp => {
          if (resp && resp.result) {
            const mr2 = this.modalService.open(PopupComponent);
            mr2.componentInstance.title = 'Attenzione';
            mr2.componentInstance.body = 'Richiesta effettuata con successo';
          }
        });
      }
    });
  }
}
