<div class="row" *ngIf="onlySize && (otherInformation.sizeShoes || otherInformation.sizeAddress)">
  <div class="col-12" *ngIf="otherInformation.sizeShoes">
    <label class="text-black-50">Misura delle scarpe:</label>
    <span *ngFor="let serv of otherInformation.sizeShoes" class="badge" (click)="sizeSelectedChange.emit(serv)"
          [ngClass]="{'badge-light' : serv !== sizeSelected, 'badge-primary': serv === sizeSelected}" >{{serv}}</span>
  </div>
  <div class="col-12" *ngIf="otherInformation.sizeAddress">
    <label class="text-black-50">Taglie disponibili:</label>
    <span class="badge" [ngClass]="{'badge-light' : !readyCustom && serv !== sizeSelected, 'badge-primary': serv === sizeSelected, 'badge-secondary': readyCustom}"
          *ngFor="let serv of otherInformation.sizeAddress" (click)="(readyCustom) ? null : sizeSelectedChange.emit(serv)">{{serv}}</span>
  </div>
  <div class="col-12 header-lightpink" *ngIf="otherInformation.customAddress">

    <ngb-accordion #a="ngbAccordion" *ngIf="!hideCustom">
      <ngb-panel id="custom-panel-1">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between" (click)="a.toggle('custom-panel-1')">
            <h6 class="m-0">Oppure richiedilo su misura</h6>
            <button class="btn">
              <i class="fa fa-chevron-down" *ngIf="!opened"></i>
              <i class="fa fa-chevron-up" *ngIf="opened"></i>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <small class="d-block">Se non hai scelto una taglia standard, inserisci qui le tue misure dopo aver controllato la tabella taglie comparativa sotto riportata - Una volta salvate, inserisci direttamente il prodotto al carrello</small>
          <div class="row">
            <div class="col-6 col-md-4 mt-1" *ngFor="let option of otherInformation.customAddressInputs">
              <label [for]="option.label">{{option.label}}</label>
              <div class="input-group">
                <input [id]="option.label" [(ngModel)]="option.value" class="form-control" type="number" max="200" min="0" [disabled]="readyCustom || (sizeSelected != '')">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2" style="background: white">cm</span>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-3">
            <button *ngIf="!readyCustom" class="btn btn-primary"
                    [disabled]="sizeSelected"
                    (click)="readyCustom = true; otherInformation.customMisure = otherInformation.customAddressInputs;">Salva misure</button>
            <small *ngIf="readyCustom"><i class="fa fa-check-circle text-success"></i> Pronto per essere inserito al carrello</small>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="col-12" *ngIf="otherInformation.image">

    <ngb-accordion #acc="ngbAccordion" class="mt-3" id="panel-white">
      <ngb-panel id="toggle-1">
        <ng-template ngbPanelHeader let-opened="opened">
          <label (click)="acc.toggle('toggle-1')" class="text-black-50 w-100" ngbPanelToggle>Tabella taglie comparativa

            <i class="mt-1 fa fa-1x"
               [ngClass]="{'fa-chevron-up': opened, 'fa-chevron-down': !opened}"></i>
          </label>
        </ng-template>
        <ng-template ngbPanelContent>
          <img class="w-100" [src]="'https://www.weddingsmart.it/api/images/shared/' + otherInformation.image">
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
<div class="row" *ngIf="!onlySize">
  <div class="col-12 col-md-6" *ngIf="otherInformation.brand">
    <label class="text-black-50">Brand</label>
    <label>{{otherInformation.brand}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.ageService">
    <label class="text-black-50">Età media {{(category === '12' ? 'dei bambini ' : '') + 'per il servizio:'}}</label>
    <label>{{otherInformation.ageService}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.otherService">
    <label class="text-black-50">{{(category === '12') ? 'Tipo di intrattenimento incluso:' : 'Servizi aggiuntivi offerti:'}}</label><br>
    <span class="badge badge-info mb-1" *ngFor="let serv of otherInformation.otherService">{{serv}}</span>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.genreService">
    <label class="text-black-50">Genere musicale disponibile:</label><br>
    <span class="badge badge-info mb-1" *ngFor="let serv of otherInformation.genreService">{{serv}}</span>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.locationService">
    <label class="text-black-50">Servizio disponibile per location:</label><br>
    <span class="badge badge-info mb-1" *ngFor="let serv of otherInformation.locationService">{{serv}}</span>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.distanceService">
    <label class="text-black-50">Servizio disponibile per massima distanza tra location:</label>
    <label>{{otherInformation.distanceService}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.styleService">
    <label class="text-black-50">Stile fotografico:</label><br>
    <span class="badge badge-info mb-1" *ngFor="let serv of otherInformation.styleService">{{serv}}</span>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.timeService">
    <label class="text-black-50">Durata massima del servizio offerto:</label>
    <label>{{otherInformation.timeService}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.quantityPhotoService">
    <label class="text-black-50">Quantità di fotografie post prodotte:</label>
    <label>{{otherInformation.quantityPhotoService}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.typeService">
    <label class="text-black-50">Tipo di servizio incluso:</label>
    <label>{{otherInformation.typeService}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.whereService">
    <label class="text-black-50">Luogo della fornitura del servizio:</label><br>
    <span class="badge badge-info mb-1" *ngFor="let serv of otherInformation.whereService">{{serv}}</span>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.styleMenu">
    <label class="text-black-50">Tipo di servizio:</label>
    <label>{{otherInformation.styleMenu}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.styleWedding">
    <label class="text-black-50">Stile del matrimonio disponibile:</label><br>
    <span class="badge badge-info mb-1" *ngFor="let serv of otherInformation.styleWedding">{{serv}}</span>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.disponibilityWedding">
    <label class="text-black-50">Disponibilità del servizio:</label><br>
    <span class="badge badge-info mb-1" *ngFor="let serv of otherInformation.disponibilityWedding">{{serv}}</span>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.styleAddress">
    <label class="text-black-50">Stile dell'abito:</label>
    <label>{{otherInformation.styleAddress}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.typeAccessories">
    <label class="text-black-50">Accessori:</label>
    <label>{{otherInformation.typeAccessories}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.colorAddress">
    <label class="text-black-50">Colore disponibile:</label>
    <label>{{otherInformation.colorAddress}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.materialAddress">
    <label class="text-black-50">Tessuto dell'abito:</label>
    <label>{{otherInformation.materialAddress}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.optionsAddress">
    <label class="text-black-50">Accessori inclusi:</label>
    <label>{{otherInformation.optionsAddress}}</label>
  </div>
  <div class="col-12 col-md-6" *ngIf="otherInformation.heightAddress">
    <label class="text-black-50">Altezza ideale:</label>
    <label>{{otherInformation.heightAddress}}</label>
  </div>
</div>
