<h4>
  Recensioni sposi ( {{reviews.length}} )
</h4>
<div class="row">
  <div class="col-auto mb-auto">
    <h5 class="mb-0">
      <b *ngIf="avgReview">{{avgReview | number:'1.0-1'}}</b> <b *ngIf="!avgReview"> 0</b>
      <span class="text-black-50"> / 5</span></h5>
  </div>
  <div class="col text-right">

    <label class="text-black-50">
      <ng-container *ngFor="let i of [1,2,3,4,5]">
        <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/star-y.png" *ngIf="i <= +avgReview else half">
        <ng-template #half>
          <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/half-star.png" *ngIf="((i - 0.5 < +avgReview) && (i > +avgReview)) else nohalf">
        </ng-template>
        <ng-template #nohalf>
          <img class="icon-star large" src="https://www.weddingsmart.it/assets/icons/star.png" *ngIf="i > +avgReview">
        </ng-template>
      </ng-container>
    </label>
  </div>
</div>
<div class="row" *ngFor="let i of [5,4,3,2,1]">
  <div class="col-auto my-auto cursor-pointer" (click)="filterVote(i)">
    <small class="text-link">{{i}} {{i !== 1 ? ' stelle' : ' stella'}}</small>
  </div>
  <div class="col my-auto pl-0 pr-0">
    <ngb-progressbar type="info" [value]="getCountPercent(i)" height="10px"></ngb-progressbar>
  </div>
  <div class="col-auto my-auto">
    <small class="text-link">{{getCount(i)}}</small>
  </div>
</div>
<ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1" class="mt-3" id="panel-white">
  <ngb-panel>
    <ng-template ngbPanelHeader let-opened="opened">
      <button class="btn btn-secondary w-100" ngbPanelToggle>Leggi tutte le recensioni

        <i class="mt-1 fa fa-1x float-right"
           [ngClass]="{'fa-chevron-up': opened, 'fa-chevron-down': !opened}"></i>
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="row" *ngIf="showFilter">
        <div class="col-8 col-md-4">
          <select class="form-control" [(ngModel)]="voteFilter">
            <option value="0">Mostra recensioni per:</option>
            <option *ngFor="let i of [1,2,3,4,5]" [value]="i">{{i}} stell{{i === 1 ? 'a' : 'e'}}</option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="reviews | reviewsByVote:voteFilter as reviewsFiltered">
        <ng-container *ngIf="reviewsFiltered.length > 0 else warning">
          <app-reviews [reviews]="reviewsFiltered" [isExtended]="true" [showItemBuyed]="showItemBuyed"></app-reviews>
        </ng-container>
        <ng-template #warning>
          <div class="alert alert-warning mt-3" role="alert">
            Nessuna recensione presente
          </div>
        </ng-template>
      </ng-container>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
