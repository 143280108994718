<div class="row h-100 d-none d-md-flex">
  <div class="col-4 col-md-3 text-right">
    <img (click)="indexSelected = i" width="85"
         *ngFor="let img of images; let i = index;"
         class="mb-3 img-selectable"
         [src]="'https://www.weddingsmart.it/api/images/offers/' + img.data">
  </div>
  <div class="col pl-0 overflow-hidden">
    <div class="mr-auto" (mouseenter)="showZoom = true" (mouseleave)="showZoom = false"
         (mousemove)="zoomImage($event)" style="width: fit-content;">

      <img class="h-md-100" [src]="'https://www.weddingsmart.it/api/images/offers/' + images[indexSelected].data"
            [style.margin-left.%]="images[indexSelected].isHorizontal ? -40 : 0"
           style="transform-origin: 0 0 0"
           [style.transform]="(showZoom ? 'scale(3.4) translate(-' + xyZoom[0] + 'px, -' + xyZoom[1] + 'px)' : 'scale(1)')"
            >
    </div>
  </div>
</div>
<div class="d-block d-md-none">
  <ngb-carousel *ngIf="images" [showNavigationArrows]="innerWidth > 768" class="carousel-sm-h mb-0" [interval]="false" #carousel>
    <ng-template ngbSlide *ngFor="let img of images">
      <div class="picsum-img-wrapper h-100" (swipeleft)="swipe(carousel, true)" (swiperight)="swipe(carousel, false)">
        <img class="h-100" [src]="'https://www.weddingsmart.it/api/images/offers/' + img.data"
             [style.margin-left.%]="img.isHorizontal ? -45 : 0">
      </div>
    </ng-template>
  </ngb-carousel>
</div>
