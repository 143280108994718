import { Pipe, PipeTransform } from '@angular/core';
import {Utility} from '../objects/Utility';

@Pipe({
  name: 'convertPrice'
})
export class ConvertPricePipe implements PipeTransform {

  transform(value: string | number, showIva = false, afterEuro = false, ...args: unknown[]): string {
    return Utility.convertPrice(value + '', showIva, afterEuro);
  }

}
