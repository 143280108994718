import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePolicyComponent implements OnInit {
  @Input() showContainer = true;
  @Input() isModal = false;
  constructor(
    public modal: NgbModal
  ) {
  }

  ngOnInit(): void {
    window.scrollTo({left:0, top: 0});
  }

}
