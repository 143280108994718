export const STORAGE_PRODUCTS = 'STORAGE_PRODUCTS';

export const MENU_LINKS = [
  {
    img: '/assets/images/menuleft/sposa.png',
    label: 'Sposa',
    links: [
      'Abiti Sposa e Cerimonia', 'Accessori e scarpe', 'Acconciature',
      'Benessere, salute e bellezza', 'Gioielli e fedi nuziali', 'Intimo sposa e lingerie'
    ]
  },
  {
    img: '/assets/images/menuleft/sposo.png',
    label: 'Sposo',
    links: [
      'Accessori e scarpe', 'Abiti Sposo e Cerimonia', 'Benessere, salute e bellezza'
    ]
  },
  {
    img: '/assets/images/menuleft/fornitori.png',
    label: 'Fornitori',
    links: [
      'Addobbi, fiori e bouquet',
      'Allestimenti per matrimonio',
      'Animazione adulti e bambini',
      'Bomboniere e regali',
      'Celebrante Matrimonio',
      'Foto e video matrimonio',
      'Fuochi d\'artificio matrimonio',
      'Musica matrimonio',
      'Noleggio auto',
      'Noleggio carrozze matrimonio',
      'Noleggio Pullman e MiniBus',
      'Noleggio tensostrutture',
      'Noleggio veicoli spiritosi',
      'Proposte innovative',
      'Partecipazioni matrimonio',
      'Viaggio di nozze',
      'Wedding planner',
    ]
  },
  {
    img: '/assets/images/menuleft/ricevimenti.png',
    label: 'Ricevimento',
    links: [
      'Castelli, ville e casali',
      'Catering matrimonio',
      'Hotel',
      'Ristoranti matrimonio',
      'Sale ricevimento',
      'Torte nuziali',
    ]
  },
  {
    img: '/assets/images/menuleft/pets.png',
    label: 'Pets Wedding',
    links: [
      'Abbigliamento e accessori',
      'Servizi',
    ]
  },
];

export const MENU_LINKS_UTILS = [
  {label: 'Come funziona', link: '/come-funziona'},
  // {label: 'Come funziona Aziende', link: ''},
  // {label: 'Come funziona Wedding Smart', link: ''},
  {label: 'Chi siamo', link: ''},
  {label: 'Lavora con noi', link: ''},
  {label: 'Condizioni generali', link: ''},
  {label: 'Informativa cookie', link: '/cookie-policy'},
  {label: 'Informativa privacy', link: '/privacy-policy'},
  {label: 'Pubblicità per le aziende', link: ''},
  {label: 'Wedding Smart Magazine', link: ''},
  {label: 'FAQ', link: '/faq/aiuto/weddingsmart-aiuto-supporto'}];


export const NOTIFICATION_TYPES = ['', 'like.png', 'unlike.png', 'dollar.png',
  'heart.png', 'heart.png', 'heart.png', 'star.png', 'dollar.png', 'ensclamation.png',
  'dollar.png', 'ensclamation.png', 'dollar.png', 'dollar.png', 'ensclamation.png',
  'ensclamation.png', 'ensclamation.png'];
