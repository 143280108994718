import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {environment} from '../../../environments/environment';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {NavigationEvent} from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CommunicatorService} from "../../services/communicator.service";
import {TerminiComponent} from "../../pages/termini/termini.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CookiePolicyComponent} from "../../pages/cookie-policy/cookie-policy.component";
import {PrivacyPolicyComponent} from "../../pages/privacy-policy/privacy-policy.component";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  url = environment.urlApi;
  showFooter$ = new Observable<boolean>();
  window = null;
  constructor(private router: Router,
              private modalService: NgbModal,
              @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit(): void {
    this.showFooter$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((e: NavigationEnd) => !e.urlAfterRedirects.includes('new-auction'))
      );
    if (isPlatformBrowser(this.platformId)) {
      this.window = window;
    }
  }

  openTermini() {
    const rf = this.modalService.open(TerminiComponent, {size: 'lg', backdrop: 'static'});
    rf.componentInstance.isModal = true;

  }

  openCookie() {
    const rf = this.modalService.open(CookiePolicyComponent, {size: 'lg', backdrop: 'static'});
    rf.componentInstance.isModal = true;
  }

  openPrivacy() {
    const rf = this.modalService.open(PrivacyPolicyComponent, {size: 'lg', backdrop: 'static'});
    rf.componentInstance.isModal = true;
  }
}
