import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {CommunicatorService} from './services/communicator.service';
import {fromEvent, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PrivacyCookieComponent} from './components/privacy-cookie/privacy-cookie.component';
import {isPlatformBrowser} from '@angular/common';
import {NavigationEnd, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {transition, trigger, useAnimation} from '@angular/animations';
import {moveFromBottomFade, moveFromLeftFade, moveFromRightFade} from 'ngx-router-animations';
import {UtilityService} from './services/utility.service';
import {HttpService} from './services/http.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

  animations: [
    trigger('moveFromBottomFade',
      [
        transition('* => *',
          useAnimation(moveFromBottomFade,
            {params: {enterTiming: '0.7', leaveTiming: '0.6', enterDelay: '0', leaveDelay: '0'}}
          ))]),
    trigger('moveFromRightFade',
      [
        transition('vetrine-fornitori => vetrina-detail',
          useAnimation(moveFromRightFade,
            {params: {enterTiming: '0.7', leaveTiming: '0.7', enterDelay: '0', leaveDelay: '0'}})),
        transition('auctions => auction-detail',
          useAnimation(moveFromRightFade,
            {params: {enterTiming: '0.7', leaveTiming: '0.7', enterDelay: '0', leaveDelay: '0'}})),
        transition('auction-detail => offer-detail',
          useAnimation(moveFromRightFade,
            {params: {enterTiming: '0.7', leaveTiming: '0.7', enterDelay: '0', leaveDelay: '0'}})),
        transition('vetrina-detail => store-detail',
          useAnimation(moveFromRightFade,
            {params: {enterTiming: '0.7', leaveTiming: '0.7', enterDelay: '0', leaveDelay: '0'}}))
      ]),
    trigger('moveFromLeftFade',
      [
        transition('vetrina-detail => vetrine-fornitori',
          useAnimation(moveFromLeftFade,
            {params: {enterTiming: '0.7', leaveTiming: '0.7', enterDelay: '0', leaveDelay: '0'}})),
        transition('auction-detail => auctions',
          useAnimation(moveFromLeftFade,
            {params: {enterTiming: '0.7', leaveTiming: '0.7', enterDelay: '0', leaveDelay: '0'}})),
        transition('offer-detail => auction-detail',
          useAnimation(moveFromLeftFade,
            {params: {enterTiming: '0.7', leaveTiming: '0.7', enterDelay: '0', leaveDelay: '0'}})),
        transition('store-detail => vetrina-detail',
          useAnimation(moveFromLeftFade,
            {params: {enterTiming: '0.7', leaveTiming: '0.7', enterDelay: '0', leaveDelay: '0'}}))
      ]),
  ]
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'WeddingSmartUniversal';
  eventSubscription$: Observable<number>;
  isFirstLoad = true;
  isServer = true;

  constructor(public communicatorService: CommunicatorService, private modalService: NgbModal,
              private utilityService: UtilityService, private communicator: CommunicatorService,
              private httpService: HttpService,
              @Inject(PLATFORM_ID) private platformId: object, private router: Router,
              private googleAnalytics: GoogleAnalyticsService
  ) {

    if (isPlatformBrowser(this.platformId)) {
      router.events.pipe(
        filter(ev => ev instanceof NavigationStart)
      ).subscribe(() => {
          window.scrollTo({top: 0, behavior: 'smooth'});
        }
      );
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isServer = false;
      this.eventSubscription$ = fromEvent(window, 'scroll').pipe(
        map(() => window.scrollY)
      );
      // setTimeout(() => {
        const cookieAccepted = localStorage.getItem('cookieAccepted');
        if (!cookieAccepted) {
          const rf = this.modalService.open(PrivacyCookieComponent, {size: 'lg', backdrop: 'static'});
          rf.componentInstance.saveEmitter.subscribe(save => {

            const cookieJson = JSON.parse(localStorage.getItem('cookieAccepted'));
            if (cookieJson.prest) {
              this.router.events.pipe(
                filter(ev => ev instanceof NavigationEnd)
              ).subscribe((event: NavigationEnd) => {
                  // setTimeout(() => {
                    console.log(document.title);
                    this.googleAnalytics.pageView(event.url, document.title);
                  // }, 500);
                }
              );
            }
          });
        } else {
          const cookieJson = JSON.parse(localStorage.getItem('cookieAccepted'));
          if (cookieJson.prest) {
            this.router.events.pipe(
              filter(ev => ev instanceof NavigationEnd)
            ).subscribe((event: NavigationEnd) => {
                // setTimeout(() => {
                  console.log(document.title);
                  this.googleAnalytics.pageView(event.url, document.title);
                // }, 500);
              }
            );
          }
          this.communicator.checkUser$.subscribe(user => {
            if (user) {
              this.httpService.saveCookie(cookieJson)
                .subscribe();
            }
          });
        }
      // }, 3000);
    }
  }

  ngAfterViewInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            if (position) {
              console.log('position: ' + position);
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              this.utilityService.position = {lat, lng};
            }
          },
          (error) => console.log(error));
      }
    }
  }

  getState(outlet: RouterOutlet) {
    if (this.isFirstLoad && isPlatformBrowser(this.platformId)) {
      // setTimeout(() => {
        this.isFirstLoad = false;
      // }, 1000);
    }
    return outlet.activatedRouteData.state;
  }
}
