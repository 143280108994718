import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpService} from '../../services/http.service';
import {IUser} from '../../objects/IUser';
import {AuctionOrder, StoreOrder} from '../../objects/IOrderDetail';
import {isPlatformBrowser} from '@angular/common';
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ResultReviewComponent} from "../../components/popup/result-review/result-review.component";

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  user$: Observable<IUser>;
  products$: Observable<StoreOrder | AuctionOrder>;
  useName = true;
  votes: { idProd: string, vote: number, title: string, desc: string }[] = [];
  params = {type: '', id: null};
  objVote = null;
  checkFirst = true;
  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private activated: ActivatedRoute,
              private modalService: NgbModal,
              private router: Router,
              private httpService: HttpService) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.user$ = this.httpService.getUser();
      this.products$ = this.activated.paramMap.pipe(
        filter(params => !!params.get('type')),
        filter(params => !!params.get('id')),
        map(params => ({type: params.get('type'), id: params.get('id')})),
        tap(params => this.params = params),
        switchMap((params) => this.httpService.getReview(params.type, +params.id))
      );
    }
  }

  isOrderAuction(prod: StoreOrder | AuctionOrder) {
    if ('order' in prod) {
      if (!this.objVote && prod.order.review) {
        this.useName = prod.order.review.author !== 'Utente sposo';
        this.objVote = {...prod.order.review, desc: prod.order.review.description};
      } else if (!this.objVote) {
        this.objVote = {id: null, vote: 0, title: '', desc: ''};
      }
      return prod as AuctionOrder;
    }
    return null;
  }

  isOrderStore(prod: StoreOrder | AuctionOrder) {
    if ('products' in prod) {
      if(this.checkFirst) {
        if (prod.reviews && prod.reviews.length > 0) {
          this.useName = prod.reviews[0].author !== 'Utente sposo';
        }
        this.checkFirst = false;
      }
      return prod as StoreOrder;
    }
    return null;
  }

  sendReview(prod: StoreOrder | AuctionOrder) {
    this.user$.subscribe(resp => {
      let votes = this.votes;
      let id = this.params.id;
      if (this.isOrderAuction(prod)) {
        votes = [this.objVote];
        id = (prod as AuctionOrder).order.id;
      }
      const body = {votes, from: (this.useName) ? resp.fullname : 'Utente sposo'};
      this.httpService.postReview(this.params.type, id, body).subscribe(() => {
        this.router.navigateByUrl('/order-detail/' + (this.params.type === 'store' ? 'order' : 'auction') + '/' + this.params.id);
        const modalRef2 = this.modalService.open(ResultReviewComponent);
        modalRef2.componentInstance.title = 'Congratulazioni';
        modalRef2.componentInstance.body = 'Salvataggio eseguito con successo';
        modalRef2.componentInstance.actionClicked.subscribe(result => {
          modalRef2.close();
          if (result === '0') {
            this.router.navigateByUrl('/vetrine-fornitori');
          } else if (result === '1') {
            this.router.navigateByUrl('/my-auctions/1');
          }
        });
      });
    });
  }

  checkReview() {
    let disabled: boolean;
    for (const vote of this.votes) {
      disabled = !vote.title || vote.vote === 0;
      if (disabled) {
        return disabled;
      }
    }
    return disabled;
  }
}
