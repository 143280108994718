import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {ISector} from '../../objects/ISector';
import {NgbAccordion, NgbDate, NgbDatepickerI18n, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IAuction} from '../../objects/IAuction';
import {Observable, of, OperatorFunction} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap, toArray} from 'rxjs/operators';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {ConfirmResetComponent} from './confirm-reset/confirm-reset.component';
import {AuctionPreviewComponent} from './auction-preview/auction-preview.component';
import {NocanAuctionsComponent} from './nocan-auctions/nocan-auctions.component';
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {Router} from '@angular/router';
import {I18n} from '../../services/datepicker/i8n.service';
import {CustomDatepickerI18n} from '../../services/datepicker/custom-datepicker-i18n.service';
import {CommunicatorService} from '../../services/communicator.service';

@Component({
  selector: 'app-new-auction',
  templateUrl: './new-auction.component.html',
  styleUrls: ['./new-auction.component.scss'],
  providers: [I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ]
})
export class NewAuctionComponent implements OnInit {
  @ViewChild(NgbAccordion) accordion: NgbAccordion;
  newAuction: Partial<IAuction> = {description: '', budget: '', title: '', other_information: {}};
  macroSectors: { idSector: string, labelSector: string; sectors: ISector[] }[] = [];
  pagesSlide: {
    options: string[], key: string, title: string; haveInputFinal: boolean;
    isCheckbox: boolean; isMultiCheckbox: boolean; isPortate?: boolean,
    checkInput?: boolean, valueInput?: string
  }[]
    = [];
  indexSelected = 0;
  widthPerson = {petto: '', vita: '', fianchi: ''};
  location: {userData: boolean, city: string} = {userData: null, city: ''};
  indexDay = null;
  indexDuration = null;
  searching = false;
  searchFailed = false;
  showImageInsert = false;
  today: NgbDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  dateSelect: NgbDateStruct;
  imageChangedEvent: any;
  imageCroppedTemp: ImageCroppedEvent;
  duration = {rangedate: '', quantitydays: '', choisestartdate: '', choiseenddate: ''};
  activeSector = '';
  displaySlide = 'block';

  imageDefaults = {
    '4': 'default/Benessere-e-salute-lei.jpg', '8': 'default/Benessere-e-salute-lui.jpg',
    '14': 'default/Macchina-fotografica.jpg', '15': 'default/Fuochi-d_artificio.jpg', '16': 'default/Musica.jpg',
    '23': 'default/Viaggio-nozze.jpg', '24': 'default/Wedding-planner.jpg', '25': 'default/Castello.jpg',
    '26': 'default/Catering.jpg', '27': 'default/Hotel.jpg', '28': 'default/Ristorante.jpg', '29': 'default/Ricevimenti.jpg'
  };

  constructor(private httpService: HttpService, private modalService: NgbModal,
              private router: Router, private communicator: CommunicatorService) {
  }

  ngOnInit(): void {
    this.httpService.canAuctions().subscribe(data => {

      if (data) {
        const stringArray: string[] = [];
        if (data['is_fullname'] !== '1') {
          stringArray.push('Nome completo');
        }
        if (data['is_phone'] !== '1') {
          stringArray.push('Numero di telefono');
        }
        if (data['is_address'] !== '1') {
          stringArray.push('Indirizzo');
        }
        if (data['is_wdate'] !== '1') {
          stringArray.push('Data delle nozze');
        }
        if (data['is_wlocality'] !== '1') {
          stringArray.push('Luogo delle nozze');
        }
        if (stringArray.length > 0) {
          const rf = this.modalService.open(NocanAuctionsComponent, {size: 'lg', backdrop:'static', keyboard: false});
          rf.componentInstance.stringArray = stringArray;
        } else {
          this.newAuction.wedding_locality = data['wedding_locality'];
          this.continueInit();
        }
      }
    });
  }

  continueInit() {
    this.httpService.getSectors().subscribe(resp => {
      for (let sector of resp) {
        const index = this.macroSectors.findIndex(e => +e.idSector === +sector.idmacro);
        if (index > -1) {
          this.macroSectors[index].sectors.push(sector);
        } else {
          let labelSector = 'Sposa';
          if (sector.idmacro === '2') {
            labelSector = 'Sposo';
          } else if (sector.idmacro === '3') {
            labelSector = 'Fornitori';
          } else if (sector.idmacro === '4') {
            labelSector = 'Ricevimento';
          } else if (sector.idmacro === '5') {
            labelSector = 'Pets Wedding';
          }
          this.macroSectors.push({idSector: sector.idmacro, labelSector, sectors: [sector]});
        }
      }
      // setTimeout(() => {
        // this.accordion.expandAll();
        if (this.communicator.auctionRepublish) {
          this.mapOldAuction(this.communicator.auctionRepublish);
          this.communicator.auctionRepublish = null;
        }
      // }, 0);
    });
  }

  prepareSlides(idSector: number) {
    if (this.newAuction.id_sector != idSector + '') {
      this.reset();
      this.newAuction.id_sector = idSector + '';
      this.newAuction.other_information = {};
    }
    this.pagesSlide = [];
    switch (idSector) {
      case 1: //abiti sposa
      case 31: //abiti sposo
        this.pagesSlide.push({
          key: 'whatNeed',
          options: ['Disegno dell abito', 'Realizzazione dell abito'],
          title: 'Cosa ti occorre?',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'whatStyle',
          options: (idSector === 31) ?
            ['Tight', 'Mezzo Tight', 'Frac', 'Smoking', 'Redingote', 'Classico']
            :
            ['Abito a sirena', 'Abito stile redingote', 'Abito stile impero', 'Abito principessa', 'Abito trasformabile (2 in 1)', 'Tailleur'],
          title: 'Che stile deve avere il tuo abito?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'whatColor',
          options: (idSector === 31) ?
            ['Bianco', 'Avorio', 'Nero', 'Rosso', 'Blu scuro', 'Panna', 'Non saprei, vorrei un consiglio dal fornitore']
            :
            ['Bianco', 'Avorio', 'Nero', 'Rosso', 'Panna'],
          title: 'Di quale colore deve essere il tuo abito?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'whatType',
          options: (idSector === 31) ?
            ['Lana', 'Misto lana', 'Seta', 'Lino', 'Lana e seta', 'Non saprei, vorrei un consiglio dal fornitore']
            :
            ['Organza', 'Raso', 'Seta', 'Tulle', 'Pizzo'],
          title: 'Quale tessuto vorresti per il tuo abito?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'addDetail',
          options: (idSector === 31) ?
            ['No', 'Pochette da uomo (Fazzoletto da taschino)', 'Pietre e cristalli', 'Ricami', 'Cilindro', 'Guanti', 'Bastone', 'Plastron di seta']
            :
            ['No', 'Strascico', 'Pietre e cristalli', 'Ricami', 'Velo', 'Diadema', 'Punto luce', 'Bustino'],
          title: 'Desideri aggiungere dettagli o accessori all\'abito?',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });

        this.pagesSlide.push({
          key: 'widthPerson1',
          options: ['36', '38', '40', '42', '44', '46', '48', '50'],
          title: 'Seleziona la tua taglia',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });


        if (idSector != 31) {
          this.pagesSlide.push({
            key: 'widthPerson2',
            options: [],
            title: 'Inserisci le tue misure',
            haveInputFinal: false,
            isCheckbox: false,
            isMultiCheckbox: false
          });
        }
        this.pagesSlide.push({
          key: 'heightPerson',
          options: [],
          title: 'Inserisci la tua altezza',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'howDressLong',
          options: ['In Acquisto', 'A Noleggio'],
          title: 'Come vorresti l’abito?',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'condition',
          options: ['Nuovo', 'Usato', 'Da sfilata', 'Da campionario', 'Collezioni passate'],
          title: 'In quali condizioni vorresti avere l’abito?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        break;
      case 25:
      case 26:
      case 28:
      case 29:
        this.pagesSlide.push({
          key: 'manyPerson',
          options: ['Meno di 25 invitati', '26-50 invitati', '51-80 invitati', '81-110 invitati', '111-150 invitati', 'Oltre 150 invitati'],
          title: 'Indica orientativamente quanti invitati saranno presenti',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'whereEvent',
          options: ['Location privata', 'Spiaggia', 'Al chiuso', 'All’aperto'],
          title: 'Dove si svolgerà l’evento?',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });
        this.pagesSlide.push({
          key: 'momentEvent',
          options: ['Pranzo', 'Cena'],
          title: 'Per quale momento della giornata ti occorre il servizio?',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });
        this.pagesSlide.push({
          key: 'typeMenu',
          options: ['Carne', 'Pesce', 'Vegetariano', 'Vegano', 'Etnico', 'Bambino'],
          title: 'Quale tipo di menù vorresti?',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });
        this.pagesSlide.push({
          key: 'numberPlates',
          options: ['Aperitivo e stuzzichini', 'Antipasti', '3 portate', '4 portate', '5 portate', 'Contorni', 'Buffet di dolci e frutta', 'Bevande alcoliche', 'Bevande analcoliche'],
          title: 'Indicare le portate che deve contenere il tuo menù',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true,
          isPortate: true
        });
        this.pagesSlide.push({
          key: 'serviceType',
          options: ['Servizio all’italiana o al piatto', 'Servizio all’inglese o al vassoio', 'Servizio alla francese (diretto)',
            'Servizio alla russa (molto scenico)', 'Buffet (unico tavolo)', 'Isole (più tavoli)'],
          title: 'Quale tipo di servizio ti occorre',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });
        break;
      case 16:

        this.pagesSlide.push({
          key: 'manyPerson',
          options: ['Meno di 25 invitati', '26-50 invitati', '51-80 invitati', '81-110 invitati', '111-150 invitati', 'Oltre 150 invitati'],
          title: 'Indica orientativamente quanti invitati saranno presenti',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'yearsPerson',
          options: ['Meno di 30 anni', '30 - 45 anni', '45 - 60', 'Oltre i 60 anni'],
          title: 'Indica l’età media delle persone presenti',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'whatNeed',
          options: ['Una band', 'Un dj', 'Cantante', 'Un duetto', 'Un quartetto', 'Un musicista', 'E’ indifferente'],
          title: 'Cosa ti occorre?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'genreMusic',
          options: ['Disco music', 'Jazz', 'Latino-americana', 'Classica', 'Cover band', 'Pop'],
          title: 'Che genere musica ti piacerebbe?',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });
        this.pagesSlide.push({
          key: 'timeNeed',
          options: ['2 - 4 ore', 'Mezza giornata', 'Tutta la durata dell’evento'],
          title: 'Per quanto tempo ti occorre?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'addServices',
          options: ['Service audio', 'Service Luci', 'Karaoke', 'Proiettore'],
          title: 'Indica eventuali servizi aggiuntivi',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });
        this.pagesSlide.push({
          key: 'whereEvent',
          options: ['Location privata', 'Spiaggia', 'Al chiuso', 'All’aperto'],
          title: 'Dove si svolgerà l’evento?',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });
        break;
      case 2:
      case 7:
        this.pagesSlide.push({
          key: 'whatAccessories',
          options: (idSector === 7) ?
            ['Scarpe', 'Guanti', 'Bastone', 'Cappello', 'Cilindro', 'Cintura', 'Papillon', 'Plastron',
              'Fazzoletto da taschino', 'Gemelli', 'Bretelle', 'Spille', 'Fascie da smoking', 'Orologio da taschino']
            :
            ['Scarpe', 'Guanti', 'Pietre e critalli', 'Cappello', 'Velo', 'Diadema', 'Punto luce', 'Borse e pochette'],
          title: 'Quali accessori stai cercando?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'whatColor',
          options: ['Bianco', 'Avorio', 'Nero', 'Rosso', 'Panna'],
          title: 'Di quale colore deve essere?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'sizeShoes',
          options: (idSector === 7) ?
            ['38', '39', '40', '41', '42', '43', '44', '45']
            :
            ['35', '36', '37', '38', '39', '40', '41', '42'],
          title: 'Seleziona la tua misura di scarpe',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        break;
      case 10:
      case 11:
      case 13:
      case 22:
        this.pagesSlide.push({
          key: 'manyPerson',
          options: ['Meno di 25 invitati', '26-50 invitati', '51-80 invitati', '81-110 invitati', '111-150 invitati', 'Oltre 150 invitati'],
          title: 'Indica orientativamente quanti invitati saranno presenti',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        if (idSector !== 13 && idSector !== 22) {
          this.pagesSlide.push({
            key: 'whereEvent',
            options: ['Location privata', 'Spiaggia', 'Al chiuso', 'All’aperto'],
            title: 'Dove si svolgerà l’evento?',
            haveInputFinal: true,
            isCheckbox: true,
            isMultiCheckbox: true
          });
        }
        break;
      case 4:
      case 8:
        let otherOptions = [];

        if (idSector === 4) {
          otherOptions.push('Ricostruzione unghie');
        }
        otherOptions.push('Vorrei un consiglio dal professionista');
        this.pagesSlide.push({
          key: 'whichService',
          options: ['Percorso benessere', 'Lampada abbronzante (bi facciale)', 'Lampada abbronzante (tri facciale)', 'Lampada abbronzante (total body)',
            'Manicure', 'Pedicure', 'Epilazione', 'Pulizia viso', 'Trucco', 'Massaggio viso', 'Massaggio rilassante', 'Massaggio drenante'].concat(...otherOptions),
          title: 'A quale servizio sei ' + ((idSector === 4) ? 'interessata' : 'interessato'),
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });
        break;
      case 6:

        this.pagesSlide.push({
          key: 'look',
          options: ['Sottoveste', 'Badyroll', 'Body', 'Pagliaccetto', 'Reggicalze', 'Guepierre', 'Perizoma', 'Giarrettiera', 'Culotte',
            'Brasiliana', 'Slip senza cuciture', 'Slip a vita alta', 'Reggiseno a fascia', 'Reggiseno a triangolo', 'Reggiseno a balconcino',
            'Kimono - vestaglia', 'Vorrei un consiglio dal professionista'],
          title: 'Scegli il look di nozze e per le occasioni particolari',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });

        this.pagesSlide.push({
          key: 'whatColor',
          options: ['Bianco', 'Avorio', 'Nero', 'Rosso', 'Panna'],
          title: 'Di quale colore deve essere?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'widthPerson1',
          options: ['36', '38', '40', '42', '44', '46', '48', '50'],
          title: 'Seleziona la tua taglia',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'widthPerson2',
          options: [],
          title: 'Inserisci le tue misure',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'heightPerson',
          options: [],
          title: 'Inserisci la tua altezza',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        break;
      case 17:
      case 18:
      case 19:
      case 20:
      case 21:
        if (idSector !== 20) {
          this.pagesSlide.push({
            key: 'whatNeed',
            options: ['Noleggio con conducente', 'Noleggio senza conducente'],
            title: 'Indica cosa ti serve',
            haveInputFinal: true,
            isCheckbox: false,
            isMultiCheckbox: false
          });
        }
        this.pagesSlide.push({
          key: 'manyPerson',
          options: ['Meno di 25 invitati', '26-50 invitati', '51-80 invitati', '81-110 invitati', '111-150 invitati', 'Oltre 150 invitati'],
          title: 'Indica orientativamente quanti invitati saranno presenti',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });


        if (idSector !== 20) {
          this.pagesSlide.push({
            key: 'quantityCars',
            options: ['1', 'Tra 2 - 5', 'Vorrei un consiglio dal professionista'],
            title: 'Quanti mezzi ti occorrono?',
            haveInputFinal: true,
            isCheckbox: false,
            isMultiCheckbox: false
          });
        }
        this.pagesSlide.push({
          key: 'whereEvent',
          options: ['Location privata', 'Spiaggia', 'Al chiuso', 'All’aperto'],
          title: 'Dove si svolgerà l’evento?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        break;
      case 5:

        this.pagesSlide.push({
          key: 'jewel',
          options: ['Fedi nuziali', 'Solitari', 'Bracciali', 'Collane', 'Orecchini',
            'Parure', 'Gemelli', 'Ferma cravatta', 'Vorrei un consiglio dal professionista'],
          title: 'Indica il gioiello a cui sei interessato',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });
        break;
      case 24:

        this.pagesSlide.push({
          key: 'styleWedding',
          options: ['Classico', 'Fiabeco', 'Fashion', 'Romantico', 'Vintage', 'Shabby', 'Rustico', 'Hawaiano', 'Country', 'Bohémien', 'In spiaggia'],
          title: 'Indica lo stile del tuo matrimonio',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'riteWedding',
          options: ['Religioso', 'Civile', 'Simbolico', 'Non lo so'],
          title: 'Indica il rito della cerimonia',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'siteWedding',
          options: ['Chiesa/Basilica', 'Sala comunale', 'Stabilimento balneare autorizzato', 'Il luogo è ancora da decidere'],
          title: 'Quale sarà il luogo della cerimonia?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'whereWedding',
          options: ['Ristorante', 'Villa', 'Castello', 'Cascina / Agriturismo', 'Borgo antico', 'Ancora da definire'],
          title: 'Dove si terrà il ricevimento?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'whereFlower',
          options: ['Cerimonia', 'Ricevimento', 'Bouquet', 'Bottoniere', 'Veicoli Sposi', 'Casa della sposa', 'Casa dello sposo',
            'Vorrei un consiglio dal professionista', 'Non sono necessari'],
          title: 'Dove desideri gli allestimenti floreali?',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });

        this.pagesSlide.push({
          key: 'needPhoto',
          options: ['Si, per l’intera giornata', 'Si, per parte della giornata', 'No, ho già provveduto'],
          title: 'Hai bisogno del servizio fotografico/video?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'needMusic',
          options: ['Si, per l’intera giornata', 'Si, per parte della giornata', 'No, ho già provveduto'],
          title: 'Hai bisogno dell’intrattenimento musicale?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'serviceWedding',
          options: ['Fotografie in formato digitale (cloud o download online)', 'Fotografie in CD, DVD o flash drive',
            'Fotografie stampate singolarmente', 'Album fotografico classico (foto stampate ed incollate)', 'Album fotografico digitale',
            'Mini album fotografici digitali (genitori e testimoni), Servizio prematrimoniale', 'Riprese aeree con drone', 'Realizzazione di video',
            'Scatti fotografici con gli invitati durante il ricevimento compreso di stampe sul posto', 'Post-produzione delle fotografie',
            'Creazione book fotografico', 'Vorrei il consiglio del professionista'],
          title: 'Quali servizi aggiuntivi vorresti avere?',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });

        this.pagesSlide.push({
          key: 'whatWedding',
          options: ['Servizio completo', 'Solo coordinamento delle attività', 'Consulenza per la scelta dei fornitori'],
          title: 'Di cosa vorresti si occupasse il professionista?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });


        this.pagesSlide.push({
          key: 'manyPerson',
          options: ['Meno di 25 invitati', '26-50 invitati', '51-80 invitati', '81-110 invitati', '111-150 invitati', 'Oltre 150 invitati'],
          title: 'Indica orientativamente quanti invitati saranno presenti',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });


        this.pagesSlide.push({
          key: 'ageChildren',
          options: ['Meno di 5', '6 - 10 anni', '11 - 13 anni', '14 - 16 anni'],
          title: 'Indica l’età media dei bambini presenti',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        break;
      case 14:
        this.pagesSlide.push({
          key: 'locationPhoto',
          options: ['Chiesa/Comune - Esterna', 'Chiesa/Comune - Ristorante', 'Preparativi sposa - Chiesa/Comune - Ristorante',
            'Preparativi sposa - Preparativi sposo - Chiesa/Comune - Ristorante', 'Preparativi sposa - Preparativi sposo - Esterna - Chiesa/Comune - Ristorante',
            'Vorrei chiedere un consiglio al professionista'],
          title: 'Indica in quali location si svolgerà il servizio fotografico',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'distancePhoto',
          options: ['Meno di 5 Km', 'Tra 6 e 15 Km', 'Tra 16 e 20 Km', 'Tra 21 e 50 Km', 'Tra 51 e 100 Km', 'Oltre i 101 Km', 'Non lo so'],
          title: 'Indica orientativamente quale distanza c’è tra una location e l’altra',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'stylePhoto',
          options: ['Stile classico o posato', 'Stile reportagistico', 'Stile bianco e nero', 'Stile boudoir', 'Stile trash the dress',
            'Stile artistico o di fine art', 'Vorrei un consiglio dal professionista'],
          title: 'Per quale stile fotografico siete orientati?',
          haveInputFinal: false,
          isCheckbox: true,
          isMultiCheckbox: true
        });

        this.pagesSlide.push({
          key: 'timePhoto',
          options: ['Solo per un breve tempo', 'Per tutta la durata dell’evento', 'Fino al taglio della torta', 'Oltre la fine dell’evento',
            'Non lo so, vorrei un consiglio dal professionista'],
          title: 'Per quanto tempo hai bisogno del fotografo?',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'serviceWedding',
          options: ['Fotografie in formato digitale (cloud o download online)', 'Fotografie in CD, DVD o flash drive',
            'Fotografie stampate singolarmente', 'Album fotografico classico (foto stampate ed incollate)', 'Album fotografico digitale',
            'Mini album fotografici digitali (genitori e testimoni), Servizio prematrimoniale', 'Riprese aeree con drone', 'Realizzazione di video',
            'Scatti fotografici con gli invitati durante il ricevimento compreso di stampe sul posto', 'Post-produzione delle fotografie',
            'Creazione book fotografico', 'Vorrei il consiglio del professionista'],
          title: 'Quali servizi aggiuntivi vorresti avere?',
          haveInputFinal: true,
          isCheckbox: true,
          isMultiCheckbox: true
        });
        this.pagesSlide.push({
          key: 'manyPhoto',
          options: ['Nessuna', 'Meno di 20', 'Tra 21 - 50', 'Tra 51 - 100', 'Tra 101 - 200', 'Oltre 200', 'Vorrei un consiglio dal professionista'],
          title: 'Quante fotografie post prodotte vorreste avere?',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'manyPerson',
          options: ['Meno di 25 invitati', '26-50 invitati', '51-80 invitati', '81-110 invitati', '111-150 invitati', 'Oltre 150 invitati'],
          title: 'Indica orientativamente quanti invitati saranno presenti',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        break;

      case 23:

        this.pagesSlide.push({
          key: 'locationTravel',
          options: ['Si, ho in mente una meta ben precisa, vorrei effettuare il seguente tour o recarmi nelle seguenti località',
            'No, non ho in mente una destinazione particolare'],
          title: 'Hai già in mente una meta da raggiungere?',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'personTravel',
          options: ['1 persona', '2 persone', '3 persone', '4 persone', '5 persone', '6 - 10 persone', 'Più di 10 persone',
            'Non conosco ancora il numero preciso'],
          title: 'Quante persone viaggeranno?',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });

        this.pagesSlide.push({
          key: 'childrenTravel',
          options: ['Si', 'No', 'Neonati', '1-5 anni', '6-10 anni', '11-13 anni', '14-16 anni'],
          title: 'Indicare se ci sono bambini e la loro età media',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });


        this.pagesSlide.push({
          key: 'timeTravel',
          options: ['Weekend', '1 settimana', '7-10 giorni', '2/3 settimane', 'Più di 3 settimane',
            'Non lo so, vorrei un consiglio dal professionista'],
          title: 'Quanto durerà il tuo viaggio',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'typeTravel',
          options: ['Città', 'Mare', 'Montagna', 'Collina', 'Pianura', 'Crociera', 'Safari', 'Mete esotiche', 'Mete orientali', 'Giro del mondo',
            'Non lo so, vorrei vagliare diverse alternative'],
          title: 'Che tipo di viaggio vuoi fare?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'howTravel',
          options: ['Auto/moto', 'Aereo', 'Nave'],
          title: 'Come vorresti effettuare i vari spostamenti?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'whereTravel',
          options: ['Hotel', 'Agriturismo', 'Bed and breakfast', 'Casa/villa', 'Campeggio',
            'Crociera', 'Non lo so, vorrei un consiglio dal professionista'],
          title: 'Dove vorresti soggiornare?',
          haveInputFinal: false,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        break;
      case 27:

        this.pagesSlide.push({
          key: 'manyRoomsHotel',
          options: ['1 camera', '2 camere', '3 camere', '4 camere', '5 camere'],
          title: 'Quante camere occorono?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        this.pagesSlide.push({
          key: 'manyPersonHotel',
          options: ['1 persona', '2 persone', '3 persone', '4 persone', '5 persone', '6 persone', '7 persone'],
          title: 'Per quante persone occorrono le camere?',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        });
        break;
    }

    if (this.imageDefaults[String(idSector)] == null) {
      this.showImageInsert = true;
    } else {
      this.newAuction.image = {imgname: this.imageDefaults[String(idSector)]};
      this.showImageInsert = false;
    }
    if (this.newAuction.id == null) {
      // setTimeout(() => {
        this.goNext();
      // }, 0);
    }
  }

  checkArray(key: string, option: string) {
    if (!this.newAuction.other_information[key]) {
      this.newAuction.other_information[key] = [option];
    } else {
      const index = (this.newAuction.other_information[key] as string[]).findIndex(e => e === option);
      if (index > -1) {
        (this.newAuction.other_information[key] as string[]).splice(index, 1);
      } else {
        (this.newAuction.other_information[key] as string[]).push(option);
      }
    }
  }

  showModalDismiss() {
    const rf = this.modalService.open(ConfirmResetComponent, {size: 'lg'});
    rf.componentInstance.widthProgress = (this.indexSelected * 100) / (this.pagesSlide.length + 9);
    rf.componentInstance.confirm.subscribe(resp => {
      rf.dismiss();
      this.reset();
    });
  }

  checkEl(page: any, option: string) {
    this.newAuction.other_information[page.key] = option;
    page.checkInput = false;
    this.checkAccessories();
    if (page.key !== 'locationTravel') {
      this.goNext();
    }
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(term => term.length > 2),
      tap(() => this.searching = true),
      switchMap(term =>
        this.httpService.searchCity(term).pipe(
          tap((response) => this.searchFailed = response.length === 0),
          switchMap(array => array),
          map(el => el.city),
          toArray(),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );

  check(item: string) {
    this.location.city = item.split(' (')[0];
  }

  onlyPrice(event: any) {
    let key = event.key;
    if (+key >= 0 && +key <= 9 || (key === ',' && !event.target.value.includes(',')) ||
      (key === '.' && !event.target.value.includes('.')) || key === 'Backspace') {

    } else {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  checkPrice() {

    let values = this.newAuction.budget.split(',');
    let int = values[0].split('.').join('');
    let decimal = '';
    if (int.length > 3) {
      int = int.substring(0, int.length - 3) + '.' + int.substring(int.length - 3);
    }
    if (this.newAuction.budget.includes(',')) {
      if (values[1]) {
        decimal = values[1];
        if (decimal.length > 2) {
          decimal = decimal.substring(0, 2);
        }
      }
      this.newAuction.budget = int + ',' + decimal;
    } else {
      this.newAuction.budget = int;
    }
  }

  readFile(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageCroppedTemp = event;
  }

  finishCropped() {
    this.imageChangedEvent = null;
    this.newAuction.image = {data: this.imageCroppedTemp.base64};
  }

  showPreview() {
    this.newAuction.budget_num = this.newAuction.budget.split('.').join('').replace(',', '.');
    if (this.widthPerson.fianchi.length > 0 && this.widthPerson.vita.length > 0 && this.widthPerson.petto.length > 0) {
      this.newAuction.other_information['widthPerson'] = 'Petto: ' + this.widthPerson.petto + ' - Vita: ' + this.widthPerson.vita + ' - Fianchi: ' + this.widthPerson.fianchi;
    }
    if (this.location.userData) {
      this.newAuction.city = this.newAuction.wedding_locality;
    } else {
      this.newAuction.city = this.location.city;
    }
    let todayExpire = new Date();
    if (this.indexDuration === 0) {
      todayExpire.setDate(todayExpire.getDate() + 7);
    } else if (this.indexDuration === 1) {
      todayExpire.setDate(todayExpire.getDate() + 10);
    } else if (this.indexDuration === 2) {
      todayExpire.setDate(todayExpire.getDate() + 15);
    }
    this.newAuction.other_information['when'] = ['In un giorno preciso', 'Vorrei indicare il mese'][this.indexDay];
    // @ts-ignore
    this.newAuction.expire = todayExpire;
    this.newAuction.offers = '0';
    // this.newAuction.code = 'XXXXX';

    const bodyAuction = JSON.parse(JSON.stringify(this.newAuction)) as IAuction;

    for (const page of this.pagesSlide) {
      const key = page.key;
      if (page.haveInputFinal && page.checkInput && page.valueInput) {
        if (page.isMultiCheckbox) {
          if (!bodyAuction.other_information[key]) {
            bodyAuction.other_information[key] = [];
          }
          bodyAuction.other_information[key].push(page.valueInput);
        } else {
          bodyAuction.other_information[key] = page.valueInput;
        }
      }
      if (page.isMultiCheckbox && typeof this.newAuction.other_information[key] !== "string") {
        bodyAuction.other_information[key] = this.newAuction.other_information[key].join(', ');
      }
    }

    const rf = this.modalService.open(AuctionPreviewComponent, {size: 'lg'});
    rf.componentInstance.newAuction = bodyAuction;
    rf.componentInstance.confirm.subscribe(resp => {
      if (resp) {
        rf.dismiss();

        if (this.indexDuration === 0) {
          bodyAuction.expire = '7';
        } else if (this.indexDuration === 1) {
          bodyAuction.expire = '10';
        } else if (this.indexDuration === 2) {
          bodyAuction.expire = '15';
        }
        this.httpService.postAuction(bodyAuction).subscribe(resp => {
          if (resp && resp.result) {
            const mr2 = this.modalService.open(PopupComponent);
            mr2.componentInstance.title = 'Attenzione';
            mr2.componentInstance.body = 'Il tuo annuncio verrà pubblicato a breve dopo la revisione del nostro staff.' +
              'Ti verrà inviata una email appena lo pubblicheremo';
            this.router.navigateByUrl('/auctions');
          }
        });
      }
    });
  }

  private reset() {

    this.indexSelected = 0;
    this.pagesSlide = [];
    this.newAuction = {wedding_locality: this.newAuction.wedding_locality, description: '', budget: '', title: '', other_information: {}};
    this.widthPerson = {petto: '', vita: '', fianchi: ''};
    this.location = {userData: null, city: ''};
    this.indexDay = null;
    this.indexDuration = null;
  }

  setDate(dateSelect: NgbDateStruct) {
    this.newAuction.other_information['date'] = new Date(dateSelect.year, dateSelect.month - 1, dateSelect.day);
  }

  isNextDisabled(): boolean {
    if (this.indexSelected === 0) {
      return this.newAuction.id_sector == null;
    } else {
      if (this.pagesSlide[this.indexSelected - 1]) {
        const page = this.pagesSlide[this.indexSelected - 1];
        const key = page.key;
        if(page.key === 'locationTravel') {
          return (!this.newAuction.other_information[key]) || (this.newAuction.other_information[key].includes('Si, ho in mente') &&
            !this.newAuction.other_information['moreLocationTravel'])
        } else {
          if (key === 'widthPerson2') {
            return !this.widthPerson.petto || !this.widthPerson.vita || !this.widthPerson.fianchi;
          }
          return (!this.newAuction.other_information[key] || this.newAuction.other_information[key].length === 0) &&
            (!page.haveInputFinal || (page.haveInputFinal && !page.valueInput));
        }
      } else {
        if(this.indexSelected === this.pagesSlide.length + 1) {
          return !this.newAuction.description

        } else if(this.indexSelected === this.pagesSlide.length + 2) {
          return !this.location.userData && !this.location.city;

        } else if(this.indexSelected === this.pagesSlide.length + 3) {
          return this.indexDay == null

        } else if(this.indexSelected === this.pagesSlide.length + 4) {
          return !this.newAuction.other_information['date'];

        } else if(this.indexSelected === this.pagesSlide.length + 5) {
          return !this.newAuction.other_information['phone'] || this.newAuction.other_information['phone'].length < 8;

        } else if(this.indexSelected === this.pagesSlide.length + 6) {
          return !this.newAuction.budget

        } else if(this.indexSelected === this.pagesSlide.length + 7) {
          return !this.newAuction.title;

        } else if(this.indexSelected === this.pagesSlide.length + 8) {
          return this.indexDuration == null

        } else if(this.indexSelected === this.pagesSlide.length + 9) {
          return !this.newAuction.image;
        }
      }
    }
  }

  checkAccessories() {
    const page = this.pagesSlide[this.indexSelected - 1];
    if (page && page.key === 'whatAccessories') {
      const index = this.pagesSlide.findIndex(e => e.key === 'sizeShoes');
      if (this.newAuction.other_information['whatAccessories'] !== 'Scarpe') {
        this.pagesSlide[index] = {
          key: 'sizeShoes',
          options: ['XS', 'S', 'M', 'L', 'XL'],
          title: 'Seleziona la tua misura',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        };
      } else {
        this.pagesSlide[index] = {
          key: 'sizeShoes',
          options: (this.newAuction.id_sector === '7') ?
            ['38', '39', '40', '41', '42', '43', '44', '45']
            :
            ['35', '36', '37', '38', '39', '40', '41', '42'],
          title: 'Seleziona la tua misura di scarpe',
          haveInputFinal: true,
          isCheckbox: false,
          isMultiCheckbox: false
        };
      }
    }
  }

  private mapOldAuction(auctionRepublish: IAuction) {
    this.httpService.getAuctionById(auctionRepublish.id).subscribe(resp => {
      let other_information = JSON.parse(resp.other_information);
      while (typeof other_information === "string") {
        other_information = JSON.parse(other_information);
      }
      this.newAuction = {...resp, other_information};
      console.log(this.newAuction);
      const macrosector = resp.macrosector;
      this.activeSector = 'custom-panel-' + ['Sposa', 'Sposo', 'Fornitori', 'Ricevimento', 'Pets Wedding'].indexOf(macrosector);
      this.widthPerson = {petto: '', vita: '', fianchi: ''};
      this.location = {userData: false, city: resp.city};
      this.indexDay = ['In un giorno preciso', 'Vorrei indicare il mese'].indexOf(other_information.when);
      this.indexDuration = null;
      if (other_information.date) {
        const date = new Date(other_information.date);
        other_information.date = new Date(other_information.date);
        this.dateSelect = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
      }
      this.newAuction.image = {imgname: resp.imgname};
      this.prepareSlides(+this.newAuction.id_sector);
    });
  }

  goNext(isIncrement = true) {
    this.displaySlide = 'block';
    // setTimeout(() => {
      this.indexSelected = this.indexSelected + (isIncrement ? 1 : -1);
      // setTimeout(() => {
        this.displaySlide = 'none';
      // }, 500);
    // }, 0);
  }
}
