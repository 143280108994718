import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../services/http.service';
import {ActivatedRoute} from '@angular/router';
import {OrderDetail, State} from './orderDetail';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  type: string;
  detailObj: OrderDetail = null;
  ordered = null;
  id = null;
  received: string = null;
  states: string[] = [];
  speditionOrder: State = null;
  constructor(private httpService: HttpService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.type === 'order') {
      this.httpService.getOrderDetail(this.id).subscribe(resp => {
        this.detailObj = resp;
        const state = resp.states.find(e => e.state_order.toUpperCase() === 'PAGATO');
        if (state) {
          this.speditionOrder = resp.states.find(e => e.state_order.toUpperCase() === 'SPEDITO');
          this.ordered = state.timestamp;
          const state2 = resp.states.find(e => e.state_order.toUpperCase() === 'RICEVUTO');
          if (state2) {
            this.received = state2.timestamp;
          }
        }
        this.states = resp.states.map(e => e.state_order.toUpperCase());
      });
    } else if (this.type === 'auction') {
      this.httpService.getOfferOrderDetail(this.id).subscribe(resp => {
        // @ts-ignore
        this.detailObj = {states: resp.states, products: [resp.order]};
        const state = resp.states.find(e => e.state_order.toUpperCase() === 'PAGATO');
        if (state) {
          this.speditionOrder = resp.states.find(e => e.state_order.toUpperCase() === 'SPEDITO');
          this.ordered = state.timestamp;
          const state2 = resp.states.find(e => e.state_order.toUpperCase() === 'RICEVUTO');
          if (state2) {
            this.received = state2.timestamp;
          }
        }
        this.states = resp.states.map(e => e.state_order.toUpperCase());
      });
    }
  }
  tracciaPacco(numero, corriere) {
    let url = '';
    switch (corriere.toUpperCase()) {
      case 'BARTOLINI':
        url = 'https://vas.brt.it/vas/sped_det_show.htm?brtCode=' + numero +
          '&RicercaBrtCode=Ricerca&referer=sped_numspe_par.htm&nSpediz=' + numero.substring(0, 12);
        break;
      case 'UPS':
        url = 'https://www.ups.com/track?loc=it_IT&requester=WT&tracknum=' + numero + '/';
        break;
      case 'TNT':
        url = 'https://www.tnt.it/tracking/Tracking.do';
        break;
      case 'DHL':
        url = 'https://www.dhl.com/it-it/home/tracciabilita.html?tracking-id=' + numero;
        break;
      case 'SDA':
        url = 'https://www.sda.it/wps/portal/Servizi_online/dettaglio-spedizione?locale=it&tracing.letteraVettura=' + numero;
        break;
    }
    if (url) {
      window.open(url, '_blank');
    }
  }
}
