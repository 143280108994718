import {Component, OnInit} from '@angular/core';
import {OrderDetail} from '../order-detail/orderDetail';
import {HttpService} from '../../services/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from '../../objects/Utility';
import {PopupComponent} from '../../components/popup/popup/popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {jsPDF} from 'jspdf';

@Component({
  selector: 'app-effettua-reso',
  templateUrl: './effettua-reso.component.html',
  styleUrls: ['./effettua-reso.component.scss']
})
export class EffettuaResoComponent implements OnInit {

  type: string;
  detailObj: OrderDetail = null;
  ordered = null;
  id = null;
  reasons = Utility.getReasonsReso;
  reasonReso = '';
  step: 0 | 1 = 0;
  comment = '';
  timestampResoAlready: Date = null;
  timestampResoTransaction: Date = null;
  constructor(private httpService: HttpService, private activatedRoute: ActivatedRoute,
              private modalService: NgbModal, private router: Router) {
  }

  ngOnInit(): void {

    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.type === 'order') {
      this.httpService.getOrderDetail(this.id).subscribe(resp => {
        this.detailObj = resp;
        const state = resp.states.find(e => e.state_order.toUpperCase() === 'PAGATO');
        this.ordered = state.timestamp;
        if (resp.reso && resp.reso.reason) {
          this.step = 1;
          this.reasonReso = resp.reso.reason;
          this.comment = resp.reso.comment;
          this.timestampResoAlready = resp.reso.timestamp;
          this.timestampResoTransaction = resp.reso.timestamptransaction;
        }
      });
    } else if (this.type === 'auction') {
      this.httpService.getOfferOrderDetail(this.id).subscribe(resp => {
        // @ts-ignore
        this.detailObj = {states: resp.states, products: [resp.order]};
        const state = resp.states.find(e => e.state_order.toUpperCase() === 'PAGATO');
        this.ordered = state.timestamp;
      });
    }
  }

  continue() {
    if (this.step === 0) {
      this.step++;
    } else {
      let idorder = null;
      let idorderauction = null;
      if (this.type === 'order') {
        idorder = this.id;
      } else {
        idorderauction = this.id;
      }
      const newReso = {comment: this.comment, reason: this.reasonReso, idorder, idorderauction};

      this.httpService.sendReso(newReso).subscribe(resp => {
        if (resp.result) {
          const mr2 = this.modalService.open(PopupComponent);
          mr2.componentInstance.title = 'Attenzione';
          mr2.componentInstance.body = 'Richiesta di reso inviata con successo';
          this.router.navigateByUrl('/order-detail/' + this.type + '/' + this.id);
        }
      });
    }

  }

  printEtichetta() {

    const doc = new jsPDF('l', 'px', 'a4');
    // html2canvas(stringHtml, {width: 1080, windowWidth: 1080, height: 4000, windowHeight: 4000}).then((result) => {
    doc.html(Utility.resoHtml(this.detailObj), {windowWidth: 540, width: 540}).then(resp => {
      doc.save('Reso ordine ' + this.detailObj.id + ' .pdf');
    });
  }
}
