import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-order-right',
  templateUrl: './order-right.component.html',
  styleUrls: ['./order-right.component.scss']
})
export class OrderRightComponent implements OnInit {
  @Input() orders: {label: string, key: string, value: string}[];
  @Input() order: {key: string, value: string};
  @Output() orderChange = new EventEmitter<{label: string, key: string, value: string}>();
  @Output() close = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
