<h6 *ngIf="isCart">Cod. {{product.code}} -  <label class="text-black-50 mb-0"><b>{{product.title}}</b></label></h6>

<h6 *ngIf="!isCart">{{product.title}}</h6>

<label class="text-black-50 w-100" [ngClass]="{'mb-0': isCart}">Condizione: &nbsp;<b class="text-dark">{{product.condition}}</b></label>
<div  *ngIf="product.type === '0' && isCart">
  <label class="text-black-50" [ngClass]="{'mb-0': isCart}">Quantità: &nbsp;</label>
  <b>{{product.quantity}}</b><br>
</div>

<div *ngIf="product.sizeSelected">
  <label class="text-black-50" [ngClass]="{'mb-0': isCart}" *ngIf="product.other_information.sizeAddress">Taglia: &nbsp;</label>
  <label class="text-black-50" [ngClass]="{'mb-0': isCart}" *ngIf="!product.other_information.sizeAddress">Misura: </label>
  <b>{{product.sizeSelected}}</b><br>
</div>
<div *ngIf="product.other_information.customMisure">
  <label class="text-black-50" [ngClass]="{'mb-0': isCart}" *ngIf="product.other_information.sizeAddress">Taglia: &nbsp;</label>
  <b>Su misura</b><br>
</div>
<div  *ngIf="product.type === '1'">
  <label class="text-black-50" [ngClass]="{'mb-0': isCart}">Quantità: &nbsp;</label>
  <b>1</b><br>
</div>
<div *ngIf="isCart">
  <label class="text-black-50 mb-0">Prezzo unitario: &nbsp;</label>
  <b>{{product.pay | convertPrice}}</b><br>
</div>
<!--<div *ngIf="isCart">-->
<!--  <label class="text-black-50 mb-0">Di cui IVA ({{iva}}%): &nbsp;</label>-->
<!--  <b>{{calcIva(product.pay, iva) | convertPrice}}</b><br>-->
<!--</div>-->
<div *ngIf="product.other_information.colorAddress">
  <label class="text-black-50" [ngClass]="{'mb-0': isCart}">Colore: &nbsp;</label>
  <b>{{product.other_information.colorAddress}}</b><br>
</div>

<label class="text-black-50 w-100 mb-0" [ngClass]="{'mb-0': isCart}" *ngIf="product.type === '1'">Durata noleggio giorni: &nbsp;<b
  class="text-dark">{{product.quantity}}</b></label>

<label class="text-black-50 w-100 mb-0" [ngClass]="{'mb-0': isCart}" *ngIf="product.type === '1'">Data consegna: &nbsp;<b
  class="text-dark">{{product.choisestartdate}}</b></label>

<label class="text-black-50 w-100" [ngClass]="{'mb-0': isCart}" *ngIf="product.type === '1'">Data restituzione: &nbsp;<b
  class="text-dark">{{product.choiseenddate}}</b></label>

<label class="text-black-50 w-100" [ngClass]="{'mb-0': isCart && product.type != '1'}" *ngIf="!isCart">
  Prezzo: &nbsp;
  <b class="text-dark price">{{calcPay(product) | convertPrice}}</b>
</label>

<!--<label class="text-black-50 w-100" *ngIf="product.type === '1' && !isCart">Prezzo: &nbsp;<b-->
<!--  class="text-dark price">{{calcPay(product) | convertPrice}}</b></label>-->

<label *ngIf="isCart" class="text-right w-100 text-black-50"><b style="font-size: 1.1rem;">
  {{ (calcPay(product) | convertPrice:false:true) }}
</b></label>
